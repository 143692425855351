input[type="checkbox"] {
    &.switch {
        position: absolute;
        opacity: 0;

        + div {
            vertical-align: middle;
            width: 45px;
            height: 20px;
            border-radius: 999px;
            background-color: #243e67;
            -webkit-transition-duration: 0.2s;
            transition-duration: 0.2s;
            -webkit-transition-property: background-color, box-shadow;
            transition-property: background-color, box-shadow;
            cursor: pointer;

            span {
                position: absolute;
                font-size: 1.6rem;
                color: $white;
                margin-top: 12px;

                &:nth-child(1) {
                    margin-left: 15px;
                }

                &:nth-child(2) {
                    margin-left: 55px;
                }
            }
        }

        &:checked + div {
            background-color: #243e67;
        }

        + div > div {
            float: left;
            width: 16px;
            height: 16px;
            border-radius: inherit;
            background: $white;
            -webkit-transition-timing-function: cubic-bezier(1,0,0,1);
            transition-timing-function: cubic-bezier(1,0,0,1);
            -webkit-transition-duration: 0.2s;
            transition-duration: 0.2s;
            -webkit-transition-property: transform, background-color;
            transition-property: transform, background-color;
            pointer-events: none;
            margin-top: 2px;
            margin-left: 3px;
        }

        &:checked + div > div {
            -webkit-transform: translate3d(23px, 0, 0);
            transform: translate3d(23px, 0, 0);
            background-color: $white;
        }
    }
}