@keyframes hideNav {
  0% {
    opacity: 0;
    transform: translate(-20px);
  }
  100% {
    opacity: 1;
    transform: translate(0);
  }
}

:global(.is-safari) .sidebar .sidebarLinks {
  overflow: visible;
}

.sidebar {
  position: fixed;
  flex-direction: column;
  display: flex;
  max-width: 100%;
  width: 100%;
  height: calc(100% - 72px);
  border-right: 1px solid var(--ub-border-light);
  background: var(--ub-sidebar-bg);

  opacity: 0;
  transform: translate(-100%);
  margin-top: 72px;
  overflow-x: hidden;
  /* transition: max-width 0.3s; */

  :global(.main-search-wrapper) {
    max-width: 100vw;
  }

  &.toggled {
    animation: 0.2s forwards alternate hideNav;
  }

  .sidebarTop {
    width: 16rem;
    max-width: 5rem;
    display: none;
    justify-content: flex-start;
    align-items: center;
    flex-shrink: 0;
    height: 7.125rem;
    padding: 1.875rem;

    transition: height 0.2s ease;

    > a {
      position: relative;

      svg:first-child {
        position: absolute;
        bottom: 0;
        left: 0;
      }
    }
  }

  .sidebarTop > a > svg:last-child {
    display: block;
  }

  .sidebarLink,
  .sidebarDiscord > a {
    height: 2rem;
    border-radius: 0.75rem;
    padding: 0 1rem;
    font-size: 1rem;
    display: flex;
    align-items: center;
    transition: padding 0.2s;
    cursor: pointer;
    > span {
      opacity: 1;
      /* transition: opacity 0.2s ease-out; */
    }
  }

  .sidebarLinks {
    flex-grow: 1;
    overflow-y: auto;
    z-index: 1;
    scrollbar-width: none;
    border-top: 1px solid var(--ub-border-light);

    &::-webkit-scrollbar {
      display: none;
    }

    .linksGroup {
      display: flex;
      flex-direction: column;
      gap: 0.375rem;
      padding: 1rem;

      &.searchLink {
        display: none;
      }

      &:not(:last-child) {
        border-bottom: 1px solid var(--ub-border-light);
      }
    }

    .sidebarLink {
      background: var(--ub-sidebar-link-bg);
      color: var(--ub-sidebar-link-c);

      &:hover {
        cursor: pointer;
        background: var(--ub-sidebar-link-hover-bg);
      }

      &.active {
        background: var(--ub-sidebar-link-active-bg);
        color: var(--ub-sidebar-link-active-c);

        svg path {
          fill: var(--ub-sidebar-link-active-c);
        }
      }

      & i {
        width: 1rem;
        height: 1rem;
        display: flex;
        justify-content: center;
        align-items: center;

        svg {
          width: 1rem;
          height: 1rem;
        }
      }

      & span {
        white-space: nowrap;
        display: block;
        margin-inline-start: 0.75rem;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  .sidebarExtras {
    display: flex;
    flex-direction: column;
    padding: 1rem 0;
    border-top: 1px solid var(--ub-border-light);

    .sidebarBanner {
      transition: height 0.3s;
      overflow: hidden;
      flex-shrink: 0;
    }

    .sidebarDiscord {
      padding: 0 1rem;

      & a {
        display: flex;
        background: #5765f2;
        color: white;

        & img {
          width: 1rem;
          height: 1rem;
          display: flex;
          justify-content: center;
          align-items: center;
          filter: brightness(13);
        }

        & span {
          white-space: nowrap;
          display: block;
          margin-inline-start: 0.75rem;
        }
      }
    }
  }

  .sidebarBottom {
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    padding: 1rem 1.25rem;
    border-top: 1px solid var(--ub-border-light);

    .sidebarCollapse {
      display: none;
      transition: transform 0.2s ease-out;

      & svg {
        height: 1.063rem;
        width: 1.063rem;
      }
    }
  }
}

/* Tablet */

@media only screen and (min-width: 600px) and (min-height: 199px) {
  /* Sidebar open */
  .sidebar {
    position: sticky;
    max-width: 16rem;
    z-index: 6;
    opacity: 1;
    transform: translate(0);
    margin-top: 0;
    height: 100%;
    overflow-x: visible;

    .sidebarTop {
      display: flex;
    }

    .sidebarLinks > .linksGroup.searchLink {
      display: flex;
    }

    &.toggled {
      animation: none;

      .sidebarTop {
        & svg:first-child {
          display: none;
        }

        & svg:last-child {
          display: block;
        }
      }
    }

    .sidebarBottom {
      .sidebarCollapse {
        display: flex;
        transform: rotate(180deg);

        &.toggled {
          transform: rotate(0deg);
        }
      }
    }

    :global(.main-search-wrapper) {
      max-width: calc(100vw - 16rem + 1px);
    }
  }

  /* Sidebar closed */
  .sidebar:not(.toggled) {
    :global(.main-search-wrapper) {
      max-width: calc(100vw - 5rem + 1px);
    }

    animation: none;
    max-width: 5rem;

    .sidebarBottom {
      flex-direction: column-reverse;
      align-items: flex-start;
    }

    .linksGroup {
      min-width: 5rem;
      width: 5rem;
    }

    .sidebarTop {
      height: 5rem;

      & svg:first-child {
        bottom: -20px;
      }

      & svg:last-child {
        display: none;
      }
    }

    .sidebarLink > span,
    .sidebarDiscord > a > span {
      opacity: 0;
    }
  }
}

/* Desktop */
@media only screen and (min-width: 992px) {
  .sidebar {
    .sidebarBottom .sidebarCollapse {
      transform: rotate(0deg);

      &.toggled {
        transform: rotate(180deg);
      }
    }

    .sidebarTop {
      height: 5rem;

      & svg:first-child {
        display: none;
      }

      & svg:last-child {
        display: block !important;
      }
    }

    &.toggled {
      position: sticky;
      max-width: 5rem;

      .sidebarBottom {
        flex-direction: column-reverse;
        align-items: flex-start;
      }

      .sidebarTop {
        height: 5rem;

        & svg:first-child {
          bottom: -20px;
          display: block;
        }

        & svg:last-child {
          display: none !important;
        }
      }

      .sidebarLink > span,
      .sidebarDiscord > a > span {
        opacity: 0;
      }

      :global(.main-search-wrapper) {
        max-width: calc(100vw - 5rem + 1px);
      }
    }

    /* Sidebar open */

    &:not(.toggled) {
      max-width: 16rem;

      :global(.main-search-wrapper) {
        max-width: calc(100vw - 16rem + 1px);
      }

      .sidebarBottom {
        flex-direction: row;
        align-items: flex-end;
      }

      .linksGroup {
        min-width: 16rem;
        width: 16rem;
      }

      .sidebarTop {
        height: 7.125rem;

        & > a > svg:last-child {
          opacity: 1;
        }
      }

      .sidebarLink > span,
      .sidebarDiscord > a > span {
        opacity: 1;
      }
    }
  }
}

/* Overrides for themeing */

.toggleDark {
  display: none;
}

.toggleLight {
  display: block;
}

:global(.theme-dark) {
  .toggleDark {
    display: block;
  }

  .toggleLight {
    display: none;
  }
}

:global(.admin-bar ~ .main-content-section) .sidebar {
  @media only screen and (min-width: 992px) {
    max-height: calc(100vh - 20px);
    max-height: calc(100dvh - 20px);
  }
}

.sidebar {
  .sidebarLinks .accountLinks {
    display: none;

    &.loggedIn {
      display: flex;
    }
  }
}

/* If user is initially logged out, hide .logged-out-hidden. */
:global(.initial-logged-out) {
  .accountLinks.loggedOutHidden {
    display: none !important;
  }
}

:global(.initial-logged-in) {
  .accountLinks.loggedOutHidden {
    display: flex !important;
  }
}

@media only screen and (min-width: 992px) {
  .sidebar.collapsed + .main-content-div {
    width: calc(100vw - 5rem);
  }

  .sidebar.open + .main-content-div {
    width: calc(100vw - 16rem - 1px);
  }
}
