.scroll-shadow {
    position: relative;

    .shadow-bottom, .shadow-top {
        content: '';
        position: fixed;
        display: block;
        width: 100%;
        height: 3rem;
        z-index: 1;
        left: 0;
        pointer-events: none;
    }

    .shadow-bottom {
        bottom: 0;
        background: linear-gradient(0deg, var(--ub-border-light), $transparent);
    }

    .shadow-top {
        top: 0;
        background: linear-gradient(180deg, var(--ub-border-light), $transparent);
    }
}
