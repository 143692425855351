.banner-wrapper {
  width: 100%;
  padding-bottom: 3rem;

  .slick-slider {
    height: 100%;

    &:hover .slick-arrow {
      &.slick-prev {
        left: 0 !important;
      }

      &.slick-next {
        right: 0 !important;
      }
    }

    .slick-arrow {
      justify-content: center;
      align-items: center;
      width: clamp(1.5rem, 3cqw, 3rem);
      height: 7.5rem;
      z-index: 1;
      visibility: visible;
      opacity: 1;
      pointer-events: none;
      display: none !important;
      @include bg(--carousel-arrow-bg);

      @media only screen and (min-width: 768px) {
        display: flex !important;
        pointer-events: all;
      }

      &:hover {
        @include bg(--carousel-arrow-hover-bg);
      }

      &.slick-prev {
        border-radius: 0 1.25rem 1.25rem 0;
        left: -2.75rem;
        transition: left 0.2s, background-color 0.2s;

      }

      &.slick-next {
        border-radius: 1.25rem 0 0 1.25rem;
        right: -2.75rem;
        transition: right 0.2s, background-color 0.2s;

      }
    }

    .slick-list {
      height: 100%;

      .slick-track {
        height: 100%;
        padding-bottom: 0;

        .slick-slide {
          padding: 0;

          @media only screen and (min-width: 768px) {
            padding-inline: clamp(1.5rem, 3cqw, 3rem);
          }

          > div,
          > div .banner {
            height: 100%;
          }

          .banner-inner {
            height: 100%;
            overflow: hidden;

            @media only screen and (min-width: 768px) {
              border-radius: 1rem;
            }

            > a {
              height: 100%;
              cursor: pointer;
            }
          }
        }
      }
    }

    .slick-dots {
      height: 24px;
      bottom: 0;
      left: 50%;
      transform: translate(-50%, 100%);
      text-align: left;
      width: fit-content;

      @media only screen and (min-width: 768px) {
        right: 52px;
      }

      li {
        width: 14px;
        margin: 0;

        &.slick-active button::before {
          @include bg(--carousel-dots-bg);
        }

        button::before {
          top: 4px;
          right: 4px;
          opacity: 1;
          height: 8px;
          width: 8px;
          content: '';
          border-radius: 50%;
          @include css4 {
            border: 1px solid cvar(--carousel-dots-bg);
          }
        }
      }
    }
  }

  .banner-slide {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 0 1rem;
    position: relative;
    background-position: left;
    background-size: cover;

    img {
      width: auto;
      height: 100%;
      position: absolute;
      top: 0;
      pointer-events: none;

      &:first-child {
        left: 0;
      }

      &:last-child {
        right: 0;
      }
    }

    .banner-slide-content {
      z-index: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 100%;
      height: 100%;

      h2,
      h3 {
        line-height: 1;
        text-align: center;
        @include c(--homepage-banner-c);
        @include css4 {
          filter: drop-shadow(0 0 4px cvar(--homepage-banner-text-shadow));
        }
      }

      h2 {
        font-size: 1.5rem;
        margin-block-end: 10px;

        @media only screen and (min-width: 420px) {
          font-size: 2.125rem;
        }

        @media only screen and (min-width: 768px) {
          font-size: 2.5rem;
        }

        @media only screen and (min-width: 1130px) {
          h2 {
            font-size: clamp(1.5rem, 3cqw, 3rem);
          }
        }

        span {
          text-decoration: underline;
        }

        img {
          position: relative;
          display: inline-block !important;
          max-width: 30px;
          max-height: 30px;
        }
      }

      h3 {
        width: 100%;
        font-size: 1rem;
        margin-block-end: 14px;

        @media only screen and (min-width: 420px) {
          font-size: 1.125rem;
        }

        @media only screen and (min-width: 768px) {
          font-size: 1.25rem;
        }

        span {
          white-space: nowrap;
          @include c(--homepage-banner-c);
        }

        img {
          position: relative;
          display: inline-block !important;
          max-width: 18px;
          max-height: 18px;
        }
      }

      button {
        line-height: 1;
        height: 2rem;
        outline: none;
        text-transform: uppercase;
        padding: 2px 20px 0;
        border-radius: 1rem;
        font-size: 1rem;
        font-weight: 600;
        margin: 0 auto;
        border: none;
        cursor: pointer;
        @include bg(--homepage-banner-button-bg);
        @include css4 {
          filter: drop-shadow(0 0 4px cvar(--homepage-banner-button-shadow));
        }

        @media (hover: hover) {
          &:hover {
            @include bg(--homepage-banner-button-hover-bg);
          }
        }

        @media only screen and (min-width: 768px) {
          height: 2.5rem;
          border-radius: 1.25rem;
          padding: 2px 32px 0;
          font-size: 1.25rem;
        }
      }
    }

    @media only screen and (max-width: 1720px) {
      img {
        position: absolute;
      }

      .banner-slide-content {
        padding: 1rem 2rem;
      }
    }

    @media only screen and (max-width: 1400px) {
      justify-content: flex-start;

      img:first-child {
        display: none;
      }

      .banner-slide-content {
        h2,
        h3 {
          text-align: left;
        }

        button {
          margin: 0 auto 0 0;
        }
      }
    }

    @media only screen and (max-width: 500px) {
      justify-content: center;

      img {
        display: none;
      }

      .banner-slide-content {
        align-items: center;
        background-color: transparent;
        box-shadow: none;
        padding: 1rem;

        h2,
        h3 {
          text-align: center;
        }

        button {
          margin: 0 auto;
        }
      }
    }
  }
}
