.ub-track {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: space-between;
    padding: 0.125rem clamp(0.75rem, 1.5cqw, 1.5rem);
	width: 100%;
	height: auto;
	min-height: 85px;
	max-height: 85px;
	gap: 0.5rem;

	@media only screen and (min-width: 450px) {
		border-radius: 1rem;
		gap: 1rem;

		&.expanded {
			border-radius: 1rem 1rem 0 0;
		}
	}

	@media (hover) {
		&:hover {
			background: linear-gradient(-90deg, var(--track-player-hover-right-bg),  var(--track-player-hover-left-bg));

			.toggle-play-pause {
				cursor: pointer;
			}

			.ub-track-tags > a,
			.ub-track-tags > div > .ub-btn-dropdown-wrapper >,
			.ub-track-waveform {
				.ub-btn.secondary,
				.ub-btn.transparent {
					@include bg(--button-primary-bg);
					@include css4 {
						color: var(--ubbtn-color, cvar(--button-primary-c));
					}

					@media (hover: hover) {
						&:hover {
							@include bg(--button-primary-hover-bg);
						}
					}

					&:focus-visible {
						@include css4 {
							border-color: cvar(--button-uppbeat-bg);
							box-shadow: 0 0 4px cvar(--button-uppbeat-hover-bg);
						}
					}

					.ub-btn-menu-icon,
					.ub-btn-menu-icon::before,
					.ub-btn-menu-icon::after {
						@include bg(--button-primary-c);
					}
				}
			}

			.ub-track-expand-button {
				@include bg(--button-secondary-bg);
				@include css4 {
					color: var(--ubbtn-color, cvar(--button-secondary-c));
				}

				@media (hover: hover) {
					&:hover {
						@include bg(--button-secondary-hover-bg);
					}
				}

				&:focus-visible {
					@include css4 {
						border-color: cvar(--button-uppbeat-bg);
						box-shadow: 0 0 4px cvar(--button-uppbeat-hover-bg);
					}
				}

				.ub-btn-menu-icon,
				.ub-btn-menu-icon::before,
				.ub-btn-menu-icon::after {
					@include bg(--button-secondary-c);
				}
			}

			+ .ub-track-dropdown {
				background: var(--track-player-hover-right-bg);

				.ub-btn {
					@include bg(--button-primary-bg);
					@include css4 {
						color: var(--ubbtn-color, cvar(--button-primary-c));
					}

					@media (hover: hover) {
						&:hover {
							@include bg(--button-primary-hover-bg);
						}
					}

					&:focus-visible {
						@include css4 {
							border-color: cvar(--button-uppbeat-bg);
							box-shadow: 0 0 4px cvar(--button-uppbeat-hover-bg);
						}
					}

					.ub-btn-menu-icon,
					.ub-btn-menu-icon::before,
					.ub-btn-menu-icon::after {
						@include bg(--button-primary-c);
					}
				}
			}
		}
	}

	.ub-track-tags-mobile {
		display: flex;
		width: 100%;
		gap: 0.2rem;

		a {
			display: flex;
			overflow: hidden;

			&:first-child {
				flex-shrink: 0;
			}
		}
	}

	&.active {
        background: linear-gradient(-90deg, var(--track-player-hover-right-bg),  var(--track-player-hover-left-bg));

		.ub-track-tags > a,
		.ub-track-tags > div > .ub-btn-dropdown-wrapper >,
		.ub-track-waveform {
			.ub-btn.secondary,
			.ub-btn.transparent {
				@include bg(--button-primary-bg);
				@include css4 {
					color: var(--ubbtn-color, cvar(--button-primary-c));
				}

				@media (hover: hover) {
					&:hover {
						@include bg(--button-primary-hover-bg);
					}
				}

				&:focus-visible {
					@include css4 {
						border-color: cvar(--button-uppbeat-bg);
						box-shadow: 0 0 4px cvar(--button-uppbeat-hover-bg);
					}
				}

				.ub-btn-menu-icon,
				.ub-btn-menu-icon::before,
				.ub-btn-menu-icon::after {
					@include bg(--button-primary-c);
				}
			}
		}

		.ub-track-expand-button {
			@include bg(--button-secondary-bg);
			@include css4 {
				color: var(--ubbtn-color, cvar(--button-secondary-c));
			}

			@media (hover: hover) {
				&:hover {
					@include bg(--button-secondary-hover-bg);
				}
			}

			&:focus-visible {
				@include css4 {
					border-color: cvar(--button-uppbeat-bg);
					box-shadow: 0 0 4px cvar(--button-uppbeat-hover-bg);
				}
			}

			.ub-btn-menu-icon,
			.ub-btn-menu-icon::before,
			.ub-btn-menu-icon::after {
				@include bg(--button-secondary-c);
			}
		}

		.ub-track-info.has-similar {
			transition: transform 0.2s;

			@media only screen and (min-width: 992px) {
				transform: translateY(-0.5rem);
			}

			.ub-track-similar-link {
				opacity: 1;
				transition: opacity 0.1s;

				span:last-child {
					margin-inline-start: 0.25rem;

					&::before {
						transform: rotate(45deg);
						bottom: 2px;
					}
					&::after {
						transform: rotate(-45deg);
						top: 2px;
					}
				}
			}
		}

		> .ub-track-info-wrapper .ub-track-number {
			@include bg(--button-secondary-bg);

			+ .ub-track-premium {
				left: 0;

				@media only screen and (min-width: 600px) {
					left: 2rem;
				}
			}
		}

		+ .ub-track-dropdown {
			background: var(--track-player-hover-right-bg);

			.ub-btn {
				@include bg(--button-primary-bg);
				@include css4 {
					color: var(--ubbtn-color, cvar(--button-primary-c));
				}

				@media (hover: hover) {
					&:hover {
						@include bg(--button-primary-hover-bg);
					}
				}

				&:focus-visible {
					@include css4 {
						border-color: cvar(--button-uppbeat-bg);
						box-shadow: 0 0 4px cvar(--button-uppbeat-hover-bg);
					}
				}

				.ub-btn-menu-icon,
				.ub-btn-menu-icon::before,
				.ub-btn-menu-icon::after {
					@include bg(--button-primary-c);
				}
			}
		}
	}

	> div {
		display: flex;
	}

	> .ub-track-info-wrapper {
		display: flex;
		align-items: center;
		justify-content: flex-start;
		width: 100%;
		min-width: 0;
		position: relative;
		transition: max-width 0.2s;
		// content-visibility: auto;

		@media only screen and (min-width: 992px) {
			margin-inline-end: 2rem;
			max-width: 20%;
		}

		@media only screen and (min-width: 1800px) {
			max-width: 15%;
		}

		&.no-tags {
			@media only screen and (min-width: 992px) {
				max-width: 30%;
			}

			@media only screen and (min-width: 1800px) {
				max-width: 25%;
			}
		}

		> .ub-track-number {
			display: flex;
			align-items: center;
			width: 1rem;
			min-width: 1rem;
			height: 1rem;
			border-radius: 1rem;
			padding: 0;
			z-index: 1;
			margin-block-start: 2.5rem;
			margin-inline-end: -1rem;
			justify-content: center;
			font-size: 0.75rem;
			@include bg(--track-player-hover-right-bg);

			div {
				width: 1.5rem;
				display: flex;
				align-items: center;
				justify-content: center;
				font-family: $font-family-heading;
				@include c(--h2-c);
			}

			+ .ub-track-premium {
				left: 0;
			}

			@media only screen and (min-width: 600px) {
				width: 3rem;
				min-width: 3rem;
				height: 2rem;
				border-radius: 1rem 0 0 1rem;
				padding-inline-start: 0.25rem;
				margin-block-start: 0;
				justify-content: unset;
				font-size: 1rem;
				z-index: unset;

				+ .ub-track-premium {
					left: 2rem;
				}
			}
		}

		.ub-track-premium {
			display: flex;
			align-items: center;
			justify-content: center;
			box-shadow: 0 0 0 2px var(--browse-tracks-bg);
			position: absolute;
			width: 1rem;
			height: 1rem;
			top: 0;
			left: 0;
			border-radius: 50%;
			z-index: 1;
			transition: top 0.2s;
			@include bg(--track-premium-star-bg);

			@media only screen and (min-width: 500px) {
				@media (hover: hover) {
					&:hover {
						.ub-track-tooltip {
							display: flex;
						}
					}
				}
			}

			@media only screen and (min-width: 992px) {
				top: 0.5rem;
			}

			&.sfx {
				top: 0 !important;
			}
		}

		.ub-track-avatar {
			height: 3.5rem;
			width: 3.5rem;
			min-width: 3.5rem;
			@include bg(--skeleton-dark-bg);

			@media only screen and (min-width: 992px) {
				height: 4rem;
				width: 4rem;
				min-width: 4rem;
			}
		}

		.ub-track-info {
			display: flex;
			flex-direction: column;
			gap: 0.5rem;

			> div:first-child {
				display: flex;
				gap: 0.25rem;
				align-items: flex-start;
				flex-direction: column;

				a {
					&:first-child {
						width: 100%;
						max-width: max-content;
					}

					white-space: nowrap;
					overflow: hidden;
					text-overflow: ellipsis;
				}

				.ub-track-info-title {
					line-height: 1.4;
				}

				.ub-track-info-title-right {
					display: none;
				}

				@media only screen and (min-width: 500px) {
					gap: 0;

					a {
						white-space: unset;
						overflow: unset;
						text-overflow: unset;
					}

					.ub-track-info-title {
						line-height: unset;
					}

					.ub-track-info-title-right {
						display: flex;
					}
				}
			}

			.desktop {
				display: none;
			}

			.mobile {
				display: flex;

				&:last-child {
					font-size: 0.875rem;
					font-family: $font-family-primary;
					color: var(--button-primary-c);
				}
			}

			@media only screen and (min-width: 500px) {
				gap: 0;

				.desktop {
					display: flex;
				}

				.mobile {
					display: none;
				}
			}
		}
	}

	> .ub-track-tags {
		width: 100%;
		justify-content: flex-end;
		margin-inline-end: 0;
		gap: 0.5rem;
		flex-wrap: nowrap;
		align-items: flex-end;
		display: none;
		transition: min-width 0.2s;

		.ub-btn:disabled {
			opacity: 0.9;
		}

		.ub-btn .ub-btn-icon .ub-track-tag-more {
			transform: rotate(90deg);
		}

		@media only screen and (min-width: 500px) {
			display: flex;
		}

		@media only screen and (min-width: 680px) {
			margin-inline-start: auto;
		}

		@media only screen and (min-width: 992px) {
			min-width: 20%;
			width: auto;
			max-width: calc(100% - 449px);
			margin-inline-end: 1.5rem;
			justify-content: flex-start;
			margin-inline-start: 0;
		}
	}

	> .ub-track-waveform {
		align-items: center;
		gap: 0.5rem;
		display: none;
		transition: max-width 0.2s;

		> wave {
			content-visibility: auto;
			contain-intrinsic-size: 40px;
		}

		@media only screen and (min-width: 1200px) {
			display: flex;
			width: 40%;
		}

		@media only screen and (min-width: 1800px) {
			margin-inline-end: 2rem;
			width: 35%;
		}

		> div:first-child {
			width: 100%;
		}

		&.uppbeataa-playing {
			pointer-events: none;
		}

		&.downloads-page {
			display: none;
		}

	}

	> .ub-track-functionality {
		display: flex;
		justify-content: flex-end;
		align-items: center;
		gap: 0.5rem;

		@media only screen and (min-width: 992px) {
			min-width: 223px;
		}

		.ub-track-expand-button {
			@media only screen and (min-width: 500px) {
				display: none;
			}

			&.up {
				transform: rotate(180deg);
			}

			// .ub-btn-icon {
			// 	transform: scale(0.8);
			// }
		}
	}


	// OLDER CODE

	@media only screen and (min-width: 992px) {
		@media (hover: hover) {
			&:hover {
				.ub-track-avatar {
					> div {
						cursor: pointer;
					}
				}

				.ub-track-info.has-similar {
					transform: translateY(-0.5rem);
					.ub-track-similar-link {
						opacity: 1;
						transition: opacity 0.1s;

						span:last-child {
							margin-inline-start: 0.25rem;

							&::before {
								transform: rotate(45deg);
								bottom: 2px;
							}
							&::after {
								transform: rotate(-45deg);
								top: 2px;
							}
						}
					}
				}
			}

			.ub-track-play-icon,
			.ub-track-pause-icon,
			.ub-track-load-icon {
				display: block;
			}
		}
	}

	&.ub-track-downloads-page {
		margin-block-end: 0.75rem;

		@media only screen and (min-width: 992px) {
			margin-block-end: 0;
		}

		.ub-track-duration-download-wrapper {
			margin-inline-start: auto;
			width: auto;
			min-width: auto;

			.ub-btn {
				margin-inline-start: 0.5rem;
			}
		}

		.ub-track-premium {
			top: 0 !important;
		}
	}

	&:hover {
		@media (hover: hover) {
			.ub-track-avatar {
				.ub-track-play-icon,
				.ub-track-pause-icon,
				.ub-track-load-icon {
					opacity: 1;
				}
			}
		}
	}

	&.active {
		.ub-track-avatar {
			.ub-track-play-icon,
			.ub-track-pause-icon,
			.ub-track-load-icon {
				opacity: 1;
			}
		}
	}

	&.deleting {
        opacity: 0.5;
        pointer-events: none;
    }

	.ub-track-avatar {
		width: 3rem;
		min-width: 3rem;
		border-radius: 50%;
		overflow: visible;
		position: relative;

		.trending-icon {
			display: flex;
			justify-content: center;
			align-items: center;
			position: absolute;
			width: 1rem;
			height: 1rem;
			bottom: 0;
			right: 0;
			z-index: 1;
			border-radius: 0.125rem;
			box-shadow: 0 0 0 2px var(--browse-tracks-bg);
		}

		.artist-avatar {
			width: 100%;
			height: 100%;
			border-radius: 50%;
			overflow: hidden;
		}

		.ub-track-play-icon,
		.ub-track-pause-icon {
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			opacity: 0;
			display: none;
			border-radius: 50%;
		}

		.ub-track-load-icon {
			position: absolute;
			top: 0.5rem;
			right: 0.5rem;
			bottom: 0.5rem;
			left: 0.5rem;
			opacity: 0;
			display: none;
			border-radius: 50%;
			background-color: $white;
		}

		@media only screen and (min-width: 992px) {
			width: 3.75rem;
			min-width: 3.75rem;

			.ub-track-play-icon,
			.ub-track-pause-icon,
			.ub-track-load-icon {
				display: flex;
				justify-content: center;
				align-items: center;
			}
		}
	}

	.ub-track-play-pause-sm {
		width: 2rem;
		min-width: 2rem;
		height: 2rem;
		min-height: 2rem;
		display: flex;
		justify-content: center;
		align-items: center;
		border-radius: 50%;
		background: $white;
		z-index: 1;
		margin-inline-start: -0.75rem;

		@media only screen and (min-width: 992px) {
			display: none;
		}

		.toggle-play-pause {
			width: 100%;
			height: 100%;
			display: flex;
			align-items: center;
			justify-content: center;

			svg {
				width: 100%;
				height: 100%;
				display: flex;
				align-items: center;
				justify-content: center;
				border-radius: 50%;
				border: 1px solid var(--ub-border-light);
			}

			.ub-track-load-icon,
			.browse-player__spinner {
				width: 1rem;
				height: 1rem;
				display: flex;
				align-items: center;
				justify-content: center;
			}
		}
	}

	.ub-track-info {
		font-family: $font-family-secondary;
		padding: 0 0 0 1rem;
		min-width: 0;
		position: relative;

		@media only screen and (min-width: 992px) {
			padding: 0 1.5rem 0 1.5rem;
			min-width: 150px;
		}

		&.has-similar {
			transition: transform 0.2s;
			@media only screen and (min-width: 992px) {
				padding: 1rem 1.5rem 0 1.5rem;
				transition: transform 0.2s;
			}
		}

		.ub-track-info-title {
			font-size: 0.875rem;
			font-weight: 500;
			display: flex;
			align-items: center;
			max-width: 100%;

			.ub-track-info-title-left {
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
				@include c(--browse-tracks-title-c);
			}

			.ub-track-info-title-right {
				margin-inline-start: 0.5rem;
				width: 0.875rem;
				height: 0.875rem;
				align-items: center;
				justify-content: center;
				position: relative;

				img {
					width: 0.875rem;
					min-width: 0.875rem;
					height: 0.875rem;
					min-height: 0.875rem;
				}

				@media (hover: hover) {
					&:hover .ub-track-tooltip {
						display: flex;
					}
				}

				.ub-track-explicit-icon {
					width: 100%;
					height: 100%;
					font-size: 0.6875rem;
					font-family: $font-family-primary;
					font-weight: 500;
					border-radius: 0.25rem;
					display: flex;
					align-items: center;
					justify-content: center;
					padding-block-start: 1px;
					@include c(--explicit-icon-c);

					.ub-track-tooltip {
						@include bg(--explicit-icon-bg);
						@include c(--explicit-icon-c);

						&::after {
							@include css4 {
								border-top: 7px cvar(--explicit-icon-bg) solid;
							}
						}
					}
				}
			}
		}

		.ub-track-info-subtitle {
			white-space: nowrap;
			display: block;
			font-family: $font-family-secondary;
			font-size: 0.6875rem;
			font-weight: 400;
			max-width: 100%;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			@include c(--track-artist-c);

			@media only screen and (min-width: 992px) {
				font-size: 0.875rem;
			}
		}

		.ub-track-similar-link {
			color: $uppbeat;
			cursor: pointer;
			font-size: 0.8125rem;
			opacity: 0;
			transition: opacity 0.2s;
			display: none;
			width: fit-content;
			align-items: center;

			@media only screen and (min-width: 992px) {
				display: flex;
			}

			> span:first-child {
				white-space: nowrap;
				margin-inline-end: 0.125rem;
			}

			> span:last-child {
				width: 12px;
				height: 2px;
				background-color: $uppbeat;
				transition: margin 0.1s;
				position: relative;
				border-radius: 1px;

				&::before,
				&::after {
					content: '';
					display: block;
					width: 7px;
					height: 2px;
					position: absolute;
					right: -2px;
					background: $uppbeat;
					border-radius: 1px;
				}

				&::before {
					transform: rotate(0);
					bottom: 0;
					transition: transform 0.1s, bottom 0.1s;
				}

				&::after {
					transform: rotate(0);
					top: 0;
					transition: transform 0.1s, top 0.1s;
				}
			}
		}
	}

	.ub-track-duration-download-wrapper {
		width: auto;
		display: flex;
		justify-content: space-between;
		align-items: center;

		@media only screen and (min-width: 992px) {
			width: 25%;
			min-width: 155px;
		}
	}

	.ub-track-duration {
		margin-inline-start: 0.5rem;
		min-width: fit-content;
	}

	.ub-track-downloads-wrapper {
		margin-inline-start: 0.5rem;
		display: flex;

		.disabled {
			pointer-events: none;
		}

		.ub-track-downloads-page-wrapper {
			display: flex;
			gap: 0.5rem;

			.ub-track-credit-btn {
				display: none;

				@media only screen and (min-width: 768px) {
					display: flex;
				}
			}

			.ub-track-download-again-btn {
				padding: 0;

				.ub-btn-icon {
					margin-inline-end: 0;
				}

				.ub-btn-label {
					display: none;
				}

				@media only screen and (min-width: 768px) {
					padding: 0 1rem;

					.ub-btn-icon {
						margin-inline-end: 0.5rem;
					}

					.ub-btn-label {
						display: flex;
					}
				}
			}
		}

		> div {
			position: relative;

			@media (hover: hover) {
				&:hover .ub-track-tooltip {
					display: flex;
				}
			}
		}
	}

	.ub-track-additional-functionality {
		margin-inline-start: 0;
		display: none;

		@media only screen and (min-width: 992px) {
			display: flex;
		}
	}

	.more-info-btn .ub-btn-icon {
		transform: scale(0.9);
	}

	.more-info-btn-desktop {
		display: none;

		@media only screen and (min-width: 768px) {
			display: flex;
		}
	}

	.more-info-btn-mobile {
		display: none;

		@media only screen and (min-width: 375px) {
			display: flex;
		}

		@media only screen and (min-width: 768px) {
			display: none;
		}
	}

	.ub-track-tooltip {
		display: none;
		justify-content: center;
		align-items: center;
		position: absolute;
		bottom: calc(100% + 14px);
		right: 50%;
		transform: translateX(50%);
		width: max-content;
		height: auto;
		padding: 0.375rem 0.75rem;
		border-radius: 0.375rem;
		font-family: futura-pt, sans-serif;
		font-weight: 500;
		font-size: 0.8125rem;
		text-align: center;
		@include bg(--tooltip-bg);
		@include c(--tooltip-c);

		&::after {
			content: "";
			display: block;
			pointer-events: none;
			position: absolute;
			top: 100%;
			right: 50%;
			transform: translate(50%);
			height: 7px;
			width: 13px;
			border-left: 6px $transparent solid;
			border-right: 6px $transparent solid;
			@include css4 {
				border-top: 7px cvar(--tooltip-bg) solid;
			}
		}
	}

	.ub-track-admin-btn {
		margin-inline-start: 0.5rem;
	}

	.ub-track-remove-btn-wrapper {
		position: relative;
		margin-inline-start: 0.5rem;

		@media only screen and (min-width: 992px) {
			@media (hover: hover) {
				&:hover .ub-track-tooltip {
					display: flex;
				}
			}
		}
	}

	.ub-track-sfx-toggle.large {
		transform: scale(0.8) translateX(2rem);

		.ub-toggle-faux-radio {
			@include bg(--main-bg);
		}
	}

	+ .ub-track-dropdown {
		display: none;
		align-items: center;
		justify-content: space-between;
		position: relative;
		padding: 0.125rem clamp(0.75rem, 1.5cqw, 1.5rem);
		width: 100%;
		height: auto;
		min-height: 54px;
		max-height: 54px;
		gap: 1rem;
		border-radius: 0;
		background: transparent;
		margin-block-start: -0.5rem;

		&.expanded {
			display: flex;
		}

		@media only screen and (min-width: 450px) {
			border-radius: 0 0 1rem 1rem;
		}

		@media only screen and (min-width: 500px) {
			display: none;

			&.expanded {
				display: none;
			}
		}

		.more-info-btn-desktop {
			display: none;
		}

		.ub-track-functionality {
			display: flex;
			justify-content: flex-end;
			align-items: center;
			gap: 0.5rem;

			.ub-track-additional-functionality {
				display: flex;
				justify-content: flex-end;
				align-items: center;
				gap: 0.5rem;
			}
		}

		.ub-btn {
			@include bg(--button-secondary-bg);
			@include css4 {
				color: var(--ubbtn-color, cvar(--button-secondary-c));
			}

			@media (hover: hover) {
				&:hover {
					@include bg(--button-secondary-hover-bg);
				}
			}

			&:focus-visible {
				@include css4 {
					border-color: cvar(--button-uppbeat-bg);
					box-shadow: 0 0 4px cvar(--button-uppbeat-hover-bg);
				}
			}

			.ub-btn-menu-icon,
			.ub-btn-menu-icon::before,
			.ub-btn-menu-icon::after {
				@include bg(--button-secondary-c);
			}
		}
	}
}

.ub-track-modal .ub-modal-container-wrapper {
	display: flex !important;

	.ub-modal-container {
		padding: 3rem 1.5rem 2rem;
	}

	> div {
		display: flex;
		flex-direction: column;
		flex-grow: 1;
	}

	.ub-track-modal-top-wrapper {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		padding: 2rem 1rem 5rem;

		.ub-track-modal-top {
			max-width: 400px;
			width: 100%;
			display: flex;
			flex-direction: column;
			align-items: center;
			position: relative;

			.ub-track-premium {
				display: flex;
				align-items: center;
				justify-content: center;
				position: absolute;
				width: 1.5rem;
				height: 1.5rem;
				top: 0;
				left: calc(50% - 3rem);
				border-radius: 50%;
				background: $uppbeat;
				z-index: 1;

				img {
					width: 1rem;
					height: 1rem;
				}
			}

			.ub-track-modal-image {
				border-radius: 50%;
				width: 6rem;
				height: 6rem;
				@include bg(--skeleton-bg);
			}

			h1 {
				margin-block: 1rem;
				text-align: center;
				font-size: 2.5rem;

				+ a {
					display: flex;
					justify-content: center;
					align-items: center;
					height: 2rem;
					border-radius: 1rem;
					padding: 0 1rem;
					font-size: 0.875rem;
					white-space: nowrap;
					@include bg(--button-secondary-bg);
					@include c(--main-primary-c);
				}
			}

			> span {
				margin-block-start: 0.5rem;
				font-family: $font-family-secondary;
				font-size: 0.6875rem;
				color: $gray-medium-01;

				span {
					font-style: italic;
					color: $uppbeat;
				}
			}

			.ub-btn:first-of-type {
				margin-block-start: 1.5rem;
			}

			.ub-btn:not(:first-of-type) {
				margin-block-start: 0.5rem;
			}
		}
	}

	.ub-track-modal-bottom-wrapper {
		display: flex;
		flex-direction: column;
		flex-grow: 1;
		align-items: center;
		padding: 1rem 1rem 3rem;
		position: relative;
		@include bg(--track-modal-bottom-bg);

		.ub-track-modal-bottom {
			max-width: 400px;
			width: 100%;
			display: flex;
			flex-direction: column;
			align-items: center;
			gap: 0.5rem;

			&::before {
				content: '';
				display: block;
				width: 300%;
				height: calc(100% + 4.5rem);
				position: absolute;
				top: -2.5rem;
				border-radius: 50% 50% 0 0;
				@include bg(--track-modal-bottom-bg);
			}

			> a {
				width: 100%;
			}

			.ub-track-modal-button-group {
				display: flex;
				gap: 0.375rem;
				width: 100%;
				margin-block-start: 1.5rem;

				> a:first-child {
					width: 100%;
				}
			}
		}
	}
}

.more-info-btn-desktop-dropdown {
	.ub-btn .ub-btn-label {
		margin-inline-end: auto;
	}

	> div {
		display: flex;
	}
}

.my-download-container, .favourites-container {
	.page-title {
		text-align: center;

		@media only screen and (min-width: 500px) {
			text-align: left;
		}
	}
}
