.ub-sidebar-banner {
    position: relative;
    transition: height 0.5s;
    z-index: 1;
    border-right: 1px solid cvar(--ub-border-light);

    &.visible {
        height: calc(80px + 0.75rem);
    }

    &.hidden {
        height: 0;
    }

    > .ub-btn {
        position: absolute;
        top: 10px;
        right: calc(1rem + 7px);
        background: $transparent;
        z-index: 1;

        &.menu.close .ub-btn-menu-icon::before,
        &.menu.close .ub-btn-menu-icon::after {
            background: $white;
        }

        @media (hover: hover) {
            &:hover {
                background: rgba($white, 0.2);
            }
        }
    }

    .slick-slider {
        .slick-list .slick-track {
            padding: 0;

            .slick-slide {
                padding: 0 1rem;
            }
        }

        .slick-dots {
            width: 2.5rem;
            height: 1.25rem;
            right: calc(1rem + 10px);
            bottom: 10px;
            display: flex !important;

            li {
                height: 1.25rem;
                padding: 0;
                width: 1.25rem;
                margin: 0;
                border-radius: 50%;

                button {
                    height: 1.25rem;
                    width: 1.25rem;
                    padding: 0;

                    &::before {
                        width: 0.625rem;
                        height: 0.625rem;
                        content: '';
                        opacity: 1;
                        top: 0.3125rem;
                        left: 0.3125rem;
                        border-radius: 50%;
                        border: 2px solid $white;
                    }
                }

                &.slick-active button::before {
                    background: $white;
                }

                @media (hover: hover) {
                    &:hover {
                        background: rgba($white, 0.2);
                    }
                }
            }
        }
    }

    .ub-sidebar-banner_slide {
        height: 80px;

        > div {
            height: 80px;
            padding: 1rem 2.5rem 1rem 1rem;
            display: flex;
            align-items: center;
            border-radius: 0.625rem;

            > span {
                font-family: $font-family-primary;
                font-size: 1rem;
                line-height: 1.25;
                font-weight: 500;
                color: $white;

                img {
                    display: inline-block;
                }
            }
        }
    }
}
