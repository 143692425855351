/*
    Available weights:
    400
    400 italic
    500
    700
*/
$font-family-primary: futura-pt, sans-serif;
// $font-family-primary: Lexend, sans-serif;

/*
    Available weights:
    400
    400 italic
    500
    500 italic
*/
$font-family-secondary: roboto, sans-serif;
// $font-family-secondary: Lexend, sans-serif;

/*
    Available weights:
    normal (displays as Black)
*/
$font-family-heading: var(--font-heading), "brandon-grotesque", sans-serif;

:root {
    --font-family-primary: #{$font-family-primary};
    --font-family-secondary: #{$font-family-secondary};
    --font-family-heading: #{$font-family-heading};
}

h1 {
	font-size: 2.5rem;
    @include c(--h1-c);
    @media only screen and (min-width: 768px) {
        font-size: 3.5rem;
    }
}

h2 {
    font-size: 1.5rem;
    @include c(--h2-c);
}

h3 {
    font-size: 1.125rem;
    @include c(--h3-c);
}

h4 {
    font-size: 1rem;
    @include c(--h4-c);
}

p {
    @include c(--p-c);
}

h1, h2, h3 {
    line-height: 1;
    font-family: $font-family-heading;
    font-weight: normal;
}

h4, h5, h6 {
    line-height: 1;
    font-weight: 500;
    font-family: $font-family-primary;
}

h1, h2, h3, h4, h5, h6, p {
    span, a {
       color: inherit;
    }
}

a {
    text-decoration: none;

    &:hover {
        text-decoration: none;
    }
}

.track-container .page-title.page-title-hero {
    flex-direction: column;
    align-items: flex-start;

    .browse-title-wrapper {
        @media only screen and (max-width: 768px) {
            align-self: center;
        }

        .browse-title-options {
            align-items: center;

            @media only screen and (max-width: 768px) {
                justify-content: center;
            }
        }

        + .browse-title-waveform {
            margin-block-start: 3rem;
            width: 100%;

            .waveform-track .waveform-ready > wave canvas {
                width: 100% !important;
            }
        }
    }
}

.browse-heading {
    margin-block-end: 1rem;
    display: inline-flex;
    align-items: center;
}

.browse-subheading {
    margin-block-end: 1rem;
    font-size: 1.25rem;
    display: inline-flex;

    @media only screen and (min-width: 768px) {
        font-size: 1.5rem;
    }
}

.browse-text {
    font-family: $font-family-secondary;
    font-size: 0.875rem;
    max-width: 32rem;
    @include c(--main-primary-c);
}

.page-title {
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 5rem clamp(1.5rem, 3vw, 3rem) clamp(1.5rem, 3cqw, 3rem);

    @media only screen and (min-width: 600px) {
        align-items: flex-start;
    }

    &.width-1200 h1 {
        width: 100%;
        max-width: 1200px;
        margin: 0 auto;
    }

    .page-heading {
        &.centered {
            text-align: center;

            @media only screen and (min-width: 768px) {
                text-align: left;
            }
        }

        + .page-subheading {
            margin-block-start: 1rem;
            line-height: 1.375;
        }

        ~ .page-premium-subheading {
            margin-block-start: 1.25rem;
            width: 100%;
            line-height: 1.5;

            span {
                margin-inline: 0.5rem;
                color: unset;
                white-space: nowrap;;
            }

            img {
                margin: 0;
            }
        }
    }

    .tag-page-subheading {
        margin-top: 1.5rem;
        font-family: $font-family-secondary;
        max-width: 800px;
        text-align: center;
        @include c(--main-primary-c);

        @media only screen and (min-width: 768px) {
            text-align: left;
        }
    }

    .tag-page-feature-list {
        margin-top: 1.5rem;
        list-style: none;
        display: flex;
        flex-wrap: wrap;
        column-gap: 1.25rem;
        row-gap: 0.25rem;
        align-items: center;
        justify-content: center;

        li {
            display: inline-flex;
            font-weight: bold;

            img {
                margin-right: 5px;
                width: 1.25rem;
            }
        }

        @media only screen and (min-width: 768px) {
            justify-content: flex-start;
        }
    }

    // For page titles with images to the left
    &.page-title-hero {
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;

        .hero-heading {
            text-align: center;
        }

        @media only screen and (min-width: 768px) {
            flex-direction: row;

            .hero-heading {
                text-align: left;
            }
        }

        > img {
            border-radius: 50%;
            height: 11.75rem;
            width: 11.75rem;
            margin-block-end: 1.5rem;

            @media only screen and (min-width: 768px) {
                margin-block-end: 0;
                margin-inline-end: 2rem;
            }
        }

        &.square > img {
            border-radius: 0.5rem;
        }

        .hero-title {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            .hero-profile {
                display: flex;
                gap: 0.5rem;
                align-items: center;
                margin-block-end: 1rem;
                flex-wrap: wrap;
                justify-content: center;

                .hero-name-strip {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;

                    img {
                        width: 2rem;
                        height: 2rem;
                        border-radius: 50%;
                        z-index: 1;
                    }

                    + div {
                        display: flex;
                        gap: 0.5rem;
                    }

                    div {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        height: 2rem;
                        border-radius: 0 1rem 1rem 0;
                        padding: 0 1rem 0 1.75rem;
                        margin-inline-start: -1rem;
                        font-size: 0.875rem;
                        white-space: nowrap;
                        @include bg(--button-secondary-bg);
                        @include c(--main-primary-c);
                    }
                }

                .hero-name-youtube {
                    svg {
                        transform: scale(1.3);
                    }
                }
            }

            .hero-heading {
                margin-block-end: 1rem;
                text-align: center;
            }

            .hero-text {
                font-family: $font-family-secondary;
                font-size: 0.875rem;
                max-width: 32rem;
                text-align: center;
                @include c(--main-primary-c);

                @media only screen and (min-width: 768px) {
                    text-align: left;
                }
            }

            @media only screen and (min-width: 768px) {
                align-items: flex-start;

                h1.hero-heading {
                    text-align: left;
                }
            }
        }
    }

    &.page-title-center {
        align-items: center;
        text-align: center;
    }
}

//Affiliate program page span override
.Apple-tab-span,
.Apple-tab-span:hover {
    @include c(--p-c);
}
