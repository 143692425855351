// Hero
//
// Hero styles/sizes

.hero {
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    padding: 20px;
    color: $white;

    @media only screen and (min-width: 420px) {
        height: 370px;
    }

    @media only screen and (min-width: 768px) {
        padding: 40px 0;
        margin-bottom: 0;
    }
}

.hero--styles {
    padding: 20px 0;
    min-height: 30vh;

    @media only screen and (min-width: 992px) {
        padding: 40px 0;
        min-height: 0;
    }
}

.hero--secondary {
    position: relative;
    padding: 40px 20px;
    background-position: center;
    background-size: cover;
    height: inherit;
    z-index: 0;

    @media only screen and (min-width: 768px) {
        padding: 80px 20px;
        max-height: 275px;
    }
}

.signin-hero {
    margin-bottom: 0 !important;
    position: relative;
    // height: calc(100vh - 53px - 155px) !important;
}

.hero-blog {
    @include heroAlternate('https://cdn.musicvine.com/images/placeholder/blogpost-header.jpg');
    background-position: center;
    min-height: 0 !important;
    padding: 2rem 0 3.5rem;
}

.hero-blog.blank {
    height: auto;
    margin-bottom: 0;

    .hero-blog-content {
        position: static;
        width: 90%;
        padding: 80px 0;
        margin: 0 auto;
        text-align: center;
        bottom: inherit;
        right: inherit;
        transform: inherit;

        @media only screen and (min-width: 992px) {
            padding: 140px 0;
        }
    }
}

.hero-blog__no-header-padding .hero-blog.blank .hero-blog-content {
    @media only screen and (min-width: 992px) {
        padding: 140px 0 40px;
    }
}

// Main
// ---------------------------------------------------------

.hero__main {
    max-width: 580px;

    @media only screen and (min-width: 768px) {
        width: inherit;
    }
}

// Content
// ---------------------------------------------------------

.hero__content {
    position: relative;
    z-index: $z-index-hero-content;
    width: 100%;
    text-align: center;

    @media only screen and (min-width: 768px) {
        width: inherit;
        text-align: left;
    }
}

.hero__text {
    line-height: 1.5;
    text-align: left;
    font-size: 13.5px;
}

.hero__text--secondary {
    padding-bottom: 30px;
    text-align: center;
    font-size: 13.5px;
}

// Intro
// ---------------------------------------------------------

.hero__intro {
    text-align: center;
}

.sub-style-parent .hero__intro--secondary {
    padding: 0;
}

.hero__intro--bare {
    padding-bottom: 0;
}

// Title
// ---------------------------------------------------------

.hero__title {
    margin-bottom: 20px;
    line-height: 1;
    text-align: left;
    font-family: $font-family-heading;
    font-weight: $font-weight-bold;
    font-size: xx-large;

    &.no-style-info {
        margin-bottom: 0;
    }

    // @media only screen and (min-width: 768px) {
    //     font-size: $header-lg * 1.25;
    // }
}

.sub-style-parent .hero__title--secondary {
    margin: 0;
}

// Description
// ---------------------------------------------------------

.hero__desc {
    // font-size: $header-sm * 1.2;
    font-weight: $font-weight-light;
    opacity: 0.85;
}

// List
// ---------------------------------------------------------

.hero__list {
    font-size: 14px;

    ul {
        text-align: center;
        margin-bottom: 20px;

        @media only screen and (min-width: 420px) {
            text-align: inherit;
            margin-bottom: inherit;
        }
    }

    li {
        font-weight: $font-weight-semi-bold;

        @media only screen and (min-width: 768px) {
            padding-bottom: 20px;
        }
    }
}

.hero__list a {
    opacity: 0.8;
    color: $white;
    transition: 0.2s;

    &:hover {
        opacity: 1;
    }
}

.hero__categories {
    text-align: center;
}

.hero__categories .grid__item:last-child ul {
    margin-bottom: 0;
}

.hero__list--center {
    text-align: center;
}

// Sub
// ---------------------------------------------------------

.hero__sub {
    margin-bottom: 10px;
    text-transform: uppercase;
    letter-spacing: 0.1rem;
    font-weight: $font-weight-normal;
}

// Thumb
// ---------------------------------------------------------



// Author
// ---------------------------------------------------------

.hero__author {
    margin-bottom: 0;
    font-family: $font-family-secondary;
    font-weight: $font-weight-medium;
    font-size: 16px;
    text-align: center;
}

// Icons
// ---------------------------------------------------------

.hero__icons {
    position: relative;
    display: inline-block;
    text-align: center;

    @media only screen and (min-width: 420px) {
        margin-bottom: 40px;
    }
}

.hero__icons .hero__custom-btn {
    display: inline-flex;
    vertical-align: middle;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background: transparentize($white, 0.2);
    margin-right: 8px;
    transition: 0.2s;

    &:hover {
        opacity: 1;
    }

    @media only screen and (min-width: 768px) {
        width: 40px;
        height: 40px;
    }
}

.hero__icons--block {
    display: flex;
    visibility: hidden;
    opacity: 0;
    position: absolute;
    right: 100%;
    transition: 0.2s;
}

.hero__icons--block.active {
    visibility: visible;
    opacity: 1;
    width: auto;
    height: auto;
}

.hero__custom-btn svg path {
    fill: $gray-dark-02;
}

.st-custom-button img {
    position: relative;
    top: 1px;
}

.hero__custom-btn:hover {
    background: $white;
}

.hero__custom-btn:hover svg {
    fill: $white;
}

.hero__icons .hero__custom-btn--share {
    cursor: pointer;
    visibility: visible;
    opacity: 0.8;
    background: none;
    border: 2px solid $white;
    transition: 0.2s;

    img {
        position: relative;
        bottom: 1px;
        right: 1px;
        width: 15px;
        height: 15px;

        @media only screen and (min-width: 768px) {
            width: 20px;
            height: 20px;
        }
    }

    &:hover {
        opacity: 1;
    }

    svg path {
        fill: $white;
    }
}

.hero__icons .hero__custom-btn--share svg {
    position: relative;
    right: 1px;
}

button.following-artist {
    border: 2px solid #38add2;
    color: $free;
}

.hero__tooltip {
    display: none;
    visibility: hidden;
    opacity: 0;
    position: absolute;
    top: calc(100% + 10px);
    right: 50%;
    transform: translateX(50%);
    min-width: 200px;
    padding: 10px;
    border-radius: 10px;
    background: $white;
    color: $gray-medium-02;
    transition: 0.2s;
    z-index: 11;

    @media only screen and (min-width: 992px) {
        display: block;
    }

    &::before {
        width: 0;
        height: 0;
        border-style: solid;
        position: absolute;
        content: '\0020';
        border-width: 0 6px 6px 6px;
        border-color: $transparent $transparent $white $transparent;
        top: -6px;
        left: 50%;
        margin-left: -6px;
    }
}

// Tertiary
// ---------------------------------------------------------

.hero--tertiary {
    background: url('https://cdn.musicvine.com/images/placeholder/collections-placeholder.png') no-repeat;
    background-position: center;
    background-size: cover;
}

// Block
// ---------------------------------------------------------

.hero__block {
    display: flex;
    margin-bottom: 20px;

    a:first-child {
        order: 4;
    }
}

// Caption
// ---------------------------------------------------------

.hero-caption-small {
    display: block;
    text-transform: uppercase;
    font-family: $font-family-secondary;
    font-weight: $font-weight-light;
    font-size: 18px;
    margin-bottom: 15px;
}

.hero-caption-large {
    @extend .hero-caption-small;

    font-size: 24px;
}

// Bare
// ---------------------------------------------------------

.hero--bare {
    margin-bottom: 0;
}

// Artist
// ---------------------------------------------------------

.blog-post__share_buttons {
    width: 100%;
    text-align: center;
    margin: 0 auto 30px;
    display: flex;
    align-items: center;
    justify-content: center;

    .SocialMediaShareButton {
        margin-right: 15px;
    }

    .SocialMediaShareCount {
        margin-right: 10px;
        position: absolute;
        top: -18px;
        right: -5px;
        background: $gray-light-03;
        border-radius: 100%;
        line-height: 10px;
        text-align: center;
        padding: 10px;
        font-family: monospace;
        font-size: 13px;
        min-width: 30px;
        min-height: 30px;
    }

    .facebook_share_button {
        position: relative;
    }

    .twitter_share_button {
        position: relative;
    }

    .count {
        top: -70%;
    }
}
