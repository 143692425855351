.ub-percentage-indicator {
    width: 2rem;
    height: 2rem;
    position: relative;
    border-radius: 50%;
    overflow: hidden;
    @include bg(--percentage-indicator-outer-bg);

    > svg {
        width: 2rem;
        height: 2rem;

        g {
            circle {
                // transition: stroke 0s, stroke-dashoffset 1.5s linear;
                @include css4 {
                    stroke: cvar(--ubpercentageindicator-stroke);
                }
            }
        }
    }

    > div {
        position: absolute;
        width: 1.5rem;
        height: 1.5rem;
        top: 0.25rem;
        left: 0.25rem;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 0.75rem;
        @include bg(--percentage-indicator-inner-bg);
        @include c(--h2-c);

        > svg {
            animation: tick 0.6s forwards;

            path {
                @include f(--percentage-indicator-green);
            }
        }
    }

    &.success {
        animation: success 0.6s forwards;
    }

    &.error,
    &.error.no-percentage {
        animation: error 0.6s forwards;

        > svg g circle {
            transition: stroke 0.2s, stroke-dashoffset 0s;
            @include css4 {
                stroke: cvar(--percentage-indicator-red) !important;
            }
        }

        > div > svg {
            animation: tick 0.6s forwards;

            rect {
                @include f(--percentage-indicator-red);
            }
        }
    }

    &.medium {
        width: 3rem;
        height: 3rem;

        > svg {
            width: 3rem;
            height: 3rem;
        }

        > div {
            width: 2.5rem;
            height: 2.5rem;
            top: 0.25rem;
            left: 0.25rem;
            font-size: 0.875rem;
            font-family: $font-family-heading;
        }
    }

    &.large {
        width: 4rem;
        height: 4rem;

        > svg {
            width: 4rem;
            height: 4rem;
        }

        > div {
            width: 3rem;
            height: 3rem;
            top: 0.5rem;
            left: 0.5rem;
            font-size: 1.25rem;
            font-family: $font-family-heading;
        }
    }
}

@keyframes error {
    0% {
        @include css4 {
            outline: 0 solid cvar(--percentage-indicator-red);
        }
    }

    100% {
        outline: 1rem solid transparent;
    }
}

@keyframes success {
    0% {
        @include css4 {
            outline: 0 solid cvar(--percentage-indicator-green);
        }
    }

    100% {
        outline: 1rem solid transparent;
    }
}

@keyframes tick {
    0% {
        transform: scale(0);
        opacity: 0;
    }

    100% {
        transform: scale(2);
        opacity: 1;
    }
}
