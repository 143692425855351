// Spinner
//
// Loading spinner

$offset: 230;
$duration: 1.4s;

@keyframes rotator {
    0% {
        transform: rotate(0deg);
    }

    50% {
        transform: rotate(360deg);
    }

    100% {
        transform: rotate(0deg);
    }
}

@keyframes colors {
    0% {
        stroke: $black;
    }

    25% {
        stroke: $free;
    }

    50% {
        stroke: $black;
    }

    75% {
        stroke: $free;
    }

    100% {
        stroke: $black;
    }
}

@keyframes colors-white-red {
    0% {
        stroke: $white;
    }

    25% {
        stroke: $uppbeat;
    }

    50% {
        stroke: $white;
    }

    75% {
        stroke: $uppbeat;
    }

    100% {
        stroke: $white;
    }
}

@keyframes colors-red {
    0% {
        stroke: $black;
    }

    25% {
        stroke: $uppbeat;
    }

    50% {
        stroke: $black;
    }

    75% {
        stroke: $uppbeat;
    }

    100% {
        stroke: $black;
    }
}

@keyframes colors-light {
    0% {
        stroke: $gray-dark-02;
    }

    25% {
        stroke: $free;
    }

    50% {
        stroke: $gray-dark-02;
    }

    75% {
        stroke: $free;
    }

    100% {
        stroke: $gray-dark-02;
    }
}

@keyframes dash {
    0% {
        stroke-dashoffset: $offset;
    }

    50% {
        stroke-dashoffset: calc($offset / 4);
        transform: rotate(135deg);
    }

    100% {
        stroke-dashoffset: $offset;
        transform: rotate(450deg);
    }
}

.loading-spinner {
    animation: rotator $duration linear infinite;
}

.spinner {
    width: 40px;
    height: 40px;
    z-index: 200000;
    opacity: 1;
    visibility: visible;

    &::after {
        content: "";
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: $white;
        z-index: 20000;
    }
}

.dark-theme .spinner::after {
    background: $black !important;
}

.category-spinner {
    position: absolute;
    top: 0;
    width: 100%;

    .spinner {
        position: relative;

        &::after {
            background: $black !important;
        }
    }
}

.spinner.spinner-track {
    width: 45px;
    height: 45px;
    margin: 0;
    position: absolute;
    transform: translate(50.2%, 50.2%);

    .path {
        animation: dash $duration ease-in-out infinite, colors-light ($duration*4) ease-in-out infinite;
    }
}

.version-icon-play-wrapper .spinner.spinner-track {
    transform: translate(50.2%, 50.6%);
}

.footer-player__btn .spinner.spinner-track {
    transform: translate(32.4%, 51.1%);
}

.homepage .spinner::after,
.styles .spinner::after {
    background: $white;
}

@keyframes cssAnimation {
    0% {
        visibility: visible;
        opacity: 1;
    }

    100% {
        visibility: hidden;
        opacity: 0;
    }
}

.js-spinner-login {
    display: flex;
    flex-wrap: wrap;
    height: 40px;
    justify-content: center;
    margin-top: 15px;
    width: 100%;

    svg {
        position: relative;
    }
}

.js-spinner-add-to-playlist {
    position: relative;
    width: 100%;

    svg {
        position: absolute;
        top: 4px;
    }
}

.spinner-hidden {
    animation: cssAnimation 0.25s ease-in 0s forwards;
}

.path {
    stroke-dasharray: $offset;
    stroke-dashoffset: 0;
    transform-origin: center;
    animation: dash $duration ease-in-out infinite, colors-white-red ($duration*4) ease-in-out infinite;
}

.red .spinner-icon {
    width: 45px;
    height: 45px;

    .path {
        animation: dash $duration ease-in-out infinite, colors-red ($duration*4) ease-in-out infinite;
    }
}

.spinner-logo {
    width: 120px;
    position: fixed;
    bottom: calc(50% + 30px);
    right: 50%;
    z-index: 2000000000;
    margin: 0 -60px -50px 0;
    height: 100px;

    svg path {
        fill: $white;
    }
}

.spinner-icon {
    width: 28px;
    height: 28px;
}
