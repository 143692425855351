.DialogContent {
  background-color: var(--card-bg);
  border-radius: 10px;
  box-shadow: 0px 4px 10px 0px #0000000d;
  position: fixed;
  color: var(--card-primary-c);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90vw;
  max-width: 480px;
  max-height: 85vh;
  padding: 25px;
  animation: contentShow 150ms cubic-bezier(0.16, 1, 0.3, 1);
}
.DialogContent:focus {
  outline: none;
}

@keyframes contentShow {
  from {
    opacity: 0;
    transform: translate(-50%, -48%) scale(0.96);
  }
  to {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
  }
}

.DialogClose {
  position: absolute;
  top: 10px;
  right: 10px;
}
