.theme-dark {
    // Utility class for inverting colors in dark mode.
    .invert {
        filter: invert(1);
    }

    // Waveform
    .ub-track-waveform > div > wave > canvas,
    .waveform-track > wave > canvas,
    .ub-heading-waveform > div > wave > canvas {
        opacity: 0.4;
    }

    // Banners
    .banner-slide {
        filter: brightness(0.92);
    }

    // Needed for new track listing
    .more-info-btn-mobile .ub-btn .ub-btn-icon img {
        filter: invert(1);
    }

    .ub-heading-functionality {
        div:nth-child(2) .ub-btn .ub-btn-icon img,
        .ub-btn:last-child .ub-btn-icon img {
            filter: invert(1);
        }
        div:nth-child(3) .ub-btn .ub-btn-icon img {
            filter: brightness(0.75);
        }
    }

    .ub-track-modal-bottom a:nth-child(3) .ub-btn .ub-btn-icon img {
        filter: brightness(0.75);
    }

    .ub-track-functionality > .ub-btn .ub-btn-icon img {
        filter: invert(1);
    }

    // Playlist pages
    .playlists-container .playlists--wrapper-container .playlists--wrapper .playlists--delete--icon img,
    .ub-track-remove-btn-wrapper .ub-btn .ub-btn-icon img {
        filter: invert(1);
    }

    // Partner playlist
    .hero-profile > div:nth-child(2) > a:first-child .ub-btn .ub-btn-icon svg {
        path {
            fill: $white !important;
        }
        polygon {
            fill: $gray-dark-02 !important;
        }
    }

    // Footer player
    .player-width-controls {
        .ub-btn:first-of-type .ub-btn-icon svg path,
        .ub-btn:last-child .ub-btn-icon svg path {
            fill: $gray-light-02 !important;
        }
    }

    .ub-volume-slider-wrapper .ub-btn .ub-btn-icon img,
    .footer-track-actions .footer-player-button:first-child .ub-btn .ub-btn-icon img,
    .footer-track-actions > .ub-btn .ub-btn-icon img {
        filter: invert(1);
    }

    .footer-track-actions .footer-player-button:nth-child(2) .ub-btn .ub-btn-icon img {
        filter: brightness(0.75);
    }

    .pricing-trustpilot-reviews-wrapper::before,
    .landing-page-container .landing-page-content .trustpilot-reviews-wrapper:before {
        filter: grayscale(1) brightness(0.5);
    }

    .ub-pricing-page {
        .ub-pricing-page_platforms {
            .ub-pricing-page_platforms-static .ub-pricing-page_platform .ub-pricing-page_platform-logo svg,
            .ub-pricing-page_platforms-carousel .slick-slide .ub-pricing-page_platform-logo svg {
                filter: invert(1);
            }
        }

        .ub-pricing-page_plan-tables .ub-pricing-page_plan-toggle .ub-toggle .ub-toggle-faux-radio .ub-toggle-faux-radio-background {
            &.option-0 + label > div {
               color: $black;
            }

            &.option-2 + label + label + label > div {
                color: $black;
            }
        }
    }


    .ub-account-page_form .ub-account-page_heading {
        .ub-account-page_heading-inner {
            &::before {
                opacity: 0.4;
                filter: brightness(0);
            }
        }

        .ub-account-page_plan-details {
            .ub-account-page_plan-details_card > div {
                .StripeElement {
                    background: $white;
                }

                > div input {
                    background: $white;
                    color: $gray-dark-01;

                    &:disabled {
                        background: $gray-light-01;
                    }
                }
            }
            .ub-account-page_plan-details-inner_right {
                .ub-btn {
                    background: $white;

                    @media (hover) {
                        &:hover {
                            background: darken($white, 5%),
                        }
                    }
                }
            }

            .ub-account-page_plan-details_auto-renew .ub-toggle.normal.success {
                .ub-toggle-thumb {
                    background: $white;

                    &::before {
                        background: $uppbeat;
                    }
                }

                input:checked + .ub-toggle-thumb {
                    background: $white;

                    &::before {
                        background: $green;
                    }
                }
            }

            .ub-account-page_auto-renew-message {
                background: $gray-dark-03;;
            }
        }
    }

    .eye-svg svg {
        @media (hover) {
            &:hover {
                path {
                    filter: brightness(2);
                }
            }
        }
    }
}

.theme-light {
    .ub-table .ub-table-body .ub-table-row .ub-table-cell .not-included path {
        fill: $gray-dark-03;
    }

    .ub-carousel .slick-slider .ub-btn .ub-btn-icon img {
        filter: brightness(0.5);
    }

    .ub-pricing-page .ub-pricing-page_plan-cards .ub-pricing-page_plan-cards-inner::before {
        opacity: 0.3;
        filter: invert(1);
    }

    .ub-download-allowance .ub-download-allowance-inner .ub-download-allowance-tokens .ub-download-allowance-token > img {
        opacity: 0.1;
    }

    @media only screen and (max-width: 991px) {
        .sidebar-main .navbar .navbar-header .navbar-header-top .sidebar-logo {
            filter: brightness(5);
        }
    }

    .eye-svg svg {
        @media (hover) {
            &:hover {
                path {
                    filter: brightness(0.6);
                }
            }
        }
    }
}
