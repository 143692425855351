// Collections
//
// Collection styles

.collections {
    display: none;

    @media only screen and (min-width: 992px) {
        display: block;
        padding: 60px 0 20px; // Avoid doubling up the margin on browse-player
        background: $browse-background-color;
    }
}

// Title
// ---------------------------------------------------------

.collections__title {
    padding-bottom: 10px;
    margin-bottom: 30px;
    border-bottom: 1px solid $gray-dark-02;
    font-weight: $font-weight-normal;
    font-size: 15px;
    color: $white;

    a {
      color: $white;
    }
}

// Header
// ---------------------------------------------------------

.collections-header {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    padding: 80px 0;
    min-height: 330px;
    text-align: center;

    &-content {
        width: 80%;
        max-width: 550px;
        margin: 0 auto;
    }
}

.collections-header--secondary {
    margin-bottom: 20px;
    margin-block-start: 20px;

    @media only screen and (min-width: 992px) {
        margin-bottom: 60px;
    }
}

// Desc
// ---------------------------------------------------------

.collections-desc {
    font-size: 16px;
    color: $gray-medium-04;
}

// Title
// ---------------------------------------------------------

.collections-title {
    margin-bottom: 30px;
}

.collections-small-title {
    font-weight: $font-weight-semi-bold;
    font-size: 1.06rem;
}

// Sub
// ---------------------------------------------------------

.collections-sub {
    padding-bottom: 5px;
    margin-bottom: 30px;
    border-bottom: 1px solid $gray-dark-02;
    font-weight: $font-weight-medium;
    font-size: 18px;
    color: $white;
}

// Block
// ---------------------------------------------------------

.collections-block {
    color: $gray-medium-01;
}

.collections-block-image-wrapper {
    position: relative;

    img {
        min-width: 100%;
    }
}

// Filter
// ---------------------------------------------------------

.collection-filter {
    padding-bottom: 20px;
    margin-bottom: 20px;

    li {
        user-select: none;
    }
}

.collection-filter li {
    display: block;
    margin-bottom: 10px;

    @media only screen and (min-width: 420px) {
        display: inline-block;
        margin-bottom: inherit;
    }
}

.collection-filter-secondary li {
    display: inline-block;
}

.collection-filter a {
    border-radius: 10px;
    cursor: pointer;
    padding-top: 3.333px;
    padding-bottom: 3.333px;
    padding-left: 20px;
    padding-right: 20px;
    border: 2px solid $transparent;
    font-weight: $font-weight-medium;
    font-size: 14px;
    transition: 0.2s;

    &:hover {
        color: $uppbeat !important;
    }

    &:active {
        background: $transparent !important;
    }
}

.collection-filter-secondary {
    margin-bottom: 20px;
}

.collection-filter-secondary {
    margin: 0 auto;
    margin-bottom: 20px;

    @media only screen and (min-width: 420px) {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    @media only screen and (min-width: 768px) {
        max-width: 419px;
    }

    @media only screen and (min-width: 992px) {
        max-width: 800px;
    }
}

.collection-filter-secondary li a {
    padding: 0;
    font-size: 15px;
    font-weight: 400;
    margin: 0;
    padding-right: 5px;
}

.collection-filter a.active {
    border: 2px solid $uppbeat;
    border-radius: 10px;
    color: $uppbeat;
}

// Grid
// ---------------------------------------------------------

.collections-grid {
    @media only screen and (min-width: 992px) {
        width: 20%;
    }
}

// Breakpoints
// ---------------------------------------------------------

.collections-breakpoints {
    width: 50%;

    @media only screen and (min-width: 768px) {
        width: 33.3333%;
    }
    @media only screen and (min-width: 992px) {
        width: 25%;
    }
    @media only screen and (min-width: 1200px) {
        width: 16.66667%;
    }

    a {
        color: $gray-medium-04;

        &:hover {
            color: lighten($gray-medium-04, 15%);
        }
    }
}

// Inactive letters
// ---------------------------------------------------------

.collection-filter-secondary a.letterId--active, .collection-filter-secondary a.letterId--inactive {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding: 0;
    padding-right: 0;
    border-radius: 50%;
    width: 30px;
    height: 30px;
}

.collection-filter a.letterId--inactive {
    pointer-events: none;
    cursor: default;
    opacity: .3;
}

// Browse
// ---------------------------------------------------------

.browse-collections.unloaded img {
    border: 1px solid $browse-background-color;
}

// Tag page collections carousel
// ---------------------------------------------------------
