.partner-sumo-container {

    .page-heading {
        padding-block-end: 1.5rem;

        @media only screen and (min-width: 768px) {
            padding-left: 20px;
        }
    }

    .partner-sumo-content {
        max-width: 320px;
        padding: 0 20px;
        font-family: $font-family-secondary;

        .desc-text {
            font-size: 12px;
            font-weight: 500;
            color: $gray-medium-03;

            &.email-text {
                color: $gray-dark-02;
                text-align: center;
            }
        }

        .partner-reponse {
            display: flex;
            flex-direction: column;

            > span:nth-child(2),
            > span:nth-child(3) {
                margin-block-start: 0.75rem;
            }

            .btn-content {
                padding: 0 0.5rem;

                .ub-btn {
                    margin-block-start: 1rem;
                }
            }
        }

        .alert-general-error {
            margin: 0.75rem 0 1.25rem;
        }

        .premium-checkout-form {
            margin: 0.25rem 0 1.5rem;
        }

        .logout-btn {
            text-decoration: underline;
            cursor: pointer;
        }

        .col-margin {
            margin: 12px 0;
        }

        .tooltip-div {
            margin-right: 7px;
        }

        .aggrement-check-wrapper {
            display: flex;
            margin-block-start: 0.75rem;

            .agreement-check[type=checkbox] {
                margin: 4px 20px 0 0;
                height: 50px;
                width: 50px;

                @media only screen and (min-width: 768px) {
                    margin: 4px 10px 0 0;
                    height: auto;
                }

                + label {
                    font-size: 0.75rem;
                }
            }

            + .form-error-helper {
                padding-block-start: 0.75rem;
                padding-inline-start: 1.5rem;

                + .btn-content {
                    margin-block-start: 1.5rem;
                }

                + div {
                    text-align: center;
                    position: relative;
                    margin-block-start: 1.5rem;
                    height: 50px;
                }
            }
        }

        .captcha-text {
            text-align: left;
        }
    }

    .partner-response-ineligible {
        margin-block-end: 1.5rem;
    }
}
