$browse-info-avatar-sm-width: 40px;
$browse-info-avatar-md-width: 60px;
$browse-info-controls-width: 100px;
$browse-info-right-width: 400px;
$browse-info-styles-width: 200px;
$browse-info-versions-width: 40px;
$browse-info-actions-width: 60px;

.sfx-duration {
    @media only screen and (min-width: 992px) {
        margin-right: 3rem!important;
    }
}

.browse-player__info {
    order: 7;
    margin-right: 10px;

    @media only screen and (min-width: 992px) {
        margin-right: 1rem;
    }
}

.browse-player__version-connector {
    display: flex;
    position: relative;

    @media only screen and (min-width: 992px) {
        order: 4;
        flex-basis: $browse-info-styles-width + $browse-info-versions-width;
    }
}

.browse-player__similar {
    background: $gray-medium-03;
    border-radius: 50px;
    color: $white;
    cursor: pointer;
    display: inline-block;
    padding: 0 20px;
    line-height: 22px;
    opacity: 0;
    transition: 0.2s;
    margin-top: 11px;
    white-space: nowrap;

    span {
        display: none;
    }

    @media only screen and (min-width: 992px) {
        span {
            display: inline;
        }

    }

    .download-svg-icon {
        display: inline-block;
        height: 14px;
        margin-left: 5px;
        width: 14px;
    }

    &:hover {
        color: $white;
    }
}

// .browse-player__track:hover .browse-player__sub-license {
//     background: $browse-track-button-hover-color;
// }
// .browse-player__track .browse-player__sub-license:hover {
//     background: $version-connecting-line-color;
// }

// SpinKit - Mobile loader
// ---------------------------------------------------------

.browse-player__spinner {
  width: 20px;
  height: 20px;

  position: relative;

    .double-bounce1, .double-bounce2 {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      background-color: #232323;
      opacity: 0.7;
      position: absolute;
      top: 0;
      left: 0;

      -webkit-animation: sk-bounce 2.0s infinite ease-in-out;
      animation: sk-bounce 2.0s infinite ease-in-out;
    }

    .double-bounce2 {
      -webkit-animation-delay: -1.0s;
      animation-delay: -1.0s;
    }
}

@-webkit-keyframes sk-bounce {
  0%, 100% { -webkit-transform: scale(0.0) }
  50% { -webkit-transform: scale(1.0) }
}

@keyframes sk-bounce {
  0%, 100% {
    transform: scale(0.0);
    -webkit-transform: scale(0.0);
  } 50% {
    transform: scale(1.0);
    -webkit-transform: scale(1.0);
  }
}

.js-track-favorite-icon-control {
    &.js-is-favourite-version {
        .icon-no-fav {
            display: none;
        }
        .icon-fav {
            display: inline;
        }
    }
}
