// Blog
//
// Blog styles

// Post
// ---------------------------------------------------------

.blog-post {
    line-height: 1.8;
    max-width: 900px;
    margin: 0 auto;
    margin-bottom: 40px;
    font-family: "georgia";
    font-size: 16px;
    color: $gray-medium-01;
    min-height: 100vh;

    .bookmark-shift {
        transform: translateY(-50px);
    }

    @media only screen and (min-width: 768px) {
        margin-bottom: 80px;
    }
}

.blog-post__secondary {
    font-family: $font-family-primary;
}

.blog-post__title {
    line-height: 1;
    font-family: $font-family-secondary;
    font-style: normal;
    font-weight: $font-weight-light;
    font-size: 20px;
    margin-top: 15px;
    margin-bottom: 0;
    text-align: center;

    @media only screen and (min-width: 768px) {
        font-size: 30px;
    }
}

// Title
// ---------------------------------------------------------

.blog-title {
    padding: 120px 0;
    margin-bottom: 15px;
    text-align: center;
    font-family: $font-family-secondary;
    font-weight: $font-weight-medium;
    font-size: 25px;
    color: $black;

    @media only screen and (min-width: 768px) {
        font-size: 35px;
    }
}

.hero-blog-title {
    line-height: 1;
}

// Video
// ---------------------------------------------------------

.blog-video {
    width: 100%;
}

// Icons
// ---------------------------------------------------------

.blog-icons-fb circle {
    stroke: darken($free, 25%);
}

.blog-icons-fb path {
    fill: darken($free, 25%);
}

.blog-icons-twitter circle {
    stroke: $free;
}

.blog-icons-twitter path {
    fill: $free;
}

// Form
// ---------------------------------------------------------

.blog-form {
    padding: 40px;
    background: $gray-light-03;
    font-size: 14px;
    text-align: center;

    @media only screen and (min-width: 768px) {
        text-align: left;
    }

    textarea {
        width: 100%;
        border: 1px solid $gray-light-02;
    }
}

.blog-form-group {
    margin-bottom: 40px;
    text-align: center;

    @media only screen and (min-width: 768px) {
        text-align: left;
    }
}

.blog-form label {
    display: inline-block;
    margin-bottom: 5px;
    font-weight: $font-weight-bold;
}

.blog-form .blog-form-label-secondary {
    font-weight: $font-weight-normal;
}

.blog-input {
    display: block;
    width: 100%;
    margin: 0 auto;
    height: 50px;
    border: 1px solid $gray-light-02;
    padding-left: 15px;
    padding-right: 15px;
    font-size: 14px;
    color: $gray-medium-01;

    @media only screen and (min-width: 768px) {
        width: 280px;
        margin: inherit;
    }

}

.blog-input::-webkit-input-placeholder {
    font-size: 14px;
    font-style: italic;
    color: $gray-medium-01;
}

.blog-input::-moz-placeholder {
    font-size: 14px;
    font-style: italic;
    color: $gray-medium-01;
}

.blog-input:-ms-input-placeholder {
    font-size: 14px;
    font-style: italic;
    color: $gray-medium-01;
}

.blog-input:-moz-placeholder {
    font-size: 14px;
    font-style: italic;
    color: $gray-medium-01;
}

.blog-form-sub {
    font-size: 14px;
    margin-bottom: 20px;
}

// Content
// ---------------------------------------------------------

.blog-post__content {
    font-family: $font-family-secondary;
    font-size: 14px;
    padding: 0 10px;

    @media only screen and (min-width: 768px) {
        font-size: 16px;
    }

    > * {
        margin-left: auto;
        margin-right: auto;
        max-width: 650px;
    }

    hr {
        margin: 40px auto;
    }

    h1,h2,h3,h4,h5,h6 {
        font-family: $font-family-primary;
        font-weight: $font-weight-medium;
        margin-top: 30px;
    }

    h1 {
        font-size: 28px;
    }

    h2 {
        font-size: 28px;
        margin-block-end: 0.75rem;
        @include c(--main-primary-c)
    }

    h3 {
        font-family: $font-family-primary;
        font-size: 16px;
        font-weight: bolder;
        margin-top: 30px;
        margin-bottom: 20px;
    }

    p {
        margin-bottom: 20px;
        a {
            color: $uppbeat;
        }
    }

    img {
        margin-top: 30px;
    }

    blockquote,
    .wide-content {
        margin: 20px 0;
        max-width: 900px;
    }

    aside {
        font-size: 26px;
        font-style: italic;
        font-weight: 300;
        text-align: center;
    }

    blockquote {
        padding: 20px 0;
        text-align: center;
        font-weight: $font-weight-light;
        font-style: italic;
        font-size: 18px;
        color: $gray-medium-04;

        @media only screen and (min-width: 768px) {
            font-size: 26px;
            padding: 40px 0;
        }
    }
}

.blog-post__content hr {
    border-top: 1px solid $gray-medium-04;
}

.blog-post__indent,
.blog-post__content ul,
.blog-post__content ol {
    padding-left: 50px;
}

.blog-post__section {
    margin-block-end: 2.5rem;
}

.wufoo-container {
    position: relative;
    min-height: 200px;

    &:before {
        content: 'Loading form...';
        font-family: $font-family-primary;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: -1;
    }
}
