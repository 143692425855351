// Filter
//
// Filter styles

.filter {
    background: $breadcrumb-background;
    border-bottom: solid 1px #2f2f37;
}

// Content
// ---------------------------------------------------------

#filter-content {
    padding-bottom: 40px;

    @media only screen and (min-width: 992px) {
        padding-top: 60px;
        padding-bottom: 80px;
    }
}

// Secondary
// ---------------------------------------------------------

.filter__dropdown--secondary {
    @media only screen and (min-width: 992px) {
        background: $styles-moods-bg;
        position: absolute;
        top: 0;
        left: 100%;
        display: none;
        transition: 0.2s * 2;
        padding: 6px 0;
        width: 180px;
    }
}

.rposition .filter__dropdown--secondary {
    top: -6px;
}

.filter__dropdown--secondary-last li::after {
    content: none;
}

// Genre
// ---------------------------------------------------------

.genre-style-item {
    display: inline-block;
    margin-right: 5px;
    margin-bottom: 5px;
    padding: 5px 8px; // -2px needed to make padding exactly the same
    list-style: none;
    background: $filter-default-color;
    font-size: 12px;
    color: $filter-text-color;

    button {
        cursor: pointer;
        margin-left: 10px;
    }

    svg path {
        stroke: $filter-text-color;
    }
}

.block-count {
    color: $filter-count-color;
}

.clear-all {
    background-color: $filter-clearall-color;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    color: #979ca2;
    font-style: italic;
    margin-left: 15px;
    padding-right: 10px;
    position: relative;
    text-decoration: underline;
    z-index: 2;

    &::after {
        background-color: $filter-clearall-color;
        content: "";
        left: 0;
        position: absolute;
        height: 20px;
        top: 0;
        transform: rotate(45deg);
        transform-origin: 0 0;
        width: 20px;
        z-index: -1;
    }

    a {
        margin: 0;
    }
}

// States
// ---------------------------------------------------------

$filter-active-color: $uppbeat;
$filter-unavailable-color: #47474b;


.filter-state-text-unavailable {
    path {
        stroke: $filter-unavailable-color;
    }

    span {
        color: $filter-unavailable-color;
    }

    .clf-24 {
        stroke: none;
        fill: $filter-unavailable-color;
    }

    .st793 {
        stroke: $filter-unavailable-color;
        fill: $filter-unavailable-color;
    }

    .cls-2,
    .cls-93 {
        fill: $filter-unavailable-color;
    }
}

// latest filter design
.filter-bar {

    + .browse-player__filters {
        padding-block-start: 0;
    }

    .filter-bar-categories {
        display: flex;
        list-style: none;
        gap: 0.25rem;
        justify-content: flex-start;

        @media only screen and (min-width: 768px) {
            justify-content: flex-start;
        }

        .filter-bar-category {
            .ub-btn-icon {
                transform: rotate(90deg);
                width: 0.75rem !important;
                height: 0.75rem !important;
            }

            i, svg, path {
                width: 0.875rem !important;
                height: 0.875rem !important;
            }

            &.active {
                .ub-btn-icon {
                    transform: rotate(-90deg);
                }
            }

            .filter-category_item {
                .filter-category_item-name {
                    font-family: $font-family-secondary;
                    font-size: 0.75rem;
                    font-weight: 500;
                    text-transform: uppercase;
                    @include c(--filter-category-c);

                    @media only screen and (min-width: 768px) {
                        font-size: 0.875rem;
                    }
                }

                svg {
                    margin-top: -5px;
                    margin-left: 10px;
                    width: 8px;

                    path {
                        @include f(--filter-category-icon-fill);
                    }
                }

                &:hover {
                    .filter-category_item-name {
                        @include c(--filter-category-hover-c);
                    }

                    svg path {
                        @include f(--filter-category-icon-hover-fill);
                    }
                }
            }

            .active {
                .filter-category_item-name {
                    @include c(--filter-category-active-c);
                }

                &:hover .filter-category_item-name {
                    @include c(--filter-category-active-c);
                }

                svg path,
                &:hover svg path {
                    @include f(--filter-category-icon-focus-fill);
                }
            }

            .active-category {
                svg path,
                &:hover svg path {
                    @include f(--filter-category-icon-active-fill);
                }
            }
        }
    }

    .filter-bar-categories-options {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        @media only screen and (min-width: 768px) {
            justify-content: flex-start;
        }

        .filter-bar-categories-options-inner {
            margin: 1rem 0 0;
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-start;
            align-items: center;
            gap: 0.5rem;

            @media only screen and (min-width: 768px) {
                justify-content: flex-start;
            }

            .filter-category-options_wrapper .ub-btn-icon {
                width: 0.75rem;
                height: 0.75rem;
                min-width: 0.75rem;

                svg {
                    width: 0.75rem;
                    height: 0.75rem;
                    min-width: 0.75rem;
                }
            }
        }
    }

    + .browse-player__filters {
        justify-content: center;
    }
}
