// Overlay
//
// Overlays

.waveform-track {
  position: relative;
  width: 100%;

  &.d-none {
    display: none;
  }
}

.waveform-track-loading {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.waveform-track-banner {
  background-size: cover;
  position: relative;
  height: 4rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-inline-end: 1rem;
  width: calc(100% + 1.625rem + 120px + 1.5rem);
  margin-left: calc( -1.625rem - 120px - 1.5rem);
  padding-left: calc(1.625rem + 120px + 2rem);

  @media only screen and (min-width: 768px) {
    border-top-right-radius: 1.5rem;
    overflow: hidden;
    padding-left: calc(1.625rem + 120px + 1.5rem);
  }

  .waveform-track-banner-img-container {
    width: fill-available;
    max-width: 194px;
    position: relative;
    height: 100%;
    margin-inline-end: auto;

    > img {
      top: 50% !important;
      transform: translate(-50%, -50%);
      left: 50% !important;
      height: unset !important;
    }
  }

  > img {
    height: 100%;
    margin-inline-end: auto;

    @media only screen and (min-width: 643px) {
      margin-inline-end: 1rem;
    }

    @media only screen and (min-width: 873px) {
      margin-inline-end: 2rem;
    }
  }

  .waveform-track-banner__title {
    color: $white;
    font-family: $font-family-secondary;
    font-weight: 500;
    font-size: 0.6875rem;
    display: none;
    margin-inline-start: auto;
    text-align: right;
    padding-inline-end: 1rem;
    filter: drop-shadow(0 1px 2px rgba($black, 0.5));

    @media only screen and (min-width: 643px) {
      display: inline;
    }

    @media only screen and (min-width: 992px) {
      font-size: 0.8125rem;
    }

    @media only screen and (min-width: 1660px) {
      font-size: 1rem;
      line-height: 1.2;
    }
  }

  .waveform-track-banner-button {
    flex-shrink: 0;

    .ub-btn-icon {
      display: none;
    }

    @media only screen and (min-width: 667px) {
      .ub-btn-icon {
        display: flex;
      }
    }
  }

  + .up-next {
    flex-shrink: 0;
    align-self: center;
    margin-inline-start: 1rem;
    display: none;

    @media only screen and (min-width: 1182px) {
      display: flex;
    }
  }
}

.wave-track-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 50;
}

.spinner-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 50;
}

.disabled-button {
  // opacity: 0.5;
  pointer-events: none;
}
