body {
  .CybotCookiebotDialogContentWrapper > #CybotCookiebotDialogHeader {
    display: none;
  }

  #CybotCookiebotDialog {
    * {
      font-family: 'roboto', sans-serif;
    }

    > .CybotCookiebotDialogContentWrapper {
      display: flex;
      align-items: center;
    }
  }

  #CybotCookiebotDialogFooter > #CybotCookiebotDialogPoweredByText {
    display: none;
  }

  #CybotCookiebotDialogBodyContent #CybotCookiebotDialogBodyContentTitle {
    font-size: 18px;
    font-family: var(--font-heading) !important;
  }

  #CybotCookiebotDialog.CybotEdge
    #CybotCookiebotDialogBody
    > .CybotCookiebotDialogBodyBottomWrapper {
    margin-top: 0;
  }

  #CybotCookiebotDialog {
    border-radius: 0;

    @media screen and (min-width: 1280px) {
      .CybotCookiebotDialogContentWrapper {
        padding: 0 2rem !important;
      }
    }
  }

  #CybotCookiebotDialogFooter
    #CybotCookiebotDialogBodyButtonsWrapper
    > .CybotCookiebotDialogBodyButton {
    font-family: 'futura-pt', sans-serif !important;
    border-radius: 0.75rem;
  }

  #CybotCookiebotDialogBodyContentText,
  #CybotCookiebotDialogBodyEdgeMoreDetailsLink {
    font-family: 'roboto', sans-serif;
  }

  #CybotCookiebotDialog .CybotExternalLinkArrow {
    filter: invert(1);
  }
}
