.ub-download-allowance {
    width: 90px;
    height: 2rem;
    position: relative;

    @media (hover: hover) {
        &:hover {
            z-index: 10000;

            .ub-download-allowance-inner {
                width: 200px;
                height: 142px;
                top: 0;
                right: 0;
                padding: 10px;
                border-radius: 0.75rem;
                @include bg(--download-allowance-active-bg);
                @include css4 {
                    border: 1px solid cvar(--download-allowance-br);
                    box-shadow: 0px 0px 4px 0px cvar(--shadow-dark);
                }

                &.renewal-visible {
                    height: 164px;
                }

                .ub-download-allowance-info {
                    opacity: 1;
                    transition: opacity 0.4s 0.2s;
                }

                .ub-download-allowance-tokens .ub-download-allowance-token {
                    width: 1.5rem;
                    height: 1.5rem;
                }
            }
        }
    }

    .ub-download-allowance-inner {
        padding: 5px 10px;
        border-radius: 0.75rem;
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        position: absolute;
        top: 0;
        right: 0;
        width: 90px;
        height: 2rem;
        transition: width 0.2s, height 0.2s, box-shadow 0.2s, top 0.2s, right 0.2s, padding 0.2s, border-radius 0.2s;
        overflow: hidden;
        border: 1px solid $transparent;
        box-shadow: 0px 0px 0px 0px $transparent;
        @include bg(--download-allowance-bg);

        .ub-download-allowance-tokens {
            display: flex;
            justify-content: flex-end;
            gap: 0.25rem;

            .ub-download-allowance-token {
                width: 1.25rem;
                height: 1.25rem;
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-items: center;
                overflow: hidden;
                @include bg(--download-allowance-token-inactive-bg);
                position: relative;
                transition: width 0.2s, height 0.2s;

                > div {
                    width: 1.25rem;
                    height: 1.25rem;
                    border-radius: 50%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    transition: transform 0.6s;
                    z-index: 1;
                }

                > img {
                    position: absolute;
                    z-index: 0;
                    opacity: 0.5;
                }

                &.active > div {
                    @include bg(--download-allowance-token-active-bg);
                }

                &.downloading > div {
                    @include bg(--download-allowance-token-active-bg);
                    transform: translateY(1.5rem);
                }

                &.inactive > div {
                    transform: translateY(1.5rem);
                }
            }
        }

        .ub-download-allowance-info {
            display: flex;
            flex-direction: column;
            text-align: left;
            gap: 0.75rem;
            opacity: 0;

            > div {
                display: flex;
                flex-direction: column;
                gap: 0.375rem;
                margin-block: 0.125rem;

                h3 {
                    @include c(--h1-c);
                }

                p {
                    line-height: 1;

                    img {
                        width: 0.625rem;
                        height: 0.75rem;
                        margin-block-start: -3px;
                        margin-inline-end: 0.375rem;

                        &.dark {
                            opacity: 0.6;
                        }

                        &.light {
                            filter: brightness(0.5);
                        }
                    }
                }
            }
        }
    }
}
