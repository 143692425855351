$header-tile-size: 11.75rem;

.ub-heading {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    width: 100%;
    position: relative;
    padding: clamp(4rem, 2vw, 5rem) clamp(1.5rem, 3cqw, 3rem);

    .ub-heading-image,
    .ub-heading-image-loading {
        display: flex;
        position: relative;
        height: $header-tile-size;
        min-width: $header-tile-size;
        max-width: $header-tile-size;
        margin-block-end: 1.5rem;
        border-radius: 1rem;
        @include bg(--skeleton-bg);
    }

    .ub-heading-image-loading {
        border-radius: 50%;

        &.ub-skeleton {
            overflow: hidden;
            -webkit-backface-visibility: hidden;
            -moz-backface-visibility: hidden;
            -webkit-transform: translate3d(0, 0, 0);
            -moz-transform: translate3d(0, 0, 0)
        }
    }

    > h1 {
        display: inline-flex;
        align-items: center;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        display: block;
        max-width: 100%;
        line-height: 1.21;

        &::first-letter {
            text-transform: uppercase;
        }

        &.ub-skeleton {
            height: 48.4px;
            width: 100%;
            max-width: 300px;
        }
    }

    .ub-heading-content {
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        max-width: 100%;

        .ub-heading-explicit {
            display: inline-flex;
            margin-left: 0.5rem;

            svg {
                height: 1.5rem;
                width: 1.5rem;
            }
        }

        h1::first-letter {
            text-transform: capitalize;
        }

        h3 {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 100%;
            font-size: 1.25rem;
            display: flex;
            gap: 0.5rem;

            > div:first-child {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                max-width: 100%;
            }

            &.ub-skeleton {
                height: 1.25rem;
                width: 100%;
                max-width: 200px;
            }

            .ub-heading-icons {
                display: flex;
                gap: 0.5rem;
                align-items: flex-start;
                justify-content: center;

                span {
                    display: flex;
                    width: 1.25rem;
                    height: 1.25rem;
                }

                .ub-track-premium {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 1.5rem;
                    height: 1.5rem;
                    top: 0;
                    left: 0;
                    border-radius: 50%;
                    z-index: 1;
                    transition: top 0.2s;
                    @include bg(--track-premium-star-bg);

                    img {
                        width: 1.125rem;
                        height: 1.125rem;
                    }
                }
            }
        }
    }

    &.ub-heading-general {
        flex-direction: column;

        p.ub-skeleton {
            height: 1.5rem;
            max-width: 700px;
            width: 100%;
        }

        p {
            color: var(--main-primary-c);
            font-family: roboto, sans-serif;
            font-size: 0.875rem;
            width: 32rem;
            max-width: 100%;
            line-height: 1.75;
        }
    }

    &.ub-heading-error {
        flex-direction: column;
        align-items: center;
        gap: 0.75rem;
        text-align: center;
    }

    &.ub-heading-track {
        flex-direction: column;


        &.sfx {

            .ub-heading-track-inner  > .ub-heading-image {
                &::after {
                    content: none;
                }
            }
        }

        .ub-heading-track-inner {
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
            align-items: center;
            justify-content: center;
            width: 100%;
            position: relative;

            .ub-heading-image {
                background-color: $transparent; // Doesn't need to be themed

                &::after {
                    content: '';
                    display: block;
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    border-radius: 50%;
                    bottom: 0;
                    right: 0;
                    background-image: linear-gradient(rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0.5) 100%); // Doesn't need to be themed
                }

                img {
                    border-radius: 50%;
                }

                .ub-heading-track-play {
                    position: absolute;
                    bottom: 0;
                    right: 0;
                    width: 3.625rem;
                    height: 3.625rem;
                    border-radius: 50%;
                    z-index: 1;
                    cursor: pointer;
                    @include css4 {
                        filter: drop-shadow(0 0 8px cvar(--shadow-dark));
                    }

                    svg {
                        width: 100%;
                        height: 100%;
                    }

                    .loading {
                        background: $white; // Doesn't need to be themed
                        width: 3.625rem;
                        height: 3.625rem;
                        border-radius: 50%;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }
                }

                .ub-track-premium {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    position: absolute;
                    width: 2.75rem;
                    height: 2.75rem;
                    top: 0;
                    left: 0;
                    border-radius: 50%;
                    background: $uppbeat;
                    z-index: 1;
                    transition: top 0.2s;
                    @include css4 {
                        border: 0.25rem solid cvar(--main-bg);
                    }

                    @media only screen and (min-width: 500px) {
                        @media (hover: hover) {
                            &:hover {
                                .ub-track-tooltip {
                                    display: flex;
                                }
                            }
                        }
                    }

                    img {
                        width: 1.5rem;
                        height: 1.5rem;
                    }

                    .ub-track-tooltip {
                        display: none;
                        justify-content: center;
                        align-items: center;
                        position: absolute;
                        bottom: calc(100% + 14px);
                        right: 50%;
                        transform: translateX(50%);
                        width: max-content;
                        height: auto;
                        padding: 0.375rem 0.75rem;
                        border-radius: 0.375rem;
                        font-family: futura-pt, sans-serif;
                        font-weight: 500;
                        font-size: 0.8125rem;
                        text-align: center;
                        color: $white;
                        background: $gray-dark-02;

                        &::after {
                            border-top: 7px $gray-dark-02 solid;
                            border-left: 6px $transparent solid;
                            border-right: 6px $transparent solid;
                            content: "";
                            display: block;
                            pointer-events: none;
                            position: absolute;
                            top: 100%;
                            right: 50%;
                            transform: translate(50%);
                            height: 7px;
                            width: 13px;
                        }
                    }
                }
            }

            .ub-heading-content {
                max-width: 100%;

                h1 {
                    padding-block-end: 0.75rem;
                    display: inline-flex;
                    align-items: center;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: block;
                    max-width: 100%;

                    &.ub-skeleton {
                        height: 48.4px;
                        width: 100%;
                        max-width: 300px;

                        @media only screen and (min-width: 768px) {
                            height: 67.75px;
                            width: 50%;
                            max-width: none;
                        }
                    }
                }

                .ub-heading-subheading {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    width: 100%;
                    overflow: visible;
                    gap: 2rem;

                    .ub-heading-subheading-left {
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                        align-items: center;
                        width: 100%;
                        gap: 0.5rem;

                        > a {
                            width: fit-content;

                            h2 {
                                font-size: 1.25rem;
                                display: inline-flex;
                                text-align: center;
                                white-space: nowrap;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                display: block;
                                max-width: 100%;

                                @media only screen and (min-width: 768px) {
                                    font-size: 1.5rem;
                                }
                            }
                        }

                        > h2.ub-skeleton {
                            margin-top: 0.5rem;
                            height: 1.25rem;
                            width: 7.5rem;

                            @media only screen and (min-width: 768px) {
                                height: 1.5rem;
                            }
                        }

                        .ub-heading-functionality {
                            display: flex;
                            gap: 0.5rem;

                            &.ub-skeleton {
                                width: 232px;
                                height: 2rem;
                            }

                            > .with-tooltip {
                                position: relative;

                                @media (hover: hover) {
                                    &:hover .tooltip {
                                        display: flex;
                                    }
                                }

                                .tooltip {
                                    display: none;
                                    justify-content: center;
                                    align-items: center;
                                    position: absolute;
                                    bottom: calc(100% + 14px);
                                    right: 50%;
                                    transform: translateX(50%);
                                    width: max-content;
                                    height: auto;
                                    padding: 0.375rem 0.75rem;
                                    border-radius: 0.375rem;
                                    font-family: futura-pt, sans-serif;
                                    font-weight: 500;
                                    font-size: 0.8125rem;
                                    text-align: center;
                                    @include c(--tooltip-c);
                                    @include bg(--tooltip-bg);

                                    &::after {
                                        content: "";
                                        display: block;
                                        pointer-events: none;
                                        position: absolute;
                                        top: 100%;
                                        right: 50%;
                                        transform: translate(50%);
                                        height: 7px;
                                        width: 13px;
                                        border-left: 6px $transparent solid; // Doesn't need to be themed
                                        border-right: 6px $transparent solid; // Doesn't need to be themed
                                        @include css4 {
                                            border-top: 7px cvar(--tooltip-bg) solid;
                                        }
                                    }
                                }
                            }
                        }
                    }

                    .ub-heading-subheading-right {
                        display: none;
                        flex-wrap: wrap;
                        align-items: center;
                        gap: 0.25rem;
                        justify-content: center;
                        max-width: 600px;

                        @media only screen and (min-width: 768px) {
                            display: flex;
                        }
                    }
                }
            }
        }

        .ub-heading-waveform {
            margin-block-start: 3rem;
            width: 100%;

            > div {
                width: 100%;
                height: 56px;
                overflow: hidden;
                border-bottom: 5px solid $gray-light-01; // Doesn't need to be themed

                > div {
                    width: 100%;
                    height: 100%;
                    top: 0;
                    left: 0;
                    opacity: 1;
                    position: relative;
                }
            }
        }

        .ub-heading-mobile-tags {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: center;
            gap: 0.25rem;
            margin-block-start: 3rem;

            @media only screen and (min-width: 768px) {
                justify-content: flex-start;
            }

            @media only screen and (min-width: 768px) {
                display: none;
            }
        }
    }

    &.ub-heading-similar-track {
        .ub-heading-image,
        .ub-heading-image-loading {
            display: none;
            border-radius: 16px;
            // clip-path: url(#squircleClip);
            overflow: hidden;

            .carousel--container-main {
                width: $header-tile-size;
                height: $header-tile-size;
            }

            @media only screen and (min-width: 768px) {
                display: flex;
            }
        }

        .ub-heading-content {
            gap: 0.5rem;

            h1 {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                display: block;
                max-width: 100%;

                &.ub-skeleton {
                    height: 48.4px;
                    width: 100%;
                    max-width: 300px;

                    @media only screen and (min-width: 768px) {
                        height: 67.75px;
                        width: 50%;
                        max-width: none;
                    }
                }
            }
        }
    }

    &.ub-heading-home {
        flex-direction: column;
        align-items: flex-start;
        gap: 1rem;

        h1 {
            white-space: normal;

            &.ub-skeleton {
                height: 96.8px;
                width: 100%;

                @media only screen and (min-width: 451px) {
                    height: 48.4px;
                    max-width: 403px;
                }

                @media only screen and (min-width: 768px) {
                    height: 67.75px;
                    max-width: 564px;
                }
            }
        }

        h2 {
            line-height: 1.375;
            max-width: 1200px;

            &.ub-skeleton {
                height: 66px;
                width: 100%;

                @media only screen and (min-width: 405px) {
                    height: 66px;
                    max-width: 358px;
                }

                @media only screen and (min-width: 541px) {
                    height: 33px;
                    max-width: 492px;
                }
            }
        }
    }

    &.ub-heading-double-image {
        .ub-heading-image,
        .ub-heading-image-loading {
            display: none;
            overflow: hidden;

            &:not(.squared) {
                border-radius: 50%;
            }

            .carousel--container-main {
                width: $header-tile-size;
                height: $header-tile-size;
            }

            @media only screen and (min-width: 768px) {
                display: flex;
            }
        }

        .ub-heading-content {
            gap: 0.5rem;

            h1 {
                white-space: normal;
                overflow: hidden;
                text-overflow: ellipsis;
                display: block;
                max-width: 100%;
                line-height: 1.2;

                &.ub-skeleton {
                    height: 48.4px;
                    width: 100%;
                    max-width: 300px;

                    @media only screen and (min-width: 768px) {
                        height: 67.75px;
                        width: 50%;
                        max-width: none;
                    }
                }

                @media only screen and (min-width: 768px) {
                    max-width: calc(100% - 11.25rem);
                    white-space: nowrap;
                }
            }

            > div {
                width: 100%;
                display: flex;
                flex-wrap: wrap;
                flex-direction: column;
                align-items: center;
                gap: 2rem;

                > div {
                    flex-grow: 1;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                }

                > a {
                    // align-self: flex-end;

                    .ub-btn span {
                        text-transform: lowercase;

                        &::first-letter {
                            text-transform: uppercase;
                        }
                    }
                }
            }

            .ub-heading-content {
                text-align: center;
            }

            p {
                font-family: $font-family-secondary;
                font-size: 0.875rem;
                width: 100%;
                max-width: 32rem;
                line-height: 1.75;
                @include c(--main-primary-c);

                &.ub-skeleton {
                    height: 49px;
                    max-width: 32rem;
                    width: 100%;
                }

                // @media only screen and (min-width: 768px) {
                //     max-width: calc(100% - 11.25rem);
                // }
            }

            ul {
                width: fit-content;
            }
        }
    }

    &.ub-heading-sfx {
        flex-direction: column;
        align-items: flex-start;
        gap: 1rem;

        .label-premium {
            height: 2rem;
            width: 220px;
            margin-block-end: 0.5rem;
            border-radius: 0.5rem;
        }

        h1 {
            white-space: normal;
            width: fit-content;

            &.ub-skeleton {
                height: 96.8px;
                width: 100%;

                @media only screen and (min-width: 417px) {
                    height: 48.4px;
                    width: 100%;
                    max-width: 369px;
                }

                @media only screen and (min-width: 768px) {
                    height: 67.75px;
                    width: 100%;
                    max-width: 516px;
                }
            }
        }

        h2 {
            &.ub-skeleton {
                height: 48.4px;
                width: 100%;
                max-width: 300px;

                @media only screen and (min-width: 768px) {
                    height: 48.75px;
                    width: 24%;
                    max-width: none;
                }
            }
        }

        h3 {
            margin-block-start: 1.25rem;
            line-height: 1.5;

            &:not(:first-of-type) {
                display: flex;
                flex-wrap: wrap;
                grid-column-gap: 1.25rem;
                column-gap: 1.25rem;
                grid-row-gap: 0.25rem;
                row-gap: 0.25rem;
                align-items: center;
            }

            a {
                white-space: nowrap;
            }

            span {
                white-space: nowrap;
                color: unset;

                img {
                    width: 1rem;
                    height: 1rem;
                    margin-block-start: -0.25rem;
                }
            }

            &.ub-skeleton {
                height: 182px;
                width: 100%;

                @media only screen and (min-width: 411px) {
                    height: 155px;
                }

                @media only screen and (min-width: 436px) {
                    height: 128px;
                }

                @media only screen and (min-width: 656px) {
                    height: 101px;
                }

                @media only screen and (min-width: 708px) {
                    height: 74px;
                    max-width: 661px;
                }
            }
        }
    }

    &.ub-heading-tag {
        flex-direction: column;
        align-items: flex-start;

        h1 {
            white-space: normal;

            &.ub-skeleton + div {
                width: 100%;
                max-width: 800px;
                height: 6rem;
                margin-block-start: 1.5rem;
            }
        }

        h1::first-letter {
            text-transform: capitalize;
        }

        > div {
            display: flex;
            flex-wrap: wrap;
            gap: 2rem;
            width: 100%;

            > div {
                flex-grow: 1;
            }

            > a {
                align-self: flex-end;

                .ub-btn span {
                    text-transform: lowercase;

                    &::first-letter {
                        text-transform: uppercase;
                    }
                }
            }
        }

        p {
            margin-block-start: 1.5rem;
            font-family: $font-family-secondary;
            max-width: 800px;
            @include c(--main-primary-c);

            &.ub-skeleton {
                height: 49px;
                max-width: 32rem;
                width: 100%;
            }
        }
    }

    &.ub-heading-blog {
        text-align: center;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        h1, h2 {
            white-space: normal;
            text-overflow: unset;

            &.ub-skeleton {
                width: 100%;
            }
        }

        h1 {
            &.ub-skeleton {
                max-width: 800px;
                height: 68px;
            }
        }

        h2 {
            margin-block: 1rem;

            &.ub-skeleton {
                max-width: 500px;
                height: 3rem;
            }
        }
    }
}

.tick-container {
    margin-block-start: 1.5rem;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    grid-column-gap: 1.25rem;
    column-gap: 1.25rem;
    grid-row-gap: 0.25rem;
    row-gap: 0.25rem;
    align-items: center;

    @media only screen and (min-width: 768px) {
        justify-content: flex-start;
    }

    li {
        display: inline-flex;
        font-weight: 700;
        @include c(--h3-c);

        svg {
            margin-right: 5px;
            width: 1.25rem;
            height: 1.25rem;
        }
    }
}

@media only screen and (max-width: 655px) {
    .seo-ticks-align-center {
        flex-flow: column;
        align-items: center;
    }
    .seo-ticks-align-left {
        flex-flow: column;
        align-items: start;
    }
}

.page-container, .browse-container {
    container-type: inline-size;

    @container (min-width: 840px) {
        .ub-heading {
            flex-wrap: nowrap;
            justify-content: flex-start;
            align-items: flex-start;

            &.ub-heading-blog {
                align-items: center;
            }

            .ub-heading-image,
            .ub-heading-image-loading {
                margin: 0;
            }

            > h1.ub-skeleton {
                height: 67.75px;
                width: 50%;
                max-width: none;
            }

            &.ub-heading-double-image,
            &.ub-heading-similar-track {
                flex-direction: row;
                align-items: center;

                .ub-heading-content {
                    display: flex;

                    > div {
                        align-items: flex-end;
                        flex-direction: row;

                        > div {
                            align-items: flex-start;
                        }
                    }
                }
            }

            .ub-heading-content {
                flex: auto;
                padding: 0;
                text-align: left;
                align-items: flex-start;

                &:not(:first-child) {
                    margin-inline-start: 2.5rem;
                }

                h3 {
                    font-size: 1.5rem;

                    &.ub-skeleton {
                        height: 1.5rem;
                        width: 30%;
                        max-width: none;
                    }

                    .ub-heading-icons span {
                        height: 1.5rem;
                        width: 1.5rem;
                    }
                }
            }

            &.ub-heading-error {
                align-items: flex-start;
                text-align: left;
            }

            &.ub-heading-track .ub-heading-track-inner {
                flex-direction: row;
                flex-wrap: nowrap;
                justify-content: flex-start;

                .ub-heading-content {

                    .ub-heading-subheading {
                        flex-direction: row;
                        justify-content: space-between;
                        align-items: stretch;
                        gap: 1rem;

                        .ub-heading-subheading-left {
                            align-items: flex-start;
                        }

                        .ub-heading-subheading-right {
                            justify-content: flex-end;
                            align-items: flex-end;
                            max-width: unset;
                        }
                    }
                }
            }
        }
    }
}
