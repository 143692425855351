// Player
// ---------------------------------------------------------

.player-tooltip {
    visibility: hidden;
    opacity: 0;
    transition: 0.2s;
    position: absolute;
    bottom: calc(100% + 12px);
    right: 50%;
    transform: translateX(50%);
    padding: 4px;
    font-size: 12px;
    text-align: center;
    pointer-events: none;
    background: #47494d;
    color: $white;

    @media only screen and (min-width: 768px) {
        bottom: calc(100% + 18px);
    }

    @media only screen and (min-width: 992px) {
        min-width: 90px;
    }

    @media only screen and (min-width: 1440px) {
        min-width: 130px;
    }

    &::after {
        content: '';
        position: absolute;
        right: 50%;
        transform: translateX(50%);
        top: 100%;
        width: 0;
        height: 0;
        border-left: 8px solid $transparent;
        border-right: 8px solid $transparent;
        border-top: 8px solid #47494d;
    }
}

span:hover + .player-tooltip,
i:hover + .player-tooltip {
    @media only screen and (min-width: 992px) {
        visibility: visible;
        opacity: 1;
    }
}

.player-tooltip--cart {
    @media only screen and (min-width: 768px) {
        bottom: calc(100% + 17px);
    }
}

.player-tooltip--responsive {
    width: 140px;
    padding: 0;
    padding-top: 10px;
    padding-bottom: 10px;
}

.player-tooltip--responsive li {
    padding-bottom: 10px;
    font-size: 14px;
}

.player-tooltip--responsive a {
    color: $white;
}

.browse-player__preview .player-tooltip,
.browse-player__remove .player-tooltip,
.browse-player__cart .player-tooltip {
    bottom: calc(100% + 9px);
}

.player-width-playlist .player-tooltip,
.player-width-favourite .player-tooltip,
.player-width-download .player-tooltip,
.browse-player__preview .player-tooltip {
    min-width: 130px;
}

.browse-player__remove .player-tooltip {
    min-width: 130px;
}

.footer-player__icon--cart .player-tooltip,
.browse-player__cart .player-tooltip {
    min-width: 90px;
}
