.DialogContent {
  border-radius: 10px;
  box-shadow: 0px 4px 10px 0px #0000000d;
  position: fixed;
  color: var(--card-primary-c);
  width: 90vw;
  max-width: 460px;
  animation: contentShow 150ms cubic-bezier(0.16, 1, 0.3, 1);
  bottom: 62px;
  left: 50%;
  top: initial;
  transform: translate(-50%, 0%);
  max-height: 85svh;
  overflow: auto;
}
.DialogContent:focus {
  outline: none;
}

@keyframes contentShow {
  from {
    opacity: 0;
    transform: translate(-50%, -2%) scale(0.96);
  }
  to {
    opacity: 1;
    transform: translate(-50%, 0%) scale(1);
  }
}

.DialogContent {
  bottom: 62px;
  left: 50%;
  top: initial;
  transform: translateX(-50%);
  max-height: 85svh;
  overflow: auto;
}

.DialogTitle {
  font-size: 22px;
  font-weight: bold;
  line-height: 1.2;
  text-align: left;
  color: var(--card-primary-c);
}

.DialogSubtitle {
  font-size: 16px;
  font-weight: 400;
  line-height: 1.2;
  text-align: left;
  color: var(--card-secondary-c);
}

.ArtistContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px auto 36px;
}

.ArtistAvatar {
  width: 56px;
  height: 56px;
  border-radius: 99999px;
  background-color: var(--card-bg);
}

.ArtistMeta {
  margin-left: 12px;
  display: flex;
  flex-direction: column;
}

.LicenseLabel {
  background-color: var(--accordion-bg);
  border-radius: 10px;
  padding: 16px;
  border: 1px solid var(--accordion-bg);
  transition: border-color 0.1s ease-in-out, box-shadow 0.1s ease-in-out,
    background-color 0.1s ease-in-out, outline 0.1s ease-in-out;
  will-change: transform;
  display: flex;
  gap: 14px;
  align-items: center;
  outline: 0px solid transparent;

  > input[type='radio'] {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
  }

  &:before {
    content: '';
    display: block;
    width: 16px;
    height: 16px;
    border-radius: 99999px;
    flex-shrink: 0;
    border: 1.5px solid var(--select-dropdown-br);
    background-color: var(--accordion-bg);
    transition: background-color 0.1s ease-in-out;
  }

  &:focus-within {
    outline: none;
  }

  &:has(input[type='radio']:focus-visible) {
    outline: 2px solid var(--main-brand-c);
  }

  &:hover:before {
    background-color: var(--radio-hover-bg);
  }
}

.LicenseLabel:has(input[type='radio']:checked) {
  border: 1px solid var(--main-brand-c);
  box-shadow: 0px 1px 4px 0.5px var(--shadow-brand);
  background-color: var(--ub-sidebar-link-active-bg);

  @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    /* Retina-specific animation */
    transition-delay: 0.1s;
    will-change: transform;
    backface-visibility: hidden;
    transform: translateZ(0);
    animation: selectLicense 200ms ease-out;
  }

  &:before {
    background-color: var(--main-brand-c);
    border-color: var(--main-brand-c);
    background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="10" height="8" fill="none"><path fill="%23fff" d="M9.577 1.521 4.364 7.604a.886.886 0 0 1-.085.117.775.775 0 0 1-.612.279.61.61 0 0 1-.343-.094.816.816 0 0 1-.225-.185L.422 4.597a.98.98 0 0 1 0-1.242.775.775 0 0 1 .59-.279c.226 0 .438.102.59.28l2.08 2.425L8.395.28a.775.775 0 0 1 .657-.276.633.633 0 0 1 .545.232.88.88 0 0 1 .2.616L9.799.9c0 .22-.071.445-.222.621Z"/></svg>');
    background-size: 10px 8px;
    background-position: center;
  }

  &:focus-visible {
    box-shadow: none;
  }
}

@keyframes selectLicense {
  0% {
    transform: scale(1);
  }
  70% {
    transform: scale(1.025);
  }
  100% {
    transform: scale(1);
  }
}

.LicenseDetails {
  display: flex;
  flex-direction: column;
  flex: 1;

  @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    backface-visibility: hidden;
    will-change: transform;
  }
}

.LicenseLabelTitle {
  font-size: 14px;
  font-weight: bold;
  line-height: 16px;
  text-align: left;
  color: var(--card-primary-c);
}

.LicenseLabelSubtitle {
  font-size: 12px;
  line-height: 16px;
  text-align: left;
  color: var(--card-secondary-c);
}

.DownloadToken {
  width: 20px;
  height: 20px;
  background-color: #37cfed;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 99999px;
}

.LicensePrice {
  font-size: 18px;
  font-weight: bold;
  text-align: right;
  color: var(--card-primary-c);
  line-height: 1;
  white-space: nowrap;
  align-self: flex-end;
}

.RemainingDownloads {
  font-size: 13px;
  line-height: 24px;
  margin-top: 24px;
}

.DialogActions {
  display: flex;
  margin-top: 8px;
  gap: 8px;
  flex-direction: column;
  align-items: start;

  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
  }
}

.CheckboxWrapper {
  display: flex;
  align-items: center;
  gap: 8px;
}

.Checkbox {
  border: 2px solid var(--hr);

  &[data-state='checked'] {
    background-color: var(--main-brand-c);
    border-color: var(--main-brand-c);
  }
}

.Label {
  font-size: 13px;
  line-height: 1;
  margin: 0;
  color: var(--card-secondary-c);
}
