.verify-email-container {
	.ub-skeleton {
		&:first-child {
			height: 56px;
			width: 520px;
		}
		&:last-child {
			height: 27px;
			margin-block-start: 1.25rem;
			width: 300px;
		}
	}
}
