.ub-carousel {
    width: var(--ub-carousel-width, 100%);

    &.spotlight {
        padding-block-end: 0;

        .slick-slider {
            position: relative;

            .slick-list {
                padding: var(--ub-carousel-padding, auto) !important;
                overflow: visible;

                .slick-track {
                    padding-block-end: 0;
                    display: flex;
                    align-items: stretch;

                    .slick-slide {
                        display: flex;
                        transition: transform 0.2s, opacity 0.2s;

                        &.slick-center {
                            &.slick-active,
                            &.slick-cloned {
                                transform: scale(1.1);
                            }
                        }

                        &:not(.slick-active):not(.slick-center) {
                            opacity: 0.5;
                        }
                    }
                }
            }

            .slick-arrow {
                opacity: 1;
                position: absolute;
                top: unset;
                bottom: -6rem;

                &.slick-prev {
                    left: calc(50% - 2.75rem);

                    .ub-btn-icon {
                        margin-inline-start: -2px;
                    }
                }

                &.slick-next {
                    right: calc(50% - 2.75rem);

                    .ub-btn-icon {
                        margin-inline-end: -2px;
                    }
                }
            }
        }

        &.spotlight-arrows-visible {
            padding-block-end: 4.75rem;
        }
    }
}
