.input-half-wrapper {
	display: grid;
	gap: 1rem;
	grid-template-columns: 1fr 1fr;
	width: 100%;
}

.input-wrapper {
	display: flex;
	flex-direction: column;
	width: 100%;
	position: relative;

	+ .input-half-wrapper .input-wrapper .input-label,
	+ .input-half-wrapper .input-wrapper .input:first-child {
		margin-block-start: 1rem;
	}

	&.input-wrapper-tooltip {
		.input-tooltip {
			position: absolute;
			right: 0.75rem;
			top: 1rem;
			transform: translateY(-100%);

			&:hover .input-tooltip-description {
				opacity: 1;
				transform: translate(0, -120%);
			}

			.input-tooltip-description {
				border-radius: 0.25rem;
				font-size: 0.75rem;
				opacity: 0;
				padding: 0.125rem 0.25rem;
				pointer-events: none;
				position: absolute;
				right: 0;
				text-align: center;
				transition: transform 0.2s;
				transform: translate(0, -100%);
				width: 100%;
				@include bg(--tooltip-bg);
				@include c(--tooltip-c);
				@include css4 {
					box-shadow: 0 2px 4px cvar(--shadow-dark);
				}

				&:after {
					content: "";
					height: 0.5rem;
					position: absolute;
					right: 50%;
					top: 100%;
					transform: translate(50%, -50%) rotate(45deg);
					width: 0.5rem;
					@include bg(--tooltip-bg);
					@include css4 {
						box-shadow: 2px 2px 2px cvar(--shadow-dark);
					}
				}
			}

			.input-tooltip-title {
				color: $gray-medium-03;
				margin: 0;
				font-size: 0.625rem;
			}
		}

		.input {
			margin-block-start: 1rem;
		}
	}

	.input-label-wrapper {
		display: flex;
		justify-content: space-between;

		+ input + p {
			margin-block-start: 0.5rem;
			font-style: italic;
			@include c(--input-c);
		}
	}

	.input-label {
		font-family: $font-family-secondary;
		font-size: 0.75rem;
		@include c(--input-label-c);

		&.edit-link {
			cursor: pointer;
			@include c(--input-edit-c);
		}
	}

	.input,
	.StripeElement {
		-webkit-font-smoothing: antialiased;
		height: 2.5rem;
		font-size: 1em;
		border-radius: 0.625rem;
		padding: 0 1rem;
		outline: none;
		font-family: sans-serif;
		font-weight: 500;
		box-shadow: none;
		@include c(--input-c);
		@include bg(--input-bg);
		@include css4 {
			border: 2px solid cvar(--input-br);
		}

		@media only screen and (min-width: 768px) {
			font-size: 0.875rem;
		}

		&::placeholder {
			font-family: sans-serif;
			font-weight: 500;
			-webkit-font-smoothing: unset;
			@include c(--input-placeholder-c);
		}

		&:placeholder-shown {
			text-overflow: ellipsis;
		}

		&:disabled {
			pointer-events: none;
			@include c(--input-disabled-c);
			@include bg(--input-disabled-bg);

			&::placeholder {
				@include c(--input-disabled-c);
			}

			&:hover {
				@include bg(--input-disabled-bg);
			}
		}

		&:hover {
			@include bg(--input-hover-bg);
		}

		&:focus,
		&:focus-within,
		&.StripeElement--focus {
			@include bg(--input-hover-bg);
			@include css4 {
				border: 2px solid cvar(--input-active-br);
			}
		}

    &.StripeElement--invalid {
      @include css4 {
				border: 2px solid #f34949;
			}
    }

		&[type=password] {
			font-family: $font-family-secondary;
		}
	}

	.StripeElement {
		display: flex;
		align-items: center;
		justify-content: stretch;

		.__PrivateStripeElement {
			flex-grow: 1;
			font-family: futura-pt, sans-serif;
			@include c(--input-placeholder-c);
		}
	}

	+ .input-wrapper .input-label,
	+ .input-wrapper .input:first-child {
		margin-block-start: 1rem;
	}

	&.select-wrapper {
		position: relative;

		select.input {
			appearance: none;
		}

		.select-icon {
			display: flex;
			justify-content: center;
			align-items: center;
			height: 1.5rem;
			width: 1.5rem;
			border-radius: 0.5rem;
			position: absolute;
			top: 0.5rem;
			right: 0.5rem;
			pointer-events: none;

			span {
				width: 4px;
				height: 10px;
				position: relative;

				&::before,
				&::after {
					content: "";
					display: block;
					width: 2px;
					height: 10px;
					border-radius: 1px;
					background-color: $gray-medium-01;
					position: absolute;
					bottom: -1px;
				}

				&::before {
					transform: rotate(45deg);
					right: -2px;
				}

				&::after {
					transform: rotate(-45deg);
					left: -2px;
				}
			}
		}
	}

	.warning {
		font-size: 0.875rem;
		margin-block-start: 0.25rem;
		@include c(--input-warning-c);
	}
}

.playlist-input-field {
	width: 100%;
}

.textarea {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	border-radius: 0.25rem;
	padding: 0.25rem 1.5rem 0.25rem 0.375rem;
	min-height: 5rem;
	font-family: $font-family-secondary;
	position: relative;
	resize: none;
	width: 100%;
	font-size: 0.75rem;
	@include c(--popup-content-c);
	@include bg(--popup-content-bg);
	@include css4 {
		border: 1px solid cvar(--popup-content-br);
	}
}
