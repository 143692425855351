// reCAPTCHA
//
// reCAPTCHA styling

.recaptcha-branding {
	  color: $gray-medium-02;
    font-family: $font-family-secondary;
    display: block;
    font-size: 0.75rem;
    text-align: center;
    margin-block-end: 1rem;

    a {
        color: $uppbeat;
    }
}

.modal-primary__recaptcha {
  width: 100%;

  &.sidebar {
    margin: 1rem 0 0 -15px;
  }

  div div div {
    margin: auto;
  }
}

.grecaptcha-badge {
    visibility: hidden;
}
