// Type
//
// Typography variables and settings.

$font-weight-x-light: 100;
$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-medium: 500;
$font-weight-semi-bold: 600;
$font-weight-bold:   600;
$font-weight-bolder:  900;
