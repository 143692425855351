// Wavesurfer Player
//
// Styles for wavesurfer player site wide
// Browse player
// ---------------------------------------------------------

.browse-player-wrapper {
    padding: 0;
    border-top: 1px solid var(--ub-border-light);

    .browse-player {
        min-height: 8.5rem;
        text-align: left;
        padding-block-end: 1.5rem;
        container-type: inline-size;

        &.fetching {
            min-height: 80vh;

            .music-loading-message {
                display: flex;
                justify-content: center;
                align-items: center;
            }

            .music-loader-message-container {
                padding: 5rem 1.25rem;
                position: relative;
                bottom: unset;
                right: unset;
                transform: none;
            }
        }

        .browse-player-header {
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            flex-direction: column;
            gap: 0.5rem;


            @media only screen and (min-width: 768px) {
                flex-direction: row;
                gap: 1rem;
            }

        }
    }
}

.browse-player--secondary {
    padding-top: 40px;
}

.browse-player--secondary {
    @media only screen and (min-width: 992px) {
        padding-top: 40px;
    }
}

// Thumb
// ---------------------------------------------------------

.browse-player__thumb {
    position: relative;
    display: inline-block;
    width: 100%;
    max-width: 40px;
    margin-bottom: 0;

    @media only screen and (min-width: 992px) {
        max-width: 60px;
    }

    &::after {
        visibility: hidden;
        opacity: 0;
        content: "";
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        @include bg(--browse-tracks-thumb-overlay-bg);
    }

    img {
        border-radius: 50%;
    }
}

// Track
// ---------------------------------------------------------

.browse-player__track.active {
    background: $browse-track-hover-color;
}

// Top
// ---------------------------------------------------------

.browse-player__top {
    text-align: center;

    @media only screen and (min-width: 992px) {
        margin: 0 20px 0 0;
        text-align: left;
    }
}

#search .browse-player__top {
    display: none;
}

.playlists-container.page-container .browse-player__top {
    display: none;
}

// Titles
// ---------------------------------------------------------

.browse-player__title,
.browse-player__sub {
    width: 100%;

    .browse-player__title-1 {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        flex-basis: auto;
        @include c(--browse-tracks-title-c);
    }

    .browse-player__title-2 {
       min-width: 20px;
       @include c(--browse-tracks-subtitle-c);
    }
}

.browse-player__title {
    position: relative;
    user-select: none;
    margin-top: 10px;
    margin-bottom: 0;
    font-family: $font-family-secondary;
    font-size: 11px;
    font-weight: 500;

    @media only screen and (min-width: 320px) {
        margin-top: inherit;
    }

    @media only screen and (min-width: 992px) {
        font-size: 15px;
    }
}

.browse-player__sub {
    padding-bottom: 10px;
    margin-bottom: 0;
    font-size: 15px;
    font-weight: $font-weight-normal;
}

.browse-player__filters {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.25rem;

    @media only screen and (min-width: 500px) {
        justify-content: flex-end;
    }
}

// Cart
// ---------------------------------------------------------
//
.browse-player__preview {
    cursor: pointer;
    display: none;
    width: 20px;
    height: 20px;
    justify-content: center;
    position: relative;
    margin-left: 20px;
    margin-top: 10px;

    svg {
        width: 20px;
        height: 20px;
    }

    svg path,
    svg circle {
        stroke: $gray-medium-03;
    }

    .dl-icon-elements {
        fill: $gray-medium-03;
        stroke: none;
    }

    @media only screen and (min-width: 420px) {
        float: right;
        margin-top: inherit;
    }

    @media only screen and (min-width: 1200px) {
        display: flex;
    }
}

.browse-player__remove {
    cursor: pointer;
    opacity: 0;
    visibility: hidden;
    width: 20px;
    height: 20px;
    justify-content: center;
    position: relative;
    margin-top: 10px;

    svg {
        width: 20px;
        height: 20px;
    }

    @media only screen and (min-width: 420px) {
        float: left;
        margin-top: inherit;
    }
}

.browse-player__loader-remove {
    position: relative;
    width: 20px;
    height: 20px;

    .spinner.spinner-track {
        width: 20px;
        height: 20px;
    }
}

.browse-player__cart {
    cursor: pointer;
    width: 25px;
    height: 25px;
    display: flex;
    justify-content: center;
    position: relative;

    svg {
        width: 25px;
        height: 25px;
    }

    svg path,
    svg circle {
        stroke: $gray-medium-03;
    }

    @media only screen and (min-width: 992px) {
        display: block;
        float: right;
        margin-top: inherit;
    }
}

.browse-player__preview:hover svg path,
.browse-player__preview:hover svg circle,
.browse-player__cart:hover svg path,
.browse-player__cart:hover svg circle {
    stroke: $white;
}

.browse-player__preview:hover .dl-icon-elements {
    fill: $white;
    stroke: none;
}

// Cart SVG styles, putting them here so they don't end up on the page 20 times
.cart84 {
    stroke: $white;
    stroke-width: 1.7786;
}

.cart84,
.cart85,
.cart86 {
    fill: none;
    stroke-miterlimit: 10;
}

.cart85 {
    stroke-linecap: round;
    stroke: $white;
    stroke-width: 1.7786;
}

.cart86 {
    stroke: #1579ff;
    stroke-width: 5;
}

// Footer player
// ---------------------------------------------------------

.footer-player {
    height: 0;
    visibility: hidden;
    opacity: 0;
    width: 100%;
    text-align: center;
    transition: opacity 0.5s, height 0.2s;
    position: relative;
    z-index: 9996;
    @include bg(--footer-player-bg);

    button:focus {
        outline: none;
    }

    .footer-player-inner {
        display: flex;
        margin-inline: 0;
        align-items: center;
        align-content: center;
        height: 4rem;
        padding: 0 1rem;

        @media only screen and (min-width: 992px) {
            padding: 0 1.625rem;
        }
    }

    .uppbeataa-progress,
    .uppbeataa-duration {
        position: absolute;
        height: 0.25rem;
        top: -0.25rem;
        left: 0;
    }

    .uppbeataa-progress {
        z-index: 1;
        transition: width 0.1s linear;

        &.uppbeat {
            background: $uppbeat;
        }

        &.grey {
            background: $gray-light-03;
        }
    }

    .uppbeataa-duration {
        width: 100vw;
        background: $gray-light-03;
        border-top: 1px solid $gray-light-01;
    }

    .player-width-artist-avatar {
        display: none;

        .ub-track-premium {
            display: flex;
            align-items: center;
            justify-content: center;
            box-shadow: 0 0 0 2px var(--browse-tracks-bg);
            position: absolute;
            width: 0.75rem;
            height: 0.75rem;
            top: 0;
            left: 0;
            border-radius: 50%;
            background: $uppbeat;
            z-index: 1;
        }

        img {
            border-radius: 50%;
        }
    }

    .player-width-title {
        padding-inline-start: 0.75rem;
        width: 100px;

        @media only screen and (min-width: 992px) {
            padding-inline-start: 1rem;
            width: 180px;
        }

        > a:first-child {
            display: flex;
            gap: 0.25rem;
            justify-content: flex-start;
            align-items: center;

            > div {
                @media only screen and (min-width: 992px) {
                    display: none;
                }
            }
        }
    }

    .footer-player-download-btn.ub-btn {
        margin-inline-start: 0.5rem;
        padding: 0;

        .ub-btn-icon.ub-btn-icon {
            margin-inline-start: 0;
        }

        .ub-btn-label {
            display: none;
        }
    }

    .footer-track-actions {
        display: none;

        .footer-player-button {
            position: relative;
            margin-inline-start: 0.5rem;
            display: flex;

            &:hover .ub-tooltip {
                display: flex;
            }

            .ub-tooltip {
                display: none;
                justify-content: center;
                align-items: center;
                position: absolute;
                bottom: calc(100% + 14px);
                right: 50%;
                transform: translateX(50%);
                width: max-content;
                height: auto;
                padding: 0.375rem 0.75rem;
                border-radius: 0.375rem;
                font-family: futura-pt, sans-serif;
                font-weight: 500;
                font-size: 0.8125rem;
                text-align: center;
                color: $white;
                background: $gray-dark-02;

                &::after {
                    border-top: 7px $gray-dark-02 solid;
                    border-left: 6px $transparent solid;
                    border-right: 6px $transparent solid;
                    content: "";
                    display: block;
                    pointer-events: none;
                    position: absolute;
                    top: 100%;
                    right: 50%;
                    transform: translate(50%);
                    height: 7px;
                    width: 13px;
                }
            }
        }
    }

    &:not(.uppbeataa) {
        .player-width-artist-avatar {
            @media only screen and (min-width: 992px) {
                position: relative;
                margin-inline-start: 0.75rem;
                display: flex;

                .ub-track-premium {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    position: absolute;
                    width: 0.75rem;
                    height: 0.75rem;
                    top: 0;
                    left: 0;
                    border-radius: 50%;
                    background: $uppbeat;
                    z-index: 1;
                }
            }
        }

        .footer-player-download-btn.ub-btn {
            @media only screen and (min-width: 768px) {
                display: flex;
                padding: 0 1rem;

                .ub-btn-icon.ub-btn-icon {
                    margin-inline-start: 0.5rem;
                }

                .ub-btn-label {
                    display: flex;
                }
            }
        }

        .footer-track-actions {
            display: none;

            @media only screen and (min-width: 992px) {
                display: flex;
            }
        }
    }

    &.uppbeataa {
        .footer-player-inner {
            padding: 0 0 0 1rem;

            @media only screen and (min-width: 768px) {
                padding: 0 0.5rem 0 1rem;
            }

            @media only screen and (min-width: 992px) {
                padding: 0 1.125rem 0 1.625rem;
            }

            @media only screen and (min-width: 1182px) {
                padding: 0 1.625rem;
            }
        }

        .player-width-artist-avatar {
            @media only screen and (min-width: 1241px) {
                position: relative;
                margin-inline-start: 0.75rem;
                display: flex;
            }
        }

        .player-width-title {
            display: none;

            @media only screen and (min-width: 1182px) {
                display: block;
            }
        }

        .footer-player-download-btn.ub-btn {
            display: none;

            @media only screen and (min-width: 1281px) {
                display: flex;
            }

            @media only screen and (min-width: 1481px) {
                padding: 0 1rem;

                .ub-btn-icon.ub-btn-icon {
                    margin-inline-start: 0.5rem;
                }

                .ub-btn-label {
                    display: flex;
                }
            }
        }

        .footer-track-actions {
            display: none;

            @media only screen and (min-width: 1401px) {
                display: flex;
            }
        }
    }

    .remove-ads-popup {
        position: absolute;
        display: none;
        justify-content: space-between;
        align-items: center;
        bottom: 0.5rem;
        right: -250px;
        height: 3rem;
        width: 250px;
        border-radius: 0.75rem;
        padding-inline: 0.75rem;
        transition: transform 0.4s;
        cursor: pointer;
        @include bg(--main-bg);
        @include c(--h1-c);
        @include css4 {
            border: 1px solid cvar(--sidebar-nav-bottom-br);
        }

        @media only screen and (min-width: 1401px) {
            display: flex;
        }

        &.active {
            transform: translateX(-258px);

            &.hide {
                transform: translateX(0);
            }
        }

        &.sfx-ad {
            width: 222px;
            right: -222px;

            &.active {
                transform: translateX(-230px);
            }

            @media only screen and (min-width: 1481px) {
                width: 304px;
                right: -304px;

                &.active {
                    transform: translateX(-312px);

                }
            }
        }

        > div:first-child {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;

            > span {
                font-size: 0.75rem;
                line-height: 1.2;
            }

            > span:first-child {
                font-family: $font-family-heading;
                text-transform: uppercase;
                @include c(--main-brand-c);
            }

            > span:last-child {
                font-family: $font-family-secondary;
                @include c(--main-primary-c);
            }
        }

        .ub-btn {
            transform: rotate(180deg);
        }

        @media (hover: hover) {
			&:hover {
                @include bg(--footer-player-bg);
            }
		}
    }
}

@keyframes playActive {
    0% {
        bottom: -#{4rem};
    }

    100% {
        bottom: 0;
    }
}

.footer-player.play-active {
    height: 4rem;
    visibility: visible;
    opacity: 1;
    animation: playActive 0s ease-in-out forwards;
    @include css4 {
        border-top: 1px solid cvar(--topbar-br);
    }
}

// Waveform
// ---------------------------------------------------------

#waveform {
    display: none;

    @media only screen and (min-width: 992px) {
        display: block;
    }
}

.waveform-track-main,
.waveform-version-main {
    position: relative;
    z-index: 1;
}

.waveform-hidden-active {
    opacity: 0;
}

.waveform-background-on-load,
.waveform-version-background-on-load {
    position: absolute;
    top: 0;
    width: calc(100% - 20px);
    z-index: 0;
}

// Profile
// ---------------------------------------------------------

.footer-player__profile {
    display: inline-block;

    @media only screen and (min-width: 320px) {
        padding: 4.5px 0;
        margin-left: 20px;
    }

    @media only screen and (min-width: 992px) {
        margin-left: 15px;
    }

    @media only screen and (min-width: 992px) {
        margin-left: 16px;
    }
}

// Settings
// ---------------------------------------------------------

.footer-player__settings {
    @media only screen and (min-width: 320px) {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-end;
        align-items: center;
        text-align: left;
    }

    @media only screen and (min-width: 768px) {
        align-items: inherit;
        text-align: center;
    }

    @media only screen and (min-width: 992px) {
        text-align: center;
    }
}

// Title
// ---------------------------------------------------------

.footer-player__title {
    display: inline-block;
    text-align: center;
    vertical-align: middle;
    margin-bottom: 10px;

    @media only screen and (min-width: 320px) {
        width: 90px;
        text-align: left;
        padding-right: 10px;
        margin-bottom: 0;
    }

    @media only screen and (min-width: 768px) {
        padding-right: 0;
        margin-right: 15px;
    }

    @media only screen and (min-width: 992px) {
        margin-left: 16px;
        margin-right: 16px;
    }

    @media only screen and (min-width: 1440px) {
        width: 150px;
    }
}

.footer-player__sub-title,
.footer-player__sub-title--secondary {
    font-family: $font-family-secondary;
    font-weight: 500;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    line-height: normal;
    text-align: left;
}

.footer-player__sub-title {
    font-size: 0.875rem;
    margin-bottom: 0;
    @include c(--browse-tracks-title-c);

    + .ub-track-premium {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 0.75rem;
        height: 0.75rem;
        min-width: 0.75rem;
        min-height: 0.75rem;
        border-radius: 50%;
        background: $uppbeat;
        order: -1;
    }
}

.footer-player__sub-title--secondary {
    font-size: 0.6875rem;
    font-weight: 400;
    color: $gray-medium-03;
    border-bottom: none;
    @include c(--track-artist-c);

    @media only screen and (min-width: 992px) {
        font-size: 0.875rem;
    }
}

// Duration
// ---------------------------------------------------------

.footer-player__duration {
    cursor: pointer;
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
    margin-right: 10px;

    svg {
        width: 20px;
        height: 20px;
    }

    @media only screen and (min-width: 768px) {
        margin-right: 12.5px;
    }

    @media only screen and (min-width: 768px) {
        margin-bottom: inherit;
    }
}

.total-duration {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;

    @media only screen and (min-width: 320px) {
        margin-bottom: 0;
    }
}

// Controls
// ---------------------------------------------------------

.footer-player__controls {
    @media only screen and (min-width: 768px) {
        text-align: left;
    }

    @media only screen and (min-width: 992px) {
        button {
            display: inline-block;
            padding-left: 0;
            margin-right: 15px - 4px;
            position: relative;
            vertical-align: middle;
        }

        button#forward {
            margin-right: 21px;
        }
    }
}

// Icons
// ---------------------------------------------------------

.footer-player__icons {
    @media only screen and (min-width: 320px) {
        vertical-align: middle;
    }

    @media only screen and (min-width: 768px) {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
    }

    @media only screen and (min-width: 992px) {
        padding-top: inherit;
    }
}

.footer-player__icon svg,
.footer-player__icon svg path,
.footer-player__icon svg circle {
    cursor: pointer;
    width: 20px;
    stroke: $black;
}

// Player Favourite icon
.footer-player__icon--favourite svg path {
    stroke: $fav-icon;
}

// Player Download icon
.footer-player__icon--sub-cart svg path {
    stroke: $white;
}

.footer-player__icon--cart svg,
.footer-player__icon--cart .svg-container {
    width: 23px;
    height: 23px;
}

.footer-player__icon--cart .player-tooltip {
    bottom: calc(100% + 17px);
}

.footer-player__icon:hover svg {
    opacity: 1;
}

// Opacity effect remove from filled heart icon.
.footer-player__icon .js-is-favourite-version svg {
    opacity: 1;
}

.footer-player__icon:hover {
    &.player-width-favourite svg,
    &.player-width-favourite svg path,
    &.player-width-favourite svg polyline,
    &.player-width-favourite svg circle,
    &.player-width-favourite svg line {
        stroke: $fav-icon;
    }

    &.player-width-download svg,
    &.player-width-download svg path,
    &.player-width-download svg polyline,
    &.player-width-download svg circle,
    &.player-width-download svg line {
        stroke: $white;
    }
    &.player-width-cart svg,
    &.player-width-cart svg path,
    &.player-width-cart svg polyline,
    &.player-width-cart svg circle,
    &.player-width-cart svg line {
        stroke: $white;
    }
}

// Button
// ---------------------------------------------------------

.footer-player__btn {
    cursor: pointer;
    padding: 0;
    vertical-align: middle;
    outline: none;
    background: none;
    border: none;

    > div {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &.disabled-button {
        opacity: 0.5;
    }

    &.uppbeataa {
        border-radius: 50%;
        background-image: radial-gradient($gray-dark-02, $gray-dark-02, $gray-dark-02, $white, $white, $white);

        path {
            fill: $white !important;
        }
    }
}

// Playback modes
// ---------------------------------------------------------

.footer-player__modes {
    visibility: hidden;
    opacity: 0;
    transition: .5s;
    transition-delay: 0.2s;
    position: absolute;
    bottom: 100%;
    right: 50%;
    transform: translateX(50%);
    width: 250px;
    text-align: center;
    background: #404245;
    color: $white;

    &::after {
        content: '';
        position: absolute;
        right: 50%;
        transform: translateX(50%);
        top: 100%;
        width: 0;
        height: 0;
        border-left: 8px solid $transparent;
        border-right: 8px solid $transparent;
        border-top: 8px solid #404245;
    }
}

.footer-player__modes-title {
    visibility: hidden;
    opacity: 0;
    position: absolute;
    bottom: 100%;
    left: 0;
    width: 100%;
    height: 30px;
    padding: 5px 0;
    background: #34373b;
    color: $gray-light-02;
}

.footer-player__modes-block {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.footer-player__modes-col {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    cursor: pointer;
    width: 25%;
    padding: 15px;
    border-right: 2px solid #35383b;

    &:last-child {
        border-right: 0;
    }

    &:hover {
        background: #404245;
        color: #a6f4f4;

        svg {
            opacity: 1;
        }
    }

    &:hover .footer-player__modes-title {
        visibility: visible;
        opacity: 1;
    }
}

.footer-player__modes-col:hover {
    background: url('https://cdn.musicvine.com/images/icons/caret-down-dark.png');
    background-repeat: no-repeat;
    background-position: 50% 0%;
}

.footer-player__modes-col.active {
    background: #34373b;
    border-right: 2px solid #35383b;

    &:first-child svg path {
        stroke: #62d3ce;
        fill: none;
    }

    svg {
        opacity: 1;
    }

    svg path {
        stroke: none;
        fill: #62d3ce;

        &:last-child {
            fill: none;
        }
    }

    &:last-child {
        border-right: 0;
    }
}

.footer-player__modes-col svg {
    opacity: 0.7;
    transition: 0.2s;
}

.footer-icon-style-path,
.footer-player__modes-col--last svg {
    fill: $white;
}

.footer-player__modes-col--last:hover svg path {
    stroke: none;
}

.footer-player__mode-icon {
    position: relative;
    vertical-align: middle;
    height: 100%;

    @media only screen and (min-width: 420px) {
        margin-left: 22px;
    }

    &:hover .footer-player__modes {
        visibility: visible;
        opacity: 1;
        transition: 0s;
    }

    &:hover .footer-player__mode-icon-main polyline,
    &:hover .footer-player__mode-icon-main line {
        stroke: #a6f4f4;
    }
}

.footer-player__mode-icon-main {
    position: relative;
    top: 1px;
    width: 20px;
    height: 20px;
    padding-left: 0;
}

.footer-player__mode-icon .svg-container svg {
    width: 100%;
    height: 100%;
}

.footer-player__mode-icon-main#current {
    padding-left: 0;
}

#play .svg-container {
    width: 3rem;
    height: 3rem;
}

.footer-player__mode-icon-main .footer-player-active-icon-main {
    display: none;
}

.footer-player__mode-icon-main#autoprogress .footer-player-active-icon-main.autoprogress,
.footer-player__mode-icon-main#shuffle .footer-player-active-icon-main.shuffle,
.footer-player__mode-icon-main#repeat .footer-player-active-icon-main.repeat,
.footer-player__mode-icon-main#firstFifteen .footer-player-active-icon-main.clock,
.footer-player__mode-icon-main#current .footer-player-active-icon-main.playback-active-track {
    display: block;
}

.footer-player__mode-icon-main#autoprogress .footer-player-active-icon-main.autoprogress path {
    stroke: #62d3ce;
}

.footer-player__mode-icon-main#shuffle .footer-player-active-icon-main.shuffle path {
    fill: #62d3ce;

    &:last-child {
        fill: none;
    }
}

.footer-player__mode-icon-main#repeat .footer-player-active-icon-main.repeat path {
    fill: #62d3ce;

    &:last-child {
        fill: none;
    }
}

.footer-player__mode-icon-main#first-fifteen .footer-player-active-icon-main.clock path {
    fill: #62d3ce;

    &:last-child {
        fill: none;
    }
}

.footer-player__mode-icon-main#current .footer-player-active-icon-main.playback-active-track path {
    fill: #62d3ce;

    &:last-child {
        fill: none;
    }
}

// Progress bar
// ---------------------------------------------------------

#progress-bar {
    overflow: hidden;
}

// Volume slider
// ---------------------------------------------------------

#slider-vertical {
    visibility: hidden;
    opacity: 0;
    transition: 0.2s;
    position: absolute;
    right: 50%;
    transform: translateX(50%);
    left: inherit;
    bottom: 100%;
    height: 112px;
    width: 14px;
    background: $black;
    border-radius: 0;
    border: 1px solid $black;

    &::before {
        content: "";
        position: absolute;
        left: -10px;
        bottom: -1px;
        width: calc(100% + 20px);
        height: 10px;
        background: #404245;
    }

    &::after {
        content: "";
        position: absolute;
        bottom: 0;
        right: 50%;
        transform: translateX(50%);
        width: calc(100% + 20px);
        height: calc(100% + 20px);
        background: #404245;
    }

    .ui-slider-handle {
        cursor: pointer;
        outline: none;
    }

    .ui-slider-range {
        background: $uppbeat;
        width: 2px;
        position: absolute;
        right: 50%;
        transform: translateX(50%);
        left: inherit;
    }

    .ui-slider-range.ui-corner-all {
        border-radius: 0;
    }
}

#slider-vertical-tail {
    position: absolute;
    top: 100%;
    right: 50%;
    transform: translate(50%);
    width: 16px;
    height: 18px;
    display: flex;
    align-items: flex-start;
}

.volume-active #slider-vertical {
    visibility: visible;
    opacity: 1;
}

.footer-player__volume-slider {
    display: none;
    position: relative;
    margin-right: 0;

    @media only screen and (min-width: 768px) {
        display: flex;
        align-items: center;
    }
}

.footer-player__volume-slider #slider-vertical .ui-slider-handle {
    border-radius: 50%;
    background: $gray-dark-04;
    border: 2px solid $gray-light-01;
    left: 0;
    width: 12.5px;
    height: 12.5px;
}

// Disabled playback state
// ---------------------------------------------------------

.disabled-playback svg {
    opacity: 0.5;
}

// Settings
// ---------------------------------------------------------

.player-settings {
    user-select: none;
    position: relative;
    cursor: pointer;
    margin-right: 20px;
    color: $white;

    @media only screen and (min-width: 768px) {
        display: none;
    }
}

.player-settings.active .player-tooltip--responsive {
    opacity: 1;
    visibility: visible;
}

// Info
// ---------------------------------------------------------

.normalised-score-readout {
    display: block;
    font-size: 11px;
    white-space: nowrap;
    padding-inline-start: 0.875rem;

    > div {
        display: inline-block;
    }

    .meta {
        color: $gray-medium-03;
        position: relative;
        width: 15px;

        .player-tooltip {
            bottom: calc(100% + 10px);
            left: 5px;
            min-width: 60px;
            right: auto;
            transform: translateX(-50%);
        }
    }

    .set {
        color: #80ace3;
        position: relative;
        width: 20px;

        .player-tooltip {
            bottom: calc(100% + 10px);
            left: 5px;
            min-width: 60px;
            right: auto;
            transform: translateX(-50%);
        }
    }

    .data {
        color: #80e3cd;
        white-space: nowrap;
    }

    span {
        margin-right: 7px;

        &:last-child {
            margin-right: 0;
        }
    }
}

.uppbeataa-readout {
    background: $white;
    bottom: 75px;
    color: $black;
    padding: 10px 20px;
    position: absolute;

    .target-hit {
        color: $green;
    }
}

// Sub title
// ---------------------------------------------------------

.browse-player__sub-title {
    color: $gray-medium-03;
    white-space: nowrap;
    display: block;
    font-family: $font-family-secondary;
    font-size: 11px;
    font-weight: 400;
    max-width: 100%;
    transition: 0.2s;

    @media only screen and (min-width: 992px) {
        font-size: 0.875rem;
    }
}

.track-duration-length {
    font-size: 0.6875rem;
    font-weight: 500;
    @include c(--browse-tracks-duration-c);

    @media only screen and (min-width: 768px) {
        font-size: 0.8125rem;
    }
}

.current-time.ub-btn {
    display: none;
    margin-inline-start: 1rem;
    width: 42px;
    @media only screen and (min-width: 992px) {
        display: flex;
    }
}

.total-time.ub-btn {
    display: none;
    margin-inline-end: 1rem;
    min-width: 42px;
    @media only screen and (min-width: 992px) {
        display: flex;
    }
}

// Controls
// ---------------------------------------------------------

#controls {
    margin-bottom: 10px;

    @media only screen and (min-width: 320px) {
        margin-bottom: 0;
    }
}

// Player widths
// ---------------------------------------------------------

.player-width-controls {
    height: 100%;
    display: flex;
    align-items: center;
    align-content: center;
    margin-inline-end: 0.75rem;
    gap: 0.25rem;
    z-index: 1;

    > .ub-btn:first-of-type {
        display: none;

        @media only screen and (min-width: 992px) {
            display: flex;
        }
    }

    &.uppbeataa-controls {
        > .ub-btn:last-of-type {
            display: none;

            @media only screen and (min-width: 792px) {
                display: flex;
            }
        }
    }

    + h3,
    + .ub-btn + h3 {
        padding: 0 1rem;
        flex-grow: 1;
        text-align: left;
    }
}

.player-width-waveform {
    flex: 1;
    display: flex;

    @media only screen and (min-width: 768px) {
        margin-inline: 0.75rem;
    }
}

.player-width-duration {
    color: $gray-dark-04;
}

.player-width-volume {
    height: 100%;
}

.player-width-sub-cart .browse-player__sub-license {
    cursor: pointer;
    background: $uppbeat;
    border-radius: 50px;
    font-size: 12px;
    color: $white;
    line-height: 20px;
    padding: 4px 16px;
    transition: 0.2s;

    span {
        display: none;

        @media only screen and (min-width: 992px) {
            display: inline;
        }
    }

    .download-svg-icon {
        color: $white;

        @media only screen and (min-width: 992px) {
            margin-left: 5px;
        }

        img {
            vertical-align: sub;
            width: 16px;
            height: 16px;
        }
    }
}

.player-width-sub-cart:hover {
    opacity: 1;
}

.footer-image {
    display: none;

    @media only screen and (min-width: 992px) {
        display: block;
    }
}

.footer-player-download-btn.ub-btn {
    margin-inline-start: 0.5rem;
    padding: 0;


    .ub-btn-icon.ub-btn-icon {
        margin-inline-start: 0;
    }

    .ub-btn-label {
        display: none;
    }

    @media only screen and (min-width: 768px) {
        display: flex;
        padding: 0 1rem;

        .ub-btn-icon.ub-btn-icon {
            margin-inline-start: 0.5rem;
        }

        .ub-btn-label {
            display: flex;
        }
    }
}

.player-icon path {
    @include f(--player-icon-c);

    @media (hover: hover) {
        &:hover {
            @include f(--player-icon-hover-c);
        }
    }
}
