// Container
//
// Base container for content

.content-container {
    padding: 0 10px;
    margin: 0 auto;

    @media only screen and (min-width: 768px) {
        padding: 0 20px;
    }

    @media only screen and (min-width: 992px) {
        padding: 0 40px;
    }
}

.grey-background-content-container {
    margin: 40px 0;
    padding: 20px 0;
    @include bg(--browse-tracks-bg);

    @media only screen and (min-width: 768px) {
        border-radius: 18px 0 0 18px;
    }
}
