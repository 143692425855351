.landing-page-container {
	.landing-page-content {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		gap: 1rem;

		@media only screen and (min-width: 768px) {
			gap: 2rem;
		}

		@media only screen and (min-width: 992px) {
			gap: 3rem;
		}

		.page-title {
			text-align: center;
			padding: 4rem 2rem 0;
			max-width: 1000px;
			margin: 0 auto 2rem;
			align-items: center;

			&.no-margin {
				margin: 0 auto;

				+ .landing-page-header-wrapper {
					border-bottom: none;
					margin: 0;
				}
			}

			.page-subheading {
				font-weight: 400;
				font-family: $font-family-secondary;
				font-size: 1.25rem;
				margin-block-end: 2rem;
			}

			.ub-btn {
				margin-block-end: 1rem;
			}
		}

		.page-subtitle {
			text-align: center;
			margin-block-end: 3rem;
			font-size: 2rem;

			span {
				@include c(--main-brand-c);
			}
		}

		.landing-page-header-wrapper {
			margin-inline: 1.5rem;
			display: flex;
			justify-content: center;
			padding-block-end: 2rem;
			margin-block-end: 2rem;
			@include css4 {
				border-bottom: 1px solid cvar(--main-br);
			}

			@media only screen and (min-width: 768px) {
				margin-inline: 0;
				padding-inline: 3rem;
				border: none;
			}

			.landing-page-header {
				width: 100%;
				max-width: 1000px;
				border-radius: 1rem;
				display: flex;
				justify-content: center;
				align-items: center;
				overflow: hidden;
				padding: 1rem 1rem 0;
				background: $white;

				&-small {
					width: 100%;
					max-width: 800px;
				}
			}
		}

		.landing-page-feature-wrapper {
			padding-inline: 1.5rem;
			display: flex;
			justify-content: center;
			margin-block-end: 3rem;

			@media only screen and (min-width: 768px) {
				padding-inline: 3rem;
			}

			.landing-page-feature {
				width: 100%;
				max-width: 1000px;
				display: flex;
				flex-direction: column;
				gap: 3rem;

				@media only screen and (min-width: 768px) {
					flex-direction: row;
				}

				.landing-page-feature-image {
					display: flex;
					border-radius: 1.5rem;
					overflow: hidden;
					position: relative;
					aspect-ratio: 16 / 9;

					@media only screen and (min-width: 768px) {
						min-width: 50%;
						width: 50%;
						max-width: 50%;
					}
				}

				.landing-page-feature-text {
					display: flex;
					flex-direction: column;
					justify-content: center;

					h3 {
						font-size: 1.75rem;
						margin-block-end: 1rem;
					}

					p {
						margin-block-end: 1rem;
						font-family: $font-family-secondary;
						@include c(--main-primary-c);
					}

					a {
						width: fit-content;
					}
				}
			}

			&:nth-child(even) .landing-page-feature {
				@media only screen and (min-width: 768px) {
					flex-direction: row-reverse;
				}
			}
		}

		.trustpilot-widget-wrapper {
			padding: 3rem 1rem 0;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;

			.trustbox-wrapper {
				justify-content: center;

				.trustpilot-individual-wrapper {
					width: min-content;
				}
			}

			.trustpilot-widget {
				width: 16rem;
			}
		}

		.trustpilot-reviews-wrapper {
			width: 100%;
			padding: 3rem 0;

			@media only screen and (min-width: 768px) {
				padding: 5rem 0;
			}

			.slick-slide {
				width: 300px;
				height: auto;
			}
		}

		> span {
			padding: 2rem;
			max-width: 800px;
			text-align: center;
			color: $gray-medium-01;
			font-family: $font-family-secondary;

			@media only screen and (min-width: 768px) {
				padding: 0 2rem;
			}

			h3 {
				margin-block-end: 0.25rem;
			}
		}
	}

	.landing-page-video {
		pointer-events: none;
		width: 100%;
	}

	.faq-wrapper {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		padding-block: 2rem;
	}
}
