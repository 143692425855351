// Pagination
//
// Site pagination

.site-pagination {
    padding-bottom: 40px;
    margin-bottom: 40px;
    background: $black;
}

.site-pagination--secondary {
    background: none;
}

// Icon
// ---------------------------------------------------------

.site-pagination__icon {
    cursor: pointer;
    transition: 0.2s;

    &:hover {
        opacity: 0.6;
    }
}

.pagination-next-icon path  {
    transition: 0.2s;
}

.pagination-next-icon:hover path {
    fill: $white;

    &:last-child {
        fill: none;
    }
}

.site-pagination__icon svg .pagination-next-fill {
    transition: 0.2s;
}

.site-pagination__icon svg:hover .pagination-next-fill {
    fill: $white;
}

// Block
// ---------------------------------------------------------

.site-pagination__ellipses {
    display: inline-block;
    margin: 0 10px;
}

// Block
// ---------------------------------------------------------

.site-pagination__block {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

// Number
// ---------------------------------------------------------

.site-pagination__number {
    cursor: pointer;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin: 0 10px;
    font-size: 16px;
    color: $gray-medium-02;
    transition: 0.2s;

    &:hover {
        color: $white;
    }
}

.site-pagination__number--active {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    border: 2px solid $pagination-color-active;
    margin: 0 5px;
    color: $uppbeat;

    &:hover {
        color: $pagination-color-active;
    }
}

// Pagination
//
// Component for pagination

.pagination {
    display: flex;
    align-items: center;
    justify-content: center;

    input[type="text"] {
        width: 45px;
        margin-right: 10px;
        text-align: center;
    }
}

// Arrow
// ---------------------------------------------------------

.pagination-arrow {
    opacity: 0.5;
    width: 30px;
    height: 30px;
    margin-right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 15px;
    border-radius: 100%;
    border: 2px solid $gray-medium-02;

    @media only screen and (min-width: 420px) {
        margin-right: 20px;
    }

    &:last-child {
        margin-right: 0;
        margin-left: 10px;

        @media only screen and (min-width: 420px) {
            margin-left: 20px;
        }
    }
}

// Input
// ---------------------------------------------------------

.pagination input {
    width: 70px;
    margin-right: 10px;

    @media only screen and (min-width: 420px) {
        margin-right: 20px;
    }
}
