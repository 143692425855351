// Base
//
// Base styling

* {
    -webkit-font-smoothing: antialiased;
}

html, body {
    font-size: 16px; // Don't change, breaks everything
    -ms-overflow-style: scrollbar;

    // @media only screen and (min-width: 768px) {
    //     font-size: 14px; // Don't change, breaks everything
    // }

    // @media only screen and (min-width: 992px) {
    //     font-size: 15px; // Don't change, breaks everything
    // }

    // @media only screen and (min-width: 1281px) {
    //     font-size: 16px; // Don't change, breaks everything
    // }
}

body {
    height: 100%;
    max-height: -webkit-fill-available;
    font-family: $font-family-primary;
    line-height: 1.5;
    overflow-scrolling: touch;
    overscroll-behavior: none;
    @include bg(--main-bg);

    .app {
        height: 100vh; // Fallback for browsers that don't support dvh
        height: 100dvh;

        > div {
            height: 100vh; // Fallback for browsers that don't support dvh
            height: 100dvh;
            display: flex;
            flex-direction: column;
        }
    }

    @media only screen and (min-width: 992px) {
        &.filter-bar-active {
            padding-top: 131px;

            &.has-heads-up {
                padding-top: 171px;
            }
        }

        &.has-heads-up {
            padding-top: 93px;
        }
    }

    &.homepage {
        padding-top: 0;
        @media only screen and (min-width: 992px) {
            &.filter-bar-active {
                padding-top: 0;

                &.has-heads-up {
                    padding-top: 40px;
                }
            }

            &.has-heads-up {
                padding-top: 40px;
            }
        }

    }

    .distraction-free-only {
        display: none !important;
    }

    &.distraction-free {

        &.has-heads-up {
            padding-top: 53px !important;
        }

        & .distraction-free-hide {
            display: none !important;
        }

        & .distraction-free-only {
            display: flex !important;
        }
    }
}

img.emoji {
    height: 1em;
    width: 1em;
    margin: 0 .05em 0 .1em;
    vertical-align: -0.1em;
}

.help-center-container {
    @media only screen and (min-width: 768px) {
        height: 100%;
    }
}
