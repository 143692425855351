.referral-graph {
    position: relative;
    height: 120px;

    .referral-graph__chart {
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 75px;

        &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            display: block;
            width: 90%;
            @include css4 {
                border-top: dashed 1px cvar(--jumbotron-br);
            }
        }

        &::after {
            content: '';
            position: absolute;
            right: 10%;
            bottom: 0;
            display: block;
            width: 73%;
            @include css4 {
                border-top: dashed 1px cvar(--jumbotron-br);
            }
        }

        .referral-graph__chart__round {
            position: absolute;
            top: 0;
            right: 0;
            width: 10%;
            height: 100%;
            border-top-right-radius: 37px;
            border-bottom-right-radius: 37px;
            @include css4 {
                border-top: dashed 1px cvar(--jumbotron-br);
                border-right: dashed 1px cvar(--jumbotron-br);
                border-bottom: dashed 1px cvar(--jumbotron-br);
            }

            &.__active {
                margin-top: -1px;
                height: calc(100% + 2px);
                @include css4 {
                    border-top: solid 3px cvar(--jumbotron-active);
                    border-right: solid 3px cvar(--jumbotron-active);
                    border-bottom: solid 3px cvar(--jumbotron-active);
                }
            }
        }

        .referral-graph__chart__circle {
            position: absolute;
            z-index: 1;
            width: 9px;
            height: 9px;
            border-radius: 50%;
            @include bg(--jumbotron-alt-bg);
            @include css4 {
                border: dashed 1px cvar(--jumbotron-br);
            }

            &.__1 {
                left: 0;
            }

            &.__2 {
                left: 36%;
            }

            &.__3 {
                left: 74%;
            }

            &.__4 {
                left: 55%;
            }

            &.__5 {
                left: 15%;
            }

            &.__1,
            &.__2,
            &.__3 {
                top: -4px;
            }

            &.__4,
            &.__5 {
                bottom: -4px;
            }

            &.__active {
                @include bg(--jumbotron-active);
                @include css4 {
                    border-color: cvar(--jumbotron-active);
                }
            }
        }

        .referral-graph__chart__progress-top,
        .referral-graph__chart__progress-bottom {
            position: absolute;
            z-index: 2;
            width: 0;
            @include css4 {
                border-top: solid 3px cvar(--jumbotron-active);
            }
        }

        .referral-graph__chart__progress-top {
            top: -1px;
            left: 4px;
        }

        .referral-graph__chart__progress-bottom {
            right: 10%;
            bottom: -1px;
        }
    }

    .referral-graph_description {
        position: absolute;
        width: 110px;

        &.__1 {
            margin-left: calc(0% - 15px);
        }

        &.__2 {
            margin-left: calc(36% - 15px);
        }

        &.__3 {
            margin-left: calc(74% - 15px);
        }

        &.__4 {
            margin-left: calc(55% - 15px);
        }

        &.__5 {
            margin-left: calc(15% - 15px);
        }

        &.__4,
        &.__5 {
            bottom: 12px;
        }
    }
}
