// Loader
//
// Track loading screen

.music-loading-message {
    position: relative;
    background: none;
    min-height: 9rem;

    .music-loader-message-container {
        position: absolute;
        bottom: 50%;
        right: 50%;
        transform: translate(50%, 50%);
        width: 80%;
        padding: 20px;
        max-width: 300px;
        text-align: center;

        .music-loading-message-title {
            font-family: $font-family-heading;
            font-weight: 500;
            font-size: 1rem;
            margin-bottom: 1rem;
            @include c(--music-loading-message-c);
        }

        .circle-loader > div {
            display: inline-block;
            margin-right: 0.3125rem;
            width: 0.8125rem;
            height: 0.8125rem;
            border-radius: 100%;
            animation: sk-circle-bouncedelay 1.4s infinite ease-in-out both;
            @include bg(--music-loading-balls-bg);

            &.bounce1 {
                animation-delay: -0.32s;
            }

            &.bounce2 {
                animation-delay: -0.16s;
            }
        }
    }
}

.circle-loader.search-loader {
    padding: 114px 0 135px;
    text-align: center;
}

@-webkit-keyframes sk-circle-bouncedelay {
    0%, 100%, 80% {
        transform: scale(0)
    }

    40% {
        transform: scale(1)
    }
}

@keyframes sk-circle-bouncedelay {
    0%, 100%, 80% {
        transform: scale(0);
    }

    40% {
        transform: scale(1);
    }
}