@tailwind base;
@tailwind components;
@tailwind utilities;

@utility fill-mode-forwards {
  animation-fill-mode: forwards;
}

/* TODO: Remove this once we upgrade to Tailwind v4 */
.field-sizing-content {
  field-sizing: content;
}
