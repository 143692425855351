.ub-ai-page {
    .ub-ai-page_top {
        display: flex;
        flex-direction: column;
        padding: clamp(1.5rem, 4vw, 5rem) clamp(1.5rem, 3cqw, 3rem) clamp(8rem, 8vw, 10rem);
        position: relative;
        align-items: center;
        overflow: hidden;
        @include bg(--ai-page-bg);

        > img {
            position: absolute;
            width: 100%;
            max-width: 1000px;
            min-width: 500px;
            height: auto;
            user-select: none;
        }

        @media only screen and (min-width: 440px) {
            &::before {
                filter: blur(100px);
            }

            &::after {
                opacity: 0.35;
                filter: blur(100px);
            }
        }

        &.animate-out,
        &.search-completed {
            transition: padding-bottom 1s;
            padding: clamp(1.5rem, 4vw, 5rem) clamp(1.5rem, 3cqw, 3rem) 0;

            &::before,
            &::after {
                top: 45%;
            }
        }

        &.mobile-searching {
            padding: 1.5rem 1.5rem 0;
        }

        > div:first-child {
            position: absolute;
            top: 0;
            bottom: 0;
            right: 0;
            left: 0;
            background-size: 200px;
            background-image: url(../../svgs/tech-pattern.svg);
            background-repeat: repeat;
            opacity: 0.3;
            z-index: 2;
        }

        .page-title {
            text-align: center;
            align-items: center;
            padding: 0 1rem;
            margin-block-end: 3rem;
            z-index: 3;
            overflow: hidden;
            height: 360px;
            opacity: 1;

            @media only screen and (min-width: 434px) {
                height: 336px;
            }

            @media only screen and (min-width: 563px) {
                height: 312px;
            }

            @media only screen and (min-width: 694px) {
                height: 274px;
            }

            @media only screen and (min-width: 768px) {
                height: 370px;
            }

            @media only screen and (min-width: 914px) {
                height: 316px;
            }

            @media only screen and (min-width: 992px) {
                height: 370px;
            }

            @media only screen and (min-width: 1101px) {
                height: 316px;
            }

            > * {
                transform: scale(1);
                transition: transform 1s;
            }

            .ub-ai-page_ai-icon {
                text-align: center;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                margin-block-end: 1rem;

                > img {
                    width: 5rem;
                    height: 5rem;
                    border-radius: 2rem;
                    margin-block-end: 1rem;

                    @media only screen and (min-width: 768px) {
                        width: 6rem;
                        height: 6rem;
                    }
                }

                h1 {
                    font-size: 1.25rem;
                    @include c(--ai-page-logo-text-c);
                    @include css4 {
                        filter: drop-shadow(0 2px 2px cvar(--shadow-dark));
                    }

                    &::after {
                        content: 'BETA';
                        display: inline-block;
                        padding: 0.25rem 0.375rem;
                        background: $uppbeat;
                        color: $white;
                        position: relative;
                        font-size: 0.75rem;
                        font-family: $font-family-primary;
                        width: fit-content;
                        margin-left: 0.5rem;
                        transform: translateY(-4px);
                    }

                    @media only screen and (min-width: 434px) {
                        font-size: 1.5rem;
                    }
                }
            }

            .page-heading {
                font-size: 2.5rem;
                margin-block-end: 1.5rem;
                @include c(--ai-page-c);
                @include css4 {
                    filter: drop-shadow(0 2px 2px cvar(--shadow-dark));
                }

                @media only screen and (min-width: 768px) {
                    font-size: 3.5rem;
                }
            }

            p {
                font-size: 1rem;
                max-width: 560px;
                @include c(--ai-page-c);
                @include css4 {
                    filter: drop-shadow(0 2px 2px cvar(--shadow-dark));
                }

                span {
                    @include c(--ai-page-highlight-c);
                }

                @media only screen and (min-width: 768px) {
                    font-size: 1.125rem;
                }
            }

            &.animate-out {
                height: 0;
                opacity: 0;
                margin-block-end: 0;
                transition: height 1s, opacity 1s, margin 1s;

                > * {
                    transform: scale(0.5);
                }
            }

            &.hidden {
                height: 0;
                opacity: 0;
                margin-block-end: 0;
            }
        }

        .ub-ai-page_search-options {
            display: flex;
            flex-direction: column;
            align-items: center;
            z-index: 3;
            margin-block-end: 1.5rem;

            h2 {
                font-size: 1.375rem;
                height: 1.5rem;
                opacity: 1;
                transform: scale(1);
                overflow: hidden;
                margin-block-end: 1rem;
                @include c(--ai-page-c);

                &.animate-out {
                    height: 0;
                    opacity: 0;
                    transform: scale(0.5);
                    margin: 0;
                    transition: height 1s, opacity 1s, transform 1s, margin 1s;
                }

                &.hidden {
                    height: 0;
                    opacity: 0;
                    transform: scale(0.5);
                    margin: 0;
                }
            }

            .ub-ai-page_search-options_buttons {
                display: grid;
                grid-template-columns: 1fr 1fr;
                gap: 0.5rem;

                .ub-btn {
                    border-radius: 1.5rem;
                    border: 2px solid $transparent;
                    position: relative;

                    &:hover {
                        @include bg(--ai-page-search-option-bg);
                    }

                    &.active {
                        overflow: visible;
                        @include css4 {
                            border-color: cvar(--ai-page-search-option-br);
                        }
                    }

                    &:disabled {
                        opacity: 1;
                    }
                }

            }

            @media only screen and (min-width: 612px) {
                margin-block-end: 3rem;

                .ub-ai-page_search-options_buttons {
                    display: flex;

                    .ub-btn {
                        height: 3rem;

                        &::after {
                            content: '';
                            display: block;
                            position: absolute;
                            width: 2px;
                            height: 3rem;
                            top: calc(3rem - 2px);
                            left: calc(50% - 1px);
                        }

                        &.active::after {
                            @include bg(--ai-page-search-option-br);
                        }
                    }
                }
            }
        }

        .ub-ai-page_search-input {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: 0.5rem;
            z-index: 3;
            margin-block-end: 1rem;
            width: 100%;

            form {
                max-width: 1000px;
                width: 100%;
                border-radius: 1rem;
                display: flex;
                align-items: center;
                gap: 1rem;
                padding: 0.5rem 0.75rem;
                @include bg(--ai-page-input-bg);
                @include css4 {
                    border: 2px solid cvar(--ai-page-input-br);
                }

                &:focus-within {
                    @include css4 {
                        border: 2px solid cvar(--ai-page-input-active-br);
                    }
                }

                .ub-ai-page_textarea-wrapper {
                    display: grid;
                    width: 100%;

                    &::after {
                        content: attr(data-replicated-value) " ";
                        white-space: pre-wrap;
                        visibility: hidden;
                    }

                    > textarea {
                        resize: none;
                        overflow: hidden;

                         &:focus {
                            outline: none;
                            border: none;
                         }
                    }
                }

                .ub-ai-page_textarea-wrapper::after,
                .ub-ai-page_textarea-wrapper > textarea {
                    width: 100%;
                    padding: 0.5rem 0;
                    border: none;
                    grid-area: 1 / 1 / 2 / 2;
                    font-size: 1rem;
                    background: $transparent;
                }

                .ub-ai-page_search-functionality {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    gap: 0.5rem;
                }

                .ub-btn {
                    .ub-btn-icon {
                        transform: scale(0.85);
                    }
                }
            }

            > div {
                max-width: 1000px;
                width: 100%;
                display: flex;
                justify-content: space-between;
                align-items: center;
                gap: 1rem;

                > * {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-wrap: wrap;
                    white-space: nowrap;
                    column-gap: 0.5rem;
                    @include c(--ai-page-alt-c);

                    &:first-child {
                        row-gap: 0.25rem;
                        justify-content: flex-start;
                    }

                    &:last-child {
                        row-gap: 0;
                        flex-shrink: 2;
                        justify-content: flex-end;
                    }

                    > span {
                        font-size: 0.75rem;

                        @media only screen and (min-width: 440px) {
                            font-size: 0.875rem;
                        }
                    }

                    > span:first-of-type {
                        @include c(--ai-page-alt-c);
                    }

                    > span:last-of-type:not(:only-of-type) {
                        cursor: pointer;
                        @include c(--main-link-c);
                    }

                }

                img {
                    height: 1.25rem;

                    @media only screen and (min-width: 440px) {
                        height: 1.5rem;
                    }
                }
            }

            @media only screen and (min-width: 500px) {
                margin-block-end: 1.5rem;

                form {
                    min-height: 4rem;
                    border-radius: 2.5rem;
                    max-height: 7.5rem;
                    gap: 2rem;
                    padding: 0.5rem 1.5rem 0.5rem 2rem;

                    .ub-ai-page_textarea-wrapper::after,
                    .ub-ai-page_textarea-wrapper > textarea {
                        font-size: 1.25rem;
                        max-height: 8.5rem;
                    }

                    .ub-ai-page_search-functionality {
                        gap: 1rem;
                    }
                }

            }
        }

        .ub-ai-page_back-to-examples {
            width: auto;
            height: 2.5rem;
            margin-block-end: 2.5rem;
            overflow: hidden;
            z-index: 2;
            transition: opacity 0.3s;
            opacity: 0;
            pointer-events: none;
            user-select: none;

            .ub-btn .ub-btn-icon {
                transform: scale(0.8);
            }

            &.visible {
                opacity: 1;
                pointer-events: all;
                user-select: all;
            }
        }

        .ub-ai-page_results {
            height: 0;
            overflow: hidden;
            width: calc(100% + 3rem);
            max-width: 1920px;
            border-radius: 0;
            z-index: 3;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: 3rem;
            position: relative;
            @include bg(--ai-page-results-bg);

            &.searching {
                height: 440px;
                padding: 2rem;
                transition: height 0.3s, padding 0.3s;

                > h2 {
                    display: flex;
                    margin-inline-start: -0.25rem;

                    > img {
                        margin-inline-end: 0.5rem;
                    }

                    > div {
                        width: 0.25rem;
                        height: 0.25rem;
                        border-radius: 50%;
                        position: relative;
                        margin: 0 0 0.1875rem 0.25rem;
                        align-self: flex-end;
                        animation: bulletFlashV1 1s infinite;
                        @include bg(--h1-c);

                        &::before,
                        &::after {
                            content: '';
                            display: block;
                            position: absolute;
                            width: 0.25rem;
                            height: 0.25rem;
                            border-radius: 50%;
                            @include bg(--h1-c);
                        }

                        &::before {
                            right: -0.375rem;
                            animation: bulletFlashV2 1s infinite;
                        }

                        &::after {
                            right: -0.75rem;
                            animation: bulletFlashV3 1s infinite;
                        }
                    }
                }

                &.mobile {
                    transition: none;
                }

                .ub-ai-page_ai-progress {
                    > svg g circle {
                        transition: stroke 0.2s, stroke-dashoffset 0.8s;
                        @include css4 {
                            stroke: cvar(--percentage-indicator-blue);
                        }
                    }

                    > div > svg path {
                        @include f(--percentage-indicator-blue);
                    }

                    &.success {
                        animation: success 0.6s forwards;
                    }
                }
            }

            &.search-completed {
                height: 360px;
                padding: 2rem;
                border-radius: 0;
                gap: 2rem;

                .ub-ai-page_results-heading {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    gap: 1rem;

                    p {
                        max-width: 600px;
                        text-align: center;
                        font-size: 0.875rem;
                    }

                    .ub-btn .ub-btn-icon {
                        filter: brightness(5);
                    }
                }
            }

            .ub-progress-indicator_wrapper {
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 1rem;

                > span {
                    h3 {
                        font-size: 0.75rem;
                    }

                    span {
                        @include c(--p-c);
                    }
                }
            }

            @media only screen and (min-width: 440px) {
                width: 100%;
                border-radius: 1rem;

                &.searching {
                    height: 360px;
                }

                &.playlist-visible {
                    border-radius: 1rem 1rem 0 0;
                }

                &.search-completed {
                    height: 360px;
                    border-radius: 1rem 1rem 0 0;

                    .ub-ai-page_results-heading p {
                        font-size: 1rem;
                    }
                }
            }

            @media only screen and (min-width: 768px) {
                .ub-progress-indicator_wrapper {
                    position: absolute;
                    top: 2rem;
                    left: 2rem;
                }
            }
        }

        .ub-ai-page_renewal-alert {
            width: 100%;
            max-width: 1000px;
            height: auto;
            padding: 1rem 2rem;
            background: $uppbeat;
            margin-block-end: 5rem;
            z-index: 2;
            border-radius: 0.75rem;
            color: $white;
            text-align: center;
        }

        .ub-ai-page_examples-wrapper {
            z-index: 2;
            width: 100%;
            height: 272px;

            > h2 {
                text-align: center;
                font-size: 1.375rem;
                margin-block-end: 2rem;
                @include c(--ai-page-highlight-c);

                span {
                    font-family: $font-family-primary;
                    @include c(--ai-page-c);
                }
            }

            .ub-ai-page_examples {
                width: 100%;
                display: none;
                flex-direction: column;
                align-items: center;
                z-index: 3;
                height: 216px;
                opacity: 1;
                transform: scale(1);

                .ub-ai-page_examples-grid {
                    display: grid;
                    grid-template-columns: repeat(3, minmax(0, 1fr));
                    gap: 1rem;
                    max-width: 1200px;
                    width: 100%;
                    height: 200px;

                    > div {
                        position: relative;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        color: $white;
                        border-radius: 1rem;
                        padding: 2rem;
                        height: 100px;
                        background-image: linear-gradient(180deg, rgba($white, 0.1), rgba($white, 0.05));
                        overflow: hidden;

                        > img {
                            position: absolute;
                            opacity: 0;
                            transition: opacity 0.2s;
                            z-index: 2;
                            right: 1rem;
                            bottom: 1rem;
                        }

                        p {
                            text-align: center;
                            @include c(--ai-page-c);

                            > img {
                                display: inline-block;
                            }
                        }

                        &:hover {
                            cursor: pointer;
                            background-image: linear-gradient(180deg, rgba($white, 0.15), rgba($white, 0.075));

                            img {
                                opacity: 1;
                            }
                        }
                    }
                }

                @media only screen and (min-width: 768px) {
                    display: flex;
                }
            }

            .ub-ai-page_examples-carousel {
                width: 100%;
                z-index: 2;
                height: 200px;

                .ub-carousel .slick-slider .slick-list .slick-track > div {
                    padding: 0 1rem;
                    max-width: 85vw;
                    width: 85vw;

                    > div {
                        max-width: 85vw;
                        width: 85vw;

                        > div {
                            width: 100%;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            flex-direction: column;
                            gap: 0.5rem;
                            color: $white;
                            border-radius: 1rem;
                            padding: 2rem;
                            height: 8rem;
                            background-image: linear-gradient(180deg, rgba($white, 0.05), rgba($white, 0.025));

                            @media only screen and (min-width: 400px) {
                                gap: 1rem;
                            }
                        }
                    }

                    > img {
                        opacity: 1;
                        transition: opacity 0.2s;
                        z-index: 2;
                        width: 0.75rem;
                        height: 0.75rem;

                        @media only screen and (min-width: 400px) {
                            width: 1rem;
                            height: 1rem;
                        }
                    }

                    p {
                        text-align: center;
                        @include c(--ai-page-c);

                        > img {
                            display: inline-block;
                        }
                    }

                    &.slick-active > div > div {
                        background-image: linear-gradient(180deg, rgba($white, 0.1), rgba($white, 0.05));

                        &:hover {
                            cursor: pointer;
                            background-image: linear-gradient(180deg, rgba($white, 0.15), rgba($white, 0.075));

                            img {
                                opacity: 1;
                            }
                        }
                    }
                }

                @media only screen and (min-width: 768px) {
                    display: none;
                }
            }

            &.animate .ub-ai-page_examples-grid {
                > div:first-child p {
                    animation: animateExamples 0.5s forwards ease-out;
                }
                > div:nth-child(2) p {
                    animation: animateExamples 0.5s forwards ease-out;
                }
                > div:nth-child(3) p {
                    animation: animateExamples 0.5s forwards ease-out;
                }
                > div:nth-child(4) p {
                    animation: animateExamples 0.5s forwards ease-out;
                }
                > div:nth-child(5) p {
                    animation: animateExamples 0.5s forwards ease-out;
                }
                > div:nth-child(6) p {
                    animation: animateExamples 0.5s forwards ease-out;
                }
            }

            &.animate .ub-ai-page_examples-carousel {
                > div {
                    animation: animateExamples 0.5s forwards ease-out;
                }
            }

            &.animate-out {
                height: 0;
                opacity: 0;
                transform: scale(0.5);
                transition: height 1s, opacity 1s, transform 1s;
                pointer-events: none;
            }

            &.hidden {
                height: 0;
                opacity: 0;
                pointer-events: none;
            }
        }

        > svg {
            position: absolute;
            width: 100% ;
            bottom: -1px;
            left: 0;
            z-index: 2;

            path {
                @include f(--main-bg);
            }
        }

        + svg {
            width: 100%;
        }

        @media only screen and (min-width: 600px) {
            padding: 5rem clamp(1.5rem, 3cqw, 3rem) clamp(8rem, 8vw, 10rem);

            &.animate-out,
            &.search-completed {
                padding: 5rem clamp(1.5rem, 3cqw, 3rem) 0;

                &::before,
                &::after {
                    top: 45%;
                }
            }
        }
    }

    .ub-ai-page_bottom {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding-inline: clamp(1.5rem, 3cqw, 3rem);

        .ub-ai-page_track-listing {
            height: 0;
            width: calc(100% + 3rem);
            max-width: 1920px;
            border-radius: 0;
            z-index: 3;
            opacity: 0;
            transition: height 0.5s, opacity 0.5s;
            overflow: hidden;
            @include bg(--ai-page-results-alt-bg);

            &.search-completed {
                animation: showTracks 1.5s forwards;

                .browse-player-wrapper {
                    padding: 1rem 0 1rem 0.5rem;

                    .browse-player {
                        padding: 0;

                        .browse-player__tracks {
                            padding: 0 !important;
                        }

                        .ub-track {
                            padding: 0.5rem 0.75rem;
                            border-radius: 3rem 0 0 3rem;

                            @media only screen and (min-width: 440px) {
                                border-radius: 3rem;
                            }

                            @media only screen and (min-width: 768px) {
                                padding: 0.625rem 1.5rem;
                            }
                        }
                    }

                    @media only screen and (min-width: 440px) {
                        padding: 1rem;
                    }
                }
            }

            @media only screen and (min-width: 440px) {
                width: 100%;
                border-radius: 0 0 1rem 1rem;
            }
        }

        .ub-ai-page_error {
            height: 0;
            overflow: hidden;
            width: calc(100% + 3rem);
            max-width: 1920px;
            border-radius: 0;
            z-index: 3;
            opacity: 0;
            transition: height 0.5s, opacity 0.5s, padding 0.5s;
            padding: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            background: $red;
            filter: brightness(1.5);

            p {
                width: 100%;
                max-width: 720px;
                text-align: center;
                color: $white;
                font-weight: 600;
            }

            &.show {
                height: 100px;
                opacity: 1;
                padding: 2rem;
            }

            @media only screen and (min-width: 420px) {
                width: 100%;
                border-radius: 0 0 1rem 1rem;
            }
        }

        .ub-ai-page_product-hunt {
            display: block;
            margin-block-start: 3rem;
        }

        .ub-ai-page_about {
            display: grid;
            grid-template-columns: 1fr;
            grid-template-rows: 0.25fr 1fr 1fr 1fr 0.75fr 1fr 1fr 1fr;
            grid-auto-flow: column;
            row-gap: 0.75rem;
            width: 100%;
            padding-block: 3rem 4rem;
            max-width: 1000px;

            > h2 {
                text-align: center;
                align-self: end;
                margin-block-end: 1rem;
            }

            > div {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                border-radius: 1rem;
                padding: 1rem;
                @include bg(--ai-page-about-bg);

                > p {
                    text-align: center;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-direction: column;
                    width: 85%;
                }

                div {
                    display: block;
                    font-size: 1.25rem;
                    margin-block-end: 0.25rem;
                }
            }

            @media only screen and (min-width: 580px) {
                grid-template-columns: repeat(2, minmax(0, 1fr));
                grid-template-rows: 0.25fr 1fr 1fr 1fr;
                column-gap: 1rem;
                row-gap: 0.75rem;
                padding-block: 4rem;

                > div {
                    padding: 1.5rem;

                    div {
                        font-size: 1.5rem;
                        margin-block-end: 0.75rem;
                    }
                }
            }

            @media only screen and (min-width: 768px) {
                column-gap: 2rem;
                row-gap: 1rem;
                padding-block: 5rem;

                > div {
                    padding: 1.5rem;
                }
            }
        }
    }

    .ub-ai-page_video {
        width: 100%;
        max-width: 1000px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 auto 5rem;
        z-index: 4;
        position: relative;

        div {
            width: 100%;
            max-width: 1000px;
            padding-top: 56.25%;
            position: relative;

            iframe {
                position: absolute;
                width: 100%;
                top: 0;
                bottom: 0;
                right: 0;
                left: 0;
                height: 100%;
                border-radius: none;
                border: none;

                @media only screen and (min-width: 1256px) {
                    border-radius: 1rem;
                }
            }
        }
    }

    .ub-ai-page_faq {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-block-end: 5rem;
        width: 100%;

        h2 {
            text-align: center;
            margin-block-end: 2rem;
        }

        .ub-accordion.premium-user {
            li:first-child,
            li:nth-child(2) {
                display: none;
            }
        }
    }
}

@keyframes animateExamples {
    0% {
        opacity: 1;
        transform: translateY(0%);
    }
    1% {
        opacity: 0;
        transform: translateY(0%);
    }
    20% {
        opacity: 0;
        transform: translateY(50%);
    }
    100% {
        opacity: 1;
        transform: translateY(0%);
    }
}

@keyframes bulletFlashV1 {
    0% {
        opacity: 0;
    }
    20% {
        opacity: 0;
    }
    30% {
        opacity: 1;
    }
    100% {
        opacity: 1;
    }
}

@keyframes bulletFlashV2 {
    0% {
        opacity: 0;
    }
    45% {
        opacity: 0;
    }
    55% {
        opacity: 1;
    }
    100% {
        opacity: 1;
    }
}

@keyframes bulletFlashV3 {
    0% {
        opacity: 0;
    }
    70% {
        opacity: 0;
    }
    80% {
        opacity: 1;
    }
    100% {
        opacity: 1;
    }
}

@keyframes success {
    0% {
        @include css4 {
            outline: 0 solid cvar(--percentage-indicator-blue);
        }
    }

    100% {
        outline: 1rem solid transparent;
    }
}

@keyframes showTracks {
    0%,
    99% {
        opacity: 0;
        height: 0;
    }

    100% {
        opacity: 1;
        height: fit-content;
    }
}

@keyframes showButton {
    0% {
        opacity: 0;
        transform: scale(0.5);
    }

    100% {
        opacity: 1;
        transform: scale(1);
    }
}
