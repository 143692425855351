// SVG
//
// Svg sizing

// Container
// ---------------------------------------------------------

.svg-container {
    display: inline-block;
}

.svg-container svg,
.svg-container img {
    width: 100%;
    height: 100%;
}

// Light
// ---------------------------------------------------------

.svg-container.light svg, .svg-container.light path, .svg-container.light polyline, .svg-container.light line {
    fill: $white;
    stroke: $white;
}

.svg-container.light-stroke svg, .svg-container.light-stroke path, .svg-container.light-stroke polyline, .svg-container.light-stroke line {
    stroke: $white;
}

// Gray
// ---------------------------------------------------------

.svg-container.gray svg, .svg-container.gray path, .svg-container.gray polyline, .svg-container.gray line {
    fill: $gray-medium-02;
    stroke: $gray-medium-02;
}

// Dark
// ---------------------------------------------------------

.svg-container.dark svg, .svg-container.dark path, .svg-container.dark polyline, .svg-container.dark line {
    fill: $black;
    stroke: $black;
}

// Play icon
// ---------------------------------------------------------

.svg-container.play-icon svg {
    fill: $white;
}

.svg-container.play-icon svg path {
    fill: $gray-medium-01;
}

// Extra Tiny
// ---------------------------------------------------------

.svg-x-tiny {
    width: 12.5px;
    height: 12.5px;
}

// Tiny
// ---------------------------------------------------------

.svg-tiny {
    width: 17.5px;
    height: 17.5px;
}

// Xxs-Small
// ---------------------------------------------------------

.svg-xxs {
    width: 20px;
    height: 20px;
}

// X-Small
// ---------------------------------------------------------

.svg-xs {
    width: 35px;
    height: 35px;
}

// Small
// ---------------------------------------------------------

.svg-sm {
    width: 50px;
    height: 50px;
}

// Semi Medium
// ---------------------------------------------------------

.svg-s-md {
    width: 60px;
    height: 60px;
}

// Large Medium
// ---------------------------------------------------------

.svg-l-md {
    width: 65px;
    height: 65px;
}

// Medium
// ---------------------------------------------------------

.svg-md {
    width: 100px;
    height: 100px;
}

// Extra Medium
// ---------------------------------------------------------

.svg-extra-md {
    width: 125px;
    height: 125px;
}
