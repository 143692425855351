@font-face {
    font-family: 'Brandon';
    src: url('../../fonts/HVDFontsBrandonTextRegular.eot');
    src: url('../../fonts/HVDFontsBrandonTextRegular.eot') format('embedded-opentype'),
    url('../../fonts/HVDFontsBrandonTextRegular.woff2') format('woff2'),
    url('../../fonts/HVDFontsBrandonTextRegular.woff') format('woff'),
    url('../../fonts/HVDFontsBrandonTextRegular.ttf') format('truetype'),
    url('../../fonts/HVDFontsBrandonTextRegular.svg#HVDFontsBrandonTextRegular') format('svg');
}

@font-face {
    font-family: 'Brandon';
    src: url('../../fonts/HVDFontsBrandonTextBold.eot');
    src: url('../../fonts/HVDFontsBrandonTextBold.eot') format('embedded-opentype'),
    url('../../fonts/HVDFontsBrandonTextBold.woff2') format('woff2'),
    url('../../fonts/HVDFontsBrandonTextBold.woff') format('woff'),
    url('../../fonts/HVDFontsBrandonTextBold.ttf') format('truetype'),
    url('../../fonts/HVDFontsBrandonTextBold.svg#HVDFontsBrandonTextBold') format('svg');
    font-weight: bold;
}

.font-regular {
    font-family: Roboto, Arial, Helvetica, sans-serif;
}

.font-branded {
    font-family: Brandon, Arial, Helvetica, sans-serif;
}

.font-size-8 {
    font-size: 8px;
}

.font-size-9 {
    font-size: 9px;
}

.font-size-10 {
    font-size: 10px;
}

.font-size-11 {
    font-size: 11px;
}

.font-size-12 {
    font-size: 12px;
}

.font-size-13 {
    font-size: 13px;
}

.font-size-14 {
    font-size: 14px;
}

.font-size-15 {
    font-size: 15px;
}

.font-size-20 {
    font-size: 20px;
}

.font-size-25 {
    font-size: 25px !important;  // scss-lint:disable ImportantRule
}

.line-height-20 {
    line-height: 20px;
}

.line-height-25 {
    line-height: 25px;
}

.line-height-30 {
    line-height: 30px;
}

.font-weight-medium {
    font-weight: 500;
}