// Toggle play/pause
// ---------------------------------------------------------

[class^="icon-static"],
[class*="icon-static"] {
    display: inline-block;
    width: 18px;
    height: auto;
    // padding: 5px;
}

[class^="icon-postion"],
[class*="icon-postion"] {
    position: absolute;
    right: 0;
    margin-top: 4px;
}

.icon-arrow-right {
    background: url("https://cdn.musicvine.com/images/icons/caret-r.svg");
    margin-right: 5px;
    background-repeat: no-repeat;
    background-size: 4px;
    background-position: top;
    vertical-align: middle;
}
