.account-container {
    .account-content {
        padding: 1.5rem;
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        @media only screen and (min-width: 768px) {
            padding: 1.5rem 3rem;
        }

        .account-single-wrapper {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            width: 100%;
            padding-block: 3rem;
            @include css4 {
                border-top: 1px solid cvar(--ub-border-light);
            }

            &:first-of-type {
                padding-block-start: 0;
            }

            .account-title {
                margin: 0 0 1.5rem;
            }

            &.account-details {
                border: none;

                .alert-general-success {
                    margin-block-end: 3rem;
                }
            }

            &.youtube-channel .modal-container p.youtube-description {
                margin-block-end: 0.75rem;
            }

            &.account-details > *,
            &.youtube-channel > * {
                max-width: 380px;

                &.modal-container {
                    align-items: flex-start;
                }
            }

            &.appearance-wrapper {
                > div {
                    display: flex;
                    gap: 1rem;
                    width: 100%;

                    @media only screen and (min-width: 768px) {
                        gap: 2rem;
                    }

                    > div {
                        width: calc(50% - 0.5rem);
                        max-width: 320px;
                        height: auto;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        gap: 1rem;

                        @media only screen and (min-width: 768px) {
                            width: calc(50% - 1rem);
                            gap: 2rem;
                        }

                        svg {
                            cursor: pointer;
                            width: 100%;
                            height: fit-content;
                            border-radius: 0.5rem;
                            transition: filter 0.2s, border-radius 0.2s;
                            border: 2px solid $transparent;

                            @media (hover: hover) {
                                &:hover {
                                    filter:brightness(0.95)
                                }
                            }

                            @media only screen and (min-width: 500px) {
                                border-radius: 0.75rem;
                            }

                            @media only screen and (min-width: 768px) {
                                border-radius: 1rem;
                            }
                        }

                        span {
                            font-size: 1.25rem;
                            display: flex;
                            gap: 0.75rem;
                            justify-content: center;
                            align-items: center;
                            @include c(--main-secondary-c);

                            .faux-checkbox {
                                width: 1.5rem;
                                height: 1.5rem;
                                border-radius: 50%;
                                cursor: pointer;
                                @include bg(--checkbox-bg);
                                @include css4 {
                                    border: 2px solid cvar(--checkbox-br);
                                }

                                img {
                                    display: none;
                                    padding: 1px;
                                    filter: brightness(4);
                                }
                            }
                        }

                        &.light-appearance {
                            svg {
                                @media (hover: hover) {
                                    &:hover {
                                        filter:brightness(0.95)
                                    }
                                }
                            }
                        }

                        &.dark-appearance {
                            svg {
                                @media (hover: hover) {
                                    &:hover {
                                        filter:brightness(0.9)
                                    }
                                }
                            }
                        }


                        &.active {
                            svg {
                                border: 2px solid $uppbeat;
                            }

                            .faux-checkbox {
                                @include bg(--checkbox-active-bg);
                                @include css4 {
                                    border: 2px solid cvar(--checkbox-active-br);
                                }

                                img {
                                    display: block;
                                }
                            }
                        }
                    }
                }
            }

            &.refer-a-friend-wrapper {
                > * {
                    max-width: 430px;
                    width: 100%;
                }

                > div:first-of-type {
                    label + div {
                        overflow: hidden;
                    }
                }
            }

            &.w-20,
            .w-20 {
                width: 20%;
            }

            &.w-280,
            .w-280 {
                width: 280px;
            }

            &.w-40 {
                width: 40%;
            }

            &.w-430 {
                max-width: 430px;
            }

            &.w-540 {
                max-width: 540px;
            }

            .save-btn,
            .subpage-btn {
                margin-top: 15px;

                &.premium-btn {
                    margin-top: 0;
                    width: 70%;
                    padding: 7px 10px;
                }

                &.submitting {
                    opacity: 50%;
                }
            }

            .subpage-btn {
                margin-top: 20px;
                text-align: center;
                width: 180px;
            }

            .youtube-description {
                font-size: 0.875rem;
            }

            .your-plan-description {
                font-family: $font-family-heading;
                font-size: 0.875rem;
                @include c(--h2-c);
            }

            .plan-box {
                width: 100%;
                font-size: 0.75rem;
                margin-block-start: 3rem;
                text-align: left;
                overflow: hidden;
                border-radius: 0.5rem;
                @include css4 {
                    box-shadow: 0 0px 10px 1px cvar(--shadow-dark);
                }

                @media only screen and (min-width: 500px) {
                    font-size: 0.875rem;
                }

                table {
                    width: 100%;
                    height: 330px;

                    th {
                        font-family: $font-family-secondary;
                        font-weight: 600;
                        height: 75px;
                        vertical-align: top;
                        padding-top: 13px;
                        @include c(--h4-c);

                        &.tf {
                            height: 55px !important;
                        }

                        .secondary {
                            @include c(--account-plan-primary-c);

                            .price-tag {
                                font-size: 18px;
                            }
                        }
                    }

                    tr {
                        height: 34px;
                        @include bg(--account-plan-primary-bg);

                        &.light-blue {
                            @include bg(--account-plan-secondary-bg);
                        }
                    }

                    td {
                        font-family: $font-family-secondary;
                        font-weight: 500;
                        @include c(--p-c);

                        &.secondary {
                            font-style: italic;
                            padding-left: 15px;
                            font-weight: 100;
                        }

                        &.emoji-cell {
                            font-size: 18px;
                        }
                    }

                    .plan-icon {
                        width: 14px;
                    }
                }

                .account-plan-footer {
                    tr {
                        &:first-child {
                            @media only screen and (min-width: 768px) {
                                display: none;
                            }

                            th {
                                padding: 1rem;
                            }
                        }

                        &:last-child {
                            display: none;

                            @media only screen and (min-width: 768px) {
                                display: table-row;
                            }

                            th {
                                padding: 1rem 1rem 1rem 0;
                            }
                        }

                    }
                }
            }

            .plan-box-disclaimer {
                font-size: 0.8125rem;
            }

            &.refer-a-friend-wrapper label {
                margin-block-start: 1rem;
                @include c(--h2-c);
            }
        }

        .border-line {
            border-bottom: 1px inset;
        }
        .referral-graph {
            margin-right: 20px;
            margin-left: 40px;

            @media only screen and (max-width: 419px) {
                margin-right: 0;
                margin-left: 10px;
            }
        }
        .progress_premium {
            width: 320px;

            @media only screen and (max-width: 419px) {
                width: 200px;
            }
        }
    }


    //Artist Account Styling
    .artist-account-container {
        align-content: center;

        .artist-page-tabs-content {
            @include css4 {
                border-bottom: 1px solid cvar(--main-br);
            }

            .artist-tabs {
                display: flex;
                margin-left: 40px;

                @media only screen and (max-width: 767px) {
                    justify-content: space-around;
                    margin-left: 0;
                }

                .tab {
                    margin-right: 40px;
                    line-height: 28px;
                    font-size: 16px;
                    font-weight: 500;
                    cursor: pointer;
                    transform: translateY(3px);
                    @include c(--p-c);

                    @media only screen and (max-width: 767px) {
                        margin-right: 0;
                    }

                    &.active {
                        @include c(--link-c);
                        @include css4 {
                            border-bottom: 3px solid cvar(--link-c);
                        }
                    }
                }

            }
        }

        //Artist Downloads Styling
        .artist-download-container {
            margin-block-start: 1.5rem;

            .filter-content {
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                padding-inline: 1.5rem;
                gap: 1rem;

                @media only screen and (min-width: 768px) {
                    padding-inline: 3rem;
                }

                @media only screen and (min-width: 992px) {
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                }

                .input-wrapper {
                    max-width: 280px;

                    select {
                        width: 100%;
                    }

                    .select-icon span {
                        &::before, &::after {
                            @include bg(--input-placeholder-c);
                        }
                    }
                }

                .artist-account-input-field {
                    font-family: $font-family-secondary;
                    font-weight: 500;
                    font-size: 13px;
                    @include c(--h3-c);

                    @media only screen and (max-width: 767px) {
                        width: 280px;
                    }

                    option {
                        font-weight: 600;
                        font-size: 12px;
                        @include c(--h3-c);
                    }
                }

                .filter-items-wrapper {
                    display: flex;
                    flex-wrap: wrap;
                    gap: 1rem;

                    @media only screen and (min-width: 768px) {
                        flex-wrap: nowrap;
                    }

                    .filter-items {
                        display: flex;
                        align-items: center;

                        .odd-tab {
                            border-radius: 10px 0 0 10px;
                        }

                        .even-tab {
                            border-radius: 0 10px 10px 0;
                        }
                    }

                    .filter-tab {
                        flex-grow: 1;
                        min-width: 100px;
                        padding: 0.625rem 1rem;
                        text-align: center;
                        cursor: pointer;
                        font-family: $font-family-secondary;
                        font-size: 13px;
                        font-weight: 500;
                        white-space: nowrap;
                        @include bg(--button-secondary-bg);
                        @include c(--button-secondary-c);
                    }

                    .active {
                        @include bg(--button-uppbeat-bg);
                        @include c(--button-uppbeat-c);
                    }
                }


            }

            .separator {
                border: none;
                margin-block-start: 1.5rem;
                @include css4 {
                    border-bottom: 1px inset cvar(--main-br);
                }
            }

            .downloaded-artist-track-content {
                display: flex;
                flex-direction: column;
                margin-top: 30px;
                margin-left: 40px;

                @media only screen and (max-width: 767px) {
                    margin: 0 20px 0 20px;
                }

                .pagination-container {
                    width: 565px;
                    padding-block-start: 2rem;

                    @media only screen and (max-width: 767px) {
                        width: 100%;
                    }

                    @media (min-width: 992px) and (max-width: 1199px) {
                        width: 80%;
                    }
                }

                table {
                    width: 565px;

                    @media only screen and (max-width: 767px) {
                        width: 100%;
                    }

                    @media (min-width: 992px) and (max-width: 1199px) {
                        width: 80%;
                    }

                    th {
                        text-align: left;
                        @include c(--h2-c);
                    }

                    tbody {
                        tr {
                            height: 40px;

                            &:nth-child(odd) {
                                @include bg(--account-plan-secondary-bg);
                            }

                            td {
                                font-size: 13px;
                                font-weight: 500;
                                @include c(--account-plan-primary-c);

                                &:last-child {
                                    font-style: italic;
                                    font-weight: 600;
                                    @include c(--link-c);
                                }

                                .track-info-section {
                                    display: flex;

                                    .tag-content {
                                        margin-left: 5px;
                                        display: flex;

                                        .own-track-tag {
                                            border-radius: 50%;
                                            height: 6px;
                                            width: 6px;
                                            align-self: center;
                                            @include bg(--link-c);
                                        }
                                    }

                                    .artist-avatar {
                                        margin-left: 5px;
                                        margin-right: 5px;
                                        font-size: 1px; // Needed to fix a vertical alignment issue

                                        img {
                                            border-radius: 50%;
                                        }
                                    }

                                    a {
                                        font-size: 13px;
                                        font-weight: 500;
                                        @include c(--account-plan-primary-c);
                                    }

                                    .track-info-is_premium {
                                        transform: translate(2px, -3px);
                                    }
                                }
                            }
                        }
                    }

                }
            }
        }

        .coming-soon-title {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            font-weight: normal;
            padding: 200px 1rem;
            text-align: center;

            @media only screen and (max-width: 767px) {
                padding: 100px 2rem;
            }

            h2 {
                font-size: 2rem;
                margin-block-end: 1rem;
            }
        }
    }

    .desc-text {
        font-size: 14px;
        font-weight: 500;
        color: $gray-medium-03;

        &.email-text {
            color: $gray-dark-02;
            text-align: center;
        }
    }

    .premium-checkout-form {
        width: 100%;
        max-width: 380px;
    }

    &.reset-password-page {
        .page-title + .alert-general-error {
            margin-block-start: 1.5rem;
        }

        .alert-general-success {
            margin-block-start: 1.5rem;
            margin-block-end: 0 !important;

            + .alert-general-error {
                margin-block-start: 1.5rem;
            }
        }

        .ub-btn {
            margin-block-start: 1.5rem;
        }
    }
}

.account-modal-form-wrapper {
    padding-block-start: 1.5rem;

    .input-wrapper {
        padding: 0.5rem 0;
    }

    .ub-btn {
        margin-block-end: 0.5rem;
    }

    .text-danger {
        color: cvar(--danger-text-color);
        font-size: 0.75rem;

        &.alt {
            margin-block-start: 0.5rem;
        }
    }
}

.jumbotron {
    padding: 1rem 1rem 1.5rem 1rem;
    margin-block-start: 2.5rem;
    border-radius: 0.75rem;
    @include css4 {
        background-image: linear-gradient(to bottom, cvar(--jumbotron-bg), cvar(--jumbotron-alt-bg));
    }

    + .jumbotron,
    > .jumbotron {
        margin-block-start: 0;
    }

    &.your-plan-jumbotron {
        > div {
            display: flex;
            justify-content: space-between;
            margin-block-end: 0.625rem;
            @include c(--jumbotron-alt-c);
        }
    }

    &.__premium {
        background-image: none;
        overflow: hidden;

        > div:first-child {
            padding-right: 0.625rem;
            max-width: 100%;
            z-index: 1;
            position: relative;

            span {
                @include c(--jumbotron-c);

                &:first-child,
                &:last-child {
                    opacity: 0.7;
                }

                &:nth-child(2) {
                    font-weight: $font-weight-bold;
                }
            }
        }

        .progress_premium {
            margin-block-start: 1rem;
            z-index: 1;
        }
    }

    &.__congratulation {
        @include c(--jumbotron-c);

        .columnar-card__info {
            > div {
                white-space: nowrap;
            }

            > div:first-child {
                padding-block-start: 0.25rem;
            }

            > div:last-child {
                opacity: 0.7;
            }
        }
    }

    .jumbotron-top {
        display: flex;
        gap: 1rem;
        margin-block-end: 1rem;

        .columnar-card {
            width: 50%;

            .columnar-card__info {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
            }

            .columnar-card__delimiter + div {
                display: flex;
                align-items: center;
            }
        }
    }

    a {
        cursor: pointer;
        text-decoration: underline;
        @include c(--link-c);
    }
}

.partner-offer  {
    padding-block: 1.5rem;
}

.user-has-receipts  {
    padding-block: 0.75rem;
}

.subscription-section {
    display: flex;
    flex-wrap: wrap;

    > div:nth-child(odd) {
        flex: 0 0 25%;
        max-width: 25%;
        position: relative;
        width: 100%;
        padding-inline-end: 1rem;
    }

    > div:nth-child(even) {
        flex: 0 0 75%;
        max-width: 75%;
        position: relative;
        width: 100%;
    }

    .subscription-inner-wrapper {
        display: flex;
    }

    .subscription-inner-list {
        padding-inline-start: 0;
    }
}

.subscription-card-wrapper {
    display: flex;
    width: 100%;
    gap: 0.5rem;
    flex-direction: row;
}

.payment-issues-container .payment-subscription-area {
    text-align: center;
    padding-block: 6rem;
    width: 100%;
    padding-inline: 1rem;

    h2 {
        margin-block-end: 1.5rem;
        font-weight: 400;
    }

    p:not(:first-of-type) {
        margin-block-start: 0.5rem;
    }

    p:only-child {
        padding-block: 6rem;
        text-align: center;
    }
}

.payment-issues-form {
    .premium-checkout-form {
        margin-block-start: 3rem;
        margin-block-end: 1.5rem;
    }

    .premium-checkout-billing-container .premium-checkout_payment-details  > p {
        margin: 0;
    }

    .update-details {
        margin-block-start: 1.5rem;

        + p {
            margin-block-start: 0.75rem;
            width: 100%;

            a {
                @include c(--link-c);
            }
        }
    }
}

.circle-loader-wrapper {
    width: 100%;
    height: auto;
    padding: 6rem 3rem;
    display: flex;
    justify-content: center;
    align-items: center;

    @media only screen and (min-width: 768px) {
        padding: 1.5rem;
    }
}

.circle-loader > div {
    display: inline-block;
    margin-right: 0.3125rem;
    width: 0.8125rem;
    height: 0.8125rem;
    border-radius: 100%;
    animation: sk-circle-bouncedelay 1.4s infinite ease-in-out both;
    @include bg(--music-loading-balls-bg);

    &.bounce1 {
        animation-delay: -0.32s;
    }

    &.bounce2 {
        animation-delay: -0.16s;
    }
}

.download-section {
    p {
        margin-block-end: 0.5rem;

        &:only-of-type,
        &:not(:only-of-type):last-of-type {
            margin-block-end: 1.5rem;
        }
    }
}

.your-plan-description + div {
    margin-block: 1.5rem;
}

.regenerate-code-btn {
    margin-block-start: 1rem;
    width: auto !important;
}
