.ub-modal .ub-modal-container-wrapper .ub-modal-container {
    .modal-payment-successful {
        background: var(--alert-success-bg);
        border-radius: 0.5rem;
        padding: 1rem;
        margin-block-end: 1.5rem;

        .ub-modal-title {
            font-weight: 500;
            margin-block-end: 1rem;
        }

        .ub-modal-text {
            font-size: 0.938rem;
            padding: 0;
            margin-block-end: 0 !important;
        }

        .ub-modal-text, .ub-modal-title {
            font-family: 'futura-pt', sans-serif;
            color: var(--alert-c);
        }
    }
}
