.ub-accordion {
    width: 100%;
    padding: 0 clamp(1.5rem, 3cqw, 3rem);
    display: flex;
    justify-content: center;
    align-items: center;

    .ub-accordion-inner {
        width: 100%;
        max-width: 1200px;

        .ub-accordion-tabs {
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 0.5rem;
            min-height: 67px;
            overflow: hidden;
            margin-block-end: 0.5rem;

            .ub-accordion-tab {
                cursor: pointer;

                &.active .ub-accordion-tab-label {
                    @include bg(--accordion-tabs-active-bg);
                    @include c(--accordion-tabs-active-c);
                }

                .ub-accordion-tab-label {
                    height: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    text-align: center;
                    font-size: 1rem;
                    font-weight: 600;
                    font-family: $font-family-primary;
                    border-radius: 1rem;
                    padding: 1rem;
                    font-size: 1.125rem;
                    @include c(--accordion-tabs-c);
                    @include bg(--accordion-tabs-bg);
                    transition: padding 0.2s, border-radius 0.2s;
                }
            }

            @media only screen and (min-width: 768px) {
                display: flex;
                justify-content: flex-start;
                align-items: flex-start;
                flex-wrap: nowrap;
                grid-template-columns: unset;
                margin-block-end: 0;

                &.first-tab-active + .ub-accordion-list {
                    border-radius: 0 1rem 1rem 1rem;
                }

                &.last-tab-active + .ub-accordion-list {
                    border-radius: 1rem 0 1rem 1rem;
                }

                .ub-accordion-tab {
                    position: relative;
                    cursor: pointer;

                    .ub-accordion-tab-label {
                        @include bg(--accordion-tabs-desktop-bg);

                        + span {
                            display: block;
                            position: relative;
                            height: 0.5rem;
                            bottom: 0;
                            left: -0.5rem;
                            width: calc(100% + 1rem);
                            margin-left: 0;
                            overflow: hidden;

                            &::before {
                                position: absolute;
                                margin: 0.5rem;
                                padding: 0.5rem;
                                top: -1rem;
                                left: -1rem;
                                border-radius: 50%;
                                content: '';
                                @include css4 {
                                    box-shadow: 0 0 0 6rem cvar(--accordion-tabs-desktop-bg);
                                }
                            }

                            &::after {
                                position: absolute;
                                margin: 0.5rem;
                                padding: 0.5rem;
                                top: -1rem;
                                right: -1rem;
                                border-radius: 50%;
                                content: '';
                                @include css4 {
                                    box-shadow: 0 0 0 6rem cvar(--accordion-tabs-desktop-bg);
                                }
                            }
                        }
                    }

                    &:hover:not(.active) {
                        .ub-accordion-tab-label {
                            @include bg(--accordion-tabs-hover-bg);
                        }
                    }

                    &.active {
                        .ub-accordion-tab-label {
                            text-decoration: underline;
                            text-decoration-thickness: 2px;
                            text-underline-offset: 0.375rem;
                            border-radius: 1rem 1rem 0 0;
                            @include c(--accordion-tabs-c);
                            @include bg(--accordion-tabs-desktop-active-bg);
                            @include css4 {
                                text-decoration-color: cvar( --accordion-link-underline-c);
                            }

                            + span {
                                &::before {
                                    @include css4 {
                                        box-shadow: 0 0 0 6rem cvar(--accordion-tabs-desktop-active-bg);
                                    }
                                }

                                &::after {
                                    @include css4 {
                                        box-shadow: 0 0 0 6rem cvar(--accordion-tabs-desktop-active-bg);
                                    }
                                }
                            }
                        }
                    }
                }
            }

            @media only screen and (min-width: 797px) {
                &.last-tab-active + .ub-accordion-list {
                    border-radius: 1rem;
                }
            }

            @media only screen and (min-width: 992px) {
                &.last-tab-active + .ub-accordion-list {
                    border-radius: 1rem 0 1rem 1rem;
                }
            }

            @media only screen and (min-width: 1052px) {
                &.last-tab-active + .ub-accordion-list {
                    border-radius: 1rem;
                }
            }
        }

        .ub-accordion-list {
            list-style: none;
            border-radius: 1rem;
            transition: border-radius 0.2s;
            overflow: hidden;
            @include bg(--accordion-bg);

            .ub-accordion-item {
                border-radius: 0;
                margin: 0;
                @include bg(--accordion-bg);

                .ub-accordion-item-inner {
                    padding: 0 1.5rem;

                    .ub-accordion-item-header {
                        padding: 1.5rem 0;
                        display: flex;
                        flex-wrap: nowrap;
                        justify-content: space-between;
                        align-items: center;
                        cursor: pointer;
                        @include css4 {
                            border-bottom: 1px solid cvar(--accordion-br);
                        }

                        > div {
                            width: 100%;
                            display: flex;
                            flex-wrap: nowrap;
                            justify-content: space-between;
                            align-items: center;

                            > div:last-child {
                                display: flex;
                                align-items: center;
                                margin-inline-start: 0.5rem;
                                min-width: 17px;

                                .ub-btn {
                                    pointer-events: none;
                                    transition: opacity 0.2s;
                                    margin-inline-start: 0.5rem;

                                    &.success {
                                        opacity: 1;
                                    }

                                    &.transparent {
                                        opacity: 0;
                                        pointer-events: none;
                                    }
                                }

                                &:first-child {
                                    padding-inline-end: 1rem;
                                }
                            }

                            .ub-btn {
                                margin: 0 auto 0 1rem;
                                pointer-events: none;
                                transition: opacity 0.2s;

                                &.success {
                                    opacity: 1;
                                }

                                &.transparent {
                                    opacity: 0;
                                    pointer-events: none;
                                }
                            }

                            &:hover .ub-btn {
                                opacity: 1;
                                pointer-events: all;
                            }

                            h4 {
                                display: flex;
                                justify-content: flex-start;
                                align-items: center;
                                font-size: 1.125rem;
                                letter-spacing: 0;
                                font-weight: 600;
                                line-height: 1.5;
                                font-family: $font-family-secondary;
                                @include c(--accordion-primary-c);

                                &::before {
                                    font-size: 2rem;
                                    content: "\2022";
                                    font-weight: 600;
                                    display: inline-block;
                                    width: 2rem;
                                    min-width: 2rem;
                                    line-height: 0;
                                    @include c(--accordion-bullet-c);
                                }
                            }

                            > div:last-child svg {
                                filter: brightness(0.5);
                                transition: transform 0.2s;
                            }
                        }
                    }

                    @media only screen and (min-width: 768px) {
                        padding: 0 2rem;

                        .ub-accordion-item-header {
                            padding: 2rem 0;

                            &:hover > div > div:last-child > svg {
                                transform: scale(1.2);
                            }
                        }
                    }
                }

                &.active {
                    @include bg(--accordion-active-bg);

                    .ub-accordion-item-inner {
                        .ub-accordion-item-header {
                            border: none;
                        }

                        .ub-accordion-item-text {
                            display: flex;
                            @include css4 {
                                border-bottom: 1px solid cvar(--accordion-br);
                            }
                        }

                        &.icon-vertical-bar {
                            display: none;
                        }
                    }
                }

                .ub-accordion-item-text {
                    justify-content: flex-end;
                    width: 100%;
                    padding: 0 0 2rem;
                    display: none;
                    gap: 2rem;

                    div {
                        > p,
                        li {
                            letter-spacing: 0;
                            font-size: 0.875rem;
                            font-family: $font-family-secondary;
                            margin-bottom: 1.25rem;
                            line-height: 1.5rem;
                            list-style-position: inside;
                            @include c(--accordion-secondary-c);

                            &:last-child {
                                margin-bottom: 0;
                            }

                            a {
                                @include c(--accordion-link-c);
                            }
                        }
                    }
                }

                &:last-child .ub-accordion-item-inner .ub-accordion-item-header,
                &:last-child .ub-accordion-item-inner .ub-accordion-item-text {
                    border-bottom: none;
                }
            }
        }
    }

    &.faq {
        padding: 2rem 0 0;
        margin-block-end: 1rem;

        .ub-accordion-inner {
            max-width: unset;
        }

        .ub-accordion-list {
            border-radius: 0;
            background: unset;
            transition: unset;

            .ub-accordion-item {
                background: unset;

                .ub-accordion-item-inner {
                    padding: 0;

                    .ub-accordion-item-header {
                        padding: 0 clamp(1.5rem, 3cqw, 3rem);
                        border-bottom: none;

                        > div {
                            padding: 2rem 0 1rem;
                            border-bottom: 1px solid $gray-dark-03;

                            h4 {
                                color: $white;
                                font-family: var(--font-family-primary);
                                font-weight: 500;

                                &::before {
                                    content: '';
                                    display: none;
                                }
                            }

                            > div:last-child svg {
                                filter: brightness(1);
                                transition: transform 0.2s;
                            }
                        }
                    }
                }

                &.active {
                    background: unset;

                    .ub-accordion-item-header > div {
                        border: none;
                    }

                    .ub-accordion-item-inner {
                        .ub-accordion-item-text {
                            background: $gray-dark-03;
                            border-bottom: none;
                            padding: clamp(1.5rem, 3cqw, 3rem);
                            flex-direction: column;
                            gap: 2.5rem;

                            > div:first-child {
                                display: flex;
                                flex-direction: row;
                                width: 100%;
                                gap: 1rem;

                                img {
                                    background: $gray-dark-02;
                                    border-radius: 1.25rem 1.25rem 1.25rem 0;
                                }

                                > div {
                                    display: flex;
                                    flex-direction: column;
                                    gap: 0.5rem;
                                    justify-content: flex-end;

                                    > span:first-of-type {
                                        color: $white;
                                        font-weight: 500;
                                        display: block;
                                        padding-bottom: 0.375rem;
                                        border-bottom: 1px solid $gray-medium-02;
                                        display: block;
                                    }

                                    > span:last-of-type {
                                        font-family: $font-family-secondary;
                                        font-size: 0.625rem;
                                        color: $gray-medium-04;
                                        display: block;
                                    }
                                }
                            }

                            > div:last-child {
                                width: 100%;

                                p {
                                    color: $gray-medium-04;
                                    width: 100%;
                                    max-width: 800px;

                                    strong {
                                        color: $gray-light-03;
                                    }
                                }
                            }
                        }

                        @container (min-width: 580px) {
                            .ub-accordion-item-text {
                                flex-direction: row;
                                gap: 2rem;

                                > div:first-child {
                                    flex-direction: column;
                                    max-width: 100px;
                                    flex: 1 0 auto;
                                    gap: 0.5rem;

                                    > div {
                                        justify-content: unset;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
