.ub-review-card {
    width: 100%;
    height: 100%;
    border-radius: 0.75rem;
    padding: 2rem 1.5rem;
    display: flex;
    flex-direction: column;
	@include bg(--review-card-bg);

    .ub-review-card_heading {
        font-size: 1.25rem;
        margin-block-end: 1rem;
        @include c(--review-card-heading-c);
    }

    .ub-review-card_review {
        font-size: 0.875rem;
        margin-block-end: 1rem;
        font-family: $font-family-secondary;
        @include c(--review-card-c);
    }

    .ub-review-card_name {
        margin-block-end: 0.5rem;
		margin-block-start: auto;
        font-family: $font-family-heading;
        @include c(--review-card-name-c);

        > img {
            display: inline;
        }
    }

    .ub-review-card_rating {
		display: flex;
		justify-content: flex-start;
		align-items: flex-start;
        gap: 0.25rem;

        > div {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 1.5rem;
            height: 1.5rem;
            @include bg(--review-card-icon-bg);
        }
	}
}