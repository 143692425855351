.ub-text-block-outer-wrapper {
    padding-inline: 1.5rem;
    display: flex;
    justify-content: center;
    margin-block-end: 3rem;
    width: 100%;

    @media only screen and (min-width: 768ps) {
        padding-inline: 3rem;
    }

    .ub-text-block-inner-wrapper {
        width: 100%;
        display: grid;
        grid-template-columns: 1fr;
        row-gap: 1rem;
        position: relative;

        &.xsmall {
            max-width: 620px;
        }

        &.small {
            max-width: 800px;
        }

        &.medium {
            max-width: 1000px;
        }

        &.large {
            max-width: 1200px;
        }

        &.col-2 {
            grid-template-columns: 1fr;
            column-gap: 2rem;

            @media only screen and (min-width: 768px) {
                grid-template-columns: 1fr 1fr;
            }
        }

        &.centered {
            text-align: center;
        }

        p, li {
            font-family: $font-family-secondary;

            img {
                width: 1.375rem;
                height: 1.375rem;
            }
        }

        ul {
            padding-inline-start: 1rem;

            li {
                list-style-position: outside;
                padding-left: 0.5rem;
                margin-block-end: 0.25rem;
            }
        }
    }

    &.framed-true {
        margin-block-end: 0;

        .ub-text-block-inner-wrapper {
            padding: 2rem;
            border-radius: 1rem;
            @include c(--main-secondary-c);
            @include bg(--text-block-bg);
        }

        .stepped-true {
            &::after {
                content: '';
                position: absolute;

                @media only screen and (min-width: 768px) {
                    height: 1rem;
                    width: 0;
                    bottom: -1.5rem;
                    left: 50%;
                    border-right: 4px dotted $gray-light-01;
                }

                @media only screen and (min-width: 992px) {
                    height: 2rem;
                    width: 0;
                    bottom: -2.5rem;
                    left: 50%;
                    border-right: 4px dotted $gray-light-01;
                }
            }
        }
    }
}
