@mixin ub-skeleton-animation() {
    -webkit-animation-name: UBSkeletonAnimation;
    -webkit-animation-duration: 1.5s;
    -webkit-animation-fill-mode: forwards;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
}

@keyframes UBSkeletonAnimation {
    0% {
        transform: translateX(-100%)
    }
    100% {
        transform: translateX(200%)
    }
}

.ub-skeleton {
    position: relative;
    border: none !important;
    overflow: hidden;
    border-radius: 0.5rem;
    @include bg(--skeleton-bg);

    canvas {
        visibility: hidden;
    }

    &::after {
        content: '';
        display: block;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        @include css4 {
            background-image: linear-gradient(90deg, cvar(--skeleton-bg) 0%, cvar(--skeleton-shine-bg) 20%, cvar(--skeleton-shine-bg) 46%, cvar(--skeleton-bg) 66%, cvar(--skeleton-bg) 100%);
        }
        @include ub-skeleton-animation;
    }

    &.ub-skeleton-dark {
        @include bg(--skeleton-dark-bg);

        &::after {
            @include css4 {
                background-image: linear-gradient(90deg, cvar(--skeleton-dark-bg) 0%, cvar(--skeleton-dark-shine-bg) 20%, cvar(--skeleton-dark-shine-bg) 46%, cvar(--skeleton-dark-bg) 66%, cvar(--skeleton-dark-bg) 100%);
            }
        }
    }

    &.ub-skeleton-darker {
        @include bg(--skeleton-darker-bg);

        &::after {
            @include css4 {
                background-image: linear-gradient(90deg, cvar(--skeleton-darker-bg) 0%, cvar(--skeleton-darker-shine-bg) 20%, cvar(--skeleton-darker-shine-bg) 46%, cvar(--skeleton-darker-bg) 66%, cvar(--skeleton-darker-bg) 100%);
            }
        }
    }
}
