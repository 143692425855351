.ub-progress-indicator {
    width: 2rem;
    height: 2rem;
    position: relative;
    border-radius: 50%;
    overflow: hidden;
    background: $gray-light-02;

    svg {
        width: 2rem;
        height: 2rem;

        g {
            circle {
                stroke: $green;
                transition: stroke 0.2s, stroke-dashoffset 0.1s;
            }
        }
    }

    &.warning-1 svg g circle {
        stroke: $business;
    }

    &.warning-2 svg g circle {
        stroke: $orange-soda;
    }

    &.warning-3 svg g circle {
        stroke: $red;
    }

    > div {
        position: absolute;
        width: 1.5rem;
        height: 1.5rem;
        background: $white;
        top: 0.25rem;
        left: 0.25rem;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 0.75rem;
    }

    &.large {
        width: 3rem;
        height: 3rem;

        svg {
            width: 3rem;
            height: 3rem;
        }

        > div {
            width: 2.5rem;
            height: 2.5rem;
            top: 0.25rem;
            left: 0.25rem;
            font-size: 1rem;
            font-family: $font-family-heading;
        }
    }
}
