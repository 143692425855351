// Styles for duration fitler
.ub-filter-slider {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 500px;
    padding-block-start: 0.375rem;
}

// Styles for volume controls in footer
.ub-volume-slider-wrapper {
    position: relative;
    width: 3rem;
    height: auto;
    background: transparent;
    border-radius: 0.5rem;
    display: none;
    flex-direction: column-reverse;
    align-items: center;
    z-index: 1;

    .ub-volume-button {
        z-index: 1;

        &:hover {
            + .ub-volume-slider-inner {
                opacity: 1;
                transition: visibility 0s 0s;
                visibility: visible;
            }
        }
    }

    .ub-volume-slider-inner {
        position: absolute;
        top: -180px;
        left: 0;
        width: 3rem;
        height: 14rem;
        opacity: 0;
        visibility: hidden;
        transition: visibility 0s 0.7s, opacity 0.5s 0.2s;
        @include bg(--slider-volume-bg);
        @include css4 {
            box-shadow: 0 0 8px cvar(--shadow-light);
        }

        &:hover {
            pointer-events: all;
            opacity: 1;
            visibility: visible;
            transition: visibility 0s 0s;
        }

        .ub-volume-slider {
            height: 11rem;
            width: 3rem;
            padding: 1.5rem 0.5rem;
            margin-block-end: 1rem;

            > span {
                padding: 0 !important;
            }
        }
    }

    @media only screen and (min-width: 768px) {
        display: flex;
    }
}
