// Error
// ---------------------------------------------------------

.alert-general-error {
    padding: 0.5rem 1.5rem;
    margin-bottom: 10px;
    text-align: center;
    font-size: 0.875rem;
    font-family: $font-family-primary;
    @include bg(--alert-error-bg);
    @include c(--alert-c);
}

// Success
// ---------------------------------------------------------

.alert-general-success {
    padding: 0.5rem 1.5rem;
    text-align: center;
    font-size: 0.875rem;
    font-family: $font-family-primary;
    @include bg(--alert-success-bg);
    @include c(--alert-c);
}
