// Table
//
// Component for Table

.table {
  font-family: $font-family-primary;
  font-size: 14px;
  color: $gray-medium-02;
}

.table-dark {
  background: lighten($gray-light-02, 35%);
}

// Head
// ---------------------------------------------------------

.table th {
  font-weight: $font-weight-normal;
  color: $brand-success;
}

.table > thead > tr > th {
  border-width: 1px;
}

// td
// ---------------------------------------------------------

.table > tbody > tr > td {
  vertical-align: middle;
}

// Responsive
// ---------------------------------------------------------

.table-responsive {
  overflow-x: visible;
  overflow-y: auto;
  padding-bottom: 40px;
  border: none;
}

.table-responsive-bare {
  padding-bottom: 0;
}

.table-responsive-alternate {
  padding-bottom: 0;
}

.table-responsive-receipt-page {
  display: block;
  width: 100%;
  overflow-x: visible;
  overflow-y: auto;
  padding-bottom: 40px;
  border: none;
  margin-inline-start: 1rem;

  @media only screen and (min-width: 768px) {
    margin-inline-start: 3rem;
  }

  .table {
    @include c(--h2-c);

    thead > tr > th {
      border: none;
      font-weight: $font-weight-semi-bold;
      text-align: left;
      @include c(--p-c);
    }

    .table-date {
      width: 35%;
    }

    .table-reference {
      width: 25%;
      display: none;

      @media only screen and (min-width: 768px) {
        display: table-cell;
      }
    }

    .table-amount {
      width: 25%;
    }

    tbody {
      padding-top: 15px;

      tr > td {
        border: none;
        margin-top: 3px;
        padding-top: 25px;
      }
    }

    .view-receipt {
      color: $uppbeat;
      cursor: pointer;
    }

    .table-row-date {
      font-weight: $font-weight-semi-bold;

      span {
        display: inline-block;
        margin-right: 5px;

        @media only screen and (min-width: 768px) {
          margin-right: 0;
          width: 40px;

          &:first-child {
            width: 35px;
          }
        }
      }

      + td {
        display: none;

        @media only screen and (min-width: 768px) {
          display: table-cell;
        }
      }
    }
  }
}


.receipts-page {
  .grey-background-content-container {
    padding-block-start: 3rem;
    border-radius: 0;

    @media only screen and (min-width: 420px) {
      border-radius: 1rem 0 0 1rem;
    }
  }

  .table {
    max-width: 640px;
  }
}

// Arrow / Caret
// ---------------------------------------------------------

.table-arrow {
  margin-top: -5px;
  margin-left: 5px;
}

// Striped
// ---------------------------------------------------------

.table-striped > tbody > tr > td {
  border: none;
}

// Checkout
// ---------------------------------------------------------

.checkout-table tr {
  font-size: 13px;
}

.checkout-table th {
  font-weight: $font-weight-semi-bold;
  color: $gray-light-02;
}

.checkout-bold-title {
  color: $black;
}

// Dropdown header
// ---------------------------------------------------------

.table-dropdown-header {
  transition: 0.2s;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  &:hover,
  &:active,
  &:focus {
    color: #67c1bf;
  }
}

.table-dropdown-icon-active {
  display: none;
}

.table-dropdown-header.active .table-dropdown-icon-active {
  display: inline;
}

.table-dropdown-header.active .table-dropdown-icon-inactive {
  display: none;
}

.license-history {
  .spacer {
    margin-bottom: 20px;
  }

  .table {
    width: 100%;
    margin-bottom: 1rem;
    color: #212529;
    font-family: brandon-grotesque, sans-serif;
    font-size: 14px;
  }

  .table-responsive {
    overflow-x: visible;
    overflow-y: auto;
    padding-bottom: 40px;
    border: none;
  }

  .table > thead > tr > th {
    border-width: 1px;
    white-space: nowrap;

    @media (min-width: 768px) {
      padding: 20px 20px 20px 5px;
    }
  }

  .table > tbody > tr > td {
    padding: 18px 10px 18px 5px;
    vertical-align: middle;

    @media (max-width: 768px) {
      white-space: nowrap;
    }
  }
}
