// Modals
//
// Popup modals

$modal-width: 380px;
$subscription-modal-transition: all 1.3s;

// Placeholder text
@mixin placeholder($color: $input-color-placeholder) {
    // Firefox
    &::-moz-placeholder {
        color: $color;
        opacity: 1; // Override Firefox's unusual default opacity; see https://github.com/twbs/bootstrap/pull/11526
    }
    &:-ms-input-placeholder { color: $color; } // Internet Explorer 10+
    &::-webkit-input-placeholder  { color: $color; } // Safari and Chrome
}
.modal-primary {
    color: #212529;
    z-index: $z-index-modal;
    position: fixed;
    bottom: 50%;
    right: 50%;
    transition: all 0.5s;
    transform: translate(50%, 50%);
    width: 96%;
    max-width: $modal-width;
    font-size: 14px;
    background: $white;

    &.client-modal {
        max-height: 98vh;
        max-width: 418px;
        overflow: auto;
    }

    @media only screen and (min-width: 768px) {
        width: 80%;
    }
}

// Modal header
// ---------------------------------------------------------

.modal-primary__header {
    padding: 20px 0;
    text-align: center;

    a,
    a:not([href]):not([tabindex]) {
        color: $free;
        text-decoration: underline;
    }

    p {
        padding: 0 30px;
        margin-bottom: 10px;
    }

    svg {
        fill: $black;
    }
}

.modal-primary__subscription-license {
    overflow: hidden;
    position: relative;
    transition: $subscription-modal-transition;

    .modal-primary__header-inner p {
        padding: 0;

        &.sub-license-error {
            color: $red;
            font-size: 12px;
            margin: 0 -20px 10px;
        }
    }

    .modal-primary__title {
        color: $gray-medium-03;
        font-size: 13px;
        font-weight: 500;
        padding: 0;
        text-transform: uppercase;
    }
}

.modal-primary__subscription-license-step-1,
.modal-primary__subscription-license-step-2,
.modal-primary__subscription-license-step-3,
.modal-primary__subscription-license-step-4 {
    width: 100%;
}

.modal-primary__subscription-license-step-1 {
    transition: opacity 0.2s;
    background: linear-gradient(to bottom, rgba($white, 0) 0%, rgba($white, 0.4) 100%), linear-gradient(47deg, $gray-light-03 0%, $gray-light-03 50%, $white 50%, $white 100%);
    padding: 20px;
    opacity: 0;
    pointer-events: none;

    @media only screen and (min-width: 768px) {
        padding: 10%;
    }

    .sub-warning {
        h2 {
            color: $red;
            font-weight: 400;
            margin-bottom: 10px;
        }

        p.sub-license-error {
            color: $black;
        }
    }
}


.modal-primary__subscription-license-step-2 {
    display: none;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    transition: $subscription-modal-transition;

    h3 {
        @extend .collections-title;
    }

    .modal-primary__header-preparing {
        padding: 20px;
    }
}


.modal-primary__subscription-license-step-3 {
    display: none;
    position: absolute;
    top: 50%;
    transform: translateY(200px);
    transition: $subscription-modal-transition;

    h3 {
        @extend .collections-title;
        color: $white;
        margin-bottom: 0;
    }

    .modal-primary__header-preparing {
        padding: 20px;
    }
}

.modal-primary__subscription-license-step-4 {
    display: none;
    transition: opacity 0.2s;
    background: linear-gradient(to bottom, rgba($white, 0) 0%, rgba($white, 0.4) 100%), linear-gradient(47deg, $gray-light-03 0%, $gray-light-03 50%, $white 50%, $white 100%);
    padding: 20px;

    @media only screen and (min-width: 768px) {
        padding: 10%;
    }
}

.license-step-1,
.license-step-2,
.license-step-3 {
    transition: $subscription-modal-transition;
}

.license-step-1 {
    .modal-primary__subscription-license-step-1 {
        opacity: 1;
        pointer-events: auto;
    }

    .modal-primary__disclaimer.sub-license-footer {
        height: 242px;
        opacity: 1;
    }
}

.license-step-2 .modal-primary__subscription-license-step-2 {
    display: block;
}

.license-step-3 {
    background: $green;

    .modal-primary__title {
        display: none;
    }

    .modal-primary__subscription-license {
        height: 200px;
    }

    .modal-primary__subscription-license-step-2 {
        display: none;

        .modal-primary__header-preparing {
            padding: 20px;

            h3 {
                color: $white;
            }
        }
    }

    .modal-primary__subscription-license-step-3 {
        display: block;
        transform: translateY(-50%);
    }

    .modal-primary__disclaimer.sub-license-footer {
        background: $white;
        height: 172px;
        opacity: 1;
    }

    &.has-warning {
        .modal-primary__subscription-license {
            height: 180px;
        }

        .modal-primary__disclaimer.sub-license-footer {
            height: auto;
        }

        .modal-primary__form .inner-padding {
            padding: 10px 20px;
        }
    }
}

.license-step-4 {
    transition: none;
    max-width: 500px;

    .modal-primary__subscription-license,
    .modal-primary__disclaimer.sub-license-footer {
        transition: none;
    }

    .modal-primary__subscription-license-step-1 {
        display: none;
    }

    .modal-primary__subscription-license-step-4 {
        display: block;
    }
}

$sub-license-complete-btn-shadow: 0px 6px 10px 0px $gray-light-02;
$sub-license-complete-btn-color: #3a9bcd;

.sub-license-complete-btn {
    background: $white;
    border-radius: 50%;
    cursor: pointer;
    box-shadow: 0px 0px 0px 2px rgba($sub-license-complete-btn-color, 0), $sub-license-complete-btn-shadow;
    color: $sub-license-complete-btn-color;
    height: 80px;
    transition: all 0.2s;
    width: 80px;

    &:hover {
        background: darken($white, 4%);
        box-shadow: 0px 0px 0px 2px rgba($sub-license-complete-btn-color, 1), $sub-license-complete-btn-shadow;
    }

    &:focus {
        outline: none;
    }

    div {
        margin: auto;
        padding: 10px;
    }
}

.sub-license-complete-label {
    color: $sub-license-complete-btn-color;
    text-align: center;
    margin-top: 10px;
    margin-bottom: 0;
    margin-left: -5px;
    width: 90px;
}

.modal-primary__sub-plan-name {
    color: $black;
    font-size: 1.8125em;
    line-height: 1;
}

.modal-primary__header-inner {
    background: $white;
    border-radius: 10px;
    box-shadow: 0px 0px 11px 0px rgba($gray-medium-02, 0.6);
    font-family: $font-family-primary;
    padding: 20px 10%;

    @media only screen and (min-width: 768px) {
        padding: 10px;
    }
}

.modal-primary__superheader {
    background: $gray-dark-02;
    position: relative;

    .modal-primary__superheader-artist-image {
        padding-right: 20px;
    }

    &::after {
        background: $gray-dark-02;
        left: 50%;
        top: 100%;
        content: "";
        height: 20px;
        position: absolute;
        transform: rotate(45deg) translate(-100%, 0%);
        transform-origin: bottom;
        width: 20px;
        z-index: 100;
    }
}

a.preview-subscription-license {
    display: inline-block;
    font-family: $font-family-primary;
    font-size: 12px;
    font-weight: 500;
    margin-bottom: 15px;
    text-decoration: none;
}

.sub-license-complete-tick {
    border: 4px solid $white;
    border-radius: 50%;
    height: 60px;
    margin: 10px auto;
    width: 60px;

    &::before,
    &::after {
        background: $white;
        content: "";
        height: 4px;
        left: 50%;
        position: absolute;
        top: 50%;
        width: 20px;
    }

    &::before {
        transform: translate(-17px, -13px) rotate(45deg);
        width: 14px;
    }

    &::after {
        transform: translate(-11px, -18px) rotate(-45deg);
        width: 30px;
    }
}

// Modal title
// ---------------------------------------------------------

.modal-primary__title {
    font-family: $font-family-secondary;
    // font-size: $header-md;
}

// Logo
// ---------------------------------------------------------

.modal-primary__header .modal__logo {
    fill: $black;
}

// Modal form
// ---------------------------------------------------------

.modal-primary__form {
    padding: 20px 30px;
    background: lighten($gray-medium-04, 10%);
    overflow: hidden;

    .inner-padding {
        padding: 20px 30px;
    }

    form.login {
        height: 138px + 20px;
        position: relative;
        transition: height .4s;

        &.validation-error {
            height: 185px + 20px;
        }
    }

}

form.register {
    position: relative;
}

.modal-primary__group {
    display: flex;
    flex-direction: column;
}

.modal-primary__form-stage-1,
.modal-primary__form-stage-2,
.modal-primary__form-mvnet-user {
    position: relative;
    transition: transform .4s;
    width: 100%;

    &.instant {
        transition: unset;
    }
}

.modal-primary__form-stage-1 {
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    padding-top: 18px;

    &.login-form-hide {
        transform: translateX(-#{$modal-width * 1.5});
    }
}

.modal-primary__form-stage-2,
.modal-primary__form-mvnet-user {
    display: none;

    &.login-form-hide {
        transform: translateX(#{$modal-width * 1.5});
    }
}

.modal-primary__form-stage-2 {
    padding-top: 2px;
}

.modal-primary__form-mvnet-user {
    padding-top: 10px;
}

.login-form-spinner {
    bottom: 0;
    margin-left: -1rem;
    position: absolute;
}

.login_modal_supplied-user-email {
    font-size: 12px;
    margin-bottom: 5px;
}

a.login_modal_reset-modal {
    color: inherit;
    text-decoration: underline !important;
}

.modal-primary__forgot-password {
    margin-top: 15px;
}

.login_modal_mvnet-user-footer {
    color: $gray-medium-02;
    font-size: 12px;
}

// Modal input
// ---------------------------------------------------------

.modal-primary__input {
    display: block;
    width: 100%;
    padding: 15px;
    background: $white;
    margin: 0 auto;
    margin-bottom: 25px;
    text-align: center;
    border: 1px solid $gray-medium-04;
    box-shadow: 0 0 10px lighten($gray-medium-04, 10%);
    color: $gray-medium-02;
}

.modal-primary__group--last .modal-primary__input {
    margin-bottom: 30px;
}

.modal-lost_your_password {
    margin: 15px auto 0;
}

// Btn
// ---------------------------------------------------------

.modal-primary__link {
    text-align: center;

    &:hover,
    &:focus,
    &:active {
        color: $white;
    }
}

.modal-download-preview {
    transform: translate(50%, 282px); // Not ideal but fixes a rendering issue

    .modal-primary__title {
        padding: 10px 0;
    }

    .modal-download_btn,
    .modal-download_text {
        cursor: pointer;
        display: inline-block;
        font-weight: 600;
        padding: 10px 5px;
        margin: 0 7px;
        width: 150px;
        vertical-align: top;
    }

    .modal-download_sub {
        display: block;
        font-weight: normal;
    }
}

// Close
// ---------------------------------------------------------

.modal-primary__close {
    cursor: pointer;
    position: absolute;
    top: 10px;
    right: 10px;
}

// Caption
// ---------------------------------------------------------

.modal-primary__caption {
    display: block;
    padding: 0 20px;
}

// Details
// ---------------------------------------------------------

.modal-primary__details-list {
    border-top: 1px solid $gray-light-02;
    padding: 10px 0;
}

.modal-primary__details li {
    padding-bottom: 10px;

    &:last-child {
        padding-bottom: 0;
    }
}

.login-error {
    margin-bottom: 0;
    transition: opacity .2s;
}

.js-register-form .login-error {
    margin-top: 20px;
}

.modal-primary__content-edits {
    font-size: 12px;
    margin: 0 -20px 10px;

    select {
        margin: 0 5px;
    }
}

.modal-primary__disclaimer {

    &.sub-license-footer {
        height: 0px;
        opacity: 0;
        transition: all 1s;

        h5 {
            color: $gray-dark-02;
            font-size: 13px;
            font-family: $font-family-primary;
            font-weight: 600;
            margin-bottom: 3px;
        }

        p,
        a {
            font-size: 12px;
        }

        p {
            color: $gray-dark-02;
            font-style: normal;
            margin-bottom: 0;
        }

        a {
            color: $free;
        }

        img {
            border: 3px solid $white;
            border-radius: 50%;
        }


        .sub-warning {
            h2 {
                font-weight: 400;
                margin-bottom: 10px;
            }

            p {
                margin-bottom: 10px;

                &:last-child {
                    margin-bottom: 0;
                }
            }

            hr {
                margin: 20px auto;
                width: 40%;
                border: none;
                border-top: 1px solid $gray-medium-04;
            }
        }
    }

    p {
        color: $gray-medium-01;
        font-size: 13px;
        font-style: italic;

        &:last-child {
            margin-bottom: 0;
        }

        a {
            color: $free;
        }

    }
}

// Details
// ---------------------------------------------------------

.modal-primary__details {
    padding: 30px;
    background: lighten($gray-medium-04, 10%);
}

.modal-primary__details-item {
    padding-bottom: 20px;
}

.modal-primary__details-title {
    margin-bottom: 10px;
}

// Tabs
// ---------------------------------------------------------

.modal-primary__tabs-item {
    background: $gray-light-03;
    cursor: pointer;
    display: block;
    padding: 10px;

    &:hover,
    &:focus {
        color: inherit;
    }

    &.current {
        background: lighten($gray-medium-04, 10%);
    }
}

.modal-primary__content {
    display: none;
    flex-direction: column;
}

.modal-primary__content.current {
    display: flex;
    flex-direction: column;

    .js-register-form {
        width: 100%;
    }
}

// Add to playlist
// ---------------------------------------------------------

$password-tooltip-width: $modal-width - 40px;
$password-tooltip-bg: lighten($black, 20%);

.modal-primary__password-req {
    position: absolute;
    right: 2px;
    transform: translateY(-100%);

    &:hover .modal-primary__password-tt {
        opacity: 1;
        transform: translate(0, -120%);
    }
}

.modal-primary__password-tt {
    background: $password-tooltip-bg;
    border-radius: 3px;
    color: $white;
    font-size: 12px;
    opacity: 0;
    padding: 2px 5px;
    pointer-events: none;
    position: absolute;
    right: -13px;
    text-align: center;
    transition: all 0.2s;
    transform: translate(0, -100%);
    width: $password-tooltip-width;

    &:after {
        background: $password-tooltip-bg;
        content: "";
        height: 10px;
        position: absolute;
        right: 62px;
        top: 100%;
        transform: translateY(-50%) rotate(45deg);
        width: 10px;
    }
}

.modal-primary__password-tt-trigger {
    color: $gray-medium-04;
    margin: 0;
    font-size: 11px;
    text-align: right;
}

// Add to playlist
// ---------------------------------------------------------
.add-to-playlist-active {
    display: block;
}

.add-to-playlist-modal__left {
    text-align: center;
    margin-bottom: 15px;
    width: 70px;
    text-align: left;
    margin-bottom: 0;
}

.add-to-playlist-modal__right {
    width: calc(100% - 70px);
}

.add-to-playlist-modal__top {
    padding-top: 40px;
    width: 80%;
    margin: 0 auto;
    text-align: center;
}

.playlist-modal-container {
    .playlist-modal__create {
        display: flex;
        flex-direction: column;

        > div {
            display: flex;
            margin-block-end: 0.75rem;

            + .col-margin {
                width: 100%;
            }
        }

        .playlist-image {
            height: 60px;
            width: 60px;

            img {
                border-radius: 50%;
                width: 100%;
            }
        }

        .playlist-name {
            display: flex;
            flex-direction: column;
            margin-left: 8px;
            justify-content: center;

            .track-name {
                font-weight: 500;
                font-size: 14px;
                @include c(--browse-tracks-title-c);
            }

            .artist-name {
                font-weight: 500;
                font-size: 13px;
                @include c(--track-artist-c);
            }
        }

        .playlist-toggle-text {
            font-weight: 500;
            padding: 6px 0 0 6px;
            font-size: 0.8125rem;
            @include c(--p-c);
        }
    }
}

.add-to-playlist-modal__title {
    font-weight: $font-weight-medium;
    font-family: $font-family-secondary;
    font-size: 30px;
    color: $gray-dark-04;
    margin-bottom: 20px;
}

.add-to-playlist-modal__desc {
    display: block;
    padding-bottom: 40px;
    font-size: 14px;
    color: $gray-medium-02;
}

.add-to-playlist-modal__create {
    padding-top: 20px;
    padding-bottom: 40px;
    background: $gray-light-01;
}

.add-to-playlist-modal__create-playlist {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 80%;
    margin: 0 auto;
    padding-top: 40px;
}

.remove_style_checkboxes {
    display: flex;
    align-items: flex-start;
    align-content: flex-start;
    flex-direction: column;

    &.sub-styles {
        margin-left: 20px;
    }
}

.remove-styles-modal {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    font-size: 16px;
    margin: 50px 27px 0;

    &[type="checkbox"] {
        position: relative;
        top: -1px;
    }

    &.remove_sub_styles {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
        margin-top: 10px;

        &[type="checkbox"] {
            margin-left: 10px;
        }
    }

    &.remove_styles-title h4 {
        margin-bottom: 2px;
        font-size: 14px;
    }

    &.remove_parent_styles {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
        margin-top: 25px;

        &[type="checkbox"] {
            margin-left: 10px;
        }
    }
}

.add-to-playlist-modal__form-container {
    width: 100%;
}

.add-to-playlist-modal__content {
    position: relative;
    width: 80%;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
}

.add-to-playlist-modal__info-title {
    margin-bottom: 0;
    font-weight: $font-weight-normal;
    font-size: 14px;
    color: $gray-dark-02;
}

.add-to-playlist-modal__playlist {
    display: block;
    padding-bottom: 10px;
    font-size: 16px;
    color: $gray-dark-02;
}

.add-to-playlist-modal__info {
    display: flex;
    height: 70px;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    background: $white;
    padding-left: 15px;
    text-align: left;

    &::after {
        content: "";
        display: block;
        position: absolute;
        top: 100%;
        right: 50%;
        transform: translateX(50%);
        border-top: 13px solid $white;
        border-right: 13px solid $transparent;
        border-bottom: 13px solid $transparent;
        border-left: 13px solid $transparent;
    }
}

.add-to-playlist-modal__author {
    font-size: 12px;
    color: $gray-medium-03;
}

.add-to-playlist-modal__entry {
    width: 100%;
    height: 45px;
    border: none;
    border-bottom: 1px solid $gray-medium-02;
    background: $white;
    text-align: center;
    text-align-last: center;
    font-size: 14px;
    margin-bottom: 15px;
    outline: none;
    color: $gray-medium-02;
    appearance: none;
    -webkit-appearance: none;
}

.add-to-playlist-modal__entry--select {
    text-align: center;
    border-bottom: 0;
    appearance: none;
    background-image: url(https://cdn.musicvine.com/images/icons/dropdown.svg);
    background-repeat: no-repeat;
    background-size: 10px;
    background-position: 95% 50%;
    overflow: hidden;
    padding: 0 30px 0 30px;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.add-to-playlist-modal__entry::-webkit-input-placeholder {
    font-size: 14px;
    color: $gray-medium-02;
    opacity: 1;
}

.add-to-playlist-modal__entry::-moz-placeholder {
    font-size: 14px;
    color: $gray-medium-02;
    opacity: 1;
}

.add-to-playlist-modal__entry:-ms-input-placeholder {
    font-size: 14px;
    color: $gray-medium-02;
    opacity: 1;
}

.add-to-playlist-modal__entry:-moz-placeholder {
    font-size: 14px;
    color: $gray-medium-02;
    opacity: 1;
}

.add-to-playlist-modal__save {
    cursor: pointer;
    width: 100%;
    height: 50px;
    border: none;
    background: #129fcc;
    font-size: 14px;
    color: $white;
    text-align: center;
}

.add-to-playlist-modal__close {
    cursor: pointer;
    position: absolute;
    top: 12px;
    right: 12px;
}

.add-to-playlist-modal__link {
    font-size: 14px;
    color: $gray-dark-02;
    margin-bottom: 20px;
    text-align: center;

    a {
        font-weight: $font-weight-light;
        color: #0088b0 !important;
    }
}

.add-to-playlist-modal__link--secondary a {
    font-weight: $font-weight-semi-bold;
    color: #129fcc;
}

// Register success modal
// ---------------------------------------------------------

.register-succcess__stage-1,
.register-succcess__stage-2 {
    display: none;

    hr {
        margin-top: 6px;
    }

    h2 {
        font-size: 17px;
        font-weight: normal;
        text-align: center;
    }
}

.register-succcess__stage-2 {
    display: none;
}

.register-succcess__stage-1-1 {
    transition: all 0.2s;
    transform: translateY(110px);
}

.register-succcess__stage-1-2 {
    opacity: 0;
    transition: all 0.2s;
    visibility: hidden;
}

.register-succcess__stage-1.profession-selected {
    .register-succcess__stage-1-1 {
        transform: translateY(0);
    }

    .register-succcess__stage-1-2 {
        opacity: 1;
        visibility: visible;
    }
}

.register-success__profession-dropdown {
    @extend .add-to-playlist-modal__entry--select;
    @extend .add-to-playlist-modal__entry;
}

.register-success__profession-dropdown-label {
    display: inline-block;
    font-size: 11px;
    margin-bottom: 4px;
}

.register-success__newsletter-signup {
    padding: 0 20px;

    label {
        display: inline-block;
        font-size: 12px;
        margin-left: 10px;
    }
}

.register-success__offer {
    font-size: 13px;
    margin: 20px auto;
    text-align: center;

    a {
        cursor: pointer;
    }
}

.signin-hero {
    margin-bottom: 0 !important;

    .signin-container {
        padding: 20px;
        position: relative;
        text-align: center;
        overflow: hidden;
        width: 100%;
        max-width: 100%;
        min-height: 30vh;
        display: flex;
        align-items: center;
        justify-content: center;

        &.validation-error {
            height: 328px;
        }

        h1 {
            font-size: 24px;
            margin-bottom: 20px;
        }

        .modal-primary__input {
            box-shadow: none;
        }

        .js-spinner-login {
            bottom: 67px;
        }

        .modal-primary__form-stage-2 .modal-primary__input {
            margin-bottom: 20px;
        }

        .password-prompt {
            position: absolute;
            bottom: 20px;
            left: 0;
            width: 100%;
        }
    }
}

.signup-hero {
    @extend .signin-hero;

    // @media only screen and (min-width: 768px) {
    //     height: 700px;
    // }

    .signup-container {
        @extend .signin-container;

        height: auto;
        max-width: 420px;
        width: 420px;
    }

    .signup-container,
    .signin-container-inner,
    .modal-primary__form {
        overflow: visible !important;
    }
}

.signin-container-inner {
    display: flex;
    flex-direction: column;
    justify-items: center;
    align-items: center;
}

.invoice-page .invoice-page-content {
    max-width: 320px;

    .desc-text {
        font-size: 13px;
        font-weight: 500;
        color: $gray-medium-03;
    }

    .premium-checkout_payment-details {
        display: flex;
        justify-content: space-between;

        .total-text {
            color: $gray-medium-01;
        }

        .period-text {
            font-size: 12px;
            font-style: italic;
        }

        .pricing-tag {
            color: $gray-dark-02;
            font-family: $font-family-heading;
            margin-right: 3px;
            font-size: 20px;
        }

        .currency-tag {
            color: $gray-medium-03;
            font-family: $font-family-heading;
            margin-right: 2px;
            font-size: 14px;
        }
    }

    .update-pay-btn {
        margin-top: 15px;
        background: $blue-google;
        color: $white;
        padding: 8px 20px;
        border-radius: 7px;
        outline: none !important;
        width: 100%;

        &[disabled] {
            opacity: 0.8;
        }
    }
}

// Debug readout
// ---------------------------------------------------------

.modal-primary__debug {
    background: $white;
    padding: 10px;
    left: 0;
    list-style: none;
    top: 0;
    position: fixed;
    max-width: 300px;
    z-index: 100000;

    li {
        padding: 5px 0;
    }
}

.sk-circle {
    margin: 0 auto;
    width: 80px;
    height: 80px;
    position: relative;
}

.sk-circle .sk-child {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
}

.sk-circle .sk-child:before {
    content: '';
    display: block;
    margin: 0 auto;
    width: 15%;
    height: 15%;
    background-color: $gray-medium-02;
    border-radius: 100%;
    -webkit-animation: sk-circleBounceDelay 1.6s infinite ease-in-out both;
    animation: sk-circleBounceDelay 1.6s infinite ease-in-out both;
}

.sk-circle .sk-circle2 {
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

.sk-circle .sk-circle3 {
    -webkit-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
}

.sk-circle .sk-circle4 {
    -webkit-transform: rotate(135deg);
    -ms-transform: rotate(135deg);
    transform: rotate(135deg);
}

.sk-circle .sk-circle5 {
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
}

.sk-circle .sk-circle6 {
    -webkit-transform: rotate(225deg);
    -ms-transform: rotate(225deg);
    transform: rotate(225deg);
}

.sk-circle .sk-circle7 {
    -webkit-transform: rotate(270deg);
    -ms-transform: rotate(270deg);
    transform: rotate(270deg);
}

.sk-circle .sk-circle8 {
    -webkit-transform: rotate(315deg);
    -ms-transform: rotate(315deg);
    transform: rotate(315deg);
}

.sk-circle .sk-circle2:before {
    -webkit-animation-delay: -1.4s;
    animation-delay: -1.4s;
}

.sk-circle .sk-circle3:before {
    -webkit-animation-delay: -1.2s;
    animation-delay: -1.2s;
}

.sk-circle .sk-circle4:before {
    -webkit-animation-delay: -1.0s;
    animation-delay: -1.0s;
}

.sk-circle .sk-circle5:before {
    -webkit-animation-delay: -0.8s;
    animation-delay: -0.8s;
}

.sk-circle .sk-circle6:before {
    -webkit-animation-delay: -0.6s;
    animation-delay: -0.6s;
}

.sk-circle .sk-circle7:before {
    -webkit-animation-delay: -0.2s;
    animation-delay: -0.2s;
}

.sk-circle .sk-circle8:before {
    -webkit-animation-delay: -0.2s;
    animation-delay: -0.2s;
}

@-webkit-keyframes sk-circleBounceDelay {
    0%,
    80%,
    100% {
        -webkit-transform: scale(0);
        transform: scale(0);
    }

    40% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}

@keyframes sk-circleBounceDelay {
    0%,
    80%,
    100% {
        -webkit-transform: scale(0);
        transform: scale(0);
    }

    40% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}

.sk-folding-cube {
    margin: 20px auto;
    width: 40px;
    height: 40px;
    position: relative;
    -webkit-transform: rotateZ(45deg);
    transform: rotateZ(45deg);
}

.sk-folding-cube .sk-cube {
    float: left;
    width: 50%;
    height: 50%;
    position: relative;
    -webkit-transform: scale(1.1);
    -ms-transform: scale(1.1);
    transform: scale(1.1);
}

.sk-folding-cube .sk-cube:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $gray-dark-04;
    -webkit-animation: sk-foldCubeAngle 2.4s infinite linear both;
    animation: sk-foldCubeAngle 2.4s infinite linear both;
    -webkit-transform-origin: 100% 100%;
    -ms-transform-origin: 100% 100%;
    transform-origin: 100% 100%;
}

.sk-folding-cube .sk-cube2 {
    -webkit-transform: scale(1.1) rotateZ(90deg);
    transform: scale(1.1) rotateZ(90deg);
}

.sk-folding-cube .sk-cube3 {
    -webkit-transform: scale(1.1) rotateZ(180deg);
    transform: scale(1.1) rotateZ(180deg);
}

.sk-folding-cube .sk-cube4 {
    -webkit-transform: scale(1.1) rotateZ(270deg);
    transform: scale(1.1) rotateZ(270deg);
}

.sk-folding-cube .sk-cube2:before {
    -webkit-animation-delay: 0.2s;
    animation-delay: 0.2s;
}

.sk-folding-cube .sk-cube3:before {
    -webkit-animation-delay: 0.6s;
    animation-delay: 0.6s;
}

.sk-folding-cube .sk-cube4:before {
    -webkit-animation-delay: 0.9s;
    animation-delay: 0.9s;
}

@-webkit-keyframes sk-foldCubeAngle {
    0%,
    10% {
        -webkit-transform: perspective(140px) rotateX(-180deg);
        transform: perspective(140px) rotateX(-180deg);
        opacity: 0;
    }

    25%,
    75% {
        -webkit-transform: perspective(140px) rotateX(0deg);
        transform: perspective(140px) rotateX(0deg);
        opacity: 1;
    }

    90%,
    100% {
        -webkit-transform: perspective(140px) rotateY(180deg);
        transform: perspective(140px) rotateY(180deg);
        opacity: 0;
    }
}

@keyframes sk-foldCubeAngle {
    0%,
    10% {
        -webkit-transform: perspective(140px) rotateX(-180deg);
        transform: perspective(140px) rotateX(-180deg);
        opacity: 0;
    }

    25%,
    75% {
        -webkit-transform: perspective(140px) rotateX(0deg);
        transform: perspective(140px) rotateX(0deg);
        opacity: 1;
    }

    90%,
    100% {
        -webkit-transform: perspective(140px) rotateY(180deg);
        transform: perspective(140px) rotateY(180deg);
        opacity: 0;
    }
}

.pro .sk-folding-cube .sk-cube:before {
    background-color: $subscription-blue;
}

.creator .sk-folding-cube .sk-cube:before {
    background-color: $subscription-orange;
}

// latest design for modal (common styling)
.modal-container {
    display: flex;
    flex-direction: column;
    color: $gray-dark-02;
    font-family: $font-family-secondary;

    .brandon-text {
        font-family: $font-family-heading;
    }

    .modal-logo {
        height: 64px;
        margin-top: 70px;
        margin-left: 10px;
        margin-bottom: 20px;
        width: 44px;

        @media only screen and (max-height: 700px) {
            margin-top: 10px;
        }
    }

    .modal-title--header {
        padding: 55px 0 10px 20px;
    }

    .modal-limited-offer {
        color: $uppbeat;
        font-style: italic;
        font-weight: bold;
        text-transform: uppercase;

        img {
            margin-right: 5px;
            margin-top: -3px;
            max-width: 30px;
        }
    }

    .modal-title {
        color: $gray-dark-02;
        line-height: 1.2;
        font-family: $font-family-heading;
        font-size: 28px;

        .premium {
            color: $uppbeat;
        }

        &.title-md {
            font-size: 26px;
        }

        .premium-star-icon-md {
            margin-bottom: 6px;
            height: 21px;
        }
    }

    .modal-deal-name {
        font-size: 20px;
        font-weight: 600;
        margin-top: 7px;
    }

    .modal-underline-title {
        color: $gray-medium-04;
        font-weight: 500;
        font-size: 16px;
    }

    .modal-description {
        color: $gray-medium-01;
        font-size: 0.875rem;
        margin-block-start: 1rem;

        + div {
            margin-block: 1rem;
        }
    }

    .modal_notes ul {
        list-style: none;

        li {
            color: $gray-medium-02;
            font-weight: $font-weight-medium;
            margin: 0 0 10px;
            font-size: 0.8125rem;

        }

        li:before {
            content: "";
            background: url(https://cdn.uppbeat.io/images/icons/UppBeat_Icons_Tick.svg);
            display: inline-block;
            height: 14px;
            margin-right: 5px;
            transform: translateY(2px);
            width: 14px;
        }
    }

    .agreement-check[type=checkbox] {
        margin: 4px 20px 0 0;

        @media only screen and (min-width: 768px) {
            margin: 4px 10px 0 0;
            height: auto;
        }
    }

    .modal-btn-submit {
        margin-top: 15px;

        &.set-yt-btn .loader {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-10px, -10px);
            height: 20px!important;
            width: 20px!important;
        }

        &.primary {
            background: $blue-google;
        }

        &.secondary {
            background: $gray-medium-04;
        }

        &.success-btn {
            background: $green;
        }

        &.h-40 {
            height: 40px;
        }

        &.premium-stage-1 {
            padding: 0 20px;
            line-height: 40px;
        }

        &.transparent-border {
            background-color: #FFF;
            border: 1px solid #767676;
            color: #767676;
            cursor: default;

            &:not(.disabled) {
                cursor: pointer;

                &:hover {
                    background-color: #f4f4f4;
                }
            }
        }
    }

    //stripe-styling
    .ElementsApp::-webkit-input-placeholder {
        color: $gray-medium-04;
        font-weight: 500;
    }
    .form-error {
        color: $red;
    }

    .primary-text {
        color: $blue-google;
    }

    .premium-text {
        color: $uppbeat;
    }

    .success-text {
        color: $green;
        font-family: $font-family-heading;
        font-size: 16px;
        letter-spacing: 0.04rem;
    }

    .font-sm {
        font-size: 12px;
    }
}

.col-margin {
    margin: 10px 0;
}

.placeholder-text {
    color: $gray-medium-04;
    font-weight: 500;
}

/*
    Placeholder styling to match Stripe
*/

::-webkit-input-placeholder {
    color: $gray-medium-01;
    font-family: sans-serif;
    font-size: 1em;
}

::-moz-placeholder {
    color: $gray-medium-01;
    font-family: sans-serif;
    font-size: 1em;
}

:-ms-input-placeholder {
    color: $gray-medium-01;
    font-family: sans-serif;
    font-size: 1em;
}

:-moz-placeholder {
    color: $gray-medium-01;
    font-family: sans-serif;
    font-size: 1em;
}

.premium-text {
    color: $uppbeat !important;
}

.modal {
    padding-left: 0 !important;  // scss-lint:disable ImportantRule

    &.__full-screen {
        z-index: 9997;

        .modal-dialog {
            margin: 0;
            width: 100%;
            max-width: 100%;
            height: 100%;
        }

        .modal-content {
            border: none;
        }
    }

    .modal-header {
        padding-right: 20px;
        border-color: $gray-light-02;

        .close {
            margin: 4px 0 0;
            padding: 0;
            width: 16px;
            height: 16px;
            background-color: $transparent;
            background-image: url('../../svgs/cross-secondary.svg');
            outline: none;

            span {
                display: none;
            }
        }

        .modal-title.h4 {
            font-family: Brandon, Arial, Helvetica, sans-serif;
            font-size: 18px;
            font-weight: bold;
            color: $gray-medium-01;
        }
    }

    .popup-close-btn {
        position: absolute;
        top: 1.25rem;
        right: 1.25rem;
    }
}

.modal-backdrop-white {
    z-index: 9997;
    background-color: $white;
    opacity: 1 !important;  // scss-lint:disable ImportantRule
}

.whitelist-modal-text,
.welcome-to-premium-modal-text {
    padding: 0 !important;
}


.playlist-btn {
    margin-top: 50px;

    .loader {
        height: 20px!important;
        width: 20px!important;
    }
}

.invited-users-list {
    display: flex;

    > div:first-child {
        flex-shrink: 0;
        min-width: 30px;
    }
}
