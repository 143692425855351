// Playlist
//
// Create playlist

// Navigation
// ---------------------------------------------------------

.playlist-nav {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    height: 45px;
    background: #1e262a;
    color: $gray-medium-04;

    &.editing-active {
        background: #401d1d
    }

    a {
        color: $gray-medium-04;
    }
}

// Header
// ---------------------------------------------------------

.playlist-header {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    height: 320px;
    background: #151c20;
    color: $white;
}

.playlist-header.editing-active {
    background: #3b1818;
}

// Title
// ---------------------------------------------------------

.playlist-title {
    font-weight: $font-weight-medium;
    font-family: $font-family-secondary;
    font-size: 40px;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 800px;
    margin-top: -12px; // Compensate for additional space from line-height that makes title appear off-centre

    @media only screen and (min-width: 768px) {
        font-size: 48px;
    }
}

.editing-active .playlist-title {
    margin-bottom: 0;
}

// Buttons
// ---------------------------------------------------------

.playlist-buttons {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;

    @media only screen and (min-width: 768px) {
        flex-direction: row;
    }

    button {
        cursor: pointer;
        width: 190px;
        height: 45px;
        background: #2f3b41;
        color: $gray-medium-04;
        margin: 0 5px 10px;
        font-family: $font-family-primary;
        font-size: 14px;
        transition: 0.2s;

        &:hover {
            background: #1992bf;
        }

        &:focus {
            outline: none;
        }

        &:first-child button {
            margin-left: 0;
        }

        &:last-child button {
            margin-right: 0;
        }
    }
}

// Arrow
// ---------------------------------------------------------

.playlist-arrow {
    display: inline-block;
    width: 0px;
    height: 0px;
    border-top: 5px solid $transparent;
    border-right: 7.5px solid $gray-medium-04;
    border-bottom: 5px solid $transparent;
    border-left: 5px solid $transparent;
    margin-right: 10px;
}

// Editing
// ---------------------------------------------------------

.editing-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-bottom: 30px;

    svg {
        cursor: pointer;
        margin-left: 15px;
    }
}

.editing-form {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 30px;
}

.editing-input {
    width: 200px;
    height: 45px;
    padding: 0;
    margin: 0;
    padding-left: 20px;
    font-size: 20px;
    background-color: $white;
    box-shadow: 0;
    border-width: 0;
    color: $gray-dark-04;

    @media only screen and (min-width: 768px) {
        width: 500px;
    }

    &:focus {
        outline: none;
    }
}

.editing-submit {
    cursor: pointer;
    padding: 0;
    margin: 0;
    width: 100px;
    height: 45px;
    font-family: $font-family-primary;
    font-size: 14px;
    color: $gray-medium-03;
    background-color: #311919;

    &:focus {
        outline: none;
    }
}

.playlist-edit-text {
    display: block;
    margin-bottom: 20px;
    width: 100%;
    text-transform: uppercase;
    letter-spacing: 0.125rem;
    font-family: $font-family-primary;
    font-weight: $font-weight-semi-bold;
    font-size: 12px;
}

.editing-btn {
    color: $white;
    outline: none;
    cursor: pointer;
    width: 190px;
    height: 45px;
    font-family: $font-family-primary;
    font-size: 14px;
    background: #2a1717;
}

// Error
// ---------------------------------------------------------

.playlist-title-error {
    display: block;
    margin-bottom: 20px;
    font-weight: $font-weight-bold;
    font-family: $font-family-primary;
    font-size: 14px;
}
