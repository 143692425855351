.playlists-container {
  .page-title {
    text-align: center;

    @media only screen and (min-width: 500px) {
      text-align: left;
    }
  }

  .playlists--wrapper-container {
    padding: 0;

    @media only screen and (min-width: 450px) {
      padding: 0 clamp(0.75rem, 1.5cqw, 1.5rem);
    }

    .playlists--wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 85px;
      padding: 0;

      @media only screen and (min-width: 450px) {
        padding: 0.125rem clamp(0.75rem, 1.5cqw, 1.5rem);
        border-radius: 1rem;
      }

      > div:first-child {
        display: flex;
        align-items: center;
      }

      > div:last-child {
        display: flex;
        align-items: center;
      }

      &:hover {
        background: linear-gradient(-90deg, var(--track-player-hover-right-bg), var(--track-player-hover-left-bg));

        .playlists--icon--wrapper img {
          filter: brightness(75%) !important;
        }

        .playlists--icon--wrapper .playlists--icon--svg {
          opacity: 1;
        }

        .playlists--edit--icon,
        .playlists--delete--icon {
          opacity: 1;
        }
      }

      .playlists--icon--wrapper {
        position: relative;
        cursor: pointer;
        width: 3.5rem;
        min-width: 3.5rem;
        height: 3.5rem;
        border-radius: 50%;
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr 1fr;
        gap: 0.125rem;
        overflow: hidden;

        @media only screen and (min-width: 992px) {
          width: 4rem;
          min-width: 4rem;
          height: 4rem;
        }

        &.single {
          grid-template-columns: 1fr;
          grid-template-rows: 1fr;
        }

        .wrapper img {
          max-width: 100%;
        }

        .playlists--icon--svg {
          position: absolute;
          top: 0;
          right: 0;
          left: 0;
          bottom: 0;
          opacity: 0;

          .ub-track-pause-icon,
          .ub-track-play-icon,
          .ub-track-load-icon {
            position: absolute;
            top: 0;
            right: 0;
            left: 0;
            bottom: 0;

            svg {
              width: 100%;
              height: 100%;
            }
          }
        }
      }

      .playlists--name a {
        display: block;
        font-family: $font-family-secondary;
        font-size: 0.875rem;
        font-weight: 500;
        padding: 0 1rem;
        @include c(--browse-tracks-title-c);

        @media only screen and (min-width: 992px) {
          padding: 0 1.5rem;
        }
      }

    .playlists--edit--icon,
    .playlists--delete--icon {
        cursor: pointer;
        opacity: 0.5;

        img {
          width: 16px;
        }
      }
    }

    .ub-track-play-pause-sm {
      width: 2rem;
      min-width: 2rem;
      height: 2rem;
      min-height: 2rem;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      background: $white;
      z-index: 1;
      margin-inline-start: -0.75rem;

      @media only screen and (min-width: 992px) {
        display: none;
      }

      .toggle-play-pause {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 50%;
          border: 1px solid var(--ub-border-light);
        }

        .ub-track-load-icon,
        .browse-player__spinner {
          width: 1rem;
          height: 1rem;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }

  .playlists-content {
    display: flex;
    flex-wrap: wrap;
    padding: 10px;
    background: $gray-light-03;
  }

  .playlist-left {
    width: 100%;
    position: relative;
    margin-bottom: 20px;

    @media (min-width: 992px) {
      width: 22.5%;
      margin-bottom: 0;
    }
  }

  .playlist-sidebar {
    padding-right: 20px;

    @media (max-width: 768px) {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  .playlist-title {
    display: inline-block;
    margin-bottom: 30px;
    font-family: brandon-grotesque, sans-serif;
    font-weight: 400;
    font-size: 24px;
    text-decoration: underline !important;
    color: $black !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-transform: capitalize;
    text-align: left;
    width: calc(100% - 80px);
    margin-top: 0;

    .playlist-like-icon {
      position: relative;
      margin-right: 10px;
      width: 30px;
      height: 30px;

      .st0 {
        stroke-width: 1px;
        stroke: $black;
      }
    }

    @media (min-width: 992px) {
      width: 100%;
    }
  }

  .playlist-right {
    display: flex;
    flex-wrap: wrap;
    margin-right: -10px;
    width: 77.5%;
  }

  .playlist-item {
    padding-right: 10px;
    margin-bottom: 20px;
    font-size: 14px;
    width: 16.66667%;

    @media (max-width: 992px) {
      width: 33.33333%;
    }
  }

  .playlist-top {
    position: relative;

    &.activeTrack.track-icon-load-state {
      visibility: visible;
      opacity: 1;
    }
  }

  .playlist-title-header {
    color: $gray-medium-03;
    font-weight: 400;
  }

  .playlist-icon {
    cursor: pointer;
    position: absolute;
    bottom: 50%;
    right: 50%;
    -ms-transform: translate(50%, 44%);
    transform: translate(50%, 44%);
    height: 85px;
    width: 85px;

    &svg circle {
      transition: opacity 0.2s;
      opacity: 0.7;
    }

    &:hover svg circle {
      transition: opacity 0.2s;
      opacity: 0.9;
    }
  }

  .playlist-bottom {
    display: flex;
    flex-direction: column;
  }

  .playlist-sub-title {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin: 0;
    padding-bottom: 3.33333px;
    font-weight: 600;
    font-size: 14px;
  }

  .playlist-view-link {
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 55px solid $gray-light-03;
    font-family: brandon-grotesque, sans-serif;
    font-weight: 400;
    font-size: 18px;
    color: #20a3e7 !important;
    background: rgba($gray-medium-04, 0.502);

    & > a {
      color: #20a3e7 !important;
    }
  }

  .playlist-link-container {
    display: flex;
    flex-direction: column;
    margin-top: 5px;
  }

  .playlist-link {
    display: inline-block;
    font-family: $font-family-primary;
    font-size: 13px;
    padding-bottom: 5px;
    cursor: pointer;
  }

  .spacer {
    margin-bottom: 20px;
  }

  .spacer-sm {
    margin-bottom: 10px;
  }
}

.earning-container {
  position: absolute;
  bottom: 85%;
  right: 40px;
  top: 90px;
  -ms-transform: translateY(85%);
  transform: translateY(85%);
  margin-top: inherit;
  text-align: right;

  @media(max-width: 768px) {
    position: initial;
    text-align: center;
    margin-bottom: 75px;
    margin-top: 10px;
    transform: translateY(0%)
  }

  .earning-amount {
    line-height: 1;
    font-weight: 300;
    font-family: brandon-grotesque, sans-serif;
    font-size: 30px;
    color: $white;
  }

  .earning-title {
    line-height: 1;
    font-weight: 300;
    font-size: 13px;
    color: #dc3545;
    margin-top: 5px;
  }
}

.playlist--filter--button {
  background-color: $transparent;
  border-color: $transparent;
  color: $gray-medium-03;

  &.btn-active {
    color: $uppbeat;
  }
}

.playlist--filter--container {
  border-bottom: 1px solid $gray-light-01;
  padding-bottom: 8px;
  margin-bottom: 6px;
}
