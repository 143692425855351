body {
  pointer-events: all !important;
}

.margin-top-s {
    margin-top: 15px;
}

.margin-top-xl {
    margin-top: 30px;
}

.margin-right-xs {
    margin-right: 10px;
}

.margin-bottom-m {
    margin-bottom: 20px;
}

.content-width-220 {
    width: 220px;
}

.opacity-4 {
    opacity: .4;
}

.opacity-6 {
    opacity: .6;
}

.opacity-7 {
    opacity: .7;
}
