.ub-btn {
	width: var(--ubbtn-width, auto);
	height: 2.5rem;
	min-width: 2.5rem;
	padding: var(--ubbtn-padding, 0 0.75rem);
	outline: none !important;
	position: relative;
	overflow: hidden;
	border: 1px solid $transparent;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: var(--ubbtn-border-radius, 0);
	cursor: pointer;
	transition: opacity 0.2s ease-in-out;
	@include css4 {
		color: var(--ubbtn-color, cvar(--button-primary-c));
		background: var(--ubbtn-background, cvar(--button-transparent-c));
	}

	// Icon
	.ub-btn-icon {
		width: 1.125rem;
		height: 1.125rem;
		min-width: 1.125rem;
		display: flex;
		justify-content: center;
		align-items: center;

		i {
			display: flex;
			align-items: center;
		}

		i, svg {
			width: 1.125rem;
			height: 1.125rem;
		}

		img {
			width: 100%;
			height: 100%;
		}
	}

	// Icon Position
	&.left .ub-btn-icon {
		margin-inline-end: 0.5rem;
	}

	&.right {
		flex-direction: row-reverse;

		.ub-btn-icon {
			margin-inline-start: 0.5rem;
		}
	}

	// Icon Only
	&.icon-only {
		width: var(--ubbtn-width, 2.5rem);
		padding: 0;

		.ub-btn-icon {
			margin: 0;
		}
	}

	&:focus-visible {
		z-index: 4;
	}

	// Label
	.ub-btn-label {
		justify-content: center;
		align-items: center;
		text-align: center;
		pointer-events: none;
		font-size: 0.875rem;
		font-weight: 400;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
		font-family: $font-family-primary;

		img {
			width: 1.25rem;
			height: 1.25rem;
			min-width: 1.25rem;
		}
	}

	// Menu
	&.menu {
		padding: 0;

		.ub-btn-menu-icon,
		.ub-btn-menu-icon::before,
		.ub-btn-menu-icon::after {
			width: 1rem;
			height: 0.125rem;
			border-radius: 0.0625rem;
		}

		.ub-btn-menu-icon {
			position: relative;
			transition: transform 0.2s;

			&::before,
			&::after {
				content: "";
				display: block;
				position: absolute;
			}

			&::before {
				transition: top 0.2s;
			}

			&::after {
				transition: transform 0.2s, bottom 0.2s;
			}
		}

		&.open {
			.ub-btn-menu-icon::before {
				top: -6px;
			}

			.ub-btn-menu-icon::after {
				bottom: -6px;
			}

			&.small .ub-btn-menu-icon {
				transform: scale(0.875);
			}

			&.xsmall .ub-btn-menu-icon {
				transform: scale(0.75);
			}
		}

		&.close {
			.ub-btn-menu-icon {
				transform: rotate(45deg) scale(1.25);
			}

			.ub-btn-menu-icon::before {
				top: 0;
			}

			.ub-btn-menu-icon::after {
				bottom: 0;
				transform: rotate(90deg);
			}

			&.small .ub-btn-menu-icon {
				transform: rotate(45deg) scale(1);
			}

			&.xsmall .ub-btn-menu-icon {
				transform: rotate(45deg) scale(0.875);
			}
		}

		&.back {
			.ub-btn-menu-icon {
				width: 0.875rem;
			}

			.ub-btn-menu-icon::before {
				width: 0.5rem;
				top: -3.5px;
				left: -0.5px;
				transform: rotate(-45deg);
			}

			.ub-btn-menu-icon::after {
				width: 0.5rem;
				bottom: -2.5px;
				left: -0.5px;
				transform: rotate(45deg);
			}

			&.small .ub-btn-menu-icon {
				transform: scale(0.875);
			}

			&.xsmall .ub-btn-menu-icon {
				transform: scale(0.75);
			}
		}

		&.xxsmall {
			.ub-btn-menu-icon {
				transform: scale(0.5) rotate(45deg);
			}
		}
	}

	// Size
	&.small {
		height: 2rem;
		min-width: 2rem;

		&.icon-only {
			width: var(--ubbtn-width, 2rem);
		}

		.ub-btn-icon {
			width: 1rem;
			height: 1rem;
			min-width: 1rem;

			i, svg {
				width: 1rem;
				height: 1rem;
			}
		}

		.ub-btn-label {
			font-size: 0.8125rem;

			img {
				width: 1rem;
				height: 1rem;
				min-width: 1rem;
			}
		}
	}

	&.xsmall {
		height: 1.5rem;
		min-width: 1.5rem;

		&.icon-only {
			width: var(--ubbtn-width, 1.5rem);
		}

		.ub-btn-icon {
			width: 0.75rem;
			height: 0.75rem;
			min-width: 0.75rem;
			font-size: 0.75rem;

			i, svg {
				width: 0.75rem;
				height: 0.75rem;
			}
		}

		.ub-btn-label {
			font-size: 0.75rem;

			img {
				width: 0.75rem;
				height: 0.75rem;
				min-width: 0.75rem;
			}
		}
	}

	&.xxsmall {
		height: 1.25rem;
		min-width: 1.25rem;

		&.icon-only {
			width: var(--ubbtn-width, 1.25rem);
		}

		.ub-btn-icon {
			width: 0.5rem;
			height: 0.5rem;
			min-width: 0.5rem;
			font-size: 0.5rem;

			i, svg {
				width: 0.5rem;
				height: 0.5rem;
			}
		}

		.ub-btn-label {
			font-size: 0.625rem;

			img {
				width: 0.5rem;
				height: 0.5rem;
				min-width: 0.5rem;
			}
		}
	}

	// Borders
	&.full {
		border-radius: 1.25rem;

		&.small {
			border-radius: 1rem;
		}

		&.xsmall {
			border-radius: 0.75rem;
		}

		&.xxsmall {
			border-radius: 0.625rem;
		}
	}

	&.half {
		border-radius: 0.75rem;

		&.small {
			border-radius: 0.625rem;
		}

		&.xsmall {
			border-radius: 0.5rem;
		}

		&.xxsmall {
			border-radius: 0.375rem;
		}
	}

	&:disabled {
		opacity: 0.5;
		pointer-events: none;
	}

	&.dropdown {
		position: relative;

		.ub-btn-dropdown {
			position: fixed;
			top: 1.5rem;
			width: max-content;
			z-index: 4;
			padding: 0.25rem;
			border-radius: 0.5rem;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			transition: top 0.2s, transform 0.2s, opacity 0.3s;
			transform: scale(0.85);
			opacity: 0;
			content-visibility: auto;

			@include bg(--button-primary-bg);
			@include css4 {
				border: 1px solid cvar(--button-dropdown-br);
				box-shadow: 0 0 8px cvar(--shadow-dark);
			}

			&.columns-0 {
				display: flex;
				flex-direction: row;
			}

			&.columns-2 {
				display: grid;
				grid-template-columns: 1fr 1fr;
			}

			&.columns-3 {
				display: grid;
				grid-template-columns: 1fr 1fr 1fr;
			}

			&::before {
				top: -0.5rem;
				left: calc(50% - 0.5rem);
				position: absolute;
				content: "";
				display: block;
				width: 0;
				height: 0;
				border-style: solid;
				border-width: 0 0.5rem 0.5rem 0.5rem;
				z-index: -1;
				@include css4 {
					border-color: transparent transparent cvar((--button-primary-bg)) transparent;
					filter: drop-shadow(0px -1px 0px cvar(--button-dropdown-br));
				}
			}
		}
	}

	// Variants
	&.uppbeat {
		@include css4 {
			color: var(--ubbtn-color, cvar(--button-uppbeat-c));
			background: var(--ubbtn-background, cvar(--button-uppbeat-bg));
		}

		@media (hover: hover) {
			&:hover:not(.disable-hover) {
				@include bg(--button-uppbeat-hover-bg);
			}
		}

		&:focus-visible {
			@include css4 {
				box-shadow: 0 0 4px cvar(--button-uppbeat-hover-bg);
			}
		}

		.ub-btn-menu-icon,
		.ub-btn-menu-icon::before,
		.ub-btn-menu-icon::after {
			@include bg(--button-uppbeat-c);
		}

		&.bordered {
			border-color: var(--button-uppbeat-br);
		}
	}

	&.uppbeat-light {
		@include css4 {
			color: var(--ubbtn-, cvar(--button-uppbeat-light-c));
			background: var(--ubbtn-background, cvar(--button-uppbeat-light-bg));
		}

		@media (hover: hover) {
			&:hover:not(.disable-hover) {
				@include bg(--button-uppbeat-light-hover-bg);
			}
		}

		&:focus-visible {
			@include css4 {
				box-shadow: 0 0 4px cvar(--button-uppbeat-light-hover-bg);
			}
		}

		.ub-btn-menu-icon,
		.ub-btn-menu-icon::before,
		.ub-btn-menu-icon::after {
			@include bg(--button-uppbeat-light-c);
		}

		&.bordered {
			border-color: var(--button-uppbeat-light-br);
		}
	}

	&.primary {
		@include css4 {
			color: var(--ubbtn-color, cvar(--button-primary-c));
			background: var(--ubbtn-background, cvar(--button-primary-bg));
		}

		@media (hover: hover) {
			&:hover:not(.disable-hover) {
				@include bg(--button-primary-hover-bg);
			}
		}

		&:focus-visible {
			@include css4 {
				border-color: cvar(--button-uppbeat-bg);
				box-shadow: 0 0 4px cvar(--button-uppbeat-hover-bg);
			}
		}

		.ub-btn-menu-icon,
		.ub-btn-menu-icon::before,
		.ub-btn-menu-icon::after {
			@include bg(--button-primary-c);
		}

		svg path {
			@include f(--button-primary-c);
		}

		&.bordered {
			border-color: var(--button-primary-br);
		}
	}

	&.secondary {
		@include css4 {
			color: var(--ubbtn-color, cvar(--button-secondary-c));
			background: var(--ubbtn-background, cvar(--button-secondary-bg));
		}

		@media (hover: hover) {
			&:hover:not(.disable-hover) {
				@include bg(--button-secondary-hover-bg);
			}
		}

		&:focus-visible {
			@include css4 {
				border-color: cvar(--button-uppbeat-bg);
				box-shadow: 0 0 4px cvar(--button-uppbeat-hover-bg);
			}
		}

		.ub-btn-menu-icon,
		.ub-btn-menu-icon::before,
		.ub-btn-menu-icon::after {
			@include bg(--button-secondary-c);
		}

    // Sorry - SB
		svg path:not([class^="fill-"]):not([class^="!fill-"]) {
			@include f(--button-secondary-c);
		}

		&.bordered {
			border-color: var(--button-secondary-br);
		}
	}

	&.ai {
		@include css4 {
			color: var(--ubbtn-color, cvar(--button-ai-c));
			background: var(--ubbtn-background, cvar(--button-ai-bg));
		}

		@media (hover: hover) {
			&:hover:not(.disable-hover) {
				@include bg(--button-ai-hover-bg);
			}
		}

		.ub-btn-menu-icon,
		.ub-btn-menu-icon::before,
		.ub-btn-menu-icon::after {
			@include bg(--button-ai-c);
		}

		svg path {
			@include f(--button-ai-c);
		}

		&.bordered {
			border-color: var(--button-ai-br);
		}
	}

	&.free {
		@include css4 {
			color: var(--ubbtn-color, cvar(--button-free-c));
			background: var(--ubbtn-background, cvar(--button-free-bg));
		}

		@media (hover: hover) {
			&:hover:not(.disable-hover) {
				@include bg(--button-free-hover-bg);
			}
		}

		.ub-btn-menu-icon,
		.ub-btn-menu-icon::before,
		.ub-btn-menu-icon::after {
			@include bg(--button-free-c);
		}

		svg path {
			@include f(--button-free-c);
		}

		&.bordered {
			border-color: var(--button-free-br);
		}
	}

	&.success {
		@include css4 {
			color: var(--ubbtn-color, cvar(--button-success-c));
			background: var(--ubbtn-background, cvar(--button-success-bg));
		}

		@media (hover: hover) {
			&:hover:not(.disable-hover) {
				@include bg(--button-success-hover-bg);
			}
		}

		.ub-btn-menu-icon,
		.ub-btn-menu-icon::before,
		.ub-btn-menu-icon::after {
			@include bg(--button-success-c);
		}

		svg path {
			@include f(--button-success-c);
		}

		&.bordered {
			border-color: var(--button-success-br);
		}
	}

	&.transparent {
		@include css4 {
			color: var(--ubbtn-color, cvar(--button-transparent-c));
			background: var(--ubbtn-background, cvar(--button-transparent-bg));
		}

		@media (hover: hover) {
			&:hover:not(.disable-hover) {
				@include bg(--button-transparent-hover-bg);
			}
		}

		&:focus-visible {
			@include css4 {
				border-color: cvar(--button-uppbeat-bg);
				box-shadow: 0 0 4px cvar(--button-uppbeat-hover-bg);
			}
		}

		.ub-btn-menu-icon,
		.ub-btn-menu-icon::before,
		.ub-btn-menu-icon::after{
			@include bg(--button-transparent-c);
		}

		svg path {
			@include f(--button-transparent-c);
		}

		&.bordered {
			border-color: var(--button-transparent-br);
		}
	}

	&.business {
		@include css4 {
			color: var(--ubbtn-color, cvar(--button-business-c));
			background: var(--ubbtn-background, cvar(--button-business-bg));
		}

		@media (hover: hover) {
			&:hover:not(.disable-hover) {
				@include bg(--button-business-hover-bg);
			}
		}

		&:focus-visible {
			@include css4 {
				box-shadow: 0 0 4px cvar(--button-business-hover-bg);
			}
		}

		.ub-btn-menu-icon,
		.ub-btn-menu-icon::before,
		.ub-btn-menu-icon::after {
			@include bg(--button-business-c);
		}

		svg path {
			@include f(--button-business-c);
		}

		&.bordered {
			border-color: var(--button-business-br);
		}
	}

  &.white {
    @include css4 {
      color: var(--ubbtn-color, cvar(--button-white-c));
      background: var(--ubbtn-background, cvar(--button-white-bg));
    }

    @media (hover: hover) {
      &:hover:not(.disable-hover) {
        @include bg(--button-white-hover-bg);
      }
    }

    &:focus-visible {
      @include css4 {
        box-shadow: 0 0 4px cvar(--button-white-hover-bg);
      }
    }

    .ub-btn-menu-icon,
    .ub-btn-menu-icon::before,
    .ub-btn-menu-icon::after {
      @include bg(--button-white-c);
    }

    svg path {
      @include f(--button-white-c);
    }

    &.bordered {
      border-color: var(--button-white-br);
    }
  }

	&.error {
		@include css4 {
			color: var(--ubbtn-color, cvar(--button-error-c));
			background: var(--ubbtn-background, cvar(--button-error-bg));
		}

		@media (hover: hover) {
			&:hover:not(.disable-hover) {
				@include bg(--button-error-hover-bg);
			}
		}

		&:focus-visible {
			@include css4 {
				box-shadow: 0 0 4px cvar(--button-error-hover-bg);
			}
		}

		.ub-btn-menu-icon,
		.ub-btn-menu-icon::before,
		.ub-btn-menu-icon::after {
			@include bg(--button-error-c);
		}

		svg path {
			@include f(--button-error-c);
		}

		&.bordered {
			border-color: var(--button-error-br);
		}
	}

	&.contrast {
		@include css4 {
			color: var(--ubbtn-color, cvar(--button-contrast-c));
			background: var(--ubbtn-background, cvar(--button-contrast-bg));
		}

		@media (hover: hover) {
			&:hover:not(.disable-hover) {
				@include bg(--button-contrast-hover-bg);
			}
		}

		&:focus-visible {
			@include css4 {
				box-shadow: 0 0 4px cvar(--button-contrast-hover-bg);
			}
		}

		.ub-btn-menu-icon,
		.ub-btn-menu-icon::before,
		.ub-btn-menu-icon::after{
			@include bg(--button-contrast-c);
		}

		svg path {
			@include f(--button-contrast-c);
		}

		&.bordered {
			border-color: var(--button-contrast-br);
		}
	}

	&.ai {
		@include css4 {
			color: var(--ubbtn-color, cvar(--button-ai-c));
			background: var(--ubbtn-background, cvar(--button-ai-bg));
		}

		@media (hover: hover) {
			&:hover:not(.disable-hover) {
				@include bg(--button-ai-hover-bg);
			}
		}

		&:focus-visible {
			@include css4 {
				box-shadow: 0 0 4px cvar(--button-ai-hover-bg);
			}
		}

		.ub-btn-menu-icon,
		.ub-btn-menu-icon::before,
		.ub-btn-menu-icon::after{
			@include bg(--button-ai-c);
		}

		svg path {
			@include f(--button-ai-c);
		}
	}
}

.ub-btn-dropdown-wrapper {
	position: relative;

	.ub-btn-dropdown {
		display: flex;
		transition: opacity 0.2s;

		&.hidden {
			pointer-events: none;
			opacity: 0;
			visibility: hidden;
		}
		&.visible {
			opacity: 1;
			visibility: visible;

			&.columns-2 {
				display: grid;
			}

			&.columns-3 {
				display: grid;
			}
		}
	}

	> div {
		position: absolute;
	}
}


.ub-btn-dropdown-container {
	overflow: visible;
	z-index: 9996;
	position: fixed;
}

.ub-btn-dropdown {
	position: fixed;
	width: max-content;
	z-index: 4;
	padding: 0.25rem;
	border-radius: 0.5rem;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	transition: top 0.2s, transform 0.2s, opacity 0.3s;
	@include bg(--button-primary-bg);
	@include css4 {
		border: 1px solid cvar(--button-dropdown-br);
		box-shadow: 0 0 8px cvar(--shadow-dark);
	}

	&.columns-0 {
		display: flex;
		flex-direction: row;
	}

	&.columns-2 {
		display: grid;
		grid-template-columns: 1fr 1fr;
	}

	&.columns-3 {
		display: grid;
		grid-template-columns: 1fr 1fr 1fr;
	}

	&.bottom-left::before {
		top: -0.4375rem;
		right: calc((var(--ubbtn-calc-width) / 2) - 7px);
	}

	&.bottom-center::before {
		top: -0.4375rem;
		left: calc(50% - 0.5rem);
	}

	&.bottom-right::before {
		top: -0.4375rem;
		left: calc((var(--ubbtn-calc-width) / 2) - 7px);
	}

	&.top-left::before {
		bottom: -0.4375rem;
		right: calc((var(--ubbtn-calc-width) / 2) - 7px);
		transform: rotate(180deg);
	}

	&.top-center::before {
		bottom: -0.4375rem;
		left: calc(50% - 0.5rem);
		transform: rotate(180deg);
	}

	&.top-right::before {
		bottom: -0.4375rem;
		left: calc((var(--ubbtn-calc-width) / 2) - 7px);
		transform: rotate(180deg);
	}

	&::before {
		position: absolute;
		content: "";
		display: block;
		width: 0;
		height: 0;
		border-style: solid;
		border-width: 0 0.5rem 0.5rem 0.5rem;
		z-index: -1;
		@include css4 {
			border-color: transparent transparent cvar((--button-primary-bg)) transparent;
			filter: drop-shadow(0px -1px 0px cvar(--button-dropdown-br));
		}
	}
}
