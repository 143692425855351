.progress_premium {
    position: relative;
    height: 40px;

    .progress_premium__back {
        position: relative;
        z-index: 2;
        height: 10px;
        border-radius: 5px;
        overflow: hidden;
        @include bg(--account-premium-progress-bar-bg);
    }

    .progress_premium__line {
        position: absolute;
        padding-right: 5px;
        width: 11%;
        min-width: 20px;
        height: 100%;
        font-family: $font-family-secondary;
        font-size: 8px;
        line-height: 10px;
        text-align: center;
        color: $business;
        background-color: $white;

        &::after {
            content: '';
            position: absolute;
            top: 0;
            left: 100%;
            display: block;
            border: 5px solid $transparent;
            border-left: 5px solid $white;
            border-top: 5px solid $white;
        }

        &.pc-100 {
            &::after {
                display: none;
            }
        }
    }

    .progress_premium__delimiter {
        position: absolute;
        top: -5px;
        z-index: 1;
        width: 1px;
        height: 20px;
        background-color: $white;

        &.__1 {
            left: 33%;
        }
        &.__2 {
            left: 66%;
        }
        &.__3 {
            left: calc(100% - 3px);
        }
    }

    .progress_premium__marker {
        position: absolute;
        top: 20px;
        width: 20px;
        font-family: Roboto, Arial, Helvetica, sans-serif;
        font-size: 13px;
        text-align: center;
        color: $white;
        opacity: .6;

        &.__1 {
            left: calc(33% - 10px);
        }
        &.__2 {
            left: calc(66% - 10px);
        }
        &.__3 {
            left: calc(100% - 13px);
        }
    }
}
