// Helpers
//
// Helper classes

// Height
// ---------------------------------------------------------

.success-text-helper {
    font-family: $font-family-secondary;
    font-size: 0.8125rem;
    line-height: 1.5;
    color: #5CC875;

    &.password {
        margin-block-end: 0.75rem;

        + .form-error {
            margin-block-end: 0.75rem;
        }
    }
}

.form-error-helper {
    font-family: $font-family-secondary;
    font-size: 0.8125rem;
    line-height: 1.5;
    color: #FF3E3E;
}
