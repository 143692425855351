// Shades
$black: #0B0C0C;
$gray-dark-01: #151919;
$gray-dark-02: #232525;
$gray-dark-03: #323434;
$gray-dark-04: #4E5152;
$gray-medium-01: #6F7475;
$gray-medium-02: #838A8A;
$gray-medium-03: #A1A8AA;
$gray-medium-04: #BEC6C6;
$gray-light-01: #CDD2D1;
$gray-light-02: #DFE1E0;
$gray-light-03: #F0F2F2;
$gray-light-04: #F7F9F9;
$white: #FFF;

// Green
$green-light: #DEF7EA; // Not used yet
$green: #33CA7F;
$green-dark: #2CAF6E; // Not used yet

$blue-google: #4C98EF; // may need removing

//Free
$free-light: #CCF4FC;
// $free: #2CD1F2;
$free: #2DCAE9;
$free-dark: #0ca3c1; // Not used yet

// AI
$ai-light: #C7FFFC;
$ai: #32FBEF;
$ai-dark: #03A59A; // Not used yet

// Uppbeat
$uppbeat-light: #FFF5F9;
$uppbeat-medium: #FFE2EE;
$uppbeat: #F23D75;
$uppbeat-dark: #E1235F;

// Red
$red-light: #FFDCDC;
$red: #F34949;
$red-dark: #E22C2C;

// Business
$business-light: #FFF2D0;
// $business: #FABC2A;
$business: #F0B62D;
$business-dark: #C48A00;

// Carousels
$orange-soda: #FD4926; // Not used yet
$orange: #FE8100; // Not used yet
$light-salmon: #FF9F6F; // Not used yet
$marigold: #F3A100; // Not used yet
$maize-crayola: #FFC703; // Not used yet
$misty-moss: #B0A961; // Not used yet
$june-bud: #BCD354; // Not used yet
$blue-green: #06CCD3; // Not used yet
$columbian-blue: #ADCBD3; // Not used yet
$cornflour-blue: #7A94D5; // Not used yet
$indigo-blue: #024962; // Not used yet
$space-cadet: #121233; // Not used yet
$dark-purple: #48033A; // Not used yet
$medium-orchid: #DE4EFF; // Not used yet
$plum-web: #FE98EB; // Not used yet
$lavender-blue: #D4C1F8; // Not used yet
$pale-silver: #DCCABA; // Not used yet
$dark-lava: #544236; // Not used yet
$dim-grey: #676874; // Not used yet

// Special
$ai-bg-01: #00101f;
$ai-bg-02: #1B264B;
$trending-01: #014461;
$trending-02: #3782a3;
$explicit: #A72525;
$discord: #5765F2;

$transparent: transparent;

$css4: true;

$lightTheme: (
	--account-icon-bg-fill: $gray-medium-01,
	--account-icon-bg-hover-fill: $gray-dark-04,
	--account-icon-man-fill: $gray-light-01,
	--account-icon-mobile-bg-fill: rgba($black, 0.35),
	--account-icon-mobile-bg-hover-fill: rgba($black, 0.45),
	--account-icon-mobile-man-fill: rgba($white, 0.9),
	--account-dropdown-bg: $gray-light-03,
	--account-dropdown-header-c: $gray-dark-03,
	--account-dropdown-c: $gray-medium-01,
	--account-premium-progress-bar-bg: rgba($business-light, 0.5),
	--account-plan-primary-bg: $white,
	--account-plan-secondary-bg: $free-light,
	--account-plan-primary-c: $free,

	--admin-bar-bg: $gray-dark-01,
	--admin-bar-c: $gray-light-03,
	--admin-bar-relevancy-bg: $green,
	--admin-bar-relevancy-br: $green,

	--accordion-bg: $gray-light-04,
	--accordion-active-bg: rgba($gray-light-03, 0.5),
	--accordion-primary-c: $gray-dark-02,
	--accordion-secondary-c: $gray-medium-01,
	--accordion-icon-fill: $gray-medium-01,
	--accordion-link-c: $uppbeat,
	--accordion-tabs-bg: $gray-light-03,
	--accordion-tabs-active-bg: $uppbeat,
	--accordion-tabs-hover-bg: $gray-light-02,
	--accordion-tabs-c: $gray-dark-02,
	--accordion-link-underline-c: $uppbeat,
	--accordion-br: $gray-light-03,
	--accordion-tabs-desktop-bg: $transparent,
	--accordion-tabs-desktop-active-bg: $gray-light-04,
	--accordion-tabs-active-c: $white,
	--accordion-bullet-c: $uppbeat,

	--ai-page-bg: $ai-bg-01,
	--ai-page-pattern-bg: $ai-bg-02,
	--ai-page-pattern-bg-alt: $ai,
	--ai-page-logo-text-c: $ai,
	--ai-page-c: $white,
	--ai-page-alt-c: rgba($white, 0.7),
	--ai-page-highlight-c: $ai,
	--ai-page-search-option-bg: $gray-dark-03,
	--ai-page-search-option-br: $ai,
	--ai-page-input-bg: $white,
	--ai-page-input-br: $gray-light-03,
	--ai-page-input-active-br: $ai,
	--ai-page-results-bg: $gray-light-03,
	--ai-page-results-alt-bg: $gray-light-04,
	--ai-page-about-bg: $gray-light-03,

	--alert-success-bg: $green,
	--alert-error-bg: $red,
	--alert-c: $white,

	--browse-tracks-bg: $gray-light-04,
	--browse-tracks-filter-c: $gray-medium-03,
	--browse-tracks-filter-active-c: $uppbeat,
	--browse-tracks-page-title-c: $gray-dark-03,
	--browse-tracks-title-c: $gray-dark-01,
	--browse-tracks-subtitle-c: $gray-medium-03,
	--browse-tracks-play-btn-fill: $gray-dark-03,
	--browse-tracks-duration-c: $gray-medium-03,
	--browse-tracks-selected-bg: $white,

	--button-primary-bg: $white,
	--button-primary-c: $gray-dark-02,
	--button-primary-hover-bg: $gray-light-04,
	--button-primary-br: $gray-light-03,
	--button-secondary-bg: $gray-light-04,
	--button-secondary-c: $black,
	--button-secondary-hover-bg: $gray-light-03,
	--button-secondary-br: $gray-light-01,
	--button-contrast-bg: $gray-dark-02,
	--button-contrast-c: $white,
	--button-contrast-hover-bg: $black,
	--button-contrast-br: $black,
	--button-transparent-bg: $transparent,
	--button-transparent-c: $gray-dark-02,
	--button-transparent-hover-bg: rgba($black, 0.05),
	--button-transparent-br: $gray-light-03,
	--button-success-bg: $green,
	--button-success-c: $white,
	--button-success-hover-bg: $green-dark,
	--button-success-br: $green-dark,
	--button-free-bg: $free,
	--button-free-c: $white,
	--button-free-hover-bg: $free-dark,
	--button-free-br: $free-dark,
	--button-ai-bg: $ai,
	--button-ai-c: $black,
	--button-ai-hover-bg: $ai-dark,
	--button-ai-br: $ai-dark,
	--button-uppbeat-bg: $uppbeat,
	--button-uppbeat-c: $white,
	--button-uppbeat-hover-bg: $uppbeat-dark,
	--button-uppbeat-br: $uppbeat-dark,
	--button-uppbeat-light-bg: $uppbeat-light,
	--button-uppbeat-light-c: $uppbeat-dark,
	--button-uppbeat-light-hover-bg: $uppbeat-medium,
	--button-uppbeat-light-br: $uppbeat-medium,
	--button-error-bg: $red,
	--button-error-c: $white,
	--button-error-hover-bg: $red-dark,
	--button-error-br: $red-dark,
	--button-business-bg: $business,
	--button-business-c: $black,
	--button-business-hover-bg: $business-dark,
	--button-business-br: $business-dark,
  --button-white-bg: $white,
  --button-white-c: $gray-dark-02,
  --button-white-hover-bg: $gray-light-04,
  --button-white-br: $gray-light-03,

	--button-dropdown-br: $gray-light-03,
	--button-header-bg: rgba($white, 0.3),
	--button-header-hover-bg: rgba($white, 0.1),
	--button-header-c: $white,
	--button-header-alt-hover-bg: rgba($black, 0.2),

	--card-bg: $white,
	--card-primary-c: $gray-dark-02,
	--card-secondary-c: $gray-medium-01,
	--card-tertiary-c: $gray-medium-02,
	--card-shadow: rgba($black, 0.2),
	--card-header-c: $white,
	--card-review-c: $uppbeat,
	--card-premium-header-bg: $uppbeat,
	--card-premium-br: $uppbeat,
	--card-premium-c: $uppbeat,
	--card-free-c: $free,
	--card-free-button-bg: $free,
	--card-free-br: $gray-light-03,
	--card-country-code-bg: $gray-light-01,
	--card-country-code-c: $black,
	--card-icon-fill: $green,

	--carousel-primary-bg: $gray-light-04,
	--carousel-secondary-bg: $white,
	--carousel-arrow-bg: rgba($white, 0.8),
	--carousel-arrow-hover-bg: $white,
	--carousel-arrow-triangle-bg: $black,
	--carousel-dots-bg: $black,
	--carousel-loading-bg: $gray-light-02,
	--carousel-image-filter: 1,
	--carousel-active-bg: rgba($black, 0.25),

	--checkbox-fill: $white,
	--checkbox-bg: $white,
	--checkbox-br: $gray-medium-03,
	--checkbox-active-bg: $uppbeat,
	--checkbox-active-br: $uppbeat,
	--checkbox-error-br: $red,
	--checkbox-error-shadow: $red-light,

	--contact-form-bg: $gray-dark-03,
	--contact-form-c: $white,
	--contact-form-success-c: $green,
	--contact-form-error-c: $red,

	--danger-text-color: $red,

	--discount-chip-bg: $green,
	--discount-chip-c: $white,

	--download-allowance-bg: $gray-light-03,
	--download-allowance-active-bg: $white,
	--download-allowance-br: $gray-light-02,
	--download-allowance-token-active-bg: $free,
	--download-allowance-token-inactive-bg: $gray-light-02,

	--explicit-icon-bg: $explicit,
	--explicit-icon-c: $white,

	--filter-default-color: #41444c,
	--filter-count-color: #64666a,
	--filter-style-color: #41444c,
	--filter-mood-color: #41444c,
	--filter-energy-color: #41444c,
	--filter-vocal-color: #41444c,
	--filter-duration-color: #41444c,
	--filter-keyword-color: #41444c,
	--filter-clearall-color: #25272B,

	--filter-category-c: $gray-medium-03,
	--filter-category-hover-c: $gray-medium-02,
	--filter-category-active-c: $gray-dark-04,
	--filter-category-icon-fill: $gray-medium-03,
	--filter-category-icon-hover-fill: $gray-medium-02,
	--filter-category-icon-focus-fill: $gray-dark-04,
	--filter-category-icon-active-fill: $uppbeat,
	--filter-category-br: $gray-light-02,

	--filter-option-bg: $gray-light-02,
	--filter-option-c: $gray-medium-02,
	--filter-option-hover-bg: $gray-light-01,
	--filter-option-hover-c: $gray-medium-01,
	--filter-option-active-bg: $uppbeat,
	--filter-option-active-c: $white,
	--filter-option-active-hover-bg: darken(desaturate($uppbeat, 20%), 10%),

	--footer-player-bg: $white,
	--footer-title-c: $gray-dark-02,
	--footer-link-c: $uppbeat,
	--footer-link-alt-c: $uppbeat,
	--footer-uppbeat-logo-c: $uppbeat,
	--footer-uppbeat-logo-alt-c: #4B4B4B,
	--footer-uppbeat-logo-third-c: $business,

	--footer-musicvine-logo-c: $black,

	--h1-c: $gray-dark-01,
	--h2-c: $gray-dark-01,
	--h3-c: $gray-dark-02,
	--h4-c: $gray-medium-01,

	--homepage-banner-c: $white,
	--homepage-banner-button-bg: $white,
	--homepage-banner-button-hover-bg: $gray-light-03,
	--homepage-banner-text-shadow: rgba($black, 0.4),
	--homepage-banner-button-shadow: rgba($black, 0.3),

	--homepage-banner-01-bg: $uppbeat,
	--homepage-banner-01-button-c: $uppbeat,
	--homepage-banner-02-bg: $business,
	--homepage-banner-02-button-c: $business,
	--homepage-banner-05-bg: $uppbeat,
	--homepage-banner-05-button-c: $uppbeat,
	--homepage-banner-06-bg: $orange-soda,
	--homepage-banner-06-button-c: $orange-soda,
	--homepage-banner-07-bg: $medium-orchid,
	--homepage-banner-07-button-c: $medium-orchid,

	--hr: $gray-light-02,

	--input-bg: $gray-light-03,
	--input-c: $gray-dark-01,
	--input-hover-bg: $gray-light-02,
	--input-disabled-bg: $gray-light-01,
	--input-disabled-c: $gray-medium-01,
	--input-placeholder-c: $gray-medium-01,
	--input-br: $transparent,
	--input-active-br: $uppbeat,
	--input-label-c: $gray-medium-01,
	--input-warning-c: $red,
	--input-edit-c: $uppbeat,
	--input-required-c: $uppbeat-dark,

	--jumbotron-bg: $gray-light-03,
	--jumbotron-alt-bg: $white,
	--jumbotron-c: $white,
	--jumbotron-alt-c: $gray-medium-03,
	--jumbotron-alt-bold-c: $gray-medium-01,
	--jumbotron-br: $gray-light-01,
	--jumbotron-active: $green,

	--link-c: $uppbeat,

	--main-bg: $white,
	--main-primary-c: $black,
	--main-secondary-c: $gray-medium-01,
	--main-brand-c: $uppbeat,
	--main-free-c: $free,
	--main-link-c: $uppbeat,
	--main-br: $gray-light-02,

	--modal-bg: $white,
	--modal-tab-bg: $gray-dark-02,
	--modal-tab-hover-bg: $gray-dark-03,
	--modal-tab-c: $white,
	--modal-single-tab-bg: $gray-light-03,
	--modal-overlay-bg: rgba($black, 0.6),
	--modal-primary-c: $gray-dark-02,
	--modal-progress-bar-container-bg: $gray-light-02,
	--modal-progress-bar-bg: $uppbeat,
	--modal-title-faded-c: $gray-medium-03,
	--modal-title-faded-success-c: $green,
	--modal-limited-offer-c: $uppbeat,
	--modal-toggle-option-bg: $gray-light-04,
	--modal-toggle-option-br: $gray-light-01,
	--modal-toggle-option-hover-bg: $gray-light-03,
	--modal-toggle-option-active-bg: $white,
	--modal-toggle-option-active-br: $uppbeat,

	--music-loading-message-c: $gray-dark-04,
	--music-loading-balls-bg: $uppbeat,

	--p-c: $gray-medium-01,

	--page-gradient-section-top-bg: $gray-light-03,
	--page-gradient-section-bottom-bg: $white,

	--page-section-bg: $gray-light-04,

	--percentage-indicator-outer-bg: $gray-light-02,
	--percentage-indicator-inner-bg: $white,
	--percentage-indicator-red: $red,
	--percentage-indicator-orange: $orange-soda,
	--percentage-indicator-yellow: $business,
	--percentage-indicator-green: $green,
	--percentage-indicator-blue: $ai,

	--pill-filled-bg: $white,
	--pill-c: $gray-medium-01,

	--player-icon-c: $gray-dark-02,
	--player-icon-hover-c: $gray-dark-01,

	--playlist-notification-bg: $uppbeat,
	--playlist-notification-c: $white,
	--playlist-notification-br: $white,

	--popup-bg: $white,
	--popup-content-bg: $gray-light-03,
	--popup-content-br: $gray-light-02,
	--popup-content-c: $gray-medium-02,
	--popup-outer-br: $gray-light-03,

	--premium-features-bg: $white,
	--premium-features-icon-fill: $uppbeat,

	--premium-label-primary-bg: rgba($uppbeat, 0.15),
	--premium-label-secondary-bg: $transparent,
	--premium-label-c: $gray-dark-04,

	--pricing-card-bg: $gray-light-03,
	--pricing-card-premium-bg: $gray-light-04,
	--pricing-card-premium-br: $white,
	--pricing-card-c: $gray-medium-01,
	--pricing-card-heading-c: $gray-dark-01,
	--pricing-card-mobile-tag-c: $gray-medium-01,
	--pricing-card-tag-c: $white,
	--pricing-card-tag-bg: $uppbeat,
	--pricing-card-br: $gray-light-02,
	--pricing-card-tick-fill: $green,
	--pricing-card-cross-fill: $gray-medium-01,

	--pricing-page-section-bg: $white,
	--pricing-page-section-alt-bg: $gray-light-03,

	--review-card-bg: $gray-light-03,
	--review-card-c: $gray-medium-01,
	--review-card-heading-c: $uppbeat,
	--review-card-name-c: $gray-dark-01,
	--review-card-icon-bg: $green,

	--search-bg: $gray-light-03,
	--search-c: $gray-dark-01,
	--search-hover-bg: $gray-light-02,
	--search-placeholder-c: $gray-medium-02,
	--search-br: $transparent,
	--search-active-br: $uppbeat,
	--search-btn-bg: $gray-medium-01,
	--search-dropdown-item-bg: $white,
	--search-dropdown-br: $gray-light-02,
	--search-dropdown-primary-c: $gray-dark-03,
	--search-dropdown-primary-hover-c: $gray-dark-02,
	--search-dropdown-secondary-c: $gray-medium-01,
	--search-dropdown-secondary-hover-c: $gray-medium-01,
	--search-input-btn-override-bg: $gray-dark-04,

	--select-dropdown-c: $gray-dark-03,
	--select-dropdown-selected-bg: $gray-light-03,
	--select-dropdown-selected-c: $gray-dark-03,
	--select-dropdown-hover-bg: $gray-light-02,
	--select-dropdown-br: $gray-light-01,

  --radio-hover-bg: rgba($gray-light-01, 0.15),

	--shadow-dark: rgba($black, 0.2),
	--shadow-medium: rgba($black, 0.15),
	--shadow-light: rgba($black, 0.1),
	--shadow-brand: rgba($uppbeat, 0.5),

	--sidebar-nav-bg: $gray-light-04,
	--sidebar-nav-primary-c: $gray-medium-01,
	--sidebar-nav-secondary-c: $gray-dark-01,
	--sidebar-nav-bottom-br: $gray-light-02,
	--sidebar-nav-active-bg: $white,
	--sidebar-nav-active-c: $uppbeat,
	--sidebar-nav-hover-bg: rgba($black, 0.02),
	--sidebar-nav-hover-c: $gray-dark-01,

	--sidebar-nav-btn-bg: rgba($black, 0.35),
	--sidebar-nav-btn-icon-bg: $white,

	--sidebar-mobile-bg: $uppbeat,
	--sidebar-mobile-header-bg: darken(desaturate($uppbeat, 20%), 10%),
	--sidebar-mobile-primary-c: rgba($white, 0.8),
	--sidebar-mobile-secondary-c: $white,
	--sidebar-mobile-bottom-br: rgba($white, 0.2),
	--sidebar-mobile-active-bg: rgba($white, 0.3),
	--sidebar-mobile-active-c: $white,
	--sidebar-mobile-hover-bg: rgba($white, 0.11),
	--sidebar-mobile-search-bg: rgba($black, 0.2),
	--sidebar-mobile-search-br: $transparent,
	--sidebar-mobile-search-c: $white,
	--sidebar-mobile-search-icon-bg: rgba($black, 0.2),
	--sidebar-mobile-search-btn-bg: rgba($black, 0.2),

	--skeleton-bg: $gray-light-03,
	--skeleton-shine-bg: rgba($gray-light-04, 0.75),
	--skeleton-dark-bg: $gray-light-02,
	--skeleton-dark-shine-bg: rgba($gray-light-03, 0.5),
	--skeleton-darker-bg: $gray-light-02,
	--skeleton-darker-shine-bg: rgba($gray-light-03, 0.5),

	--slider-track-bg: $gray-light-03,
	--slider-active-track-bg: $uppbeat,
	--slider-thumb-bg: $uppbeat,
	--slider-shadow: rgba($uppbeat, 0.2),
	--slider-volume-bg: $white,

	--split-view-bg: $gray-dark-02,
	--split-view-gradient-bg: rgba($gray-dark-02, 0),
	--split-view-br: $gray-dark-03,

	--table-subheading-bg: $gray-light-03,
	--table-row-bg: $gray-light-04,
	--table-row-left-bg: rgba($gray-light-03, 0.5),
	--table-c: $gray-dark-01,
	--table-alt-c: $gray-dark-03,

	--text-block-bg: rgba($gray-light-03, 0.5),

	--toggle-uppbeat-bg: $uppbeat,
	--toggle-uppbeat-indicator-bg: $white,
	--toggle-free-bg: $free,
	--toggle-free-indicator-bg: $white,
	--toggle-primary-bg: $gray-medium-03,
	--toggle-primary-indicator-bg: $white,
	--toggle-premium-bg: $uppbeat,
	--toggle-success-bg: $green,
	--toggle-success-large-bg: $green,
	--toggle-success-indicator-bg: $white,
	--toggle-success-indicator-active-bg: $green,
	--toggle-theme-bg: $white,
	--toggle-theme-indicator-bg: $business,
	--toggle-theme-c: $gray-medium-02,
	--toggle-theme-active-c: $gray-dark-02,
	--toggle-theme-path-bg: $gray-medium-04,
	--toggle-shadow: rgba($black, 0.4),
	--toggle-large-bg: $gray-light-03,
	--toggle-large-active-c: $white,
	--toggle-large-inactive-c: $black,

	--tooltip-bg: $gray-dark-02,
	--tooltip-c: $white,

	--topbar-br: $gray-light-02,
	--topbar-sidebar-open-bg: darken(desaturate($uppbeat, 20%), 10%),
	--topbar-sidebar-open-br: $transparent,
	--topbar-bg: $white,
	--topbar-gradient-bg: $transparent,

	--track-player-hover-right-bg: $gray-light-04,
	--track-player-hover-left-bg: rgba($gray-light-04, 0.4),

	--track-player-active-bg: $gray-light-04,
	--track-br: $gray-light-02,
	--track-premium-star-bg: $uppbeat,
	--track-artist-c: $gray-medium-03,
	--track-modal-bottom-bg: $uppbeat,

	--trending-c: $trending-01,

	--whitelist-bg: $gray-light-03,
	--whitelist-hover-bg: $gray-light-02,
	--whitelist-search-bg: $white,
	--whitelisting-input-override-bg: $white,

	// Created after theming change
	--ub-border-light: $gray-light-03,
	--ub-border-dark: $gray-light-01,

	--ub-sidebar-bg: $white,
	--ub-sidebar-link-bg: $white,
	--ub-sidebar-link-hover-bg: $gray-light-04,
	--ub-sidebar-link-active-bg: $uppbeat-light,
	--ub-sidebar-link-c: $gray-dark-02,
	--ub-sidebar-link-active-c: $uppbeat-dark,

	--ub-pink-bg: $red-light,
);

$darkTheme : (
	--account-icon-bg-fill: $gray-dark-03,
	--account-icon-bg-hover-fill: $gray-dark-04,
	--account-icon-man-fill: rgba($white, 0.35),
	--account-icon-mobile-bg-fill: $gray-dark-03,
	--account-icon-mobile-bg-hover-fill: $gray-dark-04,
	--account-icon-mobile-man-fill: rgba($white, 0.35),
	--account-dropdown-bg: $gray-dark-03,
	--account-dropdown-header-c: $white,
	--account-dropdown-c: $gray-medium-04,
	--account-premium-progress-bar-bg: rgba($business-light, 0.5),
	--account-plan-primary-bg: $gray-dark-02,
	--account-plan-secondary-bg: $free-light,
	--account-plan-primary-c: $free,

	--admin-bar-bg: $black,
	--admin-bar-c: $gray-light-02,
	--admin-bar-relevancy-bg: $green,
	--admin-bar-relevancy-br: $green,

	--accordion-bg: $gray-dark-02,
	--accordion-active-bg: $gray-dark-03,
	--accordion-primary-c: $gray-light-03,
	--accordion-secondary-c: $gray-medium-04,
	--accordion-icon-fill: $gray-medium-04,
	--accordion-link-c: $uppbeat,
	--accordion-tabs-bg: $gray-dark-02,
	--accordion-tabs-active-bg: $uppbeat,
	--accordion-tabs-hover-bg: $gray-dark-04,
	--accordion-tabs-c: $white,
	--accordion-link-underline-c: $uppbeat,
	--accordion-br: $gray-dark-03,
	--accordion-tabs-desktop-bg: $transparent,
	--accordion-tabs-desktop-active-bg: $gray-dark-02,
	--accordion-tabs-active-c: $white,
	--accordion-bullet-c: $uppbeat,

	--ai-page-bg: $ai-bg-01,
	--ai-page-pattern-bg: $ai-bg-02,
	--ai-page-pattern-bg-alt: $ai,
	--ai-page-logo-text-c: $ai,
	--ai-page-c: $white,
	--ai-page-alt-c: rgba($white, 0.7),
	--ai-page-highlight-c: $ai,
	--ai-page-search-option-bg: $gray-dark-03,
	--ai-page-search-option-br: $ai,
	--ai-page-input-bg: $white,
	--ai-page-input-br: $gray-light-03,
	--ai-page-input-active-br: $ai,
	--ai-page-results-bg: $gray-dark-03,
	--ai-page-results-alt-bg: $gray-dark-02,
	--ai-page-about-bg: $gray-dark-02,

	--alert-success-bg: $green,
	--alert-error-bg: $red,
	--alert-c: $white,

	--browse-tracks-bg: $gray-dark-02,
	--browse-tracks-filter-c: $gray-medium-02,
	--browse-tracks-filter-active-c: $uppbeat,
	--browse-tracks-page-title-c: $gray-medium-03,
	--browse-tracks-title-c: $gray-light-01,
	--browse-tracks-subtitle-c: $gray-medium-03,
	--browse-tracks-play-btn-fill: $gray-light-01,
	--browse-tracks-duration-c: $gray-medium-03,
	--browse-tracks-selected-bg: $gray-dark-01,

	--button-primary-bg: $gray-dark-01,
	--button-primary-c: $gray-light-01,
	--button-primary-hover-bg: $gray-dark-02,
	--button-primary-br: $gray-dark-03,
	--button-secondary-bg: $gray-dark-03,
	--button-secondary-c: $gray-light-03,
	--button-secondary-hover-bg: $gray-dark-02,
	--button-secondary-br: $gray-dark-04,
	--button-contrast-bg: $white,
	--button-contrast-c: $black,
	--button-contrast-hover-bg: $gray-light-04,
	--button-contrast-br: $gray-light-03,
	--button-transparent-bg: $transparent,
	--button-transparent-c: $gray-light-01,
	--button-transparent-hover-bg: rgba($white, 0.05),
	--button-transparent-br: $gray-medium-01,
	--button-success-bg: $green,
	--button-success-c: $white,
	--button-success-hover-bg: $green-dark,
	--button-success-br: $green-dark,
	--button-free-bg: $free,
	--button-free-c: $black,
	--button-free-hover-bg: $free-dark,
	--button-free-br: $free-dark,
	--button-ai-bg: $ai,
	--button-ai-c: $black,
	--button-ai-hover-bg: $ai-dark,
	--button-ai-br: $ai-dark,
	--button-uppbeat-bg: $uppbeat,
	--button-uppbeat-c: $white,
	--button-uppbeat-hover-bg: $uppbeat-dark,
	--button-uppbeat-br: $uppbeat-dark,
	--button-uppbeat-light-bg: $gray-dark-04,
	--button-uppbeat-light-c: $white,
	--button-uppbeat-light-hover-bg: $gray-dark-03,
	--button-uppbeat-light-br: $gray-dark-03,
	--button-error-bg: $red,
	--button-error-c: $white,
	--button-error-hover-bg: $red-dark,
	--button-error-br: $red-dark,
	--button-business-bg: $business,
	--button-business-c: $black,
	--button-business-hover-bg: $business-dark,
	--button-business-br: $business-dark,
  --button-white-bg: $white,
  --button-white-c: $gray-dark-02,
  --button-white-hover-bg: $gray-light-04,
  --button-white-br: $gray-light-03,

	--button-dropdown-br: $gray-dark-02,
	--button-header-bg: $gray-dark-03,
	--button-header-hover-bg: $gray-dark-04,
	--button-header-c: $white,
	--button-header-alt-hover-bg: darken(desaturate($uppbeat, 20%), 10%),

	--card-bg: $gray-dark-02,
	--card-primary-c: $gray-light-03,
	--card-secondary-c: $gray-light-01,
	--card-tertiary-c: $gray-medium-02,
	--card-shadow: rgba($black, 0.2),
	--card-header-c: $white,
	--card-review-c: $uppbeat,
	--card-premium-header-bg: $gray-dark-03,
	--card-premium-br: $gray-dark-03,
	--card-premium-c: $uppbeat,
	--card-free-c: $free,
	--card-free-button-bg: $free,
	--card-free-br: $gray-dark-02,
	--card-country-code-bg: $gray-light-01,
	--card-country-code-c: $black,
	--card-icon-fill: $green,

	--carousel-primary-bg: $gray-dark-02,
	--carousel-secondary-bg: $gray-dark-01,
	--carousel-arrow-bg: rgba($gray-dark-01, 0.8),
	--carousel-arrow-hover-bg: $gray-dark-01,
	--carousel-arrow-triangle-bg: $white,
	--carousel-dots-bg: $white,
	--carousel-image-filter: 0.92,
	--carousel-loading-bg: $gray-dark-03,
	--carousel-active-bg: rgba($black, 0.25),

	--checkbox-fill: $white,
	--checkbox-bg: $gray-dark-03,
	--checkbox-br: $gray-medium-01,
	--checkbox-active-bg: $uppbeat,
	--checkbox-active-br: $uppbeat,
	--checkbox-error-br: $red,
	--checkbox-error-shadow: $red-light,
	--checkbox-success: $green,

	--danger-text-color: $red,

	--discount-chip-bg: $green,
	--discount-chip-c: $black,

	--download-allowance-bg: $gray-dark-03,
	--download-allowance-active-bg: $gray-dark-03,
	--download-allowance-br: $gray-dark-02,
	--download-allowance-token-active-bg: $free,
	--download-allowance-token-inactive-bg: $gray-dark-04,

	--explicit-icon-bg: $explicit,
	--explicit-icon-c: $white,

	--filter-category-c: $gray-medium-03,
	--filter-category-hover-c: $gray-light-01,
	--filter-category-active-c: $gray-light-04,
	--filter-category-icon-fill: $gray-medium-03,
	--filter-category-icon-hover-fill: $gray-light-01,
	--filter-category-icon-focus-fill: $gray-light-04,
	--filter-category-icon-active-fill: $uppbeat,
	--filter-category-br: $gray-dark-03,

	--filter-option-bg: $gray-dark-02,
	--filter-option-c: $gray-light-03,
	--filter-option-hover-bg: $gray-dark-03,
	--filter-option-hover-c: $gray-light-02,
	--filter-option-active-bg: $uppbeat,
	--filter-option-active-c: $white,
	--filter-option-active-hover-bg: darken(desaturate($uppbeat, 20%), 10%),

	--footer-player-bg: $gray-dark-02,
	--footer-title-c: $gray-light-02,
	--footer-link-c: $gray-medium-03,
	--footer-link-alt-c: $uppbeat,
	--footer-uppbeat-logo-c: $uppbeat,
	--footer-uppbeat-logo-alt-c: $white,
	--footer-uppbeat-logo-third-c: $business,
	--footer-musicvine-logo-c: $white,

	--h1-c: $white,
	--h2-c: $white,
	--h3-c: $white,
	--h4-c: $gray-light-03,

	--homepage-banner-c: $white,
	--homepage-banner-button-bg: $white,
	--homepage-banner-button-hover-bg: $gray-light-03,
	--homepage-banner-text-shadow: rgba($black, 0.4),
	--homepage-banner-button-shadow: rgba($black, 0.3),

	--homepage-banner-01-bg: $uppbeat,
	--homepage-banner-01-button-c: $uppbeat,
	--homepage-banner-02-bg: $business,
	--homepage-banner-02-button-c: $business,
	--homepage-banner-05-bg: $uppbeat,
	--homepage-banner-05-button-c: $uppbeat,
	--homepage-banner-06-bg: $orange-soda,
	--homepage-banner-06-button-c: $orange-soda,
	--homepage-banner-07-bg: $medium-orchid,
	--homepage-banner-07-button-c: $medium-orchid,

	--hr: $gray-dark-04,

	--input-bg: $gray-dark-03,
	--input-c: $white,
	--input-hover-bg: $gray-dark-04,
	--input-disabled-bg: $gray-dark-02,
	--input-disabled-c: $gray-medium-02,
	--input-placeholder-c: $gray-medium-04,
	--input-br: $transparent,
	--input-active-br: $uppbeat,
	--input-label-c: $gray-light-01,
	--input-warning-c: $red,
	--input-edit-c: $uppbeat,
	--input-required-c: $uppbeat,

	--jumbotron-bg: $gray-dark-03,
	--jumbotron-alt-bg: $gray-dark-01,
	--jumbotron-c: $white,
	--jumbotron-alt-c: $gray-medium-02,
	--jumbotron-alt-bold-c: $gray-light-02,
	--jumbotron-br: $gray-medium-02,
	--jumbotron-active: $green,

	--link-c: $uppbeat,

	--main-bg: $gray-dark-01,
	--main-primary-c: $white,
	--main-secondary-c: $gray-medium-03,
	--main-brand-c: $uppbeat,
	--main-link-c: $uppbeat,
	--main-free-c: $free,
	--main-br: $gray-dark-04,

	--modal-bg: $gray-dark-02,
	--modal-tab-bg: lighten($gray-dark-03, 5%),
	--modal-tab-hover-bg: lighten($gray-dark-03, 10%),
	--modal-tab-c: $white,
	--modal-single-tab-bg: $gray-dark-03,
	--modal-overlay-bg: rgba($black, 0.6),
	--modal-primary-c: $gray-light-03,
	--modal-progress-bar-container-bg: $gray-dark-03,
	--modal-progress-bar-bg: $uppbeat,
	--modal-title-faded-c: $gray-medium-01,
	--modal-title-faded-success-c: $green,
	--modal-limited-offer-c: $uppbeat,
	--modal-toggle-option-bg: $gray-dark-01,
	--modal-toggle-option-hover-bg: $black,
	--modal-toggle-option-active-bg: $gray-dark-03,

    --music-loading-message-c: $gray-medium-03,
	--music-loading-balls-bg: $uppbeat,

	--p-c: $gray-medium-03,

	--page-gradient-section-top-bg: $gray-dark-02,
	--page-gradient-section-bottom-bg: $gray-dark-01,

	--page-section-bg: $gray-dark-02,

	--percentage-indicator-outer-bg: $gray-dark-02,
	--percentage-indicator-inner-bg: $gray-dark-04,
	--percentage-indicator-red: $red,
	--percentage-indicator-orange: $orange-soda,
	--percentage-indicator-yellow: $business,
	--percentage-indicator-green: $green,
	--percentage-indicator-blue: $ai,

	--pill-filled-bg: $gray-dark-03,
	--pill-c: $gray-light-01,

	--player-icon-c: $white,
	--player-icon-hover-c: $gray-light-02,

	--playlist-notification-bg: $uppbeat,
	--playlist-notification-c: $white,
	--playlist-notification-br: $white,

	--popup-bg: $gray-dark-03,
	--popup-content-bg: $gray-dark-02,
	--popup-content-br: $gray-dark-01,
	--popup-content-c: $gray-medium-02,
	--popup-outer-br: $gray-dark-02,

	--premium-features-bg: $gray-dark-03,
	--premium-features-icon-fill: $white,

	--premium-label-primary-bg: rgba($uppbeat, 0.3),
	--premium-label-secondary-bg: $transparent,
	--premium-label-c: $white,

	--pricing-card-bg: $gray-dark-02,
	--pricing-card-premium-bg: $gray-dark-03,
	--pricing-card-premium-br: $gray-dark-01,
	--pricing-card-c: $gray-medium-03,
	--pricing-card-heading-c: $white,
	--pricing-card-br: $black,
	--pricing-card-mobile-tag-c: $white,
	--pricing-card-tag-c: $white,
	--pricing-card-tag-bg: $uppbeat,
	--pricing-card-tick-fill: $green,
	--pricing-card-cross-fill: $gray-medium-03,

	--pricing-page-section-bg: $gray-dark-03,
	--pricing-page-section-alt-bg: $gray-dark-03,

	--review-card-bg: $gray-dark-02,
	--review-card-c: $gray-medium-03,
	--review-card-heading-c: $white,
	--review-card-name-c: $gray-light-02,
	--review-card-icon-bg: $green,

	--search-bg: $gray-dark-03,
	--search-c: $white,
	--search-hover-bg:$gray-dark-04,
	--search-placeholder-c: $gray-medium-04,
	--search-br: $transparent,
	--search-active-br: $uppbeat,
	--search-btn-bg: $gray-dark-01,
	--search-dropdown-item-bg: rgba($white, 0.05),
	--search-dropdown-br: $gray-dark-02,
	--search-dropdown-primary-c: $gray-light-01,
	--search-dropdown-primary-hover-c: $gray-light-03,
	--search-dropdown-secondary-c: $gray-medium-02,
	--search-dropdown-secondary-hover-c: $gray-medium-04,
	--search-input-btn-override-bg: $gray-dark-02,

	--select-dropdown-c: $gray-light-02,
	--select-dropdown-selected-bg: $gray-medium-01,
	--select-dropdown-selected-c: $white,
	--select-dropdown-hover-bg: $gray-dark-04,
	--select-dropdown-br: $gray-medium-02,

  --radio-hover-bg: rgba($gray-medium-02, 0.15),

	--shadow-dark: rgba($black, 0.4),
	--shadow-medium: rgba($black, 0.3),
	--shadow-light: rgba($black, 0.2),
	--shadow-brand: rgba($uppbeat, 0.5),

	--sidebar-nav-bg: $gray-dark-02,
	--sidebar-nav-primary-c: $gray-medium-04,
	--sidebar-nav-secondary-c: $white,
	--sidebar-nav-bottom-br: $gray-dark-03,
	--sidebar-nav-active-bg: $gray-dark-03,
	--sidebar-nav-active-c: $uppbeat,
	--sidebar-nav-hover-bg: rgba($white, 0.04),
	--sidebar-nav-hover-c: $white,

	--sidebar-nav-btn-bg: $gray-dark-03,
	--sidebar-nav-btn-icon-bg: $white,

	--sidebar-mobile-bg: $gray-dark-02,
	--sidebar-mobile-header-bg: $gray-dark-01,
	--sidebar-mobile-primary-c: $gray-medium-02,
	--sidebar-mobile-secondary-c: $white,
	--sidebar-mobile-bottom-br: $gray-dark-03,
	--sidebar-mobile-active-bg: $gray-dark-03,
	--sidebar-mobile-active-c: $uppbeat,
	--sidebar-mobile-hover-bg: rgba($white, 0.04),
	--sidebar-mobile-search-bg: $gray-dark-03,
	--sidebar-mobile-search-br: $transparent,
	--sidebar-mobile-search-c: $white,
	--sidebar-mobile-search-icon-bg: $black,
	--sidebar-mobile-search-btn-bg: $gray-dark-01,

	--skeleton-bg: $gray-dark-02,
	--skeleton-shine-bg: rgba($gray-dark-03, 0.25),
	--skeleton-dark-bg: $gray-dark-03,
	--skeleton-dark-shine-bg: rgba($gray-dark-04, 0.15),
	--skeleton-darker-bg: $gray-dark-04,
	--skeleton-darker-shine-bg: rgba($gray-medium-01, 0.3),

	--slider-track-bg: $gray-dark-04,
	--slider-active-track-bg: $uppbeat,
	--slider-thumb-bg: $uppbeat,
	--slider-shadow: rgba($uppbeat, 0.2),
	--slider-volume-bg: $gray-dark-03,

	--split-view-bg: $gray-dark-02,
	--split-view-gradient-bg: rgba($gray-dark-02, 0),
	--split-view-br: $gray-dark-03,

	--table-subheading-bg: $gray-dark-02,
	--table-row-bg: $gray-dark-03,
	--table-row-left-bg: rgba($gray-dark-02, 0.5),
	--table-c: $white,
	--table-alt-c: $gray-light-03,

	--text-block-bg: $gray-dark-02,

	--toggle-uppbeat-indicator-bg: $white,
	--toggle-free-bg: $free,
	--toggle-free-indicator-bg: $white,
	--toggle-success-bg: $white,
	--toggle-success-large-bg: $green,
	--toggle-success-indicator-bg: $gray-medium-02,
	--toggle-success-indicator-active-bg: $green,
	--toggle-primary-bg: $gray-medium-01,
	--toggle-primary-indicator-bg: $gray-dark-01,
	--toggle-premium-bg: $uppbeat,
	--toggle-theme-bg: $gray-dark-03,
	--toggle-theme-indicator-bg: $business,
	--toggle-theme-c: $gray-medium-01,
	--toggle-theme-active-c: $gray-light-03,
	--toggle-theme-path-bg: $white,
	--toggle-shadow: rgba($black, 0.4),
	--toggle-large-bg: $gray-dark-03,
	--toggle-large-active-c: $white,
	--toggle-large-inactive-c: $white,

	--tooltip-bg: $gray-dark-04,
	--tooltip-c: $white,

	--topbar-br: $gray-dark-03,
	--topbar-sidebar-open-bg: $gray-dark-01,
	--topbar-sidebar-open-br: $transparent,
	--topbar-bg: $gray-dark-01,
	--topbar-gradient-bg: $transparent,

	--track-player-hover-right-bg: $gray-dark-02,
	--track-player-hover-left-bg: rgba($gray-dark-02, 0.4),
	--track-player-active-bg: $gray-dark-02,
	--track-br: $gray-dark-01,
	--track-premium-star-bg: $uppbeat,
	--track-artist-c: $gray-medium-01,
	--track-modal-bottom-bg: $gray-dark-03,

	--trending-c: $trending-02,

	--whitelist-bg: $gray-dark-03,
	--whitelist-hover-bg: $gray-dark-04,
	--whitelist-search-bg: $gray-dark-02,
	--whitelisting-input-override-bg: $gray-dark-02,

	// Created after theming change
	--ub-border-light: $gray-dark-03,
	--ub-border-dark: $gray-medium-01,

	--ub-sidebar-bg: $gray-dark-01,
	--ub-sidebar-link-bg: $gray-dark-01,
	--ub-sidebar-link-hover-bg: $gray-dark-02,
	--ub-sidebar-link-active-bg: $gray-dark-03,
	--ub-sidebar-link-c: $gray-light-02,
	--ub-sidebar-link-active-c: $white,

	--ub-pink-bg: $black,
);

// Transfer variables from the map to the :root element
@if($css4) {
	:root {
		--uppbeat: #{$uppbeat};
		--red-light: #{$red-light};
		--red: #{$red};
		--red-dark: #{$red-dark};
	}
	.theme-light, :root {
		@each $variable, $value in $lightTheme {
			#{$variable}: $value;
		}
	}
}
.theme-dark {
	@each $variable, $value in $darkTheme {
		#{$variable}: $value;
	}
}

// Change value type to string
@function to-string($value) {
	@return inspect($value);
}

// Background colour
// Use in format - @include bg(--variable-name);
@mixin bg($variable) {
	background: unquote('var(' + $variable + ')');
}

// Background Darken
// Accepts 3 values:
//	1 - variable to darken
//	2 - opacity of black to darken colour (1 being darkest, 0 being lightest)
//	3 - OPTIONAL - any colour format used as a fallback for IE11
@mixin bgd($variable, $opacity, $ie: $variable) {
	background: unquote('var(' + $variable + ')');
	filter: brightness(1 - $opacity);
}

// Background Lighten
// Accepts 3 values:
//	1 - variable to lighten
//	2 - opacity of white to lighten colour (1 being lightest, 0 being darkest)
//	3 - OPTIONAL - any colour format used as a fallback for IE11
@mixin bgl($variable, $opacity, $ie: $variable) {
	background: unquote('var(' + $variable + ')');
	filter: brightness(1 + $opacity);
}

// Colour Darken
// Accepts 3 values:
//	1 - variable to darken
//	2 - opacity of black to darken colour (1 being darkest, 0 being lightest)
//	3 - OPTIONAL - any colour format used as a fallback for IE11
@mixin cd($variable, $opacity, $ie: $variable) {
	color: unquote('var(' + $variable + ')');
	filter: brightness(1 - $opacity);
}

// Colour Lighten
// Accepts 3 values:
//	1 - variable to lighten
//	2 - opacity of white to lighten colour (1 being lightest, 0 being darkest)
//	3 - OPTIONAL - any colour format used as a fallback for IE11
@mixin cl($variable, $opacity, $ie: $variable) {

	color: unquote('var(' + $variable + ')');
	filter: brightness(1 + $opacity);
}

// Text colour
// Use in format - @include c(--variable-name);
@mixin c($variable) {
	color: unquote('var(#{$variable})');
}

// SVG fill
// Use in format - @include f(--variable-name);
@mixin f($variable) {
	fill: unquote('var(#{$variable})');
}

// SVG Darken
// Accepts 3 values:
//	1 - variable to darken
//	2 - opacity of black to darken colour (1 being darkest, 0 being lightest)
//	3 - OPTIONAL - any colour format used as a fallback for IE11
@mixin fd($variable, $opacity, $ie: $variable) {
	fill: unquote('var(' + $variable + ')');
	filter: brightness(1 - $opacity);
}

// SVG Lighten
// Accepts 3 values:
//	1 - variable to lighten
//	2 - opacity of white to lighten colour (1 being lightest, 0 being darkest)
//	3 - OPTIONAL - any colour format used as a fallback for IE11
@mixin fl($variable, $opacity, $ie: $variable) {

	fill: unquote('var(' + $variable + ')');
	filter: brightness(1 + $opacity);
}

// Helper for IE
@mixin css4 {
	@content;
}

// Custom var function -- use for borders, box-shadows, gradients etc...
// Use in format:
//	@include css4 {
//		border: 1px solid cvar(--variable-name);
//	}
@function cvar($variable, $ie: $variable) {
	@if($css4==false) {
		@if (str-slice(to-string($ie), 1, 4) == 'var(') {
			$length: str-length($ie) - 1;
			$helper: str-slice($ie, 5, $length);
			@return map-get($lightTheme, $helper);
		} @else if (str-slice(to-string($ie), 1, 2) == '--') {
			@return map-get($lightTheme, $ie);
		} @else {
			@return $ie;
		}
	} @else {
		@return unquote('var(' + $variable + ')');
	}
}



// Filter colours
// ---------------------------------------------------------

$filter-green: #4bcfbf;
$filter-blue: #2c93ce;
$filter-gray: #e3e3e3;
$filter-dark: #505050;

$fav-icon: #e6556c;

$breadcrumb-background: $black;
$filter-breadcrumb-1-color: #2b2b2f;
$filter-breadcrumb-2-color: #35373d;

$filter-default-color: #41444c;
$filter-count-color: #64666a;
$filter-style-color: $filter-default-color;
$filter-mood-color: $filter-default-color;
$filter-energy-color: $filter-default-color;
$filter-vocal-color: $filter-default-color;
$filter-duration-color: $filter-default-color;
$filter-keyword-color: $filter-default-color;
$filter-clearall-color: #25272B;

$filter-text-color: #ccced4;

$styles-moods-grey: #2d2e32;

$slider-background: #43454b;
$slider-foreground: $uppbeat;

// Pagination
// ---------------------------------------------------------

$pagination-color-active: $uppbeat;

// Branding
// ---------------------------------------------------------

$brand-success: #67c1bf;


// Browse Page
// ---------------------------------------------------------


$styles-moods-bg: $styles-moods-grey;

$browse-background-color: $white;
$browse-tracklist-background-color: $gray-light-03;

$browse-track-playing-color: $white;
$browse-track-hover-color: $white;

$browse-track-button-color: #202020;
$browse-track-button-hover-color: #29343b;

$version-connecting-line-color: #18413b;

$header-sign-in-bg-color: #414148;

// Subscription
// ---------------------------------------------------------

$subscription-blue: #369fd1;
$subscription-orange: #ed5c5c;

// Footer
// ---------------------------------------------------------

$footer-icon-color: #919191;
$footer-icon-color-hover: $footer-icon-color;

// Licenses
// ---------------------------------------------------------

$scale-label-bg:    $gray-light-03;
$license-modal-bg:  #e4e4e4;
$license-blue:      #59bcdb;
$license-green:     #64d5c6;
$license-purple:    #d09dd7;
$license-orange:    #f1a877;
$license-red:       #e3907d;
$license-grey:      #a3a6a0;

// Layout
// ---------------------------------------------------------

$admin-bar-height: 20px;
$alert-height: 40px;
$footer-player-height: 64px;
$topbar-height: 76px;
