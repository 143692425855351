// Z-index
//
// Z-index specificity

$z-index-hero-content: 150;
$z-index-filter-dropdown: 200;
$z-index-search-list: 220;
$z-index-browse-version: 490;
$z-index-sticky: 650;
$z-index-modal: 1600;
