// Slide
// ---------------------------------------------------------

.slick-track {
    padding-bottom: 10px;
}

.slick-slide {
    padding-left: 15px;
    padding-right: 15px;
}

.slick-disabled {
    display: none;
    visibility: hidden;
}

.slick-next::before {
    content: url('https://cdn.musicvine.com/images/svg/next-slider.svg');
}

.slick-prev::before {
    content: url('https://cdn.musicvine.com/images/svg/prev-slider.svg');
}

.slick-slide a:not(.ub-btn) {
    display: block;
    position: relative;
    outline: none;
    margin: 0px;

    &::after {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 99;
    }
}

// Prev/Next
// ---------------------------------------------------------

@media only screen and (min-width: 992px) {
    .slick-prev,
    .slick-next {
        width: 60px;
        height: 100px;
    }

    .slick-prev {
        opacity: 0;
        transition: 0.2s;
        left: 0;
    }

    .slick-next {
        right: 0;
    }
}
