// Pricing Page
// ---------------------------------------------------------

// $pricing-section-bg-color: $gray-light-03;
$pricing-section-bg-color: $gray-light-03;
$pro-section-background: url('https://cdn.musicvine.com/images/subscription-header-pro-v2.jpg');
$creator-section-background: url('https://cdn.musicvine.com/images/subscription-header-creator-v2.jpg');
$pricing-border-style: 2px solid $pricing-section-bg-color;
$pricing-border-style-lg: 10px solid $pricing-section-bg-color;
$pricing-slider-border-color: $gray-medium-04;

$pricing-tab-size: 80px;
$pricing-tab-size-lg: 115px;

.subscription-plan-name {
    color: $gray-dark-04;
    font-family: $font-family-secondary;
    font-size: 30px;
    font-weight: 400;
    margin-bottom: 20px;
    text-align: center;

    & > span {
        color: $subscription-blue;
        font-weight: 500;

        &.subscription-plan-name--blue {
            color: $subscription-blue;
        }

        &.subscription-plan-name--orange {
            color: $subscription-orange;
        }

        &.sub-title {
            color: #e75a58 !important;
        }
    }
}

.section-container {
    min-height: 600px;
    background-color: $pricing-section-bg-color;
    background-size: cover;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;

    &.pro-section-bg {
        background-image: $pro-section-background;
        background-color: #63add4;
    }

    &.creator-section-bg {
        background-image: $creator-section-background;
        background-color: #e88172;
    }

    .content-container {
        @media screen and (max-width: 768px) {
            margin-bottom: 0 !important;

            &.pb-4 {
                padding-bottom: 0 !important;
            }

            &.mb-6 {
                margin-bottom: 0 !important;
            }
        }
    }

    &.section-content {
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        flex-direction: column;

        h1,
        h2 {
            font-size: 45px;
            color: $white;
            font-family: $font-family-secondary;
        }

        h1 {
            font-size: 36px;
            font-weight: 500;
            letter-spacing: .1rem;
            margin: 0 0 10px;

            @media screen and (max-width: 768px) {
                font-size: 30px;
            }

            @media screen and (max-width: 368px) {
                font-size: 20px;
            }
        }

        h2 {
            font-weight: 300;
            letter-spacing: 0.05rem;
            line-height: 45px;
            margin-bottom: 0;

            @media screen and (max-width: 768px) {
                margin-bottom: 45px;
                font-size: 40px;

                &.pricing-caption-h2 {
                    margin-bottom: 0;
                }
            }

            @media screen and (max-width: 368px) {
                font-size: 30px;
            }
        }

        h6 {
            font-weight: 300;
            color: $white;
            font-size: 25px;
            font-family: $font-family-secondary;
            margin-bottom: 45px;
            margin-top: 15px;
        }
    }
}

.pricing-hero {
    background-image: none;
    background-color: $pricing-section-bg-color;

    &.white {
        background-color: $white;
        min-height: 0;
    }

    &.hero-blog-content {
        width: 100%;
        text-align: center;
    }

    h1,
    p {
        color: $black;
    }
}

.hero-blog.blank .hero-blog-content {
    width: 100%;
    text-align: center;

    @media (min-width: 1024px) {
        padding: 190px 0;
    }
}

.pricing-section {
    background-color: $pricing-section-bg-color;
    font-family: $font-family-primary;
    padding: 20px 0;

    @media only screen and (min-width: 992px) {
        padding: 80px 0;
    }

    .table {
        color: $black;
    }

    .blog-post {
        font-family: $font-family-primary;
    }

    .pricing-license-desc {
        font-size: 14px;
        font-style: italic;
    }

    .pricing-ambiguous-currency {
        color: $gray-medium-03;
        font-weight: 600;
    }
}

.pricing-subtitle {
    font-family: $font-family-secondary;
    font-weight: $font-weight-medium;
}

.pricing-prompt,
.wide-content.pricing-prompt {
    text-align: center;
    font-size: 16px;
    font-weight: 300;
    margin: 40px 0;

    @media only screen and (min-width: 992px) {
        font-size: 20px;
    }

    svg {
        width: 20px;

        @media only screen and (min-width: 992px) {
            width: 34px;
        }

        .cart84,
        .cart85 {
            stroke: $gray-medium-01;
        }
    }
}

.pricing-table {
    text-align: center;
    width: 100%;

    // Reset Bootstrap
    th,
    td {
        padding: 0;
    }

    td {
        border: $pricing-border-style;

        @media only screen and (min-width: 992px) {
            border: $pricing-border-style-lg;
        }
    }
}

.pricing-lg-header {
    font-size: 12px;
    font-weight: $font-weight-bold;
    height: 50px;

    @media only screen and (min-width: 992px) {
        font-size: 16px;
    }
}

.pricing-lg-header-sub {
    font-size: 9px;

    @media only screen and (min-width: 992px) {
        font-size: 12px;
    }
}

.pricing-scale-header {
    background: $gray-light-01;

    &.upper {
        border-bottom: none;
        font-size: 12px;
        font-weight: 700;
        padding: 5px;

        @media only screen and (min-width: 992px) {
            font-size: 18px;
            padding: 20px 0 10px;
        }
    }

    &.lower {
        border-top: none;
        font-size: 8px;
        font-style: italic;
        padding: 5px;

        @media only screen and (min-width: 992px) {
            font-size: 12px;
            padding: 15px 10px;
        }
    }
}

td.pricing-coverage-header {
    background: #51b3d4;
    color: $white;
    font-size: 10px;
    font-weight: 600;
    line-height: 1.4;
    border-right: none;
    padding: 5px;

    @media only screen and (min-width: 992px) {
        font-size: 16px;
        padding: 0 5px;
    }
}

td.pricing-secondary-header {
    background: #51b3d4;
    border-left: none;
    border-top: dashed 1px $gray-medium-01;
    border-bottom: dashed 1px $gray-medium-01;
    color: $white;
    font-size: 10px;

    &.intl {
        padding: 0;
    }

    @media only screen and (min-width: 992px) {
        font-size: 14px;
        padding: 0.75rem;
    }
}

td.pricing {
    border-top: dashed 1px $gray-medium-01;
    border-bottom: dashed 1px $gray-medium-01;
    font-size: 12px;
    padding: 0.75rem 0;

    @media only screen and (min-width: 992px) {
        font-size: 14px;
        height: 90px;
        padding: 0.75rem;
    }

    &.t1 {
        background-color: $gray-light-03;
    }

    &.t2 {
        background-color: $gray-light-03;
    }

    &.t3 {
        background-color: $gray-light-03;
    }
}

// Video edit slider
// ---------------------------------------------------------

.pricing-slider {
    background: $gray-light-03;
    font-size: 14px;
    margin: 0 auto 20px;
    overflow: hidden;
    position: relative;
    text-align: center;

    h3 {
        font-size: 2rem;
        font-weight: $font-weight-normal;

        @media only screen and (min-width: 992px) {
            margin-bottom: 20px;
        }
    }

    .slider-selection.tick-slider-selection {
        background: $gray-light-01;
    }

    p {
        padding: 0 20px;
    }

    @media only screen and (min-width: 992px) {
        padding: 20px 60px 40px;

        p {
            padding: 0;
        }
    }
}

.pricing-slider__wrapper {
    padding-left: 20px;
    padding-right: 20px;
    margin-top: 10px;

    > p {
        text-align: center;
    }
}

.pricing-slider__readout {
    left: 50%;
    font-size: 18px;
    position: absolute;
    transform: translate(-50%, -80%);
}

.pricing-slider .slider.slider-horizontal {
    width: 100%;
}

.pricing-slider .pricing-slider__wrapper,
.pricing-slider .slider.slider-horizontal .slider-tick-label-container .slider-tick-label {
    font-weight: 600;
    font-size: 14px;
    color: $black;
}

.pricing-slider .pricing-slider__wrapper {
    margin-top: 15px;
}

.pricing-slider .slider.slider-horizontal .slider-tick-label-container {
    margin-top: 0;
}

.pricing-slider .slider-handle {
    display: block;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 3px solid #62cbb9;
    background: $white;
    outline: none;
    margin-top: -1px;
}

.pricing-slider .slider-selection,
.pricing-slider .slider.slider-horizontal .slider-track-high {
    background: $gray-light-01;
}

.pricing-slider .slider-tick.in-selection,
.pricing-slider .slider-tick {
    opacity: 0;
}

.pricing-slider .slider-selection {
    box-shadow: inherit;
}

.pricing-slider .slider.slider-horizontal .slider-track {
    height: 7px;
    box-shadow: inherit;
}

.pricing-slider .slider .tooltip.top {
    margin-top: 0;
}

.pricing-slider__wrapper .tooltip-main .tooltip-inner {
    visibility: hidden;
    opacity: 0;
}

.pricing-tab-container {
    border-bottom: 1px solid $pricing-section-bg-color;
    margin-bottom: 40px;
    transition: border 0.2s;

    &.active {
        border-bottom: 1px solid $pricing-slider-border-color;
    }
}

a.pricing-table-tab,
a.pricing-table-title {
    color: $gray-medium-01;
    display: inline-block;
    font-size: 12px;
    margin: 0;
    padding-bottom: 10px;
    vertical-align: top;
    width: $pricing-tab-size;

    @media only screen and (min-width: 992px) {
        margin: 0 15px;
        width: $pricing-tab-size-lg;
    }

    img {
        margin-top: 0;
        pointer-events: none;
    }
}

.pricing-slider__tt-outer {
    position: relative;
    max-width: 660px;
    margin: 80px 20px 0;
}

$pricing-slider-tt-width: 80px;

.pricing-slider__tt {
    background: $white;
    border-bottom: 1px solid $pricing-slider-border-color;
    font-size: 22px;
    left: 50%;
    pointer-events: none;
    position: absolute;
    top: -60px;
    transform: translateX(calc(-#{$pricing-slider-tt-width} / 2));
    width: $pricing-slider-tt-width;

    &::before {
        background: $white;
        border-bottom: 1px solid $pricing-slider-border-color;
        border-right: 1px solid $pricing-slider-border-color;
        content: "";
        height: 18px;
        left: 50%;
        position: absolute;
        top: 100%;
        transform: translate(-50%, -8px) rotate(45deg);
        width: 18px;
        z-index: -1;
    }
}

a.pricing-table-tab {
    border: 2px solid $pricing-section-bg-color;
    border-radius: 200px;
    height: $pricing-tab-size;
    overflow: hidden;
    padding: 14px;

    @media only screen and (min-width: 992px) {
        height: $pricing-tab-size-lg;
    }

    &.l00 {
        background-color: #00a1cf;
    }

    &.l01 {
        background-color: #1acbb9;
    }

    &.l02 {
        background-color: #cc96d4;
    }

    &.l03 {
        background-color: #f09b64;
    }

    &.l04 {
        background-color: #e17b64;
    }

    &.l05 {
        background-color: #9ea09d;
    }

    &.active-tab {
        border: 2px solid $black;

        @media only screen and (min-width: 992px) {
            border: 2px solid $pricing-section-bg-color;
        }
    }
}

.pricing-table-title.active-tab {
    position: relative;

    &::after {
        background: $pricing-section-bg-color;
        border-left: 1px solid $pricing-slider-border-color;
        border-top: 1px solid $pricing-slider-border-color;
        display: none;
        top: 62px;
        content: "";
        height: 20px;
        left: 50%;
        position: absolute;
        transform: translateX(-10px) rotate(45deg);
        width: 20px;

        @media only screen and (min-width: 992px) {
            display: block;
        }
    }
}


@media only screen and (min-width: 992px) {
    .pricing-tables {
        height: 10px;
        overflow: hidden;
        transition: height 0.5s;

        &.nothing-active {
            height: 10px;
        }

        &.table-1-active {
            height: 921px;
        }

        &.table-2-active {
            height: 1023px;
        }

        &.table-3-active {
            height: 482px;
        }

        &.table-4-active {
            height: 148px;
        }

        &.table-5-active {
            height: 890px;
        }

        &.table-6-active {
            height: 300px;
        }
    }
}

.pricing-footer {
    padding-top: 40px;
}

.pricing-caption-large {
    font-size: 45px;
    margin-top: 5px;
    color: $black;
    text-transform: inherit;
}

.brand-grid {
    margin-bottom: 6rem;

    @media only screen and (min-width: 768px) {
        display: none;
        margin-bottom: 150px;
    }

    @media only screen and (min-width: 992px) {
        display: block;
        margin-bottom: 0;
    }
}

.subscription-container {
    margin-top: -16em;
    padding-bottom: 3rem;

    @media only screen and (min-width: 768px) {
        padding-top: 3rem;
    }

    &.sticky-nav {
        padding-top: 141px;
    }

    @media screen and (max-width: 768px) {
        margin-top: 0;
        padding-left: 0;
        padding-right: 0;
    }

    .row {
        @media screen and (max-width: 768px) {
            margin-right: 0;
            margin-left: 0;
        }
    }

    .row-scroll-top {
        margin-top: -35px;
    }

    &.row-table-scroll-top {
        margin-top: -50px;

        @media screen and (max-width: 768px) {
            margin-top: -42px;
        }

        @media screen and (max-width: 414px) {
            margin-top: 0;
        }
    }

    &.row-container {
        margin-top: -50px;
    }

    .price-container {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        height: 100%;
    }

    .price-container-supplemental {
        height: 70px;

        p,
        a {
            width: 100%;
        }

        a,
        a:hover {
            color: $free;
            cursor: pointer;
        }
    }

    .box {
        padding: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        height: 100%;
        margin-right: 15px;
        margin-left: 15px;
        text-align: center;

        @media only screen and (min-width: 768px) {
            padding: 20px;
        }

        @media only screen and (min-width: 992px) {
            padding: 40px;
        }

        h4 {
            color: $gray-dark-02;
            font-size: 16px;
            font-weight: 600;
        }

        @media screen and (max-width: 768px) {
            display: none;

            &.active {
                display: flex;
            }
        }

        &.box-shadow-top {
            background-image: linear-gradient(to bottom, rgba($white, 0), rgba($white, 1) 90%);
            box-shadow: 0px 18px 40px -16px rgba($black, 0.15);

            > .link-contianer {
                padding-bottom: 10px;
            }
        }

        &.box-shadow-bottom {
            box-shadow: 0px 18px 40px -16px rgba($black, 0.15);
            background-image: linear-gradient(to top, rgba($white, 0), $white 30%);
        }

        &:first-child {
            margin-left: 0;
        }

        &:last-child {
            margin-right: 0;
        }

        h2 {
            font-size: 17px;
            color: $black;
            font-weight: 400;
            font-family: $font-family-secondary;
            line-height: 20px;
            min-height: 80px;
        }

        .extension-options p {
            color: $gray-dark-02;
            font-size: 11px;
            margin-bottom: 15px;

            &.strong {
                font-size: 13px;
                font-weight: 600;
                margin-bottom: 5px;
            }

            &:last-child {
                margin-bottom: 0;
            }
        }

        hr {
            border: none;
            border-bottom: 2px solid $gray-light-01;
            margin: 40px 0;
            width: calc(100% + 80px);
        }

        .description {
            color: $gray-dark-02;
            font-size: 13px;
            line-height: 1.6;

            @media only screen and (min-width: 1440px) {
                font-size: 15px;
            }
        }

        .price-container {
            background: $gray-light-02;
            width: 100%;
            padding: 20px;
            margin: 30px 0 0;
            text-align: center;
            height: 130px;

            p {
                font-family: $font-family-secondary;
                font-weight: 400;
                font-size: 40px;
                color: $gray-dark-02;

                > span {
                    color: $gray-dark-04;
                    font-size: 18px;
                    font-weight: 400
                }
            }

            span {
                color: #38a2ce;
                font-size: 14px;
                font-family: $font-family-primary;
                line-height: 1.375rem;
            }

            h6 {
                font-size: 14px;
                color: $black;
                font-weight: 100;
                font-family: $font-family-primary;
                line-height: 1.375rem;
            }
        }

        .link-contianer {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;

            .btn-link {
                background: $subscription-blue;
                color: $white;
                cursor: pointer;
                padding: 10px;
                text-transform: uppercase;
                display: block;
                max-width: 130px;
                width: 100%;
                text-align: center;
                font-family: $font-family-secondary;
            }
        }

        .disclaimer {
            font-size: 11px;
            margin-bottom: 10px;

            a {
                text-decoration: underline;
            }
        }
    }

    .icon-static {
        display: block;
        width: auto;
        height: auto;
        padding: 3px;

        > svg {
            width: 30px;
            height: 30px;

            > path {
                fill: $green;
            }
        }
    }
}

.pl-table {
    width: 100%;
    margin-bottom: 40px;

    @media screen and (max-width: 768px) {
        margin-bottom: 12px;
    }

    .row {
        margin: 0;
        padding: 0 5px;
        align-items: center;
    }

    .col {
        padding: 0 10px;
        text-overflow: ellipsis;
    }

    .pl-thead {
        text-transform: uppercase;
        color: $gray-dark-04;
        font-family: $font-family-secondary;
        font-weight: 500;
        border-bottom: 2px solid $gray-light-01;
        padding-bottom: 1em;

        .col {
            padding-top: 15px;
            padding-bottom: 15px;
            font-size: 14px;
        }
    }

    .is-open.icon-vertical-bar {
        display: none;
    }

    .pl-tbody {
        @media screen and (max-width: 768px) {
            display: none;

            &.active {
                display: block;
            }
        }

        .active {
            @media screen and (max-width: 768px) {
                display: block;
            }
        }

        .in-active {
            @media screen and (max-width: 768px) {
                display: none;
            }
        }

        .row {
            padding-top: 7px;
            padding-bottom: 7px;
            color: $gray-medium-02;
            font-size: 11px;

            &:nth-child(odd) {
                background-color: $white
            }

            .col {
                border: none;
                padding-top: 8px;
                padding-bottom: 8px;
                color: $subscription-blue;
                font-size: 16px;
                font-family: sans-serif;

                > p {
                    color: $gray-dark-04;
                    font-size: 14px;
                    margin: 0;
                }

                > .icon-static > svg {
                    width: 20px;
                    height: 20px;
                }
            }
        }
    }

    .info-icon {
        display: flex;

        @media screen and (max-width: 768px) {
            display: none;
        }
    }

    > svg {
        width: 18px;
        height: 18px;
    }
}

.pricing-tabs {
    padding: 20px 15px;
    margin-bottom: 3em;
    background: $white;
    box-shadow: -20px 15px 45px -24px rgba($black, 0.5);
    display: none;

    @media screen and (max-width: 768px) {
        display: block;
    }

    &.is-fixed {
        @media screen and (max-width: 768px) {
            position: fixed;
            top: 0;
            width: 100%;
            z-index: 9999;
        }
    }

    ul {
        list-style: none;
        border: 1px solid $gray-medium-03;

        li {
            font-size: 16px;
            padding: 15px 10px;
            border-left: 1px solid $gray-medium-03;
            cursor: pointer;
            color: $gray-medium-02;
            font-family: $font-family-secondary;
            line-height: 25px;
            background: $gray-light-02;
            justify-content: center;
            display: flex;
            flex-direction: column;

            p,
            span {
                line-height: 15px;
            }

            &.active {
                color: $subscription-blue;
                background: $white;
            }

            &:first-child {
                border-left: none;
            }
        }
    }
}

.switch-text-container {
    position: relative;
    text-align: center;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 10px;
    flex-direction: column;

    label {
        margin-left: 10px;
        margin-right: 10px;
        margin-bottom: 0;
    }

    .switch-text {
        display: flex;
        align-items: center;
        padding-left: 15px;

        p {
            font-size: 15px;
            font-family: $font-family-primary;
            opacity: 1;
            font-weight: 400;
            padding-top: 0;
            font-style: normal;
        }

        &.creator-switch-text input[type="checkbox"].switch + div {
            background-color: #80463d
        }
    }

    .switch-text-disclaimer {
        color: $black;
        font-size: 15px;
        height: 32px;
        overflow: hidden;
        position: relative;
        width: 240px;

        span {
            left: 0;
            position: absolute;
            text-align: center;
            width: 240px;
            transition: transform 0.2s;
            transition-timing-function: cubic-bezier(1, 0, 0, 1);

            &:first-child {
                transform: translateX(0%);
            }

            &:last-child {
                transform: translateX(-100%);
            }
        }
    }

    p {
        padding-top: 5px;
        font-size: 16px;
        color: $white;
        opacity: 0.7;
        font-weight: 100;
        font-style: italic;

        @media screen and (max-width: 990px) {
            color: $black
        }
    }
}

@media only screen and (min-width: 768px) {
    .creator .switch-text-disclaimer {
        color: #fae1e0;
    }

    .pro .switch-text-disclaimer {
        color: #dce5f3;
    }
}

.pricing-logo-intro {
    color: $white;
    font-style: italic;
}

.pl-row-heading {
    color: $subscription-blue;
    font-weight: 400;
}

.pl-additional {
    &.addon {
        color: $subscription-blue;
        font-size: 10px;
        font-style: italic;

        @media only screen and (min-width: 768px) {
            font-size: 16px;
        }
    }

    &.limited {
        color: $gray-dark-04;
        border-bottom: 1px dotted $gray-dark-04;
    }
}
