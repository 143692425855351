// We never actually want a button with the default button styles, so remove
// them here and let each component style itself.
button {
  appearance: none;
  background: none;
  border: none;
  color: inherit;
  font: inherit;
  line-height: inherit;
  margin: 0;
  outline: none;
  overflow: visible;
  padding: 0;
  vertical-align: initial;
  width: auto;
}
