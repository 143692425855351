.columnar-card {
    display: flex;
    padding: 0.625rem 0.8125rem;
    border-radius: 0.5rem;
    @include bg(--jumbotron-alt-bg);

    .columnar-card__delimiter {
        margin: 0 12px;
        width: 1px;
        opacity: 0.4;
        @include bg(--jumbotron-br);

        + div {
            display: flex;
            align-items: center;

            > div {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                gap: 0.25rem;
                height: 100%;
                justify-content: space-between;

                p {
                    font-size: 0.6875rem;
                }
            }
        }
    }

    .columnar-card__info {
        flex-shrink: 0;
        width: 34px;
        text-align: center;

        > div:first-child {
            font-size: 1.25rem;
            line-height: 1.25rem;
            font-family: $font-family-heading;
            white-space: nowrap;
            @include c(--jumbotron-alt-bold-c);

            &.text-success {
                @include c(--jumbotron-active);
            }
        }

        > div:last-child {
            font-size: 0.5625rem;
            @include c(--jumbotron-alt-c);
        }
    }

    &.__graph {
        position: relative;
        padding-right: 1.25rem;
        padding-left: 1.5rem;
        border-radius: 2rem;
        background-color: $transparent;
        margin: 2rem auto 1rem;
        @include css4 {
            border: dashed 1px cvar(--jumbotron-br);
        }

        .columnar-card__delimiter {
            margin-inline: 0.5rem;
        }

        &:after {
            content: '';
            position: absolute;
            bottom: -5px;
            left: 50%;
            display: block;
            margin-inline-start: -0.25rem;
            width: 9px;
            height: 9px;
            border-radius: 50%;
            @include bg(--jumbotron-alt-bg);
            @include css4 {
                border: dashed 1px cvar(--jumbotron-br);
            }
        }

        &.__active {
            @include css4 {
                border: solid 3px cvar(--jumbotron-active);
            }

            &:after {
                bottom: -6px;
                @include bg(--jumbotron-active);
                @include css4 {
                    border: solid 1px cvar(--jumbotron-active);
                }
            }
        }
    }

    &.__pure {
        padding: 0;
        background-color: $transparent;
    }

    &.__spacing_s .columnar-card__delimiter {
        margin-right: 8px;
        margin-left: 8px;
    }
}

.ub-modal .ub-modal-container .jumbotron {
    margin-block-end: 0.5rem;

    .columnar-card .columnar-card__info div {
        color: $white;
    }
}
