.license-history {
  .table {
    font-family: brandon-grotesque, sans-serif;
    font-size: 14px;
  }

  .table th {
    border-top: none;
  }

  a.btn.disabled,
  fieldset[disabled] a.btn {
    pointer-events: none;
  }

  .btn.disabled,
  .btn[disabled],
  fieldset[disabled] .btn {
    cursor: not-allowed;
    opacity: 0.65;
    filter: alpha(opacity=65);
    box-shadow: none;
  }

}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
  max-width: 100%;
}

.spacer-r {
  margin-right: 20px;
}

.form-control {
  width: 100%;
  height: 34px;
  padding: 6px 12px;
  background-color: $white;
  background-image: none;
  border: 1px solid $gray-medium-04;
  border-radius: 0px;
  box-shadow: inset 0 1px 1px rgba($black, 0.075);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

  &:focus {
    border-color: #66afe9;
    outline: 0;
    box-shadow: inset 0 1px 1px rgba($black, 0.075),
      0 0 8px rgba(102, 175, 233, 0.6);
  }
}

small,
sub,
sup {
  font-size: 83.3333%;
}

.form-general-group {
  padding: 0 20px 20px;
  background: $gray-light-03;
  margin-bottom: 40px;
}

.form-general-group label {
  margin-bottom: 5px;
}

.form-general-title {
  padding-top: 30px;
  font-weight: 600;
  font-size: 18px;
  color: $gray-medium-02;
  margin-bottom: 15px;
}

.form-control[disabled],
.form-control[readonly],
fieldset[disabled] .form-control {
  background-color: rgba($gray-medium-02, 0.5);
  opacity: 1;
}

.form-control,
output {
  display: block;
  font-size: 14px;
  line-height: 1.42857;
  color: $gray-medium-02;
}

label.required:after {
  content: " *";
  text-decoration: inherit;
  vertical-align: inherit;
}

.spacer-sm {
  margin-bottom: 10px;
}

.form-general-sub {
  display: block;
  font-weight: 600;
  font-size: 14px;
  margin-bottom: 10px;
  color: $black;
}

.form-general-group input[type="checkbox"] + label {
  margin-right: -4px;
  width: 90%;
  padding-left: 5px;
  vertical-align: middle;
  margin-bottom: 0;
}

.subscription-inner-list {
  padding: 10px;
  text-align: left;
  margin: 0;
  font-weight: normal;

  p {
    font-size: 0.875rem;
    font-weight: normal;
    margin-bottom: 0;
    @include c(--h3-c);
  }

  a {
    color: #3a9ecf;
    text-decoration: underline;
  }

  small {
    font-size: 83.3333%;
  }

  .extra-bold {
    font-weight: 600;
  }

  span {
    font-weight: normal;
  }

  .pl-2,
  .px-2 {
    padding-left: 0.5rem !important;
  }

  .ellipsis {
    white-space: pre;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

.spacer-lg {
  margin-bottom: 40px;
}

.font-size-small {
  font-size: 2rem;
}

.submission-form {
  padding: 0;
}

.social-section-info {
  background: $gray-light-03;
  border-radius: 3px;
  box-shadow: 1px 3px 5px -2px rgba($black, 0.4);
  height: 60px;
  margin: 20px 30px 0;
  overflow: hidden;
  position: relative;
  user-select: none;

  img {
    max-width: 60px;
  }

  .yt_data-title {
    display: inline-block;
    font-size: 14px;
    max-width: 188px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .yt_data-id {
    font-size: 10px;
  }

  .yt-cancel {
    background: $transparent;
    font-size: 21px;
    line-height: 32px;
    padding: 0;
    top: 0;
    width: 34px;
    outline: none;
    cursor: pointer;
    transform: translateX(-10px);
  }
}

.account-error-message {
  padding: 8rem 2rem;
  text-align: center;

  h2 {
    font-family: $font-family-heading;
    font-weight: 400;
  }
}

.toogle-text {
  display: inline-block;
  width: auto;
  text-align: left;
  vertical-align: top;
  margin: 0 auto;
  padding: 0;
  line-height: 28px;

  p {
    font-weight: 600;
    font-size: 14px;
    color: $gray-medium-01;
    margin-bottom: 0;
    text-transform: capitalize;
  }
}

.toogle-section {
  display: inline-block;
  width: auto;
  text-align: left;
  margin-right: 20px;
  margin-left: 16px;

  .switch {
    position: relative;
    display: inline-block;
    width: 26px;
    height: 28px;

    input {
      display: none;

      &:checked + .slider {
        background-color: $green;

        &:before {
          transform: translateX(26px);
        }
      }
    }

    .slider {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: $gray-medium-04;
      -webkit-transition: 0.2s;
      transition: 0.2s;
      margin-left: -15px;
      margin-right: -15px;
      border-radius: 34px;

      &:before {
        position: absolute;
        content: "";
        height: 20px;
        width: 20px;
        left: 5px;
        bottom: 4px;
        background-color: $white;
        -webkit-transition: 0.2s;
        transition: 0.2s;
        border-radius: 50%;
      }
    }
  }
}

.editCard {
  cursor: pointer;
  text-decoration: underline;
}

.social-section-info {
  background: $gray-light-03;
  border-radius: 3px;
  box-shadow: 1px 3px 5px -2px rgba($black, 0.4);
  height: 60px;
  margin: 20px 0 0;
  overflow: hidden;
  position: relative;
  user-select: none;
  display: flex;
  flex-wrap: wrap;

  img {
    max-width: 60px;
  }

  .yt_data-title,
  .yt_data-id {
    color: $gray-dark-02;
  }

  .yt-cancel {
    background: $transparent;
    font-size: 21px;
    line-height: 32px;
    padding: 0;
    top: 0;
    width: 34px;
    outline: none;
    cursor: pointer;
  }
}

.toogle-text {
  display: inline-block;
  width: auto;
  text-align: left;
  vertical-align: top;
  margin: 0 auto;
  padding: 0;
  line-height: 28px;

  p {
    font-weight: 600;
    font-size: 0.875rem;
    margin-bottom: 0;
    text-transform: capitalize;
    @include c(--h3-c)
  }
}

.toogle-section {
  display: inline-block;
  width: auto;
  text-align: left;
  margin-right: 20px;
  margin-left: 16px;

  .switch {
    position: relative;
    display: inline-block;
    width: 26px;
    height: 28px;

    input {
      display: none;

      &:checked + .slider {
        background-color: $green;

        &:before {
          transform: translateX(26px);
        }
      }
    }

    .slider {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: $gray-medium-04;
      -webkit-transition: 0.2s;
      transition: 0.2s;
      margin-left: -15px;
      margin-right: -15px;
      border-radius: 34px;

      &:before {
        position: absolute;
        content: "";
        height: 20px;
        width: 20px;
        left: 5px;
        bottom: 4px;
        background-color: $white;
        -webkit-transition: 0.2s;
        transition: 0.2s;
        border-radius: 50%;
      }
    }
  }
}

.editCard {
  cursor: pointer;
  text-decoration: underline;
}

.license__checkboxes {
	padding-left: 10px;
}
