.whitelistContainer {
  display: grid;
  gap: 1rem;
  margin: 2rem 0;
  grid-template-columns: repeat(1, minmax(0, 1fr));

  &.fullWidth {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }

  @media only screen and (min-width: 500px) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  @media only screen and (min-width: 768px) {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}

.inputWrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  padding: 0.75rem 1rem;
}

.input {
  background: var(--whitelisting-input-override-bg) !important;
}

.searchButton {
  position: absolute;
  right: 1.5rem;
  top: 50%;
  transform: translateY(-50%);
  margin: 0;
  pointer-events: none;
  background: var(--search-input-btn-override-bg) !important;

  > :global(.ub-btn-icon img) {
    filter: brightness(4);
  }
}

.whitelistedChannel {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.75rem;
  padding: 0.75rem;
  border-radius: 1rem;
  background: var(--input-bg);

  @container (min-width: 250px) {
    /* padding: 1rem; */
    gap: 1rem;
  }

  .whitelistedDetails {
    margin-inline-end: auto;
    overflow: hidden;
    max-width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .channelName {
    display: block;
    overflow: hidden;
    max-width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: var(--h2-c);
    font-size: 0.875rem;
    font-family: roboto, sans-serif;
    font-weight: 500;

    @container (min-width: 250px) {
      font-size: 1rem;
    }
  }

  .channelId {
    font-size: 0.75rem;

    @container (min-width: 250px) {
      font-size: 1rem;
    }
  }

  img {
    height: 4rem;
    width: 4rem;
    border-radius: 50%;
  }

  :global(.ub-btn) {
    margin-block-end: 0 !important;
  }
}

.whitelistPopup {
  container-type: inline-size;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #232525;
  font-family: roboto, sans-serif;
  width: 100%;
  height: 100%;
  z-index: 1;
  border-radius: 0.75rem;
  transition: opacity 0.2s ease-in-out;

  &.loading {
    opacity: 0.8;
  }

  .resultsContainer {
    background: var(--input-bg);
    border-radius: 0.75rem;
    position: absolute;
    top: 0;
    width: 100%;

    .results {
      padding: 1rem 0;

      .channelDetails {
        display: flex;
        align-items: center;
        margin-block-end: 0.5rem;
        border-radius: 1.25rem;
        cursor: pointer;
        transition: margin 0.2s;

        > img {
          width: 2.5rem;
          height: 2.5rem;
          min-width: 2.5rem;
          border-radius: 50%;
        }

        > p {
          color: var(--h2-c);
          font-weight: 500;
          padding-inline-start: 1rem;
          font-size: 0.75rem;
          font-family: roboto, sans-serif;
          overflow: hidden;
          max-width: 100%;
          white-space: nowrap;
          text-overflow: ellipsis;
        }

        &:hover,
        &:focus {
          margin-inline-start: 1rem;
          background: var(--search-dropdown-item-bg);
        }
      }
    }

    .inputWrapper {
      padding: 1rem 0;

      .searchButton {
        right: 0.5rem;
      }
    }
  }
}

.whitelistItem {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 6rem;
  border-radius: 0.75rem;
  background: var(--input-bg);
  border: 1px dashed var(--main-br);
  pointer-events: all;

  &.relativePosition {
    height: 100%;
    min-height: 6rem;
  }

  :not(.active)&:hover {
    background: var(--input-hover-bg);
  }

  &.active {
    align-items: flex-start;
    border: none;
  }

  .addChannelContainer {
    cursor: pointer;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .addChannelButton {
      margin-block-end: 0 !important;
      transform: rotate(45deg);
    }
  }
}

.whitelistChannelID {
  padding: 1rem 0;

  .whitelistInfo {
    font-size: 0.75rem;
    margin-block-end: 0.5rem;
    display: flex;
    align-items: flex-end;
    color: var(--search-dropdown-primary-c);

    .whitelistInfoButton {
      margin: 0;
      margin-inline-start: 0.5rem;
      margin-block-end: 0 !important;
    }
  }

  .channelIDInputWrapper {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 0.5rem;
    flex: 1 1 auto;

    .confirmButton {
      width: 100%;
      margin-block-end: 0 !important;
    }

    @container (min-width: 300px) {
      flex-direction: row;

      .confirmButton {
        max-width: 25%;
      }
    }
  }
}

.tooltip {
  z-index: 10000;
}
