.checkbox {
  cursor: pointer;
  position: relative;
  background-color: var(--checkbox-fill);
  width: 18px;
  height: 18px;
  min-width: 18px;
  min-height: 18px;
  border-radius: 4px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.08s cubic-bezier(0.46, 0.03, 0.52, 0.96);

  &[data-state='checked'] {
    background: var(--checkbox-active-bg);
  }

  &:active {
    transform: scale(0.8);
  }
}

.checkbox:hover {
  opacity: 0.8;
}

:not(.hideFocus) {
  &.checkbox:focus,
  &.checkbox:active {
    box-shadow: 0 0 0 3px var(--ub-border-dark);
  }
}

.indicator {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  color: var(--checkbox-fill);
  height: 100%;

  svg {
    width: 20px;
  }
}
