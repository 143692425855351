.email-container {
	.email-content {
		padding: 1.5rem;

		@media only screen and (min-width: 768px) {
			padding: 1.5rem 3rem
		}

		.email-settings {
			padding-block-end: 3rem;

			h2 {
				margin: 0 0 1.5rem;
			}

			p {
				margin-block-end: 0.5rem;
			}

			hr {
				opacity: 0;
				padding-block-end: 1rem;
			}

			.checkbox-wrapper {
				display: flex;
				align-items: center;
				margin-block-end: 0.5rem;

				input {
					margin-inline-end: 0.5rem;
					margin-block-start: -1px
				}

				label {
					margin: 0;
					@include c(--p-c);
				}
			}
		}
	}

	.email-footer-text {
		padding-block-start: 2rem;
		font-size: 0.75rem;
	}
}