.ub-allowance-indicator {
    width: auto;
    height: calc(1rem + 2px);
    display: inline-flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;
    gap: 1px;
    overflow: hidden;
    border-radius: 0.125rem;
    @include bg(--allowance-indicator-br);
    @include css4 {
        border: 0.5px solid cvar(--allowance-indicator-br);
    }

    .ub-allowance-indicator_initial,
    .ub-allowance-indicator_new {
        width: auto;
        display: flex;
        gap: 1px;

        > div {
            width: 0.75rem;
            height: 1rem;
            text-align: center;
            line-height: 1.25;
            font-family: $font-family-heading;
            display: flex;
            justify-content: center;
            align-items: center;
            @include c(--allowance-indicator-c);
            @include bg(--allowance-indicator-bg);
        }
    }

    &.new-value-smaller {
        flex-direction: column;

        > div {
            animation: change-number-smaller 1s forwards;

            div {
                padding-block-start: 2px;
            }
        }
    }

    &.new-value-larger {
        flex-direction: column-reverse;

        > div {
            animation: change-number-larger 1s forwards;

            div {
                padding-block-start: 2px;
            }
        }
    }
}

@keyframes change-number-smaller {
    0% {
        transform: translateY(0);
    }

    100% {
        transform: translateY(100%);
    }
}

@keyframes change-number-larger {
    0% {
        transform: translateY(0);
    }

    100% {
        transform: translateY(-100%);
    }
}
