$report-cell-width: 15%;
$report-cell-width-first: 25%;
$report-cell-width-last: 30%;

.share-report-container {
    .share-report-content {
        padding: 0 1.5rem;

        @media only screen and (min-width: 768px) {
            padding: 0 3rem;
        }

        .report-heading {
            margin-block-end: 1.5rem;

            .single-heading {
                margin-top: 20px;
                padding: 0 10px;
                font-size: 15px;
                font-weight: 500;
                font-family: $font-family-secondary;

                .title {
                    color: $gray-dark-02;
                    display: block;
                }

                .description {
                    color: #30BDE5;
                    display: block;
                }
            }
        }

        @media only screen and (min-width: 768px) {
            .report-heading {
                display: flex;
                flex-direction: column;

                .single-heading {
                    display: flex;
                    margin-top: 8px;
                    padding: 0;

                    .title {
                        flex-basis: 18%;
                    }
                }
            }
        }

        .report-table {
            table {
                font-family: $font-family-secondary;

                @media only screen and (max-width: 767px) {
                    width: 100%;
                }

                @media only screen and (min-width: 992px) {
                    width: 60%;
                }

                thead tr {
                    height: 30px;
                    font-size: 14px;

                    @media only screen and (min-width: 992px) {
                        width: 60%;
                    }

                    th {
                        &:first-child {
                            text-align: left;
                            padding-left: 25px;
                        }

                        &:last-child {
                            width: $report-cell-width-last;
                            text-align: end;

                            span {
                                padding-inline-end: 25px;
                            }
                        }

                        span {
                            white-space: nowrap;
                            padding-inline-end: 1rem;

                            &.small {
                                display: block;

                                @media only screen and (min-width: 992px) {
                                    display: none;
                                }
                            }

                            &.large {
                                display: none;

                                @media only screen and (min-width: 992px) {
                                    display: block;
                                }
                            }
                        }

                        @media only screen and (max-width: 767px) {
                            text-align: center;
                            width: $report-cell-width;

                            span {
                                font-size: 12px;
                                padding-inline: 0.5rem;
                            }

                            &:first-child {
                                width: $report-cell-width-first;
                            }
                        }
                    }
                }

                tbody tr {
                    height: 40px;

                    &:nth-child(odd) {
                        background: #F3F8F9;
                    }

                    td {
                        color: $free;
                        font-size: 14px;
                        font-weight: 500;

                        @media only screen and (max-width: 767px) {
                            text-align: center;
                        }

                        &:first-child {
                            padding-left: 25px;
                            text-align: left;
                        }

                        &:last-child {
                            width: 35%;
                            padding-right: 25px;
                            text-align: end;
                        }
                    }
                }
            }
        }
    }
}
