// Header
//
// Site header

header {
    padding-top: 0;
    padding-bottom: 0;
    text-align: center;

    @media only screen and (min-width: 992px) {
        text-align: left;
    }
}

.admin-bar {
    font-family: $font-family-primary;
    height: $admin-bar-height;
    width: 100%;
    display: none;
    padding-inline: 0.5rem;
    @include bg(--admin-bar-bg);
    @include c(--admin-bar-c);

    @media only screen and (min-width: 992px) {
        display: flex;
        flex-wrap: wrap;
    }

    > div:first-child {
        flex: 1 0 auto;

        .admin-bar_links {
            display: flex;

            p {
                height: 20px;
                line-height: 20px;
            }

            li {
                display: flex;

                a {
                    font-size: 12px;
                    line-height: 20px;
                    padding: 0 20px;
                    @include c(--admin-bar-c);
                }
            }
        }
    }

    > div:nth-child(2) {
        flex: 1;
        display: flex;
        justify-content: flex-end;
    }

    > div:nth-child(3) {
        flex: 1 0 auto;
        display: flex;
        justify-content: flex-end;
        gap: 0.75rem;
    }

    .admin-bar_relevancy-toggle {
        border-radius: 50%;
        cursor: pointer;
        display: inline-block;
        height: 16px;
        margin-top: 2px;
        margin-left: 5px;
        transition: 0.2s;
        width: 16px;
        @include bg(--admin-bar-bg);
        @include css4 {
            border: 2px solid cvar(--admin-bar-c);
        }

        &.on {
            @include bg(--admin-bar-relevancy-bg);
            @include css4 {
                border: 2px solid cvar(--admin-bar-relevancy-br);
            }
        }
    }
}
