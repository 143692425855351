.ub-table {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 clamp(1.5rem, 3cqw, 3rem);

    .ub-table-inner {
        width: 100%;
        display: flex;
        flex-direction: column;

        .ub-table-heading {
            display: none;
        }

        .ub-table-subheading {
            width: 100%;
            min-height: 3rem;
            border-radius: 0.75rem;
            margin-block-end: 0.5rem;
            display: flex;
            align-items: center;
            padding: 0.5rem 1.5rem;
            @include bg(--table-subheading-bg);

            h3 {
                font-size: 1.25rem;
            }
        }

        .ub-table-body {
            width: 100%;
            display: flex;
            flex-direction: column;

            .ub-table-row {
                width: 100%;
                display: flex;
                min-height: 3rem;
                border-radius: 0.75rem;
                margin-block-end: 0.5rem;
                overflow-x: hidden;
                @include bg(--table-row-bg);

                .ub-table-left-cell {
                    min-width: 50%;
                    width: 50%;
                    min-height: 3rem;
                    font-size: 0.875rem;
                    display: flex;
                    align-items: center;
                    padding: 0.5rem 1.5rem;
                    font-family: $font-family-heading;
                    @include bg(--table-row-left-bg);
                    @include c(--main-primary-c);
                }

                .ub-table-cells {
                    display: flex;
                    width: 50%;
                    overflow: hidden;

                    .ub-table-cell {
                        min-width: 100%;
                        width: 100%;
                        min-height: 3rem;
                        font-size: 0.875rem;
                        display: flex;
                        align-items: center;
                        padding: 0.5rem 1.5rem;
                        font-family: $font-family-secondary;
                        transform: translateX(var(--ub-table-cells-position, auto));
                        transition: transform 0.2s;
                        @include c(--main-primary-c);
                    }
                }
            }
        }

        .ub-table-footer {
            display: none;
        }
    }

    @media only screen and (min-width: 768px) {
        .ub-table-inner {
            max-width: 1200px;

            .ub-table-heading {
                width: 100%;
                display: flex;
                border-radius: 0.75rem;
                margin-block-end: 1.5rem;

                .ub-table-cell {
                    flex: 1;
                    padding: 0 1.5rem;

                    h2 {
                        font-size: 1.5rem;
                        @include c(--table-c);
                    }
                }
            }

            .ub-table-body {
                width: 100%;
                display: flex;
                flex-direction: column;

                .ub-table-row {
                    width: 100%;
                    display: flex;
                    min-height: 3rem;
                    border-radius: 0.75rem;
                    margin-block-end: 0.5rem;
                    @include bg(--table-row-bg);

                    .ub-table-left-cell {
                        min-width: unset;
                        width: var(--ub-table-left-cell-width, auto);
                        flex: 1;
                        min-height: 3rem;
                        font-size: 0.875rem;
                        display: flex;
                        align-items: center;
                        padding: 0.5rem 1.5rem;
                        font-family: $font-family-heading;
                        @include bg(--table-row-left-bg);
                        @include c(--table-c);
                    }

                    .ub-table-cells {
                        display: flex;
                        width: var(--ub-table-cells-width, auto);

                        .ub-table-cell {
                            min-width: unset;
                            width: auto;
                            flex: 1;
                            min-height: 3rem;
                            font-size: 0.875rem;
                            display: flex;
                            align-items: center;
                            padding: 0.5rem 1.5rem;
                            font-family: $font-family-secondary;
                            transform: none;
                            transition: none;
                            @include c(--table-alt-c);
                        }
                    }
                }
            }

            .ub-table-footer {
                display: flex;

                > div {
                    min-width: unset;
                    width: auto;
                    flex: 1;
                    display: flex;
                    align-items: center;
                    padding: 0.5rem 1.5rem;
                }
            }
        }

        + .ub-table {
            margin-block-start: 1.5rem;
        }
    }

    @media only screen and (min-width: 992px) {
        .ub-table-inner .ub-table-heading .ub-table-cell h2 {
            font-size: 1.75rem;
        }
    }
}
