// Form
//
// Form element styles

// Select
// ---------------------------------------------------------

@media screen and (-webkit-min-device-pixel-ratio:0) and ( max-width: 768px ) {
  select,
  textarea,
  input {
    font-size: 16px;
  }
}

select::-ms-expand {
    display: none;
}

// Search
// ---------------------------------------------------------

.btn:focus {
    outline: none;
}
