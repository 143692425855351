.main-search-wrapper {
    width: 100vw;
    display: flex;
    position: fixed;
    top: 0;
    left: unset;
    right: 0;
    bottom: 0;
    height: 100%;
    max-height: -webkit-fill-available;
    z-index: 10;

    border-left: 1px solid var(--ub-border-light);

    @media only screen and (min-width: 600px) {
        position: absolute;
        z-index: -1;
        left: 100%;
    }

    .main-search-close-button {
        display: none;
        position: absolute;
        top: 0.75rem;
        right: 0.75rem;
        @media only screen and (min-width: 600px) {
            display: flex;
        }

    }

    > div:first-child {
        width: 100%;
        background: var(--main-bg);
        height: 100%;
        position: relative;
        // transition: margin 0.2s;
        display: flex;
        flex-direction: column;
        container-type: inline-size;

        @media only screen and (min-width: 600px) {
            max-width: 520px;
        }

        .main-search-input-wrapper {
            width: 100%;
            height: auto;
            display: flex;
            align-items: center;
            gap: 0.75rem;
            position: relative;
            padding: 0 1.5rem 0;

            @media only screen and (min-width: 600px) {
                padding-top: 80px;
            }

            form {
                position: relative;
                width: 100%;
                padding: 0 0 0.25rem 0;

                > svg:first-child {
                    position: absolute;
                    left: 0;
                    top: 0.625rem;
                    width: 1.625rem;
                    height: 1.625rem;

                    path {
                        fill: var(--button-secondary-c)
                    }
                }

                input {
                    height: 100%;
                    text-align: left;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    width: 100%;
                    white-space: nowrap;
                    color: var(--h1-c);
                    font-size: 2rem;
                    padding: 0 2.5rem;
                    border: none;
                    background: none;
                    font-family: $font-family-heading;
                    box-shadow: none;
                    position: relative;

                    &::placeholder {
                        font-family: $font-family-heading;
                        color: var(--h1-c);
                        opacity: 0.25;
                    }

                    &:focus {
                        outline: none;
                        box-shadow: none;
                    }

                    + svg {
                        position: absolute;
                        right: 0;
                        top: 1.125rem;
                        width: 1rem;
                        height: 1rem;
                        cursor: pointer;

                        @media (hover) {
                            &:hover {
                                filter: brightness(0.8);
                            }
                        }
                    }
                }

                .ub-btn {
                    position: absolute;
                    right: 1.5rem;
                    top: 4rem;
                }

                &::before,
                &::after {
                    content: '';
                    display: block;
                    position: absolute;
                    height: 1px;
                    bottom: 0;
                }

                &::before {
                    width: 100%;
                    left: 0;
                    background: var(--ub-border-light);
                }

                &::after {
                    width: 0;
                    left: 50%;
                    transform: translateX(-50%);
                    transition: width 0.4s, opacity 0.4s;
                    background: var(--ub-border-dark);
                    opacity: 0;
                }

                &:focus-within::after {
                    width: 100%;
                    opacity: 1;
                }
            }

            &:focus-within {
                box-shadow: 0 28px 4px -24px rgba(var(--shadow-light), 0.5);
            }
        }

        .main-search-results-wrapper {
            overflow-y: auto;
            overflow-x: hidden;

            .main-search-results {
                &:first-child {
                    padding-block-start: 0.5rem;
                }

                &:not(:first-child) {
                    border-top: 1px solid var(--ub-border-light);
                }

                &.popular {
                    text-transform: capitalize;
                    padding-block-end: 1.5rem;

                    + .popular {
                        border-top: none;

                        h4 {
                            padding-block-start: 0;
                        }
                    }

                    > div {
                        display: flex;
                        flex-wrap: wrap;
                        gap: 0.375rem;
                        padding: 0 1.5rem;
                    }
                }

                &.artists {
                    padding-block-end: 1.5rem;

                    h4 {
                        padding: 1.5rem 1.5rem 0.5rem;
                    }

                    > div {
                        display: flex;
                        flex-direction: column;

                        .main-search-result-artist {
                            display: flex;
                            align-items: center;
                            gap: 1rem;
                            padding: 0.25rem 1.5rem;

                            @media (hover) {
                                &:hover {
                                    background: var(--track-player-hover-right-bg);
                                }
                            }

                            > img {
                                border-radius: 50%;
                            }

                            > div {
                                display: flex;
                                flex-direction: column;
                                align-items: flex-start;
                                justify-content: center;
                                gap: 0.125rem;

                                span {
                                    color: var(--browse-tracks-title-c);
                                    font-size: 1rem;
                                    font-weight: 500;
                                }

                                p {
                                    font-size: 12px;

                                    @media only screen and (min-width: 420px) {
                                        font-size: 14px;
                                    }
                                }
                            }
                        }
                    }
                }

                &.carousel {
                    padding-block-end: 1.06125rem;

                    + .carousel {
                        border-top: none;

                        h4 {
                            padding-block-start: 0;
                        }
                    }
                }

                &.standard {
                    h4 {
                        padding: 1.5rem 1.5rem 0.75rem;
                    }

                    ul {
                        list-style: none;
                        padding-block-end: 1.5rem;

                        li .main-search-result {
                            width: 100%;
                            padding: 0.25rem 1.5rem;
                            display: flex;
                            gap: 0.75rem;
                            align-items: center;
                            justify-content: flex-start;

                            @media (hover) {
                                &:hover {
                                    background: var(--track-player-hover-right-bg);
                                }
                            }

                            .main-search-result-image {
                                position: relative;

                                > img {
                                    width: 3.5rem;
                                    min-width: 3.5rem;
                                    height: 3.5rem;
                                    border-radius: 50%;
                                    background: var(--skeleton-bg);
                                }
                            }

                            .main-search-result-left {
                                margin-inline-end: auto;
                                overflow: hidden;
                                white-space: nowrap;
                                overflow: hidden;
                                text-overflow: ellipsis;

                                span {
                                    color: var(--browse-tracks-title-c);
                                }
                            }

                            .main-search-result-tags {
                                display: flex;
                                gap: 0.25rem;
                            }
                        }
                    }
                }

                h4 {
                    color: var(--h2-c);
                    font-family: $font-family-heading;
                    font-size: 1.125rem;
                    width: 100%;
                    text-transform: capitalize;
                    padding: 1.5rem 1.5rem 1rem;
                }
            }
        }
    }

    > .search-background {
        width: 100%;
        height: 100%;
        position: absolute;
        z-index: -2;
        background: var(--modal-overlay-bg);
    }
}

.search-page-tabs-content {
    display: flex;
    padding: 1rem;
    gap: 0.25rem;
}
