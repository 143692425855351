.outstanding-invoice--panel {
  padding: 1.5rem;

  @media only screen and (min-width: 768px) {
    padding: 1.5rem 3rem;
  }

  p {
    margin-top: 10px;
  }

  img {
    width: 2rem;
    height: 2rem;
    margin-inline-start: 0.5rem;
  }
}

// Pagination
//
// Site pagination

.site-pagination {
  padding-bottom: 40px;
  margin-bottom: 40px;
  background: $black;
}

.site-pagination--secondary {
  background: none;
}

// Icon
// ---------------------------------------------------------

.site-pagination__icon {
  cursor: pointer;
}

.pagination-next-icon path {
  transition: 0.2s;
}

.pagination-next-icon:hover path {
  fill: $white;

  &:last-child {
    fill: none;
  }
}

.site-pagination__icon svg .pagination-next-fill {
  transition: 0.2s;
}

.site-pagination__icon svg:hover .pagination-next-fill {
  fill: $white;
}

// Block
// ---------------------------------------------------------

.site-pagination__block {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

// Number
// ---------------------------------------------------------

.site-pagination__number {
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-right: 20px;
  font-size: 16px;
  color: $gray-medium-02;
  transition: 0.2s;

  &:hover {
    color: $white;
  }
}

.site-pagination__number {
  color: $gray-medium-02;
  transition: 0.2s;

  &:hover {
    color: $gray-dark-04;
  }
}

.site-pagination__number--active {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  border: 2px solid $free;
  margin-right: 10px;
  color: $free;

  &:hover {
    color: $free;
  }
}

// Pagination
//
// Component for pagination

.pagination {
  display: flex;
  align-items: center;
  justify-content: center;

  input[type="text"] {
    width: 45px;
    margin-right: 10px;
    text-align: center;
  }
}

// Arrow
// ---------------------------------------------------------

.pagination-arrow {
  opacity: 0.5;
  width: 30px;
  height: 30px;
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px;
  border-radius: 100%;
  border: 2px solid $gray-medium-02;

  @media only screen and (min-width: 420px) {
    margin-right: 20px;
  }

  &:last-child {
    margin-right: 0;
    margin-left: 10px;

    @media only screen and (min-width: 420px) {
      margin-left: 20px;
    }
  }
}

// Input
// ---------------------------------------------------------

.pagination input {
  width: 70px;
  margin-right: 10px;

  @media only screen and (min-width: 420px) {
    margin-right: 20px;
  }
}

.checkout {
  .page-title {
    padding-bottom: 80px;
    padding-top: 80px;
    text-align: center;

    h3 {
      font-family: brandon-grotesque, sans-serif;
      line-height: 1;
      font-weight: 500;
      font-size: 35px;
      color: $gray-dark-02;
    }
  }

  .steps-container {
    text-align: center;
  }

  .checkout-steps {
    margin-bottom: 20px;
    list-style: none;
    user-select: none;
    display: inline-block;

    .item-step {
      display: inline-block;
      vertical-align: middle;
      padding-right: 30px;

      @media (min-width: 420px) {
        padding-right: 75px;
      }

      p {
        font-size: 16px;
        cursor: auto;
        color: rgba(77, 158, 210, 0.39);
        margin-bottom: 20px;
      }

      .step-wrapper {
        margin-bottom: 5px;
      }

      &.active {
        transform: translateY(-6px);

        p {
          color: #20a3e7;
          margin-bottom: 0;
        }
      }

      &.inactive {
        position: relative;
        top: 7px;

        .step-wrapper {
          margin-bottom: 10px;
        }

        .step-circle {
          position: relative;
          width: 28px;
          height: 28px;
          border-radius: 50%;
          background: $white;
          color: $white;
          border: 3px solid #bfddee;
          margin: 0 auto;
          font-size: 18px;

          &.active-sm {
            background: #20a3e7;
            border: none;

            svg {
              transform: translateY(-1px);
              width: 15px;
            }
          }
        }
      }

      .step-circle {
        position: relative;
        width: 42px;
        height: 42px;
        border-radius: 50%;
        background: #20a3e7;
        color: $white;
        margin: 0 auto;
        font-size: 18px;

        &:after {
          content: "";
          display: block;
          position: absolute;
          bottom: 50%;
          left: 100%;
          -ms-transform: translateY(2px);
          transform: translateY(2px);
          width: 75px;
          height: 3px;
          background: #bfddee;

          @media (min-width: 420px) {
            width: 130px;
          }
        }
      }

      &:last-child {
        padding-right: 0;

        .step-circle::after {
          display: none;
        }
      }
    }
  }

  .checkout-options {
    font-size: 14px;
    margin-bottom: 40px;
    text-align: center;

    .option-container {
      display: flex;
      align-items: center;
      justify-content: center;

      p {
        color: $gray-medium-03;
      }

      a {
        font-weight: 500;
        padding-left: 5px;
      }

      .italic {
        font-style: italic;
        font-weight: 700;
      }

      .font-normal {
        font-weight: 400;
      }
    }
  }
}

.highlight-light-blue {
  color: #20a3e7;

  &:hover,
  &:focus,
  &:active {
    color: #20a3e7;
  }
}

.checkout-table {
  margin-bottom: 40px;
  max-width: 1100px;
  margin: 0 auto;
  color: #212529 !important;

  th {
    font-weight: 600;
    color: rgba($gray-medium-02, 0.5) !important;
  }

  > thead:first-child > tr:first-child > th {
    border-top: 0;
  }

  > thead > tr > th {
    padding: 20px 20px 20px 5px;
  }

  > tbody > tr > td {
    padding: 18px 10px;
    border: none;
  }

  .semi-bold {
    font-weight: 600;
  }

  .highlight-light-blue {
    font-weight: 500;
    font-size: 12px;
  }

  .checkout-artist-title {
    font-weight: 600;
    font-size: 14px;
    width: 160px;
  }

  .checkout-price-small {
    font-size: 16px;
    font-weight: 600;
  }

  .basket-track-quantity {
    text-align: center;
    border-bottom: 0;
    background-image: url("https://cdn.musicvine.com/images/icons/dropdown.svg");
    background-position: 88% 50%;
    background-repeat: no-repeat;
    background-size: 12px;
    height: 30px;
    line-height: 30px;
    width: 40px;
    appearance: none;
    background-color: $transparent;
    border-style: none;
    outline: none;
  }

  .list-bare {
    font-size: 12px;
    color: $gray-medium-02;
  }

  .remove-track-button {
    margin-right: 10px;

    & svg {
      fill: $gray-medium-02;
      stroke: $gray-medium-02;
    }
  }

  .end-client-container {
    font-weight: 500;
    font-size: 12px;
    color: #75c3b8;
    cursor: pointer;
  }
}

.basket-total-container {
  .checkout-total-container {
    max-width: 300px;
    margin: 0 auto;
    padding-bottom: 30px;
    font-size: 14px;
    padding-top: 0;
    padding-bottom: 60px;

    .sub-total-container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 10px;

      &.discount-code {
        font-size: 12px;
        color: $gray-medium-02;

        .svg-container {
          padding-left: 2px;

          svg circle {
            fill: $gray-medium-03;
          }
        }
      }
    }

    .sub-total-number {
      font-size: 16px;
    }

    .tip-text-label {
      cursor: pointer;

      &:hover {
        color: #20a3e7;
      }
    }

    .total-container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 20px;

      span {
        color: $black;
        font-weight: 600;

        small {
          font-size: 10px;
          font-weight: 400;
        }
      }

      .total-number {
        font-size: 26px;
        color: $black;
        font-family: $font-family-primary;
        font-weight: 400;
      }
    }

    .discount-container {
      display: flex;
      align-items: flex-end;
      flex-direction: column;
      margin-bottom: 10px;

      .highlight-light-bold {
        padding-bottom: 10px;
        color: $gray-dark-02;
        font-weight: 700;
      }

      #discountModal {
        cursor: pointer;
      }

      .sub-text-container {
        span:first-child {
          padding-right: 5px;
          font-size: 12px;
          font-style: italic;
        }

        span:last-child {
          font-size: 12px;
          font-style: italic;
        }

        .highlight-light-blue {
          cursor: pointer;
        }
      }
    }

    .button-container {
      text-align: center;

      .proceed-to-payment-btn {
        background: #20a3e7;
        padding: 12px 0;
      }
    }
  }

  .message-container {
    font-size: 12px;
    margin-bottom: 20px;
    text-align: center;
  }
}

.sub-title-container {
  margin-bottom: 20px;

  .sub-title {
    font-weight: 600;
    font-size: 20px;
    color: $gray-dark-02;
  }
}

.checkout-form-group {
  padding: 20px;
  background: $gray-light-03;
  transition: 0.2s ease;

  label {
    font-weight: 600;
  }

  .yt-advanced-fields .d-flex {
    justify-content: space-between;

    .yt-advanced-off {
      font-size: 11px;
      font-weight: 400;
      margin-top: 22px;
    }
  }

  &label.required:after {
    color: #e17d71;
    font-size: 1rem;
    font-weight: 700;
  }

  &.spacer,
  .spacer {
    margin-bottom: 20px;
  }

  .form-group-lg .form-control {
    height: 46px;
    padding: 10px 16px;
    box-shadow: none;
    font-size: 18px;
    line-height: 1.33333;
  }

  .validate-invalid {
    color: $red;
  }

  .validate-status {
    margin-top: 10px;
    margin-bottom: 5px;
    padding: 0 10px;
    font-weight: 600;
  }

  .vat-section-outer {
    position: relative;

    .form-control {
      padding-right: 122px;
    }

    .validate-group {
      bottom: 0;
      display: block;
      position: absolute;
      right: 0;

      .btn-validate {
        border: 0;
        line-height: 46px;
        margin-bottom: 0;
        padding: 0;
        outline: none;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        height: 46px;
        width: 110px;
        font-size: 13.5px;
        color: $white;
      }

      .btn-validate-active {
        background: #d85647;
      }

      .btn-validate-remove {
        background: $gray-medium-03;
      }

      .btn-validate-disabled {
        background: #e17d71;
        cursor: not-allowed;
      }

      .js-spinner-validate {
        width: 20px;
        height: 20px;
        display: flex;
        align-items: center;

        .search-loading-icon {
          padding: 0;
        }

        &.spinner-icon {
          width: 18px;
          height: 18px;
        }
      }
    }
  }

  .label-prompt {
    font-weight: 700;
    margin-bottom: 0;
    display: inline-block;
  }

  &.payment-form {
    h4 {
      margin-bottom: 20px;
      line-height: inherit;
      font-weight: 700;
      font-size: 1.414rem;
    }

    .pay-with-card-button {
      margin-top: 1.5rem;
    }
  }
}

.row-container {
  margin-bottom: 40px;
}

.client-modal {
  border-radius: 6px;

  .modal-primary__header {
    padding: 30px 40px;

    p {
      padding: 0;
      font-size: 15px;
    }

    @media only screen and (min-width: 768px) {
      padding: 30px 40px;
    }
  }

  .modal-title {
    margin-bottom: 20px;
    margin-top: 35px;
    font-family: brandon-grotesque, sans-serif;
    font-weight: 500;
    font-size: 28px;
    color: $black;
    line-height: 1.42857;
  }

  p.modal-paragraph {
    padding: 0;
    text-align: center;
    font-size: 15px;
    color: $gray-dark-04;
  }

  p.modal-sub-paragraph {
    font-size: 12px;
    margin-bottom: 0;
    padding: 0;
    color: $gray-dark-04;
  }

  hr {
    margin: 20px auto 21px;
    width: 67%;
    border: 0;
    border-top: 1px solid rgba($black, 0.1);
  }

  .modal-secondary-body {
    background: $gray-light-02;
    color: $gray-medium-01;
    padding: 30px 40px;
  }

  .btn-done {
    height: 40px;
    outline: none;
  }

  .form-control::-webkit-input-placeholder {
    color: $gray-medium-02;
    opacity: 0.54;
  }

  .client-container {
    .discount-group {
      position: relative;

      &::after {
        content: attr(data-currency);
        background-image: none;
        width: auto;
        height: auto;
        display: block;
        position: absolute;
        bottom: 50%;
        left: 20px;
        transform: translateY(50%);
      }
    }

    .modal-input[type="number"] {
      line-height: 34px;
      padding: 0;
      -webkit-appearance: none;
      -moz-appearance: textfield;
      text-align: center;

      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
      }
    }

    .track-container {
      margin-bottom: 20px;
      background: $white;
      display: flex;
      align-items: center;

      .track-name-container {
        width: 77.5%;
        padding-left: 15px;
        text-align: left;

        .track-name {
          margin-bottom: 0;
          font-weight: 500;
          font-size: 14px;
          color: $black;
        }

        small {
          font-size: 83.3333%;
        }
      }
    }

    .form-control {
      padding: 20px 0;
      margin-bottom: 20px;
    }

    .btn-default {
      width: 100%;
      height: 40px;
      outline: none;
    }

    .btn-block-bottom {
      margin-bottom: 5px;
    }

    .error-text {
      margin-bottom: 20px;
    }

    .client-label-name {
      font-weight: 600;
      font-size: 14px;
      margin-bottom: 20px;
    }

    .single-form-input {
      padding: 6px 12px;
    }
  }

  .discount-container {
    .form-control {
      padding: 20px 0;
      margin-bottom: 20px;
    }

    .error-text {
      margin-bottom: 20px;
    }
  }

  .questions-container {
    .question-label {
      font-weight: 600;
      font-size: 14px;
      margin-bottom: 20px;
    }

    .checkbox {
      position: relative;
      display: block;
      margin-top: 10px;
      margin-bottom: 10px;

      label {
        min-height: 20px;
        padding-left: 20px;
        margin-bottom: 0;
        font-weight: 400;
        cursor: pointer;
      }

      .checkbox-input {
        position: absolute;
        margin-left: -20px;
        margin-top: 3px;
        -webkit-appearance: checkbox;
        -moz-appearance: checkbox;
      }
    }
  }
}

.basket-panel {
  width: 100%;
  padding: 10px;
  position: sticky;
  top: 80px;
  background: $gray-light-03;

  .basket-panel-group {
    padding: 20px;
    background: $white;
    transition: 0.2s ease;

    .track-container {
      display: flex;
      margin-bottom: 20px;

      .track-name {
        h5 {
          font-size: 1rem;
        }

        p {
          font-size: 10px;
        }
      }
    }

    .link-container {
      display: flex;
      flex-direction: column;
      align-items: flex-end;

      a {
        font-weight: 700;
        font-size: 13px;
      }
    }
  }

  .basket-item-container {
    display: flex;
    flex-direction: column;
    padding: 0 10px;
    margin-top: 20px;

    .basket-item {
      padding: 10px 0;
      border-bottom: 1px solid $gray-light-01;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 13px;

      &:last-child {
        border-bottom: none;
      }
    }

    .total-number {
      font-size: 26px;
      color: $black;
    }
  }
}

.basket-empty {
  font-family: $font-family-primary;
  font-size: 2.827rem;
  font-weight: 400;
  padding-bottom: 70px;
}

.checkout-purchase-order {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  padding-bottom: 30px;

  .order-error-message {
    margin-top: 30px;
  }

  .checkout-purchase-order-group {
    margin-bottom: 10px;
    padding-top: 20px;
    font-size: 20px;

    .checkout-purchase-order-text {
      color: $gray-dark-02;
    }
  }

  .checkout-purchase-date {
    font-size: 16px;
    margin-bottom: 30px;
  }

  .download-button-container a {
    display: inline-block;
    line-height: 33px;
    text-align: center;
  }
}

.checkout-purchase-table {
  width: 100%;
  margin-bottom: 1rem;
  color: #212529 !important;
  font-family: brandon-grotesque, sans-serif !important;

  thead tr {
    background: $white;

    th {
      border-top: 0;
      padding: 20px 20px 20px 5px;
    }
  }

  tbody {
    .checkout-purchase-license {
      color: #3a9ecf;
      display: block;
      font-size: 12px;
    }

    tr {
      background: rgba($gray-light-02, 0.5);
    }

    td {
      padding: 18px 10px 18px 5px;
      vertical-align: middle;
    }

    .license-history-track a {
      max-width: 185px;
      display: inline-block;
      top: 50%;
      position: absolute;
      -ms-transform: translateY(-50%);
      transform: translateY(-50%);
      line-height: 18px;
    }

    .checkout-purchase-license-btn {
      background: none;
      color: #20a3e7;
      border: 1px solid #20a3e7;
      font-family: $font-family-primary;
      font-size: 14px;

      &:hover,
      &:focus,
      &:active {
        color: $white;
        background-color: $free;
        border-color: #269abc;
      }
    }
  }
}

.bold {
  font-weight: 700;
}

.checkout-purchase-table-mobile-details {
  hr {
    margin: 2rem auto;
    width: 80%;
    border: 0;
    border-top: 1px solid rgba($black, 0.1);
  }

  p {
    margin-bottom: 0.5rem;
  }

  .checkout-purchase-license-btn {
    background: none;
    color: #20a3e7;
    border: 1px solid #20a3e7;
  }

  select.basket-track-quantity {
    background-color: $gray-light-03;
    border-radius: 2px;
    font-size: 16px;
    padding: 3px 10px;
    width: 100%;
    border-style: none;
  }

  .remove-track-button {
    cursor: pointer;
    border: none;
    padding: 6px 12px;
    background: #20a3e7;
    color: $white;
  }

  .checkout-price-small {
    font-size: 16px;
    font-weight: 600;
    color: $gray-dark-02;
  }

  .end-client-container {
    font-weight: 500;
    font-size: 12px;
    color: #75c3b8;
    cursor: pointer;
  }
}

.checkout-purchase-download {
  font-size: 20px;
  color: $gray-dark-02;
  margin-bottom: 60px;

  .highlight-light-pink {
    color: $uppbeat;
  }
}

.payment-order .payment-pending-order-container {
  text-align: center;
  padding: 0 20% 10%;

  .main-title-data-featured {
    font-weight: 500;
    font-size: 35px;
    color: $gray-dark-02;
    font-family: brandon-grotesque, sans-serif;
    margin-top: 80px;
    margin-bottom: 20px;
  }

  .confirmation_delay_msg {
    margin-bottom: 20px;
    font-size: 1.4375rem;
  }

  .confirmation_delay_message {
    .alert {
      padding: 15px;
      margin-bottom: 20px;
      border: 1px solid $transparent;
      border-radius: 4px;
    }

    p {
      margin-bottom: 20px;
    }

    .alert-warning {
      background-color: #fcf8e3;
      border-color: #faebcc;
      color: #8a6d3b;

      p {
        margin-bottom: 20px;
      }
    }
  }

  .confirmation_pending_msg {
    margin-bottom: 20px;
    font-family: $font-family-primary;
    font-size: 13px;
  }
}

.button-loader {
  color: $black;
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px;

  div {
    transform-origin: 32px 32px;
    animation: c 1.2s linear infinite;

    &:after {
      content: " ";
      display: block;
      position: absolute;
      top: 3px;
      left: 29px;
      width: 5px;
      height: 14px;
      border-radius: 20%;
      background: $black;
    }

    &:first-child {
      transform: rotate(0deg);
      animation-delay: -1.1s;
    }

    &:nth-child(2) {
      transform: rotate(30deg);
      animation-delay: -1s;
    }

    &:nth-child(3) {
      transform: rotate(60deg);
      animation-delay: -0.9s;
    }

    &:nth-child(4) {
      transform: rotate(90deg);
      animation-delay: -0.8s;
    }

    &:nth-child(5) {
      transform: rotate(120deg);
      animation-delay: -0.7s;
    }

    &:nth-child(6) {
      transform: rotate(150deg);
      animation-delay: -0.6s;
    }

    &:nth-child(7) {
      transform: rotate(180deg);
      animation-delay: -0.5s;
    }

    &:nth-child(8) {
      -ms-transform: rotate(210deg);
      transform: rotate(210deg);
      animation-delay: -0.2s;
    }

    &:nth-child(9) {
      transform: rotate(240deg);
      animation-delay: -0.2s;
    }

    &:nth-child(10) {
      transform: rotate(270deg);
      animation-delay: -0.2s;
    }

    &:nth-child(11) {
      transform: rotate(300deg);
      animation-delay: -0.2s;
    }

    &:nth-child(12) {
      transform: rotate(330deg);
      animation-delay: 0s;
    }
  }
}

@keyframes c {
  0% {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

.pay-with-paypal-button {
  background: none;
  outline: none !important;
  cursor: pointer;

  .button-loader {
    display: none;
  }

  img {
    max-width: 100px;
  }

  &:disabled img {
    opacity: 0.5;
  }

  &.button-pushed {
    padding-right: 30px;

    .button-loader {
      display: inline-block;
    }
  }

  .button-loader {
    position: absolute;
    right: 0;
    transform: scale(0.5) translate(37px, -37px);
  }
}

.order-detail-container {
  padding-top: 20px;

  .page-title {
    margin-top: 40px;
    margin-bottom: 20px;
  }

  .order-details-breadcrumbs .svg-container {
    margin: 0 3px;
  }
}

.order-border-top {
  border-top: 2px solid $gray-light-02;
}

.validate-invalid {
  color: $red;
}

.validate-valid {
  color: $green;
}
