.ub-modal {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;

	.ub-modal-google-wrapper {
		align-items: center;
		display: flex;
		justify-content: center;
		width: 100%;

		> div {
			width: 100%;
			height: 2.5rem;

			> div > div {
				width: 100%;
			}
		}

		> div > div > div > div > div > div {
			// transform: scale(0.8);
			border-radius: 50%;
			// margin-inline-start: -0.25rem !important;

			+ span,
			+ span + span {
				// position: absolute;
				width: 100%;
				// font-family: $font-family-primary;
				font-weight: 500;
				font-size: 0.875rem;
				// margin-inline-start: 5rem;
			}
		}
	}

	.ub-modal-header {
		display: flex;
		justify-content: space-between;
		align-items: center;

		.ub-modal-header_tabs {
			height: 4rem;
			width: 100%;
			display: flex;

			.ub-modal-header_tab {
				position: relative;
				display: flex;
				justify-content: center;
				align-items: center;
				height: 4rem;

				&.active {
					@include bg(--modal-single-tab-bg);
					@include css4 {
						box-shadow: 0 1px 0 0 cvar(--modal-single-tab-bg);
					}

					&:first-child {
						padding: 1rem 0 1rem 1rem;
						z-index: 1;

						&::after {
							content: '';
							display: block;
							width: 100%;
							height: 100%;
							transform: translateX(24px) skewX(15deg);
							position: absolute;
							top: 0;
							left: 0;
							border-radius: 0 0.75rem 0 0;
							@include bg(--modal-single-tab-bg);
							@include css4 {
								box-shadow: 0 1px 0 0 cvar(--modal-single-tab-bg);
							}
						}
					}

					&:nth-child(2) {
						cursor: pointer;
						flex-grow: 1;
						justify-content: flex-start;
						padding: 1rem 1rem 1rem 0;

						&::before {
							content: '';
							display: block;
							width: 4rem;
							height: 100%;
							transform: translateX(-24px) skewX(-15deg);
							position: absolute;
							top: 0;
							left: 0;
							border-radius: 0.75rem 0 0 0;
							@include bg(--modal-single-tab-bg);
							@include css4 {
								box-shadow: 0 1px 0 0 cvar(--modal-single-tab-bg);
							}
						}
					}

					span {
						@include c(--h2-c);
					}
				}

				&.inactive {
					cursor: pointer;
					@include bg(--modal-tab-bg);
					@include css4 {
						box-shadow: 0 1px 0 0 cvar(---modal-tab-bg);
					}

					@media (hover) {
						&:hover {
							@include bg(--modal-tab-hover-bg);
							@include css4 {
								box-shadow: 0 1px 0 0 cvar(---modal-tab-hover-bg);
							}
						}
					}

					&:first-child {
						padding: 1rem 3rem 1rem 1rem;
						@include c(--modal-tab-c);
					}

					&:nth-child(2) {
						padding-inline-start: 3rem;
						flex-grow: 1;
						justify-content: flex-start;
					}

					span {
						@include c(--modal-tab-c);
					}
				}

				span {
					z-index: 1;
					position: relative;
					font-weight: 600;
					font-size: 0.875rem;
					display: flex;
					justify-content: center;
					align-items: center;
					gap: 0.25rem;
					line-height: 1;

					svg {
						display: block;
						height: 0.875rem;
						width: 0.875rem;
					}

					img {
						height: 2rem;
						width: auto;

						&.premium {
							width: 0.875rem;
							height: 0.875rem;
						}

						&.free {
							width: 1rem;
							height: 1rem;
						}
					}
				}
			}
		}

		> .ub-modal-header_tab {
			width: 100%;
			height: 4rem;
			display: flex;
			align-items: center;
			padding: 1rem;
			@include bg(--modal-single-tab-bg);

			span {
				z-index: 1;
				position: relative;
				font-weight: 600;
				font-size: 0.875rem;
				display: flex;
				justify-content: center;
				align-items: center;
				gap: 0.25rem;
				@include c(--h2-c);

				svg {
					display: block;
					height: 0.875rem;
					width: 0.875rem;
				}

				img {
					height: 2rem;
					width: auto;

					&.premium {
						width: 0.875rem;
						height: 0.875rem;
					}

					&.free {
						width: 1rem;
						height: 1rem;
					}

					&.uppbeat-logo {
						transform: scale(1.1) translate(6px, 0);
					}
				}
			}
		}

		.ub-btn {
			position: absolute;
			top: 0.75rem;
			right: 0.5rem;
		}

		.ub-progress-bar-container {
			width: 100%;
			height: 0.25rem;
			background-color: $gray-light-02;
			@include bg(--modal-progress-bar-container-bg);

			.ub-progress-bar {
				width: 0;
				height: 0.25rem;
				transition: width 0.3s;
				@include bg(--modal-progress-bar-bg);
			}
		}

		+ div {
			flex-grow: 1;
			overflow-y: auto;
			overflow-x: hidden;
		}
	}

	.ub-modal-container-wrapper {
		display: flex;
		@include bg(--modal-bg);

		.ub-modal-btn-wrapper {
			margin-top: 16px;

			.ub-btn-label {
				font-size: 16px;
			}
		}

		&.ub-modal-container-wrapper-1 {
			width: 100%;
			display: block;
		}

		&.ub-modal-container-wrapper-2 {
			width: 200%;
			transition: transform 0.2s;

			&.modal-1 {
				transform: translateX(0);

				> div:not(:first-child) {
					visibility: hidden;
				}
			}

			&.modal-2 {
				transform: translateX(-50%);

				> div:not(:nth-child(2)) {
					visibility: hidden;
				}
			}
		}

		&.ub-modal-container-wrapper-3 {
			width: 300%;
			transition: transform 0.2s;

			&.modal-1 {
				transform: translateX(0);

				> div:not(:first-child) {
					visibility: hidden;
				}
			}

			&.modal-2 {
				transform: translateX(-33.333%);

				> div:not(:nth-child(2)) {
					visibility: hidden;
				}
			}

			&.modal-3 {
				transform: translateX(-66.666%);

				> div:not(:nth-child(3)) {
					visibility: hidden;
				}
			}
		}

		&.ub-modal-container-wrapper-4 {
			width: 400%;
			transition: transform 0.2s;

			&.modal-1 {
				transform: translateX(0);

				> div:not(:first-child) {
					visibility: hidden;
				}
			}

			&.modal-2 {
				transform: translateX(-25%);

				> div:not(:nth-child(2)) {
					visibility: hidden;
				}
			}

			&.modal-3 {
				transform: translateX(-50%);

				> div:not(:nth-child(3)) {
					visibility: hidden;
				}
			}

			&.modal-4 {
				transform: translateX(-75%);

				> div:not(:nth-child(4)) {
					visibility: hidden;
				}
			}
		}

		&.ub-modal-container-wrapper-5 {
			width: 500%;
			transition: transform 0.2s;

			&.modal-1 {
				transform: translateX(0);

				> div:not(:first-child) {
					visibility: hidden;
				}
			}

			&.modal-2 {
				transform: translateX(-20%);

				> div:not(:nth-child(2)) {
					visibility: hidden;
				}
			}

			&.modal-3 {
				transform: translateX(-40%);

				> div:not(:nth-child(3)) {
					visibility: hidden;
				}
			}

			&.modal-4 {
				transform: translateX(-60%);

				> div:not(:nth-child(4)) {
					visibility: hidden;
				}
			}

			&.modal-5 {
				transform: translateX(-80%);

				> div:not(:nth-child(5)) {
					visibility: hidden;
				}
			}
		}

		&.ub-modal-container-wrapper-6 {
			width: 600%;
			transition: transform 0.2s;

			&.modal-1 {
				transform: translateX(0);

				> div:not(:first-child) {
					visibility: hidden;
				}
			}

			&.modal-2 {
				transform: translateX(-16.666%);

				> div:not(:nth-child(2)) {
					visibility: hidden;
				}
			}

			&.modal-3 {
				transform: translateX(-33.333%);

				> div:not(:nth-child(3)) {
					visibility: hidden;
				}
			}

			&.modal-4 {
				transform: translateX(-50%);

				> div:not(:nth-child(4)) {
					visibility: hidden;
				}
			}

			&.modal-5 {
				transform: translateX(-66.666%);

				> div:not(:nth-child(5)) {
					visibility: hidden;
				}
			}

			&.modal-6 {
				transform: translateX(-83.333%);

				> div:not(:nth-child(6)) {
					visibility: hidden;
				}
			}
		}

		&.ub-modal-container-wrapper-7 {
			width: 700%;
			transition: transform 0.2s;

			&.modal-1 {
				transform: translateX(0);

				> div:not(:first-child) {
					visibility: hidden;
				}
			}

			&.modal-2 {
				transform: translateX(-14.286%);

				> div:not(:nth-child(2)) {
					visibility: hidden;
				}
			}

			&.modal-3 {
				transform: translateX(-28.571%);

				> div:not(:nth-child(3)) {
					visibility: hidden;
				}
			}

			&.modal-4 {
				transform: translateX(-42.857%);

				> div:not(:nth-child(4)) {
					visibility: hidden;
				}
			}

			&.modal-5 {
				transform: translateX(-57.143%);

				> div:not(:nth-child(5)) {
					visibility: hidden;
				}
			}

			&.modal-6 {
				transform: translateX(-71.429%);

				> div:not(:nth-child(6)) {
					visibility: hidden;
				}
			}

			&.modal-7 {
				transform: translateX(-85.714%);

				> div:not(:nth-child(7)) {
					visibility: hidden;
				}
			}
		}

		&.ub-modal-container-wrapper-8 {
			width: 800%;
			transition: transform 0.2s;

			&.modal-1 {
				transform: translateX(0);

				> div:not(:first-child) {
					visibility: hidden;
				}
			}

			&.modal-2 {
				transform: translateX(-12.5%);

				> div:not(:nth-child(2)) {
					visibility: hidden;
				}
			}

			&.modal-3 {
				transform: translateX(-25%);

				> div:not(:nth-child(3)) {
					visibility: hidden;
				}
			}

			&.modal-4 {
				transform: translateX(-37.5%);

				> div:not(:nth-child(4)) {
					visibility: hidden;
				}
			}

			&.modal-5 {
				transform: translateX(-50%);

				> div:not(:nth-child(5)) {
					visibility: hidden;
				}
			}

			&.modal-6 {
				transform: translateX(-62.5%);

				> div:not(:nth-child(6)) {
					visibility: hidden;
				}
			}

			&.modal-7 {
				transform: translateX(-75%);

				> div:not(:nth-child(7)) {
					visibility: hidden;
				}
			}

			&.modal-8 {
				transform: translateX(-87.5%);

				> div:not(:nth-child(8)) {
					visibility: hidden;
				}
			}
		}

		&.ub-modal-container-wrapper-9 {
			width: 900%;
			transition: transform 0.2s;

			&.modal-1 {
				transform: translateX(0);

				> div:not(:first-child) {
					visibility: hidden;
				}
			}

			&.modal-2 {
				transform: translateX(-11.111%);

				> div:not(:nth-child(2)) {
					visibility: hidden;
				}
			}

			&.modal-3 {
				transform: translateX(-22.222%);

				> div:not(:nth-child(3)) {
					visibility: hidden;
				}
			}

			&.modal-4 {
				transform: translateX(-33.333%);

				> div:not(:nth-child(4)) {
					visibility: hidden;
				}
			}

			&.modal-5 {
				transform: translateX(-44.444%);

				> div:not(:nth-child(5)) {
					visibility: hidden;
				}
			}

			&.modal-6 {
				transform: translateX(-55.555%);

				> div:not(:nth-child(6)) {
					visibility: hidden;
				}
			}

			&.modal-7 {
				transform: translateX(-66.666%);

				> div:not(:nth-child(7)) {
					visibility: hidden;
				}
			}

			&.modal-8 {
				transform: translateX(-77.777%);

				> div:not(:nth-child(8)) {
					visibility: hidden;
				}
			}

			&.modal-9 {
				transform: translateX(-88.888%);

				> div:not(:nth-child(9)) {
					visibility: hidden;
				}
			}
		}

		&.ub-modal-container-wrapper-10 {
			width: 1000%;
			transition: transform 0.2s;

			&.modal-1 {
				transform: translateX(0);

				> div:not(:first-child) {
					visibility: hidden;
				}
			}

			&.modal-2 {
				transform: translateX(-10%);

				> div:not(:nth-child(2)) {
					visibility: hidden;
				}
			}

			&.modal-3 {
				transform: translateX(-20%);

				> div:not(:nth-child(3)) {
					visibility: hidden;
				}
			}

			&.modal-4 {
				transform: translateX(-30%);

				> div:not(:nth-child(4)) {
					visibility: hidden;
				}
			}

			&.modal-5 {
				transform: translateX(-40%);

				> div:not(:nth-child(5)) {
					visibility: hidden;
				}
			}

			&.modal-6 {
				transform: translateX(-50%);

				> div:not(:nth-child(6)) {
					visibility: hidden;
				}
			}

			&.modal-7 {
				transform: translateX(-60%);

				> div:not(:nth-child(7)) {
					visibility: hidden;
				}
			}

			&.modal-8 {
				transform: translateX(-70%);

				> div:not(:nth-child(8)) {
					visibility: hidden;
				}
			}

			&.modal-9 {
				transform: translateX(-80%);

				> div:not(:nth-child(9)) {
					visibility: hidden;
				}
			}

			&.modal-10 {
				transform: translateX(-90%);

				> div:not(:nth-child(10)) {
					visibility: hidden;
				}
			}
		}

		.ub-modal-container {
			padding: 3rem 1.5rem;
			overflow-x: hidden;

			.ub-modal-title-faded {
				margin-block-end: 1.5rem;
				@include c(--modal-title-faded-c);

				&.success {
					@include c(--modal-title-faded-success-c);

					img {
						margin-inline-end: 0.5rem;
					}
				}
			}

			.ub-modal-title {
				margin-block-end: 1.5rem;

				&.large {
					font-size: 1.75rem;

					.ub-modal-premium-star {
						margin-block-start: -0.625rem;
						margin-inline-end: 0.25rem;
    					height: 1.5rem;
					}
				}

				~ .ub-modal-text {
					margin-block-end: 1rem;

					+ .ub-btn {
						margin-block-start: 2rem;
					}
				}

				> .gray {
					font-size: 1rem;
					@include c(--modal-title-faded-c);
				}

				.ub-modal-title-small {
					font-size: 0.875rem;
				}

				&.deal-title {
					text-transform: capitalize;
					font-size: 1.25rem;
					margin-block-start: -0.5rem;

					img {
						max-height: 1.5rem;
						height: 1.5rem;
						width: 1.5rem;
						max-width: 1.5rem;
					}
				}

				span.premium {
					color: $uppbeat
				}

				span.business {
					color: $business;
					filter: brightness(0.95);
				}
			}

			.ub-modal-limited-offer {
				font-family: $font-family-secondary;
				font-style: italic;
				font-weight: 700;
				text-transform: uppercase;
				margin-block-end: 0.5rem;
				@include c(--modal-limited-offer-c);

				img {
					margin-right: 5px;
					margin-top: -3px;
					max-width: 30px;
				}

				+ .ub-modal-title {
					margin-block-end: 1rem;
				}
			}

			.ub-modal-banner {
				margin-block-end: 1.75rem;
				cursor: pointer;
			}

			.ub-modal-notes ul {
				list-style: none;
				margin-block-end: 1.5rem;

				li:not(:last-child) {
					margin: 0 0 0.5rem;
				}

				li {
					svg {
						margin-block-start: -1px;
						margin-inline-end: 0.125rem;

						path {
							fill: $green;
						}
					}
				}
			}

			.ub-modal-premium-pricing {
				font-weight: $font-weight-medium;
				font-size: 0.875rem;
				margin-block-start: 1.25rem;

				p {
					margin-block-end: 1.5rem;
				}

				.currency-tag {
					font-size: 1.25rem;
					font-family: $font-family-heading;
					display: inline-block;
					top: -1.25rem;
					@include c(--h1-c);
				}

				.price-tag {
					margin-inline-end: 0.25rem;
					font-size: 3rem;
					font-family: $font-family-heading;
					line-height: 1;
					@include c(--h1-c);

					sup {
						font-size: 1.25rem;
						top: -1.25rem;
						left: -0.125rem;
					}
				}

				.month-tag {
          margin: 0 0.25rem;
          font-size: 0.8125rem;
          font-family: $font-family-secondary;
          @include c(--h1-c);
        }

        .cancel-tag {
          font-size: 0.8125rem;
          font-family: $font-family-secondary;
          font-weight: 400;
          @include c(--main-secondary-c);
        }

				.ub-btn {
					margin-block-end: 0;
				}
			}

			hr {
				border: none;
				margin-block-end: 1.5rem;
				@include css4 {
					border-bottom: 1px solid cvar(--hr);
				}
			}

			.premium-checkout_payment-details {
				display: flex;
				justify-content: space-between;
				padding-block: 0.75rem;

				p {
					position: relative;;
					margin-inline-end: 2.375rem;
				}

				.currency-tag {
					font-size: 1rem;
					vertical-align: text-bottom;
					@include c(--p-c);
				}

				.placeholder-text {
					font-size: 0.9rem;
				}

				.pricing-tag {
					font-family: $font-family-heading;
					font-size: 3.375rem;
					line-height: 1;
					position: relative;
					@include c(--h1-c);

					sup {
						font-size: 1rem;
						top: 1rem;
						position: absolute;
						white-space: nowrap;
					}

					+ .currency-tag {
						font-size: 0.75rem;
						padding: 0.25rem 0.5rem;
						border-radius: 1rem;
						position: absolute;
						white-space: nowrap;
						bottom: 0.375rem;
						left: 100%;
						@include c(--p-c);
					}
				}

				+ .modal-primary__recaptcha {
					margin-block-end: 0.5rem;
				}
			}

			.ub-modal-content {
				width: 100%;
				height: auto;
				display: flex;
				flex-direction: column;

				&.ub-modal-2-step {
					width: calc(200% + 1.5rem);
					flex-direction: row;
					justify-content: space-between;
					transition: transform 0.2s;

					&.step-1 > div:not(:first-child) {
						visibility: hidden;
					}

					&.step-2 > div:not(:nth-child(2)) {
						visibility: hidden;
					}

					> div {
						width: calc(50% - 0.75rem);
						display: flex;
						flex-direction: column;

						.ub-modal-pill-wrapper {
							display: flex;
							flex-direction: row;
							flex-wrap: wrap;
							align-items: flex-start;
							margin-block-end: 2rem;

							.ub-btn {
								margin-inline-end: 0.5rem;
								margin-block-end: 0.5rem;

								.ub-btn-label {
									font-weight: 400;
								}
							}
						}

						.ub-btn {
							margin-block-start: auto;
						}
					}

					&:not(.upsell-quickLogin):not(.upsell-notquickLogin):not(.upsell-quick-pricing-page):not(.step-0):not(.step-1) {
						transform: translateX(calc(-50% - 0.75rem));
					}

					&.upsell-quickLogin {
						&.step-4 {
							transform: translateX(0);
						}
						&.step-5 {
							transform: translateX(calc(-50% - 0.75rem));
						}
					}

					&.upsell-notquickLogin {
						&.step-3 {
							transform: translateX(0);
						}
						&.step-4 {
							transform: translateX(calc(-50% - 0.75rem));
						}
					}

					&.upsell-quick-pricing-page {
						&.step-2 {
							transform: translateX(0);
						}
						&.step-3 {
							transform: translateX(calc(-50% - 0.75rem));
						}
					}
				}
			}

			.ub-btn {
				margin-block-end: 1rem;
			}

			.ub-modal-btn-wrapper {
				display: flex;
				flex-wrap: nowrap;
				margin-block-end: 1rem;

				.ub-btn {
					margin-block-end: 0;

					&:not(:last-child) {
						margin-inline-end: 0.5rem;
					}
				}
			}

			&.ub-modal-upsell-account .ub-modal-btn-wrapper,
			&.ub-modal-upsell-business .ub-modal-btn-wrapper {
				margin: 0;
			}

			&.ub-modal-upsell-business {
				.ub-modal-title span {
					color: $business;
					filter: brightness(0.95);
				}
				h4 {
					font-weight: 600;
					@include c(--h2-c);

					+ .ub-modal-notes {
						margin-block-start: 1.5rem;
					}
				}
			}

			.premium-checkout_subscribe .ub-btn:last-child {
				margin-block-end: 0;
			}

			.ub-modal-question {
				padding: 1.5rem 1rem;
				border-radius: 0.5rem;
				margin-block: 1.5rem;
				@include css4 {
					border: 2px dashed cvar(--main-br);
				}

				h3 {
					margin-block-end: 0.375rem;

					+ .textarea {
						margin-block-start: 1rem;
					}
				}

				p {
					margin-block-end: 0.75rem;
				}

				.ub-btn {
					margin-block-end: 0.5rem;

					&:last-child {
						margin: 0;
					}

					.ub-btn-label {
						margin-inline-end: auto;
					}

					.check {
						position: absolute;
						width: 1rem;
						height: 1rem;
						border-radius: 50%;
						right: 0.5rem;
						@include bg(--button-success-c);

						i {
							width: 1rem;
							height: 1rem;
							padding: 0.25rem;
							display: flex;

							path {
								@include f(	--button-success-hover-bg);
							}
						}
					}

					&.light-youtube img {
						filter: brightness(0.15);
					}
				}

				hr {
					margin-block: 1rem;
				}
			}

			&.before-you-go {
				> p {
					margin-block-end: 1.5rem;
				}

				> div {
					margin-block-end: 1.5rem;

					h3 {
						margin-block-end: 0.75rem;
					}
				}
			}

			&.confirm-cancel {
				> p {
					margin-block-end: 1.5rem;
				}
				> h3 {
					margin-block-end: 1rem;
				}
			}
		}

		.ub-modal-or-line {
			margin: 1.5rem 0 2.5rem;
			width: 100%;
			text-align: center;
			line-height: 0.1rem;
			@include css4 {
				border-bottom: 1px solid cvar(--main-br);
			}

			.ub-modal-or-text {
				padding: 0 0.625rem;
				font-weight: 600;
				@include c(--h2-c);
				@include bg(--modal-bg);
			}
		}

		.ub-modal-forgot-password-text {
			font-size: 0.75rem;
			font-weight: 400;
			margin: 0.5rem 0 2rem;
			cursor: pointer;
			text-align: right;
			font-family: $font-family-secondary;
			@include c(--link-c);
		}

		.alert-general-error.spacer.login-error {
			margin-block-start: 1rem;
		}

		.ub-modal-forgot-password-inputs {
			.input-wrapper {
				margin-block-end: 1rem;
			}
		}

		&.ub-modal-show-email {
			.input-wrapper,
			.alert-general-error {
				margin-block-end: 1rem;
			}
		}
	}

	.input-wrapper .input + div {
		display: none;
	}

	.ub-modal-pill-wrapper .ub-modal-btn-wrapper {
		margin-block-start: 1.5rem;
	}
}

.input-wrapper.whitelist-input-wrapper {
	margin-block-end: 0.5rem;

	.ub-modal-whitelist-input-results {
		list-style: none;
		overflow: hidden;
		margin-block-start: 0.5rem;
		border-radius: 0.625rem;
		@include bg(--whitelist-bg);
		@include css4 {
			box-shadow: 0 0 8px cvar(--shadow-dark);
		}

		li {
			display: flex;
			cursor: pointer;

			&:hover {
				@include bg(--whitelist-hover-bg);
			}

			> div {
				display: flex;
				flex-direction: column;
				justify-content: center;
				padding: 0.5rem;

				&:first-child {
					align-items: center;

					img {
						min-width: 3rem;
						width: 3rem;
						height: 3rem;
						border-radius: 50%;
					}
				}

				&:last-child {
					align-items: start;

					p {
						margin: 0;

						&:first-child {
							font-weight: 600;
							font-size: 0.875rem;
							overflow: hidden;
							text-overflow: ellipsis;
							white-space: nowrap;
							@include c(--h2-c);
						}

						&:last-child {
							font-size: 0.75rem;
							@include c(--p-c);
						}
					}
				}
			}
		}
	}

	.ub-modal-whitelist-input-channel {
		display: flex;
		margin-block-start: 0.5rem;
		border-radius: 0.625rem;
		@include bg(--whitelist-bg);
		@include css4 {
			box-shadow: 0 0 8px cvar(--shadow-dark);
		}

		> div {
			display: flex;
			flex-direction: column;
			justify-content: center;
			padding: 0.5rem;
			align-items: center;

			&:first-child img {
				min-width: 3rem;
				width: 3rem;
				height: 3rem;
				border-radius: 50%;
			}

			&:nth-child(2) {
				align-items: start;
				margin-inline-end: auto;
				overflow: hidden;

				p {
					overflow: hidden;
					text-overflow: ellipsis;
					white-space: nowrap;
					max-width: 100%;
					font-size: 0.75rem;
					@include c(--p-c);

					a span {
						font-weight: 600;
						font-size: 0.875rem;
						@include c(--h2-c);
					}
				}
			}

			.ub-btn {
				margin: 0;
			}
		}
	}
}


.ub-modal-toggle-options-wrapper {
  width: 100%;
  display: flex;
  position: relative;
  margin-block-end: 1rem;
	margin-top: 1rem;

  input {
    position: absolute;
    left: 0;
    top: 0;
    min-width: 1em;
    width: 2rem;
    height: 100%;
    z-index: 2;
    opacity: 0;
    margin: 0;
    padding: 0;
    cursor: pointer;

    &:checked + .ub-modal-toggle-options {
      svg {
        @include bg(--checkbox-active-bg);
        @include css4 {
          border-color: cvar(--checkbox-active-br);
        }

        path {
          @include css4 {
            stroke: cvar(--checkbox-fill) !important;
            fill: cvar(--checkbox-fill) !important;
          }
        }
      }
    }
  }

  .ub-modal-toggle-options {
    position: relative;

    svg {
      position: absolute;
      top: 50%;
      width: 1rem;
      height: 1rem;
      transform: translateY(-50%);
      border-radius: 0.125rem;
      @include bg(--checkbox-bg);
      @include css4 {
        border: 1px solid cvar(--checkbox-br);
      }

      path {
        @include css4 {
          stroke: cvar(--checkbox-bg) !important;
          fill: cvar(--checkbox-bg) !important;
        }
      }
    }

    &.error svg {
      @include css4 {
        border: 1px solid cvar(--checkbox-error-br);
        box-shadow: 0 0 4px cvar(--checkbox-error-shadow);
      }
    }

    .ub-modal-toggle-options-text {
      padding-inline-start: 2rem;
      margin-block-end: 0;
    }
  }
}

.ub-modal-video {
  margin-block-end: 1rem;
}

.ub-modal-text {
  font-size: 0.8125rem;
  font-family: $font-family-secondary;
  @include c(--p-c);

  &.centered {
    text-align: center;
    font-size: 0.75rem;
  }

  a, span {
    cursor: pointer;
    @include c(--main-brand-c);
  }

  &.checkbox-text {
    font-size: 0.75rem;
  }
}

.ub-modal-account-inputs {
  margin-block-end: 1rem;
}

.ub-whitelist {
  margin-block-end: 1.5rem;

  .ub-whitelist-channel .ub-btn {
    margin: 0;
  }

  .ub-whitelist-channel-id {
    input {
      width: 100%;
    }

    .ub-whitelist-channel-id-confirm .ub-btn {
      padding: 0 1rem!important;
      width: min-content;
    }
  }

  .ub-track-tooltip {
    right: 0 !important;
    max-width: 200px;
    transform: none !important;

    &::after {
      right: 2.5rem !important
    }
  }
}

.ub-modal-toggle {
  padding: 0.5rem;
  border-radius: 0.75rem;
  @include bg(--modal-toggle-option-bg);

  &.ub-modal-pricing-toggle .ub-modal-toggle-option {
    height: 4rem;

    &.ab-test {
      border-radius: 0.75rem !important;
    }
  }

  @media (hover) {
    &:hover {
      @include bg(--modal-toggle-option-hover-bg);

      .ub-modal-toggle-option:not(.active) {
        @include bg(--modal-toggle-option-hover-bg);
      }
    }
  }

  .ub-modal-toggle-option {
    position: relative;
    margin: 0;
    padding: 0 0.6125rem;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 0;
    z-index: 0;
    border-radius: 0.5rem;
    cursor: pointer;
    @include bg(--modal-toggle-option-bg);

    &.active {
      z-index: 1;
      cursor: none;
      pointer-events: none;
      @include bg(--modal-toggle-option-active-bg);
      box-shadow: 0 0 12px rgba($black, 0.05);
    }

    > div:first-child {
      display: flex;
      align-items: center;

      > div {
        display: flex;
        flex-direction: column;
      }
    }

    .checkbox-round {
      margin-right: 6px;
      width: 1.25rem;
      height: 1.25rem;
      border-radius: 50%;
      vertical-align: middle;
      appearance: none;
      outline: none;
      cursor: pointer;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;

      &:before {
        background-image: url("https://cdn.uppbeat.io/images/icons/UppBeat_Icons_Checkout-Unselected.svg");
        background-size: contain;
        content: "";
        height: 16px;
        width: 16px;
        display: inline-block;
        position: absolute;
      }
    }

    .checkbox-round:checked::before {
      background-image: url("https://cdn.uppbeat.io/images/icons/UppBeat_Icons_Checkout-Selected.svg");
    }

    .duration_tag {
      font-weight: 500;
      @include c(--h2-c);
    }

    .cancel-tag {
      font-size: 0.6875rem;
      font-weight: 500;
      @include c(--p-c);
    }

    .discount_tag {
      font-size: 0.6875rem;
      font-weight: 500;
      @include c(--discount-chip-bg);
    }

    .pricing-tag {
      font-family: $font-family-heading;
      margin-right: 3px;
      font-size: 1.5rem;
      @include c(--h2-c);

      sup {
        font-size: 0.875rem;
      }
    }

    .currency-tag {
      font-family: $font-family-heading;
      margin-right: 2px;
      font-size: 0.875rem;
      @include c(--p-c);
    }

    .premium-checkout_duration-tag-pricing {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;

      p {
        text-align: end;
      }

      .sub-text {
        font-size: 0.75rem;
        font-family: $font-family-secondary;
        margin-block-end: 4px;
        margin-block-start: -6px;
        text-align: right;
        @include c(--p-c);

        &.strikethrough {
          text-decoration: line-through;
        }
      }
    }
  }

  .ub-modal-toggle-option-text {
    padding: 1rem 0;

    h3 {
      font-size: 1rem;
      margin-block-end: 6px;
      font-family: $font-family-heading;
      display: flex;
      align-items: center;

      i {
        display: inline-flex;
        width: 18px;
        height: 18px;
        margin-inline-end: 0.25rem;
        margin-inline-start: -2px;

        svg {
          fill: $uppbeat;
        }
      }
    }

    ul {
      list-style: none;

      li {
        font-size: 0.875rem;
        @include c(--p-c);

        svg {
          margin-block-start: -1px;
          margin-inline-end: 0.125rem;

          path {
            fill: $green;
          }
        }

        &:not(:last-child) {
          margin-block-end: 0.25rem;
        }
      }
    }
  }

  .signup-option-price {
    position: absolute;
    top: 0.75rem;
    right: 0.75rem;
    display: flex;
    flex-direction: row !important;

    span {
      font-size: 0.75rem;
      @include c(--p-c);

      &.currency {
        align-self: flex-start;
      }

      &.price {
        font-weight: 600;
        font-size: 0.875rem;
        align-self: center;
        line-height: 1;
        margin-inline: 1px;
        @include c(--h2-c);
      }

      &.term {
        align-self: flex-end;
        margin-block-end: -1px;
      }
    }
  }
}
