// Generic
//
// As well as using normalize also remove margin and padding
// from some elements.

body,
h1, h2, h3, h4, h5, h6,
blockquote, p, pre,
dl, dd, ol, ul,
figure,
hr,
fieldset, legend {
    margin: 0;
    padding: 0;
}

.d-flex {
    display: flex;
}
