.ub-popup {
  position: fixed;
  z-index: 300;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  pointer-events: none;
  height: auto;
  padding: 2rem 1rem calc(2rem + 56px);

  .download-token {
    border-radius: 50%;
    display: inline-block;
    height: 24px;
    width: 24px;
    text-align: center;
    @include bg(--download-allowance-token-active-bg);

    img {
      transform: translate(1px, -2px);
    }

    @media only screen and (min-width: 768px) {
      img {
        transform: translate(1px, 0px);
      }
    }
  }

  > .ub-popup-bottom {
    border-radius: 0.625rem;
    overflow: hidden;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 1rem;
    width: 100%;
    max-width: 440px;
    gap: 1rem;
    pointer-events: all;
    @include bg(--popup-bg);
    @include css4 {
      border: 1px solid cvar(--popup-outer-br);
      box-shadow: 0 4px 8px cvar(--shadow-dark);
    }

    h2 {
      font-size: 1.4rem;
      @media only screen and (min-width: 768px) {
        font-size: 1.3rem;
      }
    }

    .popup-close-btn {
      position: absolute;
      top: 0.5rem;
      right: 0.5rem;
    }

    &.ub-popup-download {
      padding: 20px;
    }
  }

  > .ub-popup-recaptcha {
    max-width: 334px;

    .ub-popup-content-wrapper {
      overflow: hidden;
    }
  }

  .ub-popup-alert-emoji {
    font-size: 1.5rem;
    height: 1.5rem;
    text-align: center;
  }

  h2 {
    padding-inline-end: 2rem;
  }

  .ub-popup-checkpoint {
    font-size: 0.9rem;
  }

  .ub-popup-content-wrapper {
    position: relative;
    width: 100%;

    .ub-popup-download-content-banner {
      display: flex;
      align-items: start;
      padding: 9px 14px;
      border-radius: 10px 10px 0 0;
      background-color: $gray-dark-02;
      color: $white;
      font-size: 14px;

      &.free {
        background-color: $free;
        color: $black;
      }

      > svg {
        flex-shrink: 0;
      }
    }

    .ub-popup-download-content {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      border-radius: 0 0 10px 10px;
      padding: 11px 14px;
      min-height: 5rem;
      font-style: italic;
      font-family: $font-family-secondary;
      position: relative;
      resize: none;
      width: 100%;
      font-size: 0.75rem;
      @include c(--popup-content-c);
      @include bg(--popup-content-bg);
      @include css4 {
        border: 1px solid cvar(--popup-content-br);
        border-top: 0;
      }

      &.free {
        .theme-dark & {
          @include css4 {
            border-color: $free-dark;
          }
        }
      }

      &.sfx {
        @include bg(--popup-bg);
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        width: 100%;
        min-width: 0;
        position: relative;
        font-style: normal;
        align-items: center;
        border-radius: 0.5rem;

        .ub-track-avatar {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 3.5rem;
          width: 3.5rem;
          min-width: 3.5rem;
          border-radius: 50%;
          overflow: hidden;
          position: relative;
          margin-left: 0.4rem;
        }

        .ub-track-info {
          display: flex;
          flex-direction: column;
          padding-left: 0.8rem;
          width: calc(100% - 55px);

          .ub-track-info-title-left {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            font-size: 0.875rem;
            font-weight: 500;
            @include c(--browse-tracks-title-c);
          }

          .ub-track-info-subtitle {
            display: block;
            font-family: $font-family-secondary;
            max-width: 100%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            font-size: 0.875rem;
            font-weight: 400;
            @include c(--track-artist-c);
          }
        }
      }

      &:focus {
        outline: none;
      }

      &.loading {
        padding: 0.25rem;
        align-items: center;
        justify-content: center;
      }

      &.free {
        background-color: rgba($free-light, 0.5);
        color: #005b6e;

        p {
          color: #005b6e;
        }

        .theme-dark & {
          background-color: rgba($free-dark, 0.5);
          color: $white;
          @include css4 {
            border-color: $free-dark;
          }

          p {
            color: $white;
          }
        }
      }
    }

    .ub-btn {
      position: absolute;
      bottom: 0.375rem;
      right: 0.375rem;
      overflow: visible;
      z-index: 1;

      &::after {
        content: '';
        width: 100%;
        height: 100%;
        border-radius: 100%;
        position: absolute;
        transform: scale(1);
        transition: transform 0.6s, opacity 0.6s;
        z-index: -1;
        opacity: 0.75;
        @include bg(--button-transparent-bg);
      }

      &.copied {
        img {
          filter: brightness(4);
        }

        &::after {
          transform: scale(1.75);
          opacity: 0;
          @include bg(--button-success-bg);
        }
      }
    }
  }

  .ub-popup-download-footer {
    display: flex;
    justify-content: space-between;
    font-size: 0.6875rem;
    flex-direction: column-reverse;
    gap: 0.5rem;

    .ub-popup-download-footer-downloads-left {
      margin-left: auto;
    }

    > .ub-btn {
      position: relative;
      z-index: 1;

      &::after {
        content: '';
        width: 100%;
        height: 100%;
        border-radius: 1rem;
        position: absolute;
        transform: scale(1);
        transition: transform 0.6s, opacity 0.6s;
        z-index: -1;
        opacity: 0.75;
        @include bg(--button-transparent-bg);
      }

      img {
        width: 0.75rem;
      }

      &.sending {
        img {
          animation: sendingEmail 1.5s infinite forwards;
        }
      }

      @keyframes sendingEmail {
        0%,
        100% {
          transform: scale(1) translate(0, 0);
          opacity: 1;
        }

        50% {
          transform: translate(20px, -25px) scale(0.5);
          opacity: 0;
        }

        51% {
          transform: translate(-20px, 25px) scale(0.5);
          opacity: 0;
        }
      }
    }

    > .ub-popup-download-footer-whitelists {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-direction: row;
      gap: 0.5rem;
      flex-wrap: wrap;

      img {
        filter: brightness(0.8);
      }
    }

    > div {
      display: flex;
      flex-direction: row;
      align-items: flex-end;
      justify-content: space-between;
    }

    p {
      display: flex;
      gap: 0.25rem;

      + p span {
        cursor: pointer;
        @include c(--link-c);
      }
    }

    @media only screen and (min-width: 500px) {
      flex-direction: row;

      > div {
        flex-direction: column;
      }
    }
  }
}
