.header-main {
	z-index: 9;
	padding: 1rem;
	width: 100%;
	max-width: 100%;
	display: flex;
	align-items: center;
	justify-content: flex-end;
	position: sticky;
	top: 0;
	right: 0;
	gap: 0.5rem;
	transition: box-shadow 0.2s;
	@include bg(--topbar-bg);

	@media only screen and (min-width: 600px) {
		width: fit-content;
		position: fixed;
		border-bottom-left-radius: 1.5rem;

		z-index: 9;
	}

	@media only screen and (min-width: 768px) {
		height: max-content;
		min-height: auto;
	}

	.ub-smooth-corner {
		bottom: 0;
		left: 0;
		top: unset;
		transform: rotate(270deg);
		width: 3rem;
		height: 3rem;
		display: none;

		@media only screen and (min-width: 600px) {
			display: block;
		}

		path:first-child {
			fill: var(--topbar-bg);
		}
	}

	.mobile-search-button {
		display: flex;

		&.sidebar-open {
			display: none;
		}

		@media only screen and (min-width: 600px) and (min-height: 499px) {
			display: none;
		}
	}

	&.blue-bg .ub-smooth-corner path:first-child,
	&.blue-bg.shrink .ub-smooth-corner path:first-child {
		fill: $ai-bg-01;
	}

	> a:first-child {
		cursor: pointer;
		max-height: 2.5rem;
		display: block;
		margin-inline-end: auto;

		@media only screen and (min-width: 600px) {
			display: none;
		}
	}

	.topbar-search-button {
		@media only screen and (min-width: 600px) {
			display: none;
		}
	}

	.header-search-button {
		margin-inline-start: auto;

		@media only screen and (min-width: 600px) {
			display: none;
		}
	}

	&.shrink {
		@include css4 {
			box-shadow: 0 1px 4px cvar(--shadow-light);
		}

		.ub-smooth-corner path:first-child {
			fill: var(--shadow-light);
		}
	}

	.navbar-toggler {
		@media only screen and (min-width: 600px) and (min-height: 499px) {
			display: none;
		}
	}
}

.header-profile-logo {
	border-radius: 50%;
	cursor: pointer;
	width: 2rem;
	height: 2rem;
	position: relative;

	svg {
		width: 2rem;
		height: 2rem;

		path {
			transform: scale(0.8);
		}
	}

	img {
		width: 2rem;
		height: 2rem;
		border-radius: 50%;

		&:hover {
			filter: brightness(0.8)
		}
	}

	&::after {
		position: absolute;
		content: '';
		display: block;
		pointer-events: none;
		border-radius: 50%;
		width: 2rem;
		height: 2rem;
		border: 0.125rem solid $gray-light-01;
		top: 0;
		left: 0;
	}

	+ div {
		position: absolute;
		top: 0;
		right: 0;

		.account_dropdown {
			border-radius: 0.875rem;
			padding: 1.25rem 0.75rem;
			position: absolute;
			text-align: center;
			top: 4.5rem;
			right: 0.75rem;
			height: auto;
			width: auto;
			z-index: 1;
			@include bg(--account-dropdown-bg);
			@include css4 {
				box-shadow: 0 0 8px cvar(--shadow-dark);
			}

			@media only screen and (min-width: 768px) {
				top: 4.5rem;
			}

			.account_dropdown-header {
				font-family: $font-family-heading;
				font-size: 22px;
				line-height: 1;
				min-height: 22px;
				text-overflow: ellipsis;
				overflow: hidden;
				white-space: nowrap;
				@include c(--account-dropdown-header-c);
			}

			.account_dropdown-account-btn {
				margin: 1rem 0 !important;
			}

			.account_dropdown-log-out {
				@include c(--account-dropdown-c);
				cursor: pointer;
				text-decoration: underline;
			}

			&::after {
				content: "";
				display: block;
				position: absolute;
				width: 0;
				height: 0;
				border-style: solid;
				border-width: 0 10px 15px 10px;
				top: -14px;
				right: 50px;

				@include css4 {
					border-color: transparent transparent cvar(--account-dropdown-bg) transparent;
				}

				@media only screen and (min-width: 600px) {
					right: 10px;
				}
			}
		}
	}
}

.theme-dark .topbar-search-button img {
	filter: brightness(2) !important;
}

@media only screen and (min-width: 992px) {
	.admin-bar + .main-content-section .main-layout-container .header-main {
		top: 1.25rem;
	}
}
