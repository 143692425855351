.ub-categories {
    padding: clamp(1.5rem, 3cqw, 3rem);

    h3 {
        margin-block-end: 2rem;
    }

    .ub-categories-desktop {
        display: none;

        > hr:first-child {
            display: none;
        }
    }

    .ub-categories-desktop-grouped {
        grid-column-gap: 20px;
        column-gap: 20px;
        width: 100%;
        display: none;

        .ub-categories-desktop-grouped-item {
            break-inside: avoid-column;

            > hr {
                margin-block-end: 1.25rem;
                padding-block-start: 1.25rem;
            }

            & > h4 {
                padding: 0.75rem 0;
                height: 2.5rem;
            }
        }
    }

    .ub-categories-mobile {
        .ub-categories-mobile-header {
            display: flex;
            flex-wrap: wrap;
            padding-block-end: 1rem;
            margin-block-end: 1.5rem;
            @include css4 {
                border-bottom: 1px solid cvar(--main-br);
            }

            .ub-btn {
                margin-block-end: 0.5rem;

                &:not(:last-child) {
                    margin-inline-end: 0.5rem;
                }

                .ub-btn-label {
                    display: flex;

                    img {
                        width: auto;
                        height: 0.875rem;
                        min-width: 0;
                        margin: 0 0.25rem 0 0;
                    }
                }
            }
        }

        .ub-categories-mobile-list {
            display: flex;
            flex-wrap: wrap;

            a {
                margin-block-end: 0.5rem;

                &:not(:last-child) {
                    margin-inline-end: 0.5rem;
                }
            }
        }
    }

    @media only screen and (min-width: 768px) {
        h3 {
            padding-inline-start: 0.5rem;
        }

        .ub-categories-mobile {
            display: none;
        }

        .ub-categories-desktop {
            display: grid;
            grid-auto-flow: column;
            column-gap: 1rem;
            row-gap: 0.25rem;
            grid-template-columns: repeat(4, 1fr);

            > hr,
            > h4,
            .ub-categories-col > hr,
            .ub-categories-col > h4,
            .ub-categories-desktop-grouped-item > h4 {
                align-self: center;
                margin-inline: 0.5rem;
            }

            .ub-categories-col {
                display: grid;
                grid-auto-flow: column;
                grid-template-columns: 1fr;
                grid-template-rows: repeat(52, 1fr);
            }

            hr {
                border: none;
                @include css4 {
                    border-bottom: 1px solid cvar(--ub-border-light);
                }
            }

            h4 {
                font-family: $font-family-heading;
                @include c(--h3-c);
            }

            a {
                width: fit-content;
            }

            .ub-btn {
                min-width: 0;
            }
        }

        &.ub-categories-music .ub-categories-desktop {
            grid-template-rows: repeat(33, 1fr);
        }

        &.ub-categories-sfx .ub-categories-desktop {
            grid-template-rows: repeat(1, 1fr);
            grid-template-columns: 1fr;
        }

        .ub-categories-col {
            grid-template-rows: repeat(62, 1fr);
        }

        .ub-categories-desktop-grouped {
            display: block;
            column-count: 4;

            .ub-categories-desktop-grouped-item:first-child,
            .ub-categories-desktop-grouped-item:nth-child(7),
            .ub-categories-desktop-grouped-item:nth-child(15),
            .ub-categories-desktop-grouped-item:nth-child(22) {
                > hr {
                    display: none;
                }
            }

            .ub-categories-desktop-grouped-item:nth-child(22) {
                break-before: column;
            }
        }
    }

    @media only screen and (min-width: 1200px) {
        .ub-categories-desktop {
            grid-template-columns: repeat(5, 1fr);
        }

        &.ub-categories-music .ub-categories-desktop {
            grid-template-rows: repeat(27, 1fr);

            hr {
                &:nth-of-type(6) {
                    display: none;
                }
            }
        }

        &.ub-categories-sfx .ub-categories-desktop {
            grid-template-rows: repeat(1, 1fr);

            .ub-categories-col {
                grid-template-rows: repeat(48, 1fr);
            }
        }

        .ub-categories-desktop-grouped {
            column-count: 5;

            .ub-categories-desktop-grouped-item:first-child,
            .ub-categories-desktop-grouped-item:nth-child(6),
            .ub-categories-desktop-grouped-item:nth-child(10),
            .ub-categories-desktop-grouped-item:nth-child(16),
            .ub-categories-desktop-grouped-item:nth-child(22) {
                > hr {
                    display: none;
                }
            }

            .ub-categories-desktop-grouped-item:nth-child(7),
            .ub-categories-desktop-grouped-item:nth-child(15) {
                > hr {
                    display: block;
                }
            }

            .ub-categories-desktop-grouped-item:nth-child(22) {
                break-before: unset;
            }
        }
    }

    @media only screen and (min-width: 1440px) {
        .ub-categories-desktop {
            grid-template-columns: repeat(6, 1fr);
        }

        &.ub-categories-music .ub-categories-desktop {
            grid-template-rows: repeat(22, 1fr);
        }

        &.ub-categories-sfx .ub-categories-desktop {
            grid-template-rows: repeat(1, 1fr);

            .ub-categories-col {
                grid-template-rows: repeat(40, 1fr);
            }
        }

        .ub-categories-desktop-grouped {
            column-count: 6;

            .ub-categories-desktop-grouped-item:first-child,
            .ub-categories-desktop-grouped-item:nth-child(6),
            .ub-categories-desktop-grouped-item:nth-child(9),
            .ub-categories-desktop-grouped-item:nth-child(14),
            .ub-categories-desktop-grouped-item:nth-child(19),
            .ub-categories-desktop-grouped-item:nth-child(23) {
                > hr {
                    display: none;
                }
            }

            .ub-categories-desktop-grouped-item:nth-child(10),
            .ub-categories-desktop-grouped-item:nth-child(16),
            .ub-categories-desktop-grouped-item:nth-child(22) {
                > hr {
                    display: block;
                }
            }
        }
    }
}
