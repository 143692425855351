@mixin keyframes($animation-name) {
    @-webkit-keyframes #{$animation-name} {
        @content;
    }
    @-moz-keyframes #{$animation-name} {
        @content;
    }
    @-ms-keyframes #{$animation-name} {
        @content;
    }
    @-o-keyframes #{$animation-name} {
        @content;
    }
    @keyframes #{$animation-name} {
        @content;
    }
}

@mixin animation($str) {
    -webkit-animation: #{$str};
    -moz-animation: #{$str};
    -ms-animation: #{$str};
    -o-animation: #{$str};
    animation: #{$str};
}

.ub-toggle {
    display: flex;
    align-items: center;

    &.normal {
        .ub-toggle-faux-checkbox {
            position: relative;
            display: inline-block;
            width: 3rem;
            height: 1.625rem;
            margin: 0 0.5rem;
            cursor: pointer;

            input {
                opacity: 0;
                width: 0;
                height: 0;

                &:focus + .ub-toggle-thumb:before {
                    @include css4 {
                        box-shadow: 0 0 6px cvar(--toggle-shadow);
                    }
                }
            }

            .ub-toggle-thumb {
                position: absolute;
                cursor: pointer;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                border-radius: 1rem;
                transition: transform 0.2s;
                overflow: hidden;

                &::before {
                    position: absolute;
                    content: "";
                    height: 1.125rem;
                    width: 1.125rem;
                    left: 0.25rem;
                    bottom: 0.25rem;
                    border-radius: 50%;
                    transition: transform 0.2s;
                }
            }

            input:checked + .ub-toggle-thumb:before {
                transform: translateX(1.375rem);
            }
        }

        &.uppbeat {
            input {
                &:checked + .ub-toggle-thumb {
                    @include bgd(--toggle-uppbeat-bg, 0.05);
                }
            }

            .ub-toggle-thumb {
                @include bg(--toggle-uppbeat-bg);

                &::before {
                    @include bg(--toggle-uppbeat-indicator-bg)
                }
            }
        }

        &.success {
            input {
                &:checked + .ub-toggle-thumb {
                    @include bg(--toggle-success-indicator-bg);

                    &::before {
                        @include bg(--toggle-success-indicator-active-bg);
                    }
                }
            }

            .ub-toggle-thumb {
                @include bg(--toggle-success-indicator-bg);

                &::before {
                    @include bg(--toggle-success-bg)
                }
            }
        }

        &.primary {
            input {
                &:checked + .ub-toggle-thumb {
                    @include bgd(--toggle-primary-bg, 0.05);
                }
            }

            .ub-toggle-thumb {
                @include bg(--toggle-primary-bg);

                &::before {
                    @include bg(--toggle-primary-indicator-bg)
                }
            }
        }

        &.free-premium {
            input {
                &:checked + .ub-toggle-thumb {
                    @include bg(--toggle-premium-bg);
                }
            }

            .ub-toggle-thumb {
                @include bg(--toggle-free-bg);

                &::before {
                    @include bg(--toggle-uppbeat-indicator-bg)
                }
            }
        }

        &.theme {
            > div {
                text-transform: capitalize;
                @include c(--sidebar-mobile-primary-c);

                &.active {
                    @include c(--sidebar-mobile-secondary-c);
                }

                @media only screen and (min-width: 992px) {
                    @include c(	--toggle-theme-c);

                    &.active {
                        @include c(	--toggle-theme-active-c);
                    }
                }
            }

            input {
                &:checked + .ub-toggle-thumb {
                    @include bgd(--toggle-theme-bg, 0.05);
                }
            }

            .ub-toggle-thumb {
                @include bg(--toggle-theme-bg);

                svg {
                    position: absolute;
                    content: "";
                    height: 1.125rem;
                    width: 1.125rem;
                    right: 0.25rem;
                    bottom: 0.25rem;
                    border-radius: 50%;
                    transition: 0.6s;
                    overflow: visible;
                }

                .sun {
                    &.visible {
                        bottom: -1.5rem;
                        animation: 0.6s ease 0.4s forwards sunrise;
                        @include keyframes(sunrise) {
                            0% {bottom: -1.5rem;}
                            100% {bottom: 0.25rem;}
                        }

                        circle {
                            opacity: 0;
                        }

                        circle:nth-of-type(1) {
                            animation: 0.6s ease 0.8s forwards circle1visible;
                            @include keyframes(circle1visible) {
                                0% {opacity: 0}
                                100% {opacity: 1}
                            }
                        }

                        circle:nth-of-type(8) {
                            animation: 0.6s ease 0.85s forwards circle2visible;
                            @include keyframes(circle2visible) {
                                0% {opacity: 0}
                                100% {opacity: 1}
                            }
                        }

                        circle:nth-of-type(12) {
                            animation: 0.6s ease 0.9s forwards circle3visible;
                            @include keyframes(circle3visible) {
                                0% {opacity: 0}
                                100% {opacity: 1}
                            }
                        }

                        circle:nth-of-type(4) {
                            animation: 0.6s ease 0.95s forwards circle4visible;
                            @include keyframes(circle4visible) {
                                0% {opacity: 0}
                                100% {opacity: 1}
                            }
                        }

                        circle:nth-of-type(6) {
                            animation: 0.6s ease 1s forwards circle5visible;
                            @include keyframes(circle5visible) {
                                0% {opacity: 0}
                                100% {opacity: 1}
                            }
                        }

                        circle:nth-of-type(10) {
                            animation: 0.6s ease 1.05s forwards circle6visible;
                            @include keyframes(circle6visible) {
                                0% {opacity: 0}
                                100% {opacity: 1}
                            }
                        }

                        circle:nth-of-type(2) {
                            animation: 0.6s ease 1.1s forwards circle7visible;
                            @include keyframes(circle7visible) {
                                0% {opacity: 0}
                                100% {opacity: 1}
                            }
                        }

                        circle:nth-of-type(7) {
                            animation: 0.6s ease 1.15s forwards circle8visible;
                            @include keyframes(circle8visible) {
                                0% {opacity: 0}
                                100% {opacity: 1}
                            }
                        }

                        circle:nth-of-type(11) {
                            animation: 0.6s ease 1.2s forwards circle9visible;
                            @include keyframes(circle9visible) {
                                0% {opacity: 0}
                                100% {opacity: 1}
                            }
                        }

                        circle:nth-of-type(3) {
                            animation: 0.6s ease 1.25s forwards circle10visible;
                            @include keyframes(circle10visible) {
                                0% {opacity: 0}
                                100% {opacity: 1}
                            }
                        }

                        circle:nth-of-type(5) {
                            animation: 0.6s ease 1.3s forwards circle11visible;
                            @include keyframes(circle11visible) {
                                0% {opacity: 0}
                                100% {opacity: 1}
                            }
                        }

                        circle:nth-of-type(9) {
                            animation: 0.6s ease 1.35s forwards circle12visible;
                            @include keyframes(circle12visible) {
                                0% {opacity: 0}
                                100% {opacity: 1}
                            }
                        }
                    }

                    &.hidden {
                        animation: 0.6s ease 0s forwards sunset;
                        @include keyframes(sunset) {
                            0% {bottom: 0.25rem;}
                            30% {bottom: 0.375rem;}
                            100% {bottom: -1.5rem;}
                        }

                        circle:nth-of-type(1) {
                            animation: 0.6s ease 0.6s forwards circle1;
                            @include keyframes(circle1) {
                                0% {opacity: 1}
                                100% {opacity: 0}
                            }
                        }

                        circle:nth-of-type(8) {
                            animation: 0.6s ease 0.6s forwards circle2;
                            @include keyframes(circle2) {
                                0% {opacity: 1}
                                100% {opacity: 0}
                            }
                        }

                        circle:nth-of-type(12) {
                            animation: 0.6s ease 0.6s forwards circle3;
                            @include keyframes(circle3) {
                                0% {opacity: 1}
                                100% {opacity: 0}
                            }
                        }

                        circle:nth-of-type(4) {
                            animation: 0.6s ease 0.6s forwards circle4;
                            @include keyframes(circle4) {
                                0% {opacity: 1}
                                100% {opacity: 0}
                            }
                        }

                        circle:nth-of-type(6) {
                            animation: 0.6s ease 0.6s forwards circle5;
                            @include keyframes(circle5) {
                                0% {opacity: 1}
                                100% {opacity: 0}
                            }
                        }

                        circle:nth-of-type(10) {
                            animation: 0.6s ease 0.6s forwards circle6;
                            @include keyframes(circle6) {
                                0% {opacity: 1}
                                100% {opacity: 0}
                            }
                        }

                        circle:nth-of-type(2) {
                            animation: 0.6s ease 0.6s forwards circle7;
                            @include keyframes(circle7) {
                                0% {opacity: 1}
                                100% {opacity: 0}
                            }
                        }

                        circle:nth-of-type(7) {
                            animation: 0.6s ease 0.6s forwards circle8;
                            @include keyframes(circle8) {
                                0% {opacity: 1}
                                100% {opacity: 0}
                            }
                        }

                        circle:nth-of-type(11) {
                            animation: 0.6s ease 0.6s forwards circle9;
                            @include keyframes(circle9) {
                                0% {opacity: 1}
                                100% {opacity: 0}
                            }
                        }

                        circle:nth-of-type(3) {
                            animation: 0.6s ease 0.6s forwards circle10;
                            @include keyframes(circle10) {
                                0% {opacity: 1}
                                100% {opacity: 0}
                            }
                        }

                        circle:nth-of-type(5) {
                            animation: 0.6s ease 0.6s forwards circle11;
                            @include keyframes(circle11) {
                                0% {opacity: 1}
                                100% {opacity: 0}
                            }
                        }

                        circle:nth-of-type(9) {
                            animation: 0.6s ease 0.6s forwards circle12;
                            @include keyframes(circle12) {
                                0% {opacity: 1}
                                100% {opacity: 0}
                            }
                        }
                    }
                }

                .moon {
                    left: 0.25rem;
                    padding: 0.1875rem;

                    &.visible {
                        bottom: -1.5rem;
                        animation: 0.6s ease 0.2s forwards moonrise;
                        @include keyframes(moonrise) {
                            0% {bottom: -1.5rem;}
                            100% {bottom: 0.25rem;}
                        }

                        path {
                            @include f(--toggle-theme-path-bg);
                        }
                    }

                    &.hidden {
                        animation: 0.6s ease 0s forwards moonset;
                        @include keyframes(moonset) {
                            0% {bottom: 0.25rem;}
                            30% {bottom: 0.375rem;}
                            100% {bottom: -1.5rem;}
                        }

                        path {
                            @include f(--toggle-theme-path-bg);
                        }
                    }
                }

                &::before {
                    display: none;
                }
            }
        }
    }

    &.large {
        .ub-toggle-faux-radio {
            height: 2.5rem;
            display: grid;
            grid-auto-columns: minmax(0, 1fr);
            grid-auto-flow: column;
            border-radius: 0.75rem;
            position: relative;
            z-index: 0;
            @include bg(--toggle-large-bg);

            .ub-toggle-faux-radio-background {
                height: 2.5rem;
                position: absolute;
                z-index: 1;
                border-radius: 0.75rem;
                transition: left 0.2s;
            }

            label {
                display: flex;
                margin: 0;
                z-index: 2;

                input {
                    opacity: 0;
                    width: 0;
                    height: 0;
                }

                > div {
                    width: 100%;
                    height: 2.5rem;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    padding: 0  0.75rem;
                    font-family: $font-family-primary;
                    font-weight: 500;
                    font-size: 1rem;
                    cursor: pointer;
                    transition: color 0.2s;
                    white-space: nowrap;
                    user-select: none;
                    @include c(--toggle-large-inactive-c);
                }

                &:first-child > div {
                    border-radius: 0.75rem 0 0 0.75rem;
                }

                &:last-child > div {
                    border-radius: 0 0.75rem 0.75rem 0;
                }

                &.ub-toggle-current-option > div {
                    @include c(--toggle-large-active-c);
                }
            }
        }

        &.uppbeat {
            .ub-toggle-faux-radio .ub-toggle-faux-radio-background {
                @include bg(--toggle-uppbeat-bg);
            }
        }

        &.free {
            .ub-toggle-faux-radio .ub-toggle-faux-radio-background {
                @include bg(--toggle-free-bg);
            }
        }

        &.primary {
            .ub-toggle-faux-radio .ub-toggle-faux-radio-background {
                @include bg(--toggle-primary-bg);
            }
        }

        &.success {
            .ub-toggle-faux-radio .ub-toggle-faux-radio-background {
                @include bg(--toggle-success-large-bg);
            }
        }
    }
}
