// Stripe
// ---------------------------------------------------------

.StripeElement:not(.express) {
  box-sizing: border-box;
  height: 40px;
  border: 1px solid $transparent;;
  background: $gray-light-03;
  border-radius: 10px;
  padding: 10px 20px;
  outline: none;
  width: inherit;
}

.StripeElement--focus {
  box-shadow: 0 1px 3px 0 #cfd7df;
}

.StripeElement--invalid {
  border-color: #fa755a;
}

.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}

.card-active {
    background: $white;
    border-radius: 3px;
    border: 1px solid $gray-medium-02;
}

#new-card-form .remember-card {
    display: none;
}

.existing-card-info > label {
    font-size: 14px;
    margin: 0;
    padding: 10px;
    width: calc(100% - 24px);
}
