// Icons
//
// icon designs

// SVG Styling
// ---------------------------------------------------------

.e17e3c6b-a30f-4c87-a083-b6b0075be92d {
  fill: #eed7d5;
}

.\31 2e58829-0eea-4476-b4f3-0a4a98a20b87, .\31 c255999-2464-4e5a-a5c0-11fa1061283a, .\35 993379e-e7f3-437c-b41d-b300adab6f2f, .\38 af29e29-018b-4758-b757-f1fbc30f1700 {
  fill: $black;
}

.\38 af29e29-018b-4758-b757-f1fbc30f1700, .fd44daac-164a-4632-82cf-22504dc408f6 {
  opacity: 0.06;
}

.a0564532-bc7c-4401-9090-30b44a2aff25 {
  fill: #e88c80;
}

.\36 e16274d-e3bd-4836-9671-06b8c2235d98 {
  fill: #dc7d78;
  opacity: 0.3;
}

.\32 3aec488-9c64-4108-8f47-8534274271a4 {
  fill: #faf7ed;
}

.b89b0a60-10f5-4c99-a075-25f8130f2d78 {
  fill: #e5e2d6;
}

.bcacd8ab-4046-4c6e-bbb6-4b8f282ca415 {
  fill: #ed9386;
}

.\30 7bf5898-0233-413e-8ead-cbbcdf5736af {
  fill: #e28881;
}

.\36 3f61ed4-0442-48b3-b841-33e95bc92487 {
  fill: #9fd7cc;
}

.\32 3391861-5aa6-4132-97ea-46dc06704a50 {
  fill: #bfbcc4;
}

.\36 a491589-53ab-4ef8-b565-f91228f24a8b {
  fill: #857e96;
}

.be276823-039a-4692-85c8-9acccd3faa7d {
  fill: #8c869c;
}

.df57b231-3d5f-42ac-8962-f580bc108e35 {
  opacity: 0.2;
}

.f94f266f-5633-4836-b2e9-db0cb313fec2 {
  fill: #f4daa7;
}

.\38 472a1a9-fc2a-44eb-a4cd-ccf1ac341bc2 {
  fill: #f2c078;
}

.bf96fb53-5f51-41b3-bc73-e6e1ede88f6e {
  fill: #c57b72;
}

.\31 2e58829-0eea-4476-b4f3-0a4a98a20b87 {
  opacity: 0.03;
}

.\33 710634f-d7d4-4d85-8e4f-5e66ba2ea458, .\35 993379e-e7f3-437c-b41d-b300adab6f2f {
  opacity: 0.04;
}

.\35 74f5070-2bfc-4e46-827b-f649a06cb436 {
  fill: #ccb18e;
}

.d65d33f9-e455-41db-b5d4-c4cbed5013cb {
  fill: #857f96;
}

.\33 710634f-d7d4-4d85-8e4f-5e66ba2ea458 {
  fill: $white;
}

.bbfd9246-2616-4be7-b94e-58d767391cbf {
  fill: #cccbd3;
}

.\35 800c720-a979-42bb-92fd-4c221a6a9673 {
  fill: #90929f;
}

.\35 45bd757-eba8-4f4b-b651-aa73cef70083 {
  fill: #8b849b;
}

// Tabs
// ---------------------------------------------------------

$tab-size: 120px;
$tab-size-lg: 180px;
$tab-border-size: 4px;

.contact-form-tab,
.contact-form-title {
    background: $transparent;
    cursor: pointer;
    display: inline-block;
    margin: 0 15px;
    transition: 0.2s;
    width: $tab-size;

    @media only screen and (min-width: 992px) {
      width: $tab-size-lg;
    }

    &:focus {
        outline: none;
    }

    &:first-child {
        margin-left: 0;
    }

    &:last-child {
        margin-right: 0;
    }
}

.contact-form-tab {
    border: $tab-border-size solid $white;
    border-radius: $tab-size;
    height: $tab-size;
    padding: 7px;

    @media only screen and (min-width: 992px) {
      height: $tab-size-lg;
    }

    &.active-tab {
        border-color: $gray-medium-01;
    }
}


.contact-form-title {
    color: $gray-medium-02;
    font-size: 12px;
    opacity: 0.8;

    @media only screen and (min-width: 992px) {
      font-size: 16px;
    }

    &.active-tab {
        opacity: 1;
    }

    &:focus {
        outline: none;
    }

    &:first-child {
        margin-left: 0;
    }

    &:last-child {
        margin-right: 0;
    }
}

.contact-form-tab-row {
    margin-bottom: 10px;
}

// Page Styling
// ---------------------------------------------------------
.hero-blog.blank .hero-blog-content.contact-page-hero {
    padding: 40px 0;
    min-height: 0;

    @media only screen and (min-width: 992px) {
      padding: 80px 0;
    }
}

.contact-page-hero .hero-blog-title {
  margin-bottom: 20px;
}

.contact-forms {
  max-width: 700px;
  margin: 0 auto;
  font-size: 14px;
  text-align: center;
  color: $gray-dark-04;

  @media only screen and (min-width: 992px) {
    font-size: 16px;
  }

  h3 {
    margin-bottom: 20px;
  }

  p {
    color: $gray-medium-01;
    line-height: 1.8;
    margin-bottom: 1rem;
    text-align: left;

    &:last-of-type {
      margin-bottom: 2rem;
    }
  }
}

.contact-forms {

  h3 {
    font-weight: normal;
  }

  a {
    color: $free;
  }

  .wufoo-form-container,
  .wufoo-form-container-preloader {
    background-image: url(https://cdn.musicvine.com/images/form-preloader.gif);
  }

  .wufoo-form-container-preloader {
    height: 1px;
    opacity: 0.01;
    position: absolute;
    width: 1px;
  }

  .wufoo-form-container {
    background-position: center;
    background-size: 64px;
    margin: 0 auto 40px;
    min-height: 200px;
  }
}
