* {
    .ub-smooth-corner {
        position: absolute;

        path:first-child {
            fill: var(--modal-overlay-bg);
        }

        path:last-child {
            fill: var(--main-bg);
        }

        &:first-of-type {
            top: 0;
            left: 0;
        }

        &:nth-of-type(2) {
            top: 0;
            right: 0;
            transform: rotate(90deg);
        }

        &:nth-of-type(3) {
            bottom: 0;
            right: 0;
            transform: rotate(180deg);
        }

        &:nth-of-type(4) {
            bottom: 0;
            left: 0;
            transform: rotate(270deg);
        }
    }
}
