.premium-checkout-billing-container {
    // stage-1 styling

    .premium-checkout-notes {
        font-size: 11px;
        font-weight: 600;

        .renew-date {
            color: #3BA6F7;
        }
    }

    .modal-desc {
        color: $gray-medium-02;
        font-size: 12px;
        padding: 10px 0 20px;

        strong {
            color: $uppbeat;
        }
    }

    .savings-tag {
        color: #0ec051;
        font-family: $font-family-secondary;
        font-size: 12px;
        font-weight: 400;
    }

    .full-price-tag {
        color: $gray-medium-02;
        text-decoration: line-through;
        font-family: $font-family-secondary;
        font-weight: 400;

        small {
            font-size: 10px;
        }
    }

    .premium-checkout_pricing {
        margin-block-start: 1.5rem;
        font-weight: $font-weight-medium;
        font-size: 0.875rem;

        p {
            margin-block-end: 1.5rem;
        }

        .currency-tag {
            font-size: 1.25rem;
            font-family: $font-family-heading;
            display: inline-block;
            top: -1.25rem;
            @include c(--h1-c);
        }

        .price-tag {
            margin-inline-end: 0.25rem;
            font-size: 3rem;
            font-family: $font-family-heading;
            @include c(--h1-c);

            sup {
                font-size: 1.25rem;
                top: -1.25rem;
                left: -0.125rem;
            }
        }

        .month-tag {
            margin: 0 0.25rem 0 -2rem;
            font-size: 0.8125rem;
            font-family: $font-family-secondary;
            @include c(--h1-c);
        }

        .cancel-tag {
            font-size: 0.8125rem;
            color: $gray-medium-03;
            font-family: $font-family-secondary;
            font-weight: 400;
            @include c(--main-secondary-c);
        }
    }

    //stage-2 styling

    &.checkout-stage-2 {

        .modal-title {
            margin-top: 5px;
        }

        hr {
            border: none;
            border-top: 1px solid $gray-light-02;
        }

    }

    .modal-underline-title {
        margin-top: 0;
    }

    .toggle-invoice-info {
        cursor: pointer;
    }

    &.resolve-payment-pricing-container {
        max-width: 380px;
        width: 100%;
    }

    .premium-checkout_payment-details {
        display: flex;
        justify-content: space-between;

        p {
            position: relative;;
            margin-inline-end: 1.25rem;
        }

        .currency-tag {
            font-size: 1rem;
            @include c(--h1-c);
            vertical-align: text-bottom;
        }

        .placeholder-text {
            font-size: 0.9rem;
        }

        .pricing-tag {
            font-family: $font-family-heading;
            font-size: 3.4rem;
            line-height: 1;
            @include c(--h2-c);

            sup {
                font-size: 1rem;
                top: -2.125rem;
            }

            + .currency-tag {
                font-size: 0.75rem;
                padding: 0.25rem 0.5rem;
                border-radius: 1rem;
                background: $gray-light-01;
                color: $gray-dark-02;
                position: absolute;
                white-space: nowrap;
                bottom: 0.4375rem;
                right: -1.25rem;
            }
        }

        &.resolve-payment-pricing {
            display: flex;
            justify-content: space-between;
            max-width: 380px;
            width: 100%;
        }
    }

    .premium-checkout_subscribe {
        display: flex;
        justify-content: flex-end;

        .ub-btn:last-child {
            margin-block-end: 0;
        }
    }

    .vat-number-field {
        position: absolute;
        right: 10px;
        border-radius: 10px;
        background: $uppbeat;
        cursor: pointer;
        border: none;
        outline: none;
        padding: 10px 20px;
        color: $white;
    }
}

.premium.login {
    cursor: pointer;
}
