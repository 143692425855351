.exceptional-music {
  background-color: $gray-dark-02;
  background-image: url(https://cdn.musicvine.com/images/homepage-hero.jpg);
  background-position: right;
  background-size: auto 100%;
  padding-top: 100px;
  padding-bottom: 100px;
  position: relative;

  // Move content down for sticky nav
  @media only screen and (min-width: 992px) {
    height: 84vh;
    min-height: 600px;
    padding-top: 0;
    padding-bottom: 0;

    &.has-heads-up {
      height: calc(100vh - 93px);
    }
  }

  .content-container,
  .col {
    height: 100%;
  }

  h1 {
    font-size: 46px;
    font-family: $font-family-heading;
    font-weight: 700;
    line-height: 1.2;

    @media only screen and (min-width: 992px) {
      font-size: 64px;
    }
  }

  p {
    font-size: 16px;
    margin-top: 30px;
    max-width: 520px;

    @media only screen and (min-width: 992px) {
      font-size: 18px;
    }
  }

  .cta {
    font-weight: 700;
    margin-top: 40px;

    a {
      margin-right: 10px;
      margin-bottom: 20px;

      @media only screen and (min-width: 992px) {
        margin-bottom: 0;
      }
    }
  }

  .brand-strip {
    bottom: 0;
    display: none;
    height: 100px;
    left: 0;
    padding: 0 10px 20px;
    position: absolute;
    text-align: center;
    width: 100%;
    overflow: hidden;

    @media only screen and (min-width: 992px) {
      display: block;
    }

    div {
      display: inline-block;
      max-width: 180px;
    }
  }
}

.content-container.pitch-section {
  border-bottom: solid 4px $gray-dark-02;
  padding-left: 0;
  padding-right: 0;
  min-height: 400px;
  max-width: 1300px;

  .row {
    margin-left: 0;
    margin-right: 0;
  }

  .pitch-icon {
    display: block;
    margin-bottom: 5px;
  }

  h2 {
    font-family: $font-family-heading;
    font-size: 36px;
    font-weight: normal;
    line-height: 1.2;
    margin-bottom: 20px;

    @media only screen and (min-width: 992px) {
      font-size: 40px;
    }
  }

  p {
    font-size: 15px;
    line-height: 2;
    margin-bottom: 20px;

    @media only screen and (min-width: 992px) {
      margin-bottom: 0;
    }
  }
}

.pitch-video {
  max-width: 100%;
}

.pitch-video-overlay {
  box-shadow: inset 0 0 30px 15px $black;
  content: " ";
  height: 100%;
  left: 10px;
  position: absolute;
  top: 0;
  width: calc(100% - 20px);
  z-index: 190;
}

.pitch-inner {
  max-width: 480px;
  padding-top: 6rem;

  @media only screen and (min-width: 992px) {
    padding-top: 0;
  }
}

.pitch-artist-wrapper {
  max-width: 100%;
  overflow: hidden;
}

.artist-grid-outer {
  height: 300px;
  overflow: hidden;
  position: relative;
  perspective: 400px;
  width: 100%;

  @media only screen and (min-width: 992px) {
    height: 410px;
    width: 800px;
  }

  .artist-grid-gradient-1,
  .artist-grid-gradient-2 {
    height: 100%;
    position: absolute;
    width: 100%;
  }

  .artist-grid-gradient-1 {
    background: linear-gradient(to bottom, $black 0%, rgba($black, 0.2) 52%, rgba($black, 0) 100%);
    ;
    z-index: 3;

    @media only screen and (min-width: 768px) {
      background: linear-gradient(90deg, $black 3%, rgba($black, 0) 50%, rgba($black, 1) 97%);
    }
  }

  .artist-grid-gradient-2 {
    background: rgba($black, 0.1);
    z-index: 2;
  }

  .artist-grid-inner {
    position: absolute;
    transform: translateX(-47%) translateY(-29%) translateZ(-129px) rotate(2deg) matrix3d(0.59172, -0.03002, 0, -0.00024, -0.14906, 0.71698, 0, 0, 0, 0, 1, 0, 148, 35, 0, 1);
    height: 800px;
    width: 1280px;
    z-index: 1;

    @media only screen and (min-width: 768px) {
      transform: translateX(-35%) translateY(-29%) translateZ(9px) rotate(2deg) matrix3d(0.59172, -0.03002, 0, -0.00024, -0.14906, 0.71698, 0, 0, 0, 0, 1, 0, 148, 35, 0, 1);
    }

    img {
      border: 2px solid $black;
      width: calc(100% / 8);
    }
  }
}

.jumbotron-button {
  border: 2px solid $white;
  color: $white;
  display: inline-block;
  padding: 9px 0;
  text-align: center;
  width: 200px;

  &.large {
    font-size: 18px;
    width: 260px;
  }

  &.solid {
    background: $white;
    color: $black;
  }
}

.content-container.pitch-section.homepage-collections {
  padding: 12vh 0;

  h2 {
    font-size: 45px;
    line-height: 1.2;
    margin-bottom: 110px;
  }
}

.playlist-category-select {
  padding-left: 30px;

  li {
    color: #6d6f77;
    font-family: $font-family-heading;
    font-size: 26px;
    list-style: none;
    cursor: pointer;

    &.active {
      color: $uppbeat;
      position: relative;

      &::before {
        content: "";
        left: -30px;
        top: 13px;
        position: absolute;
        width: 0;
        height: 0;
        border-top: 7px solid $transparent;
        border-bottom: 7px solid $transparent;
        border-left: 11px solid $uppbeat;
      }
    }
  }
}

.new-collections-grid {
  padding-left: 66px;

  .collection-player-gradient {
    position: absolute;
    height: 100%;
    transition: all 0.2s;
    width: 100%;
    z-index: 1;
  }

  .collection-player-outer {
    cursor: pointer;
    margin-bottom: 20px;
    position: relative;

    .collection-player-overlay {
      position: absolute;
      height: 100%;
      width: 100%;
      z-index: 2;

      .miniplayer {
        bottom: 0;
        display: flex;
        height: 40px;
        position: absolute;
        width: 100%;

        .miniplayer-play,
        .miniplayer-next,
        .miniplayer-spacer {
          text-align: right;
          width: 20%;

          img {
            margin-top: 4px;
            max-width: 30px;
          }
        }

        .miniplayer-play {
          opacity: 0.85;
        }

        .miniplayer-next {
          display: none;
        }

        .miniplayer-link {
          text-align: center;
          width: 60%;
        }

        .miniplayer-spacer {
          height: 1px;
        }

        .view-all {
          color: $uppbeat;
          line-height: 40px;
          opacity: 0;
          transition: all 0.2s;
        }
      }
    }

    &.playing,
    &:hover {
      .collection-player-overlay .miniplayer {
        .miniplayer-play,
        .view-all {
          opacity: 1;
        }
      }

      .collection-player-gradient {
        background: linear-gradient(to bottom, rgba($black, 0) 0%, rgba($black, 0) 50%, rgba($black, 0.65) 100%);
      }
    }

    &.playing .collection-player-overlay {
      box-shadow: inset 0 0 0 1px $uppbeat;

      .miniplayer {
        .miniplayer-next {
          display: block;
        }

        .view-all {
          opacity: 1;
        }
      }
    }
  }
}

// heads up message subscription
.ub-alert {
  background: $uppbeat;
  display: none;
  font-size: 16px;
  font-family: $font-family-primary;
  height: $alert-height;
  justify-content: center;
  line-height: 40px;
  font-weight: 500;
  width: 100%;

  p,
  p a:hover {
    color: $white;
  }

  @media only screen and (min-width: 992px) {
      display: flex;
  }

  a {
      color: $white;
      text-decoration: underline;
  }

  strong {
      font-weight: 600;
  }

  &.warning {
      background: #fdefbb;
  }

  &.alert {
      background: #9bcce4;
  }
}

// latest layout css

.main-layout {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  // Use vh as fallback for dvh https://caniuse.com/?search=dvh
  max-height: 100vh;
  max-height: 100dvh;
  overflow: hidden;
  @include bg(--main-bg);

  // .main-layout-grid {
  //   @media only screen and (min-width: 600px) {
  //     display: grid;
  //     grid-template-columns: min-content;
  //     grid-template-areas:
  //       "sidebar content";
  //   }
  // }

  .main-content-section {
    // overflow-y: auto;
    // overflow-x: hidden;
    overflow: hidden;
    height: 100%;
    transition: height 0.2s;

    &.no-scroll {
      overflow: hidden;
    }

    @media only screen and (max-width: 991px) {
      width: 100%;
    }

    .main-layout-container {
      display: flex;
      position: relative;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      width: 100%;
      height: 100%;
      @include bg(--main-bg);

      // @media only screen and (min-width: 600px) {
      //   display: grid;
      // }

      .main-content-div {
        scroll-behavior: smooth;
        overflow-x: hidden;
        display: flex;
        flex-direction: column;
        flex-grow: 1;
      }
    }
  }
}

@media only screen and (min-width: 992px) {
  .ub-alert ~ .main-content-section {
    .sidebar-main {
      height: calc(100vh - $alert-height);
    }
  }

  .admin-bar ~ .main-content-section {
    .sidebar-main {
      height: calc(100vh - $admin-bar-height);
    }
  }

  .admin-bar ~ .ub-alert ~ .main-content-section {
    .sidebar-main {
      height: calc(100vh - ($admin-bar-height + $alert-height));
    }
  }

  .footer-player-active .main-content-section {
    .sidebar-main,
    .ub-sidebar {
      height: calc(100vh - $footer-player-height);
    }
  }

  .footer-player-active .ub-alert ~ .main-content-section {
    .sidebar-main {
      height: calc(100vh - ($footer-player-height + $alert-height));
    }
  }

  .footer-player-active .admin-bar ~ .main-content-section {
    .sidebar-main {
      height: calc(100vh - ($footer-player-height + $admin-bar-height));
    }
  }

  .footer-player-active .admin-bar ~ .ub-alert ~ .main-content-section {
    .sidebar-main {
      height: calc(100vh - ($footer-player-height + $admin-bar-height + $alert-height));
    }
  }
}

.sfx-checkmark {
  margin-right: 15px;
  margin-top: -3px;
  max-width: 18px;
}

.sfx {
  font-weight: 500;
}

.home-page-deals-banner {
  padding: 0 3rem 3rem;
  cursor: pointer;

  @media only screen and (max-width: 767px) {
    padding: 0 1.5rem 2rem;
  }

  &:first-child {
    padding: 2rem 3rem 2rem;
  }

  img {
    display: none;
    width: 100%;

    &.home-page-deals-banner-lg {
      @media (min-width: 1500px) {
        display: inline;
        // max-width: 1560px;
      }
    }

    &.home-page-deals-banner-md {
      @media (min-width: 520px) and (max-width: 1499px) {
        display: inline;
        // max-width: 1000px;
      }
    }

    &.home-page-deals-banner-sm {
      @media (max-width: 519px) {
        display: inline;
      }
    }
  }
}
