.modal-signup-container {
    hr {
        border: none;
        border-bottom: 1px solid $gray-light-02;
    }

    // stage-1

    .success-text {
        margin: 0.5rem 0 0.75rem;
    }

    &.modal-container {
        .modal-title {
            font-size: 24px;
            margin-block-start: 1.5rem;
            font-family: $font-family-heading;
            font-weight: normal;
            padding: 0 10px;

            .youtube-secondary-text {
                font-size: 14px;
                color: $gray-medium-03;
            }

            + div {
                margin-block-start: 0.5rem;

                .youtube-copyright-notes {
                    display: flex;
                    font-weight: 500;
                    margin: 10px 0;

                    .item {
                        border-radius: 50%;
                        width: 18px;
                        max-width: 18px;
                        height: 18px;
                        border: 2px solid $red;
                        color: $red;
                        text-align: center;
                        font-size: 10px;
                        margin-right: 4px;
                    }

                    .description {
                        color: $gray-medium-03;
                        flex: 1;
                        font-family: $font-family-secondary;
                        font-weight: normal;
                        font-size: 0.75rem;

                        a {
                            color: $uppbeat;
                            cursor: pointer;
                        }
                    }
                }
            }
        }

        .result-container {
            background: $white;
            list-style: none;
            overflow: hidden;
            padding: 5px;
            margin: 0px 10px;
            box-shadow: 0 5px 10px rgba($black, 0.2);

            li {
                display: flex;
                flex-wrap: wrap;
                border-bottom: 1px solid $gray-light-03;
                cursor: pointer;

                &:hover {
                    background: $gray-light-03;
                }

                &:last-child {
                    border-bottom: none;
                }

                > div:first-child {
                    flex: 0 0 25%;
                    position: relative;
                    width: 100%;
                    padding-inline-end: 0.625rem;
                }

                > div:last-child {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                }
            }

            .d-flex {
                flex-direction: column;
                justify-content: center;
            }

            p {
                margin: 0;

                &:first-child {
                    font-weight: 600;
                    font-size: 12px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    color: $gray-dark-02;
                }

                &:last-child {
                    font-size: 10px;
                    color: $gray-medium-03;
                }
            }
        }

        .social-section-info {
            > div:first-child {
                position: relative;
                width: 60px;
            }

            > div:nth-child(2) {
                position: relative;
                padding: 0 0.625rem 0 1rem;
                flex-grow: 1;
                display: flex;
                align-items: center;
            }

            > div:last-child {
                position: relative;
                width: 3rem;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }

        .alert-general-success {
            margin-block-start: 0.5rem;
        }

        .ub-btn {
            margin-block: 1rem;
        }

        .form-error {
            margin-block-start: 0.5rem;
        }
    }


    .google-icon {
        float: left;
        vertical-align: sub;
    }

    .agreement-check[type=checkbox] {
        margin: 4px 20px 0 0;

        @media only screen and (min-width: 768px) {
            margin: 4px 10px 0 0;
            height: auto;
        }
    }

    // stage-1.5

    .captcha-text {
        text-align: left;
    }

    .tooltip-div {
        right: 10px;

        .tooltip-title {
            font-size: 10px;
        }

        .tooltip-desc {
            width: auto;
        }
    }
}
