.ub-pricing-page {
    .page-title {
        text-align: center;
        align-items: center;
    }

    .ub-pricing-page_duration-toggle {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;

        > div:last-child {
            margin-inline-start: 0.5rem;
            color: $green;
        }

        @media only screen and (min-width: 768px) {
            width: auto;

            > div:last-child {
                margin: unset;
                position: absolute;
                left: calc(100% + 0.5rem);
                top: 50%;
                transform: translateY(-50%);
                white-space: nowrap;
            }
        }
    }

    .ub-pricing-page_plan-cards {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding: 3rem clamp(1.5rem, 3cqw, 3rem) 2rem;

        .ub-pricing-page_plan-cards-inner {
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: 1.5rem;
            margin-block: 2rem;

            @media only screen and (min-width: 768px) {
                max-width: 1200px;
                width: fit-content;
                height: auto;
                position: relative;
                align-items: stretch;
                justify-content: center;
                flex-direction: row;
                gap: 0;
                border-radius: 1rem;
                margin-block: 4rem 3rem;
                @include bg(--pricing-card-bg);
            }
        }
    }

    .ub-pricing-page_cards-asterisk {
        font-size: 0.875rem;
        margin-block-end: 0.5rem;
        text-align: center;

        &:nth-of-type(2) {
            margin-block-end: 2rem;
        }
    }

    .ub-pricing-page_platforms {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: clamp(3rem, 4vw, 5rem) 0;

        > * {
            padding: 0 1.5rem;
        }

        .ub-pricing-page_platforms-carousel {
            display: none;

            @media only screen and (min-width: 768px) {
                display: block;
            }

            .slick-slide {
                .ub-pricing-page_platform-logo {
                    width: 150px;
                    height: 150px;
                    border-radius: 0.75rem;
                    position: relative;
                    @include bg(--pricing-page-section-alt-bg);

                    img {
                        position: absolute;
                        top: 0;
                        right: 0;
                        bottom: 0;
                        left: 0;
                        filter: invert(0.25);
                    }
                }
            }
        }

        > .ub-pricing-page_platform-pills {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;
            gap: 0.5rem;
            padding: 3rem 3rem 0;

            .ub-pricing-page_platform-pill {
                height: 2.5rem;
                padding: 0.5rem 0 0.5rem 0.5rem;
                border-radius: 1.25rem;
                display: flex;
                @include bg(--pricing-page-section-alt-bg);

                > div:first-child {
                    width: 1.5rem;
                    height: 1.5rem;
                    border-radius: 50%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    @include c(--pill-c);

                    svg {
                        width: 1.5rem;
                        fill: $green;
                    }
                }

                > div:last-child {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    padding: 0 1rem 0 0.5rem;
                    font-size: 0.8125rem;
                    @include c(--h1-c)
                }

            }
        }
    }

    .ub-pricing-page_carousel {
        padding: 3rem 0;

        @media only screen and (min-width: 768px) {
            padding: 5rem 0;
        }

        .slick-slide {
            width: 300px;
            height: auto;
        }
    }
    .slick-slide {
        width: 300px;
        height: auto;
    }
    .ub-pricing-page_plan-tables {
        padding: clamp(3rem, 4vw, 5rem) 0;

        .ub-pricing-page_plan-toggle {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            transition: display 0 1s;

            .ub-toggle .ub-toggle-faux-radio .ub-toggle-faux-radio-background {
                transition: background-color 0.2s left 0.2s;

                &.option-0 {
                    background: $free;
                }

                &.option-1 {
                    background: $uppbeat;
                }

                &.option-2 {
                    background: $business;
                }
            }

            @media only screen and (min-width: 768px) {
                max-height: 0;
                visibility: hidden;
                opacity: 0;
                overflow: hidden;
            }
        }

        .ub-table:nth-child(2) {
            margin: 2rem 0 1rem;
        }

        .ub-table:nth-child(3) {
            margin-block-end: 2rem;
        }

        .ub-pricing-page_table-button {
            padding: 0 1.5rem;

            @media only screen and (min-width: 768px) {
                display: none;
            }

            + p {
                text-align: center;
                font-size: 0.875rem;
                padding: 1.5rem 1.5rem 0;
            }
        }
    }

    .ub-pricing-page_trustpilot-widget {
		padding: clamp(3rem, 4vw, 5rem) 1rem;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;

		.trustbox-wrapper {
			justify-content: center;

			.trustpilot-individual-wrapper {
				width: min-content;
			}
		}

		.trustpilot-widget {
			width: 16rem;
		}
	}

    .ub-pricing-plan_accordion {
        padding-block: clamp(3rem, 4vw, 5rem);
    }

    .page-subtitle {
        margin-block-end: 3rem;
        text-align: center;
        font-size: 2rem;
    }
}
