// Browse
//
// Browse styles

$browse-track-width-play: 30px;
$browse-track-width-description: 150px;
$browse-track-width-time: 45px;
$browse-track-width-controls: 90px;
$browse-track-width-controls-md: 140px;

$browse-track-width-padding: 5px;
$browse-track-width-controls-height: 30px;

$browse-track-width-total: $browse-track-width-play + $browse-track-width-description + $browse-track-width-time + $browse-track-width-controls;
$browse-track-width-total-md: $browse-track-width-play + $browse-track-width-description + $browse-track-width-time + $browse-track-width-controls-md;
$browse-track-width-total-adjust-sm: 20px;

$version-line-pos: 22px;

// Content
// ---------------------------------------------------------

body {
    backface-visibility: hidden;
}

$similar-hero-image-size: 164px;
$similar-hero-image-border-radius: 13px;

.browse-title-wrapper {
    .carousel--container-main {
        border-radius: $similar-hero-image-border-radius;
        display: block;
        width: $similar-hero-image-size;
    }
}

.favorite-target,
.add-to-playlist-target {
    width: 18px;

    @media only screen and (min-width: 768px) {
        width: 20px;
    }
}

.browse-title-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    position: relative;

    .artist_avatar_container {
        display: flex;
        position: relative;
        height: 8.75rem;
        min-width: 8.75rem;
        max-width: 8.75rem;
        position: relative;
        background-size: cover;
        border-radius: 50%;
        font-size: 0.8125rem;
        margin-block-end: 2.5rem;
        overflow: hidden;

        &.has-play::after {
            content: '';
            display: block;
            width: 100%;
            height: 100%;
            position: absolute;
            bottom: 0;
            right: 0;
            background-image: linear-gradient(rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0.5) 100%);
        }
        img {
            border-radius: 50%;
        }

        .search-loading-icon {
            padding: 0;
        }
    }

    .similar_avatar_container {
        display: none;
        @extend .artist_avatar_container;
        border-radius: 0;

        @media only screen and (min-width: 768px) {
            display: flex;
        }
    }

    @media only screen and (min-width: 768px) {
        flex-wrap: nowrap;
        justify-content: flex-start;

        .artist_avatar_container {
            margin-block-end: 0;
        }
    }
}

.browse-track__title {
    padding: clamp(1.5rem, 3vw, 3rem) clamp(1.5rem, 3cqw, 3rem) 1.5rem;
    text-align: left;
    font-weight: 500;
    font-size: 1.5rem;
    font-family: var(--font-heading);
    @include c(--browse-tracks-page-title-c);
}

.browse-track-waveform {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100px;
    background: rgba($gray-medium-03, 0.07);
}

.browse-track-icons {
    display: inline-block;

    @media only screen and (min-width: 420px) {
        padding-bottom: 5px;
        padding-left: 10px;
    }

    @media only screen and (min-width: 992px) {
        padding-left: 20px;
    }
}

.waveform-track-container {
    // position: relative;
    height: 56px;
    overflow: hidden;
    border-bottom: 5px solid $gray-light-01;

    .waveform-track {
        // position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        opacity: 0;
        transition: 0.75s;

        &.waveform-ready {
            opacity: 1;
        }
    }
}

// Style
// ---------------------------------------------------------

.browse-track__style {
    display: inline-block;
    transition: 0.2s;
    color: $gray-medium-02;

    &:hover {
        color: $white;
    }
}

.browse-track-hero__icon--play {
    position: absolute;
    top: 86px;
    left: calc(50% + 16px);
    width: 3.375rem;
    height: 3.375rem;
    @include css4 {
        filter: drop-shadow(0 0 8px cvar(--shadow-dark));
    }

    @media only screen and (min-width: 768px) {
        top: 86px;
        left: 86px;
    }
}

.browse-title {
    flex: 0 0 100%;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @media only screen and (min-width: 768px) {
        flex: auto;
        padding: 0;
        text-align: left;
        align-items: flex-start;

        &:not(:first-child) {
            margin-inline-start: 40px;
        }
    }
}

.browse-track__play svg {
    transition: 0.2s;
    fill: $gray-light-01;
}

.browse-track__play:hover svg {
    fill: $white;
}

// Button
// ---------------------------------------------------------

.browse-track-hero__options-btn {
    cursor: pointer;
    outline: none;
    width: 170px;
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 6px;
    padding-right: 6px;
    margin-bottom: 20px;
    border: 2px solid $white;
    border-radius: 25px;
    background: none;
    text-align: center;
    font-size: 13.5px;
    color: $white;
    transition: 0.2s;
    transition: 0.2s;
    opacity: 0.7;

    &:hover {
        border: 2px solid $gray-medium-04;
        background: $gray-medium-04;
        color: $black;
    }

    @media only screen and (min-width: 768px) {
        margin-bottom: 0;
        margin-right: 10px;
    }
}

$btcat-background-color: $gray-dark-02;

// Categories
// ---------------------------------------------------------

.browse-track-categories {
    padding-top: 30px;
    padding-bottom: 10px;
    background: $btcat-background-color;
    text-align: center;

    @media only screen and (min-width: 420px) {
        text-align: left;
    }
}

.browse-track-categories__unwatermarked {
    text-align: center;
    max-width: $browse-track-width-total;
}

.browse-track-categories__item {
    padding-bottom: 20px;
    font-size: 13px;
    color: $gray-medium-02;
}

.browse-track-categories__item-child {
    padding-bottom: 5px;

    @media only screen and (min-width: 420px) {
        padding-bottom: 0;
    }

    &:nth-child(even) {
        .browse-track-categories__version {
            background: $gray-dark-02;
        }
    }
}

.browse-track-categories__item-child--last {
    padding-bottom: 0;
}

.browse-track-categories__label {
    display: inline-block;
    padding-bottom: 5px;
    font-weight: $font-weight-semi-bold;
    font-size: 13px;
    color: $gray-medium-04;

    @media only screen and (min-width: 420px) {
        padding-bottom: 0;
    }
}

.browse-track-categories__label--version {
    display: inline-block;
    padding-bottom: 10px;

    @media only screen and (min-width: 768px) {
        padding-bottom: 0;
    }
}

.browse-track-categories__link {
    text-decoration: underline;
    font-style: italic;
    color: #2e7fa7;
    transition: 0.2s;

    &:hover {
        text-decoration: underline;
        color: lighten(#2e7fa7, 10%);
    }
}

.browse-track-categories__version-play {
    line-height: $browse-track-width-controls-height;
    text-align: center;
    width: $browse-track-width-play;

    a {
        position: absolute;
        top: 50%;
        transform: translate(-5px, -15px);
    }
}

.browse-track-categories__version-description {
    line-height: 1.4;
    padding: 11px 5px !important;
    width: $browse-track-width-description;

    @media only screen and (max-width: 1100px) {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        width: $browse-track-width-description - $browse-track-width-total-adjust-sm;
    }
}

.browse-track-categories__version-time {
    line-height: $browse-track-width-controls-height;
    text-align: center;
    width: $browse-track-width-time;

    span {
        position: absolute;
        top: 50%;
        transform: translate(-13px, -15px);
    }
}

.browse-track-categories__version-controls-outer {
    background: linear-gradient(to right, rgba($btcat-background-color,0) 0%,rgba($btcat-background-color,1) 40%,rgba($btcat-background-color,1) 100%);
    text-align: right;
    height: 40px;
    width: $browse-track-width-controls;

    @media only screen and (min-width: 768px) {
        width: $browse-track-width-controls-md;
    }
}

.browse-track-categories__version {
    display: inline-block;
    position: relative;
    width: 100%;
    max-width: $browse-track-width-total;

    @media only screen and (min-width: 768px) {
        max-width: $browse-track-width-total-md;
    }

    > span {
        padding: $browse-track-width-padding;
        float: left;

        &:last-child {
            float: right;
        }
    }

    @media only screen and (max-width: 1100px) {
        width: $browse-track-width-total-md - $browse-track-width-total-adjust-sm;
    }
}

.browse-track-categories__version-fold {
    max-height: 0px;
    overflow: hidden;
    transition: all 0.2s;

    &.open {
        max-height: 1000px;
        transition: all 1s;
    }
}

.browse-track-categories__fold-toggle {
    background: $gray-dark-02;
    border-radius: 100px;
    cursor: pointer;
    display: inline-block;
    padding: 5px;
    margin-top: 10px;
    margin-right: 2px;
    width: 220px;

    span {
        display: inline-block;
        transform: translateY(0%) rotate(0deg);
        transform-origin: 50% 50%;
        font-size: 14px;
        margin-left: 10px;
        width: 12px;
    }

    &.open span {
        transform: translateY(10%) rotate(-180deg);
    }
}

.browse-track-categories__version-controls {
    display: block;
    position: absolute;
    top: 50%;
    transform: translateY(calc(-#{$browse-track-width-controls-height} / 2));
    right: 5px;

    > span {
        background: $gray-dark-02;
        cursor: pointer;
        height: $browse-track-width-controls-height;
        padding: 5px;
        width: 40px;

        &:nth-child(2) {
            border-bottom-left-radius: 100px;
            border-top-left-radius: 100px;
            border-right: 1px solid $btcat-background-color;
            padding-left: 13px;
        }

        @media only screen and (min-width: 768px) {
            &:first-child {
                border-bottom-left-radius: 100px;
                border-top-left-radius: 100px;
                padding-left: 13px;
            }

            &:nth-child(2) {
                border-radius: 0;
                padding-left: 5px;
                border-left: 1px solid $btcat-background-color;
                border-right: 1px solid $btcat-background-color;
                width: 34px;
            }
        }

        &:last-child {
            border-bottom-right-radius: 100px;
            border-top-right-radius: 100px;
            padding-right: 13px;
        }

        &:hover  svg {
            .st512 {
                fill: $white;
                stroke: $white;
            }

            .st354 {
                stroke: $white;
            }

            .dl-icon-elements {
                color: $white;
            }
        }
    }

    svg {
        .st512 {
            fill: $gray-medium-01;
            stroke: $gray-medium-01;
        }

        .st354 {
            stroke: $gray-medium-01;
        }
    }
}

.browse-track__track-options {
    @media only screen and (min-width: 992px) {
        display: inline-block;
        transition: 0.2s;
        margin-left: 5px;

        .browse-track-categories__favourite {
            margin-right: 5px;
        }

        &:hover span {
            opacity: 1;
        }

        span {
            opacity: 0;
            transition: 0.2s;

            &:hover {
                opacity: 1;
            }

            &.js-is-favourite-version {
                opacity: 1;
            }
        }
    }
}

.browse-track-categories__favourite i svg path {
    transition: 0.2s;
}

.browse-track-categories__favourite i svg:hover path {
    stroke: #ee5874;
}

.browse-track-categories__add i svg {
    transition: 0.2s;

    &:hover {
        stroke: #7ad3ff;
    }
}

.browse-player__category {
    cursor: pointer;
    display: block;
    font-size: 11px;
    color: $gray-medium-01;
    transition: 0.2s;

    a {
        color: $gray-medium-01;
        transition: 0.2s;

        &:hover {
            color: $gray-medium-02;
        }
    }
}

// Browse player versions
// ---------------------------------------------------------
.browse-player-versions {
    opacity: 0;
    visibility: hidden;
    height: 0;
}

.versions-toggle {
    opacity: 1;
    visibility: visible;
    height: auto;
}

.browse-player-versions-track {
    position: relative;
    padding-top: 20px;

    @media only screen and (min-width: 1200px) {
        &:hover::before {
            content: "";
            display: block;
            position: absolute;
            top: 20px;
            left: -9999px;
            width: 100000px;
            height: 100%;
            background: $browse-track-hover-color;
        }
    }
}

@media only screen and (min-width: 420px) {
    .browse-player-versions-track {
        opacity: 0;
        position: relative;
        padding-top: 60px;

        &:first-child {
            margin-top: 20px;
        }
    }

    .browse-player-versions-line-left {
        position: relative;

        &::before {
            content: "";
            position: absolute;
            bottom: 0;
            left: 0;
            width: 1px;
            height: 79px;
            background: $version-connecting-line-color;
        }
    }

    .browse-player-versions-overflow {
        position: relative;
    }

    .browse-player-versions-track:last-child .browse-player-versions-line-left::before {
        bottom: 8px;
    }

    .browse-player__title--line {
        position: relative;
        padding-left: 30px;

        &::after {
            content: "";
            position: absolute;
            bottom: 8px;
            left: 0;
            width: 15px;
            height: 1px;
            background: $version-connecting-line-color;
        }
    }

    .browse-player__duration-line {
        width: 53px;
        padding-right: 20px;

        &::before {
            content: "";
            position: absolute;
            bottom: 0;
            right: 16px;
            width: 1px;
            height: calc(100% + 80px);
            background: $version-connecting-line-color;
        }

        &::after {
            content: "";
            position: absolute;
            bottom: 8px;
            left: 73px;
            width: calc(100% - 90px);
            height: 1px;
            background: $version-connecting-line-color;
        }
    }

    .browse-player-versions-track:last-child .browse-player__duration-line::before {
        bottom: 8px;
    }
}

@media only screen and (min-width: 1200px) {
    .browse-player-versions-track {
        padding-top: 40px;
    }

    .browse-player-versions-line-left {
        &::before {
            height: 59px;
        }
    }
}

@media only screen and (min-width: 1200px) {
    .browse-player__duration-line:before {
        height: calc(100% + 60px);
    }
}

.browse-version__number {
    @media only screen and (min-width: 992px) {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }
}

.browse-player-versions-track__options {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin: 0 auto;
    margin-bottom: 20px;
    width: 100px;
    justify-content: space-between;

    @media only screen and (min-width: 420px) {
        position: absolute;
        bottom: 60%;
        left: calc(0% + 30px);
        transform: translateY(60%);
        padding-right: 10px;
        margin: 0;
        margin-bottom: 0;
    }

    @media only screen and (min-width: 1200px) {
        bottom: -5px;
        right: 100%;
        left: inherit;
        transform: translateY(0%);
        width: 11%;
        padding-right: 10px;
        visibility: hidden;
        opacity: 0;
        transition: 0.2s;
    }

    @media only screen and (min-width: 1440px) {
        bottom: -10px;
    }
}

.browse-player-versions-track:hover .browse-player-versions-track__options {
    @media only screen and (min-width: 1200px) {
        visibility: visible;
        opacity: 1;
    }
}

.browse-player-versions-track__svg {
    cursor: pointer;
    width: 20px;
    height: 20px;
}

.browse-player-versions-track__svg--first {
    width: 30px;
    height: 30px;
    transition: 0.2s;

    &:hover {
        opacity: .8;
    }

    @media only screen and (min-width: 1440px) {
        width: 40px;
        height: 40px;
    }
}

.browse-player-versions-track__svg--add svg {
    transition: 0.2s;

    &:hover {
        stroke: #7ad3ff;
    }
}

.browse-player-versions-track__svg--favourite svg path {
    transition: 0.2s;
}

.browse-player-versions-track__svg--favourite svg:hover path {
    stroke: #ee5874;
}

// Attachments
// ---------------------------------------------------------

.browse-player .browse-player__title--version {
    font-style: italic;
    color: $gray-medium-03;
    cursor: default;
    transition: 0.2s;

    @media only screen and (min-width: 420px) {
        padding-left: 20px;
    }
}

.browse-version-horizontal-line {
    @media only screen and (min-width: 420px) {
        position: relative;
        width: calc(100% - #{$version-line-pos});
        height: 1px;
        background: $version-connecting-line-color;
    }
}

.browse-version-item-height {
    @media only screen and (min-width: 420px) {
        position: relative;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        height: 80px;
    }
}

.version-icon {
    margin-right: 5px;

    @media only screen and (min-width: 992px) {
        margin-right: 8px;
    }

    &:last-child {
        margin-right: 0;
    }
}

.version-icon-play-wrapper {
    flex: 0 0 45px;
    position: relative;
}

.version-icon--play {
    width: 20px;
    height: 20px;
    margin-bottom: 0;
    margin-right: 0;

    @media only screen and (min-width: 420px) {
        margin-bottom: 0;
        width: auto;
        height: auto;
        flex: 0 0 45px;
        width: 45px;
    }
    @media only screen and (min-width: 768px) {
        width: 45px;
        height: 45px;
    }
}

.version-icon--secondary {
    cursor: pointer;
}

.version-icon--secondary:first-child svg path:first-of-type {
    fill: #6db2cb;
}

.version-icon--secondary:last-child svg path:first-of-type {
    stroke: $fav-icon;
}

.icon-fav {
    display: none;
}

.footer-player__icon--favourite .js-is-favourite-version,
.browse-title-options .btn-favorite.js-is-favourite-version {
    opacity: 1;
    visibility: visible;

    .icon-no-fav {
        display: none;
    }

    .icon-fav {
        display: inline;
    }
}

// Titles
// ---------------------------------------------------------

.hero__main--secondary {
    text-align: center;
}

.hero__intro--secondary {
    display: inline-block;
}

.browse-parent__style {
    margin: 0;
}

.browse-parent__style a {
    position: relative;
    font-weight: $font-weight-medium;
    font-size: 15px;
    opacity: 0.7;
    transition: opacity 0.2s;
    color: $white;

    &:hover {
        opacity: 1;
    }
}

.browse-parent__style-line {
    content: "";
    display: block;
    width: 100%;
    height: 1px;
    background: transparentize($white, .8);
    margin-bottom: 10px;
}

.browse-player-versions__duration {
    user-select: none;
}

.browse-player__tracks {
    padding: 0;

    @media only screen and (min-width: 450px) {
        padding-inline: clamp(0.75rem, 1.5cqw, 1.5rem);
        display: flex;
        flex-direction: column;
        gap: 0.25rem;
	}

    &.fetching {
        min-height: 80vh;
    }

    + .ub-btn {
        margin: 1rem 0 2rem;
        transform: translateX(-50%);
        left: 50%;
    }
}

.track-icon-play-state,
.track-icon-pause-state,
.track-icon-load-state,
.version-track-icon-play-state,
.version-track-icon-pause-state,
.version-track-icon-load-state {
    @media only screen and (min-width: 992px) {
        opacity: 0;
    }
}

.is-safari {
    .track-icon-play-state,
    .track-icon-pause-state,
    .track-icon-load-state,
    .version-track-icon-play-state,
    .version-track-icon-pause-state,
    .version-track-icon-load-state {
        svg {
            /* Fix for a Safari painting issue */
            transform: translateZ(0);
        }
    }
}

.browse-latest {
    display: flex;
    flex-direction: column;

    @media only screen and (min-width: 992px) {
        display: block;
    }
}
