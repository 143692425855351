.side-drawer-wrapper {
    width: 100%;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    height: 100%;
    max-height: -webkit-fill-available;
    z-index: 9999;
    // backdrop-filter: blur(10px);
    @include bg(--modal-overlay-bg);

    .side-drawer-main {
        width: 100%;
        position: relative;
        top: 0;
        right: 0;
        margin-left: auto;
        height: 100%;
        max-height: -webkit-fill-available;
        overflow: hidden;

        @media only screen and (min-width: 768px) {
            max-width: 340px;
        }

        > div {
            height: 100%;
        }
    }

    .side-drawer-section {
        min-height: 100%;
        height: 100%;
        max-height: -webkit-fill-available;
        padding: 1.5rem;
        overflow-x: hidden;

        @media only screen and (min-width: 768px) {
            padding: 1.5rem 1.5rem 6rem;
        }

        .side-drawer-header-section {
            display: flex;
            justify-content: flex-end;
            margin-bottom: 5px;

            .side-drawer-close-btn {
                cursor: pointer;
            }
        }

        p .premium {
            color: $uppbeat;
        }
    }
}
