dialog.modalContainer {
	border: none;
	padding: 0;
	background-color: initial;
	overflow: visible;

	max-width: initial;
	max-height: initial;
}
.modalContent {
	animation: loadin 0.2s ease-in-out forwards;
}
@keyframes loadin{
	0%{
		opacity:0;
		transform: scale(0.97);
  }
  100%{
		opacity:1;
		transform: scale(1);
  }
}



dialog.modalContainer::backdrop {
	position: absolute;
	top: 0;
	left: 0;
	background: rgba(0,0,0,0.4);
	width: 100%;
	height: 100%;
	z-index: -1;
	backdrop-filter: blur(0px);
}


dialog.modalContainer[open]::backdrop {
	opacity: 1;
	backdrop-filter: blur(10px);
}


dialog.modalContainer::backdrop, .closeButton {
	animation: fadein 0.2s ease forwards;
}

@keyframes fadein{
	0%{
		opacity:0;
  }
  100%{
		opacity:1;
  }
}

.closeButton {
	position: absolute;
	top: -50px;
	right: 0;
}
