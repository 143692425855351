.videoContainer {
  aspect-ratio: 16 / 9;
}

.videoContainer.isModal {
  width: 100vw;
  max-height: 70vh;
  border-radius: 0px;
}

.videoContainer > iframe {
  height: 100%;
  width: 100%;
  background-color: black;
}

.videoLength {
  padding: 7px 8px;
  position: absolute;
  bottom: 0;
  right: 0;
  border-radius: 6px;
  background-color: var(--button-contrast-bg);
  color: var(--button-contrast-c);
  font-size: 0.75rem;
  font-weight: 450;
  margin: 0.5rem;
  padding: 0.2rem 0.5rem;
  border-radius: 0.5rem;
}

.facadeContainer {
  position: relative;
  cursor: pointer;
  height: 100%;
}

.imageContainer {
  background: #000;
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
}

.image {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 102%;
  height: 102%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 0;
  opacity: 0.8;
}

.image::before {
  content: '';
  width: 100%;
  position: absolute;
  top: 0;
  pointer-events: none;
  z-index: 25;
  background-position: top;
  height: 100px;
  background-repeat: repeat-x;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAADGCAYAAAAT+OqFAAAAdklEQVQoz42QQQ7AIAgEF/T/D+kbq/RWAlnQyyazA4aoAB4FsBSA/bFjuF1EOL7VbrIrBuusmrt4ZZORfb6ehbWdnRHEIiITaEUKa5EJqUakRSaEYBJSCY2dEstQY7AuxahwXFrvZmWl2rh4JZ07z9dLtesfNj5q0FU3A5ObbwAAAABJRU5ErkJggg==);
  transition: opacity 0.25s cubic-bezier(0, 0, 0.2, 1);
}

.videoTitle {
  font-family: var(--font-family-secondary);
  position: absolute;
  top: 20px;
  left: 20px;
  padding-bottom: 10px;
  max-width: 90%;
  color: white;
  font-size: 1rem;
  z-index: 2;
  font-weight: 500;
  overflow: hidden;

  white-space: nowrap;
  text-overflow: ellipsis;
}

.playButton {
  color: #151919;
  width: 48px;
  height: 48px;
  z-index: 2;
  cursor: pointer;

  position: absolute;
  top: 50%;
  left: 50%;
  background: white;
  transform: translate(-50%, -50%);
  border-radius: 100%;

  transition: transform 0.4s cubic-bezier(0.16, 1, 0.3, 1);
}

.playButton::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 125%;
  height: 125%;
  transform: translate(-50%, -50%);
  background: rgba(255, 255, 255, 0.3);
  border-radius: 100%;
  z-index: -1;
}

.videoContainer:hover .playButton {
  transform: translate(-50%, -50%) scale(1.05);
}

.playButton > svg {
  width: 1.8rem;
  height: 100%;
}

@media only screen and (min-width: 500px) {
  .videoContainer.isModal {
    width: 90vw;
    border-radius: 16px;
  }
}

@media only screen and (min-width: 768px) {
  .videoContainer.isModal {
    width: 70vw;
  }
}
@media only screen and (min-width: 1200px) {
  .videoContainer.isModal {
    max-height: initial;
  }
}
