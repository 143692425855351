// Add to playlist - Tooltip
// ---------------------------------------------------------
.playlist-success_tooltip {
    cursor: default;
    display: flex;
    flex-direction: row;
    justify-content: stretch;
    right: -20rem;
    top: 5.25rem;
    position: fixed;
    width: 300px;
    border-radius: 0.875rem;
    padding: 1rem;
    z-index: 500;
    animation: playlist-notification 4000ms ease 1 forwards;
    @include bg(--playlist-notification-bg);
    @include css4 {
        box-shadow: 0 0 8px cvar(--shadow-dark);
    }

    @media only screen and (min-width: 1280px) {
        top: 6rem;
    }

    @keyframes playlist-notification {
        0% {
            transform: translate(0, 0);
        };
        12% {
            transform: translate(-21.25rem, 0);
        };
        88% {
            transform: translate(-21.25rem, 0);
        };
        100% {
            transform: translate(0, 0);
        }
    }

    .playlist-success_left {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0.5rem;
        height: 100px;
        width: 100px;

        img {
            border-radius: 50%;
            @include css4 {
                border: 0.5rem solid cvar(--playlist-notification-br);
            }
        }
    }

    .playlist-success_right {
        display: flex;
        align-items: center;
        flex-grow: 1;
        padding: 0.5rem;

        .playlist-success_right--inner {
            width: 100%;
        }

        h3 {
            font-size: 1rem;
            font-weight: 600;
            padding-block-end: 1rem;
            margin-bottom: 1rem;
            line-height: 1.2;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            @include c(--playlist-notification-c);
            @include css4 {
                border-bottom: 1px solid cvar(--playlist-notification-br);
            }
        }

        p {
            font-size: 0.8125rem;
            line-height: 1.2;
            margin: 0;
            font-weight: 600;
        }

        .playlist-success_added {
            margin-block-end: 0.5rem;
            @include c(--playlist-notification-c);
        }

        .playlist-success_playlist {
            font-style: italic;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            @include c(--playlist-notification-c);
        }
    }
}