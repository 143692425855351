// Footer
//
// Site footer

.footer {
	display: flex;
	flex-direction: column;
	margin-block-start: auto;
	width: 100%;

	.clamp {
		padding: clamp(1.5rem, 3cqw, 3rem);
	}

	.footer-top {
		display: flex;
		align-items: stretch;
		justify-content: space-between;
		padding: clamp(1.5rem, 3cqw, 3rem);
		@include css4 {
			border-top: 1px solid cvar(--ub-border-light);
			border-bottom: 1px solid cvar(--ub-border-light);
		}

		.trustpilot-widget {
			width: 100%;
			max-width: 600px;
			margin-inline-start: -1.5rem;
		}

		.footer-tag-line {
			white-space: nowrap;
			font-family: $font-family-heading;
			font-size: 2rem;
			font-weight: 600;
			@include c(--footer-title-c);
		}
	}

	.footer-bottom {
		display: flex;
		justify-content: space-between;
		align-items: stretch;
		padding: clamp(1.5rem, 3cqw, 3rem);

		.footer-box {
			min-width: 276px;
			width: 25%;
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			padding-inline-end: 1rem;

			.footer-link {
				text-align: right;
				margin-block-end: 0.5rem;
				font-size: 0.875rem;
				font-weight: 600;
				font-family: $font-family-primary;
				display: block;
				@include c(--footer-link-alt-c);

				&:last-child {
					margin: 0;
				}

				a {
					@include c(--footer-link-c);
				}
			}

			&.large {
				width: 50%;
				align-items: flex-end;
				padding-inline-end: 0;
			}

			.footer-logo {
				margin-block-end: 1rem;
				min-height: 30px;
				width: 150px;

				@media only screen and (min-width: 992px) {
					height: 50px;
				}

				.colour-a {
					@include f(--footer-uppbeat-logo-c);
				}

				.colour-b {
					@include f(--footer-uppbeat-logo-alt-c);
				}

				.colour-c {
					@include f(--footer-uppbeat-logo-third-c);
				}
			}

			.footer-text {
				font-size: 0.875rem;
				line-height: 1.375;
				margin-block-end: 1rem;
				font-family: $font-family-secondary;
			}
		}
	}

	.footer-social-icon {
		display: flex;
		align-items: center;
		justify-content: center;
		max-width: 1.25rem;
		max-height: 1.25rem;
		margin-inline-start: 1.25rem;

		div, svg {
			display: flex;
			align-items: center;
			justify-content: center;
			max-width: 1.25rem;
			max-height: 1.25rem;
			transition: filter 0.2s;
		}

		&:hover svg {
			filter: brightness(0.75);
		}

		&:first-child {
			margin-inline-start: 0;

			a {
				margin-block-end: -1px;
			}
		}
	}


	.footer-copyright {
		text-align: center;
		font-size: 0.75rem;
	}

	@media only screen and (max-width: 80rem) {
		.footer-top {
			flex-direction: column-reverse;
			padding: 2rem 1.5rem;

			.trustpilot-widget {
				margin: 0 auto;
			}

			.footer-tag-line {
				font-size: 1.5rem;
				margin-block-end: 1.75rem;
				text-align: center;
			}
		}

		.footer-bottom {
			width: 100%;
			flex-direction: column;
			justify-content: stretch;
			align-items: center;
			padding: 1.5rem 0;

			.footer-box {
				width: 100%;
				max-width: 500px;
				align-items: center;
				padding: 0 1.5rem;

				.footer-text {
					text-align: center;
					margin-block-end: 1rem;
				}

				&:not(.large) .footer-link {
					margin-block-end: 1.5rem;
				}

				&.large {
					width: 100%;
					max-width: 100%;
					align-items: center;
					padding: 1.5rem 0 0;
					@include css4 {
						border-top: 1px solid cvar(--ub-border-light);
					}

					.footer-link {
						text-align: center;
					}
				}

				.footer-copyright {
					padding: 0 1.5rem;
					text-align: center;
				}
			}
		}
	}
}
