.sayThanksTitle {
  font-size: 24px !important;
  font-weight: bold;
  text-align: center;
  padding-inline-end: 0 !important;
}

.sayThanksBody {
  font-size: 16px;
  text-align: center;
  text-wrap: balance;
}

.SelectTrigger {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  padding: 0 12px;
  font-size: 16px;
  line-height: 1;
  height: 32px;
  gap: 5px;
  color: var(--button-secondary-c);
  background: var(--button-secondary-bg);
  width: 200px;
  margin: 0 auto;
  font-weight: 400;
  text-align: center;

  :global(.theme-dark) & {
    background: var(--card-bg);
  }

  > span {
    display: flex;
    align-items: center;
    gap: 5px;
    flex-grow: 1;
    width: 100%;

    > span {
      flex-grow: 1;
      margin-right: 8px;
    }
  }
}

.SelectTrigger:focus {
  outline: none;
}
.SelectTrigger:focus-visible {
  box-shadow: 0 0 0 2px black;
}
.SelectTrigger[data-placeholder] {
  color: #a0a0a0;
}

.SelectIcon {
  margin-left: auto;
  width: 16px;
  height: 16px;
}

.SelectContent {
  background-color: var(--card-bg);
  color: var(--card-primary-c);
  border-radius: 6px;
  box-shadow: 0px 10px 38px -10px rgba(22, 23, 24, 0.35),
    0px 10px 20px -15px rgba(22, 23, 24, 0.2);
  z-index: 1000;
  border: 1px solid var(--popup-outer-br);
  overflow: hidden;
  width: 300px;
  max-height: var(--radix-select-content-available-height);
  transform-origin: top center;
  animation: contentShow 150ms cubic-bezier(0.16, 1, 0.3, 1);

  :global(.theme-dark) & {
    box-shadow: 0px 10px 38px -10px rgba(0, 0, 0, 0.8),
      0px 10px 20px -15px rgba(0, 0, 0, 0.4);
  }
}

@keyframes contentShow {
  from {
    opacity: 0;
    transform: translateY(2%) scale(0);
  }
  to {
    opacity: 1;
    transform: translateY(0) scale(1);
  }
}

.SelectViewport {
  padding: 14px;
}

.SelectGroup {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-rows: auto;
  gap: 10px;
}

.SelectItem {
  font-size: 16px;
  line-height: 20px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  padding: 7px 14px;
  position: relative;
  user-select: none;
  font-weight: 400;
  cursor: pointer;
  transition: background-color 0.1s ease-in-out, color 0.1s ease-in-out;

  &:hover {
    background-color: var(--button-secondary-bg);
  }

  > span {
    width: 100%;
    display: flex;

    > span:first-child {
      flex-shrink: 0;
    }

    > span:last-child {
      flex-grow: 1;
      text-align: center;
    }
  }
}

.SelectItem[data-state='checked'] {
  background-color: var(--button-uppbeat-light-bg);
  color: var(--button-uppbeat-light-c);
}

.SelectItemText {
  display: flex;
  align-items: center;
  gap: 10px;
  flex: 1;
}

.SelectItem[data-disabled] {
  pointer-events: none;
}

.SelectItem[data-highlighted] {
  outline: none;
}

.SelectLabel {
  padding: 0 25px;
  font-size: 12px;
  line-height: 25px;
}

.SelectSeparator {
  height: 1px;
  margin: 5px;
}

.SelectItemIndicator {
  position: absolute;
  left: 0;
  width: 25px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.SelectScrollButton {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 25px;
  background-color: var(--card-bg);
  cursor: default;
}

.SelectSocialIcon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  text-align: center;
  line-height: 20px;
  margin-right: 8px;
}

.SelectArrow {
  color: var(--card-bg);
  width: 16px;
  height: 14px;
  transform: translateY(-1.5px);
}

.warningContainer {
  z-index: 999;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  backdrop-filter: blur(5px);
  background-color: rgba(0, 0, 0, 0.05);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
}

.warningContent {
  background-color: var(--card-bg);
  padding: 1rem;
  text-align: center;
  border-radius: 0.5rem;
  max-width: 300px;
}

.warningTitle {
  text-align: center;
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 8px;
}

.warningBody {
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  text-wrap: balance;
  line-height: normal;
  margin-bottom: 12px;
}

.warningActions {
  display: flex;
  justify-content: center;
  gap: 1rem;
}
