// Hero
//
// Hero mixin

#general-page .hero-blog:not(.blank) {
    @media only screen and (min-width: 768px) {
        margin-bottom: 80px;
    }

}

@mixin heroAlternate($background, $skipUrl: false) {
    position: relative;

    @if $skipUrl == true {
        background: $background;
    } @else {
        background: url($background) no-repeat;
    }

    background-size: cover;
    background-position: center bottom;
    margin-bottom: 20px;

    @media only screen and (min-width: 768px) {
        min-height: 320px;
        height: 575px;
        margin-bottom: 20px;
    }

    &-content {
        padding: 15vh 10px;
        text-align: center;

        @media only screen and (min-width: 768px) {
            bottom: 50%;
            padding: 0;
            position: absolute;
            right: 50%;
            transform: translate(50%, 50%);
            width: 80%;
        }
    }

    &-title {
        font-family: $font-family-heading;
        font-weight: 400;
        font-size: 25px;
        margin-bottom: 0;

        @media only screen and (min-width: 768px) {
            font-size: 50px;
        }
    }
}
