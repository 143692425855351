.ub-pricing-card {
    width: 100%;
    height: auto;
    border-radius: 0.75rem;
    display: flex;
    flex-direction: column;
    position: relative;
	@include bg(--pricing-card-bg);
    @include css4 {
        border: 1px solid cvar(--pricing-card-br)
    }

    .ub-pricing-card_image {
        display: none;
        width: 100%;
        height: 8rem;
        border-radius: 0.75rem 0.75rem 0 0;
        overflow: hidden;
        position: relative;
    }

    &:not(.premium) {
        .ub-pricing-card_image {
            border-radius: 0 0.75rem 0 0;
        }
    }

    .ub-pricing-card_inner {
        padding: 2.5rem 1.75rem;
        position: relative;

        .ub-pricing-card_top-details {
            display: flex;
            justify-content: space-between;

            > span {
                h2 {
                    font-size: 1.75rem;
                    white-space: nowrap;
                    margin-block-end: 1rem;

                    img {
                        display: inline-block;
                        margin-block-start: -0.25rem;
                        margin-inline-start: 0.5rem;
                        width: 1.5rem;
                    }
                }

                p {
                    display: none;
                    font-size: 0.875rem;
                    font-family: $font-family-secondary;
                    margin-block-end: 2rem;
                    @include c(--pricing-card-c);
                }
            }

            .ub-pricing-card_price {
                margin-block-end: 2rem;
                position: relative;
                display: flex;
                align-items: baseline;
                white-space: nowrap;

                .ub-pricing-card_price-from {
                    position: absolute;
                    font-family: $font-family-secondary;
                    font-size: 0.75rem;
                    top: -0.25rem;
                    left: 0;
                    @include c(--pricing-card-c);
                }

                .ub-pricing-card_price-currency {
                    font-family: $font-family-heading;
                    font-size: 2rem;
                    line-height: 1;
                    @include c(--h1-c);
                }

                .ub-pricing-card_price-value {
                    font-family: $font-family-heading;
                    line-height: 1;
                    display: flex;
                    @include c(--h1-c);

                    .ub-pricing-card_price-integer {
                        font-size: 4rem;
                    }

                    .ub-pricing-card_price-decimal {
                        font-size: 2rem;
                        padding-block-start: 0.25rem;
                    }

                    + .ub-pricing-card_price-currency {
                        align-self: start;
                        padding-block-start: 0.25rem;
                    }
                }

                .ub-pricing-card_price-duration {
                    font-family: $font-family-secondary;
                    font-size: 0.75rem;
                    position: absolute;
                    bottom: 0.25rem;
                    right: 0;
                    @include c(--pricing-card-c);
                }

                .ub-pricing-card_price-asterisk {
                    position: absolute;
                    font-family: $font-family-secondary;
                    font-size: 0.75rem;
                    right: 0;
                    white-space: nowrap;
                    bottom: -1.125rem;
                    @include c(--pricing-card-c);
                }
            }
        }

        > .ub-btn {
            margin-block-end: 2rem;
        }

        > h4 {
            margin-block-end: 1rem;
            @include c(--h2-c);
        }

        ul {
            list-style: none;

            .ub-pricing-card_bullet {
                display: flex;

                &.positive {
                    svg {
                        margin-block-start: 0.375rem;
                        margin-inline-end: 0.5rem;

                        path {
                            @include f(--pricing-card-tick-fill);
                        }
                    }
                }

                &.negative {
                    svg {
                        margin-block-start: 0.25rem;
                        margin-inline-end: 0.5rem;
                        opacity: 0.5;

                        rect {
                            @include f(--pricing-card-cross-fill);
                        }
                    }

                    span {
                        opacity: 0.5;
                    }
                }

                &:not(:last-child) {
                    margin-block-end: 0.5rem;
                }

                div {
                    @include c(--pricing-card-c);

                    > span {
                        display: flex;
                        flex-direction: column;
                        font-size: 0.875rem;
                        font-family: $font-family-secondary;

                        span {
                            font-size: 0.6125rem;
                        }
                    }
                }
            }
        }

        .ub-skeleton {
            height: 80px;
            width: 40%;
            margin-block-end: 1rem;
        }
    }

    .ub-pricing-card_tag {
        font-size: 1rem;
        font-weight: 500;
        white-space: nowrap;
        padding: 2.5rem 1.75rem 0;
        line-height: 1;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        z-index: 1;
        @include c(--pricing-card-mobile-tag-c);

        img {
            margin-inline-end: 0.5rem;
            width: 1.5rem;
        }
    }

    &.premium {
        order: -1;

        .ub-pricing-card_inner {
            padding: 1rem 1.75rem 2.5rem;
        }
    }

    @media only screen and (min-width: 420px) {
        .ub-pricing-card_inner .ub-pricing-card_top-details {
            > span h2 {
                font-size: 2.5rem;

                img {
                    width: 2rem;
                }
            }
        }
    }

    @media only screen and (min-width: 550px) {
        .ub-pricing-card_tag {
            position: absolute;
            padding: 0 0.75rem;
            border-radius: 0.75rem;
            height: 2rem;
            display: flex;
            justify-content: center;
            align-items: center;
            top: -1rem;
            right: 1.75rem;
            left: unset;
            transform: unset;
            @include c(--pricing-card-tag-c);
            @include bg(--pricing-card-tag-bg);
        }

        .ub-pricing-card_inner {
            .ub-pricing-card_top-details {
                display: flex;
                justify-content: space-between;
                align-items: flex-end;

                > span {
                    max-width: 75%;

                    p {
                        display: block;
                        font-size: 0.875rem;
                        font-family: $font-family-secondary;
                        margin-block-end: 2rem;
                    }
                }

                .ub-pricing-card_price {
                    margin-block-end: 2rem;
                    position: relative;
                }
            }
        }
    }

    @media only screen and (min-width: 768px) {
        width: 100%;
        max-width: 24rem;
        border: none;

        .ub-pricing-card_tag {
            padding: 0 0.75rem;
            left: 50%;
            right: unset;
            font-size: 0.875rem;
            transform: translateX(-50%);
            @include css4 {
                box-shadow: 0 0.5rem 0.5rem rgba($black, 0.4);
            }
        }

        .ub-pricing-card_inner,
        &.premium .ub-pricing-card_inner {
            padding: 2rem 1.5rem;
        }

        &.free {
            overflow: hidden;
            border-radius: 0.75rem 0 0 0.75rem;

            .ub-pricing-card_image img {
                object-position: right;
            }

            .ub-pricing-card_inner {
                padding: 2rem 2rem 2rem 1.5rem;
            }
        }

        &.business {
            overflow: hidden;
            border-radius: 0 0.75rem 0.75rem 0;

            .ub-pricing-card_image img {
                object-position: left;
            }

            .ub-pricing-card_inner {
                padding: 2rem 1.5rem 2rem 2rem;
            }
        }

        &.premium {
            order: unset;
            transform: scale(1.05);
            z-index: 1;
            @include bg(--pricing-card-premium-bg);
            @include css4 {
                box-shadow: 0 0 0 0.5rem cvar(--pricing-card-premium-br);
            }

            .ub-pricing-card_image img {
                object-position: center;
            }
        }

        .ub-pricing-card_image {
            display: block;

            img {
                display: block;
                height: 100%;
                width: 100%;
                object-fit: cover;
            }
        }

        .ub-pricing-card_inner {
            .ub-pricing-card_top-details {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;

                > span {
                    max-width: 100%;

                    h2 {
                        text-align: center;
                        font-size: 2rem;

                        img {
                            width: 1.5rem;
                        }
                    }

                    p {
                        text-align: center;
                        font-size: 0.75rem;
                    }
                }

                .ub-pricing-card_price .ub-pricing-card_price-asterisk {
                    right: unset;
                    left: 50%;
                    transform: translateX(-50%);
                }
            }

            > h4 {
                font-size: 0.875rem;
            }

            > ul .ub-pricing-card_bullet div > span {
                font-size: 0.75rem;
            }

            .ub-skeleton {
                width: 40%;
                margin-block-end: 1rem;
            }
        }
    }

    @media only screen and (min-width: 1400px) {
        .ub-pricing-card_tag {
            padding: 0 1rem;
            font-size: 1rem;
        }

        .ub-pricing-card_inner,
        &.premium .ub-pricing-card_inner {
            padding: 2.5rem 2rem;

            .ub-pricing-card_top-details > span {
                > h2 {
                    font-size: 2.5rem;

                    img {
                        width: 2rem;
                    }
                }

                > p {
                    font-size: 0.875rem !important;
                }
            }

            > ul .ub-pricing-card_bullet div > span {
                font-size: 0.875rem;
            }

            h4 {
                font-size: 1rem;
            }
        }

        &.free {
            .ub-pricing-card_inner {
                padding: 2.5rem 2.5rem 2.5rem 2rem;
            }
        }

        &.business {
            .ub-pricing-card_inner {
                padding: 2.5rem 2rem 2.5rem 2.5rem;
            }
        }
    }
}
