.jumbotron {
    &.__gradient-secondary {
        background-image: linear-gradient(to bottom, $gray-light-03, $white);
    }

    &.__premium {
        background-color: $business;
    }

    &.__cup {
        position: relative;

        &::after {
            content: '';
            position: absolute;
            top: 25px;
            right: 0;
            width: 67px;
            height: 85px;
            background-image: url('../../svgs/cup.svg');
        }
    }

    &.__congratulation {
        position: relative;

        &::after {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            width: 100px;
            height: 98px;
            background-image: url('../../svgs/congratulation.svg');
        }
    }
}
